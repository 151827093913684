import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgrammeDetailsView from '../component/ProgrammeDetailsView';
import { programmesSelectors, programmesActions } from '../../../../../../state/ducks/programmes';
import { sessionsSelectors, sessionsActions } from '../../../../../../state/ducks/sessions';
import { getDataFeedById } from '../../../../../../state/ducks/dataFeeds/selectors';
import { safeGet } from '../../../../../../utils';

const { getCurrentTheatre } = programmesSelectors;
const { fetchChangeTheatreName, changeTheatreImage, fetchChangeTheatreOptions, fetchChangeTheatreHTMLTitle } = programmesActions;
const { getSessionList, getSessionListByDate } = sessionsSelectors;
const { fetchRemoveSession, fetchRemoveSessionsByDate } = sessionsActions;

class ProgrammeDetailsViewContainer extends Component {
  constructor(props) {
    super(props);
    this.titleNameField = React.createRef();
    this.htmlTitleField = React.createRef();
  }

  handleChangeTheatreImage = image => {
    const {
      currentTheatre: { id },
    } = this.props;
    this.props.changeTheatreImage(image, id);
  };

  handleChangeNameOfProgramme = event => {
    const { value } = event.target;
    const {
      changeTheatreName,
      currentTheatre: { id },
    } = this.props;
    const removeExtraSpaces = value.replace(/\s+/g, ' ').trim();
    if (removeExtraSpaces.length !== 0) {
      changeTheatreName(removeExtraSpaces, id);
      this.titleNameField.current.getRenderedComponent().inputRef.current.blur();
    }
  };

  handleChangeHTMLTitle = event => {
    const { value } = event.target;
    const {
      changeTheatreHTMLTitle,
      currentTheatre: { id, title },
    } = this.props;
    const removeExtraSpaces = value.replace(/\s+/g, ' ').trim();
    if (removeExtraSpaces.length !== 0) {
      changeTheatreHTMLTitle(removeExtraSpaces, id);
      this.htmlTitleField.current.getRenderedComponent().inputRef.current.blur();
    } else if (removeExtraSpaces.length === 0) {
      changeTheatreHTMLTitle(title, id);
      this.htmlTitleField.current.getRenderedComponent().inputRef.current.blur();
    }
  };

  handleProgrammeOptionsChange = options => {
    const {
      currentTheatre: { id },
    } = this.props;
    console.log(options);
    this.props.changeTheatreOptions(JSON.stringify(options), id);
  };

  handleRemoveSession = id => {
    const { removeSession } = this.props;
    removeSession(id);
  };

  handleEditSession = id => {
    const { handleOpenSessionModal } = this.props;
    handleOpenSessionModal('edit', id);
  };

  handleAddSessionToDate = date => {
    const {
      handleOpenSessionModal,
      currentTheatre: { id },
    } = this.props;
    handleOpenSessionModal('new', id, date);
  };

  handleRemoveSessionByDate = date => {
    const {
      removeSessionsByDate,
      currentTheatre: { id },
    } = this.props;
    removeSessionsByDate(id, date);
  };

  render() {
    const {
      handleOpenSessionModal,
      currentTheatre,
      sortedSessionList,
      sessionRemovalList,
      dataFeedOfThisProgramme,
      backRoute,
    } = this.props;

    return (
      <ProgrammeDetailsView
        refTitleName={this.titleNameField}
        refHTMLTitle={this.htmlTitleField}
        handleOpenSessionModal={handleOpenSessionModal}
        handleAddSessionToDate={this.handleAddSessionToDate}
        handleRemoveSessionByDate={this.handleRemoveSessionByDate}
        sessionList={sortedSessionList}
        theatre={currentTheatre}
        handleRemoveSession={this.handleRemoveSession}
        handleEditSession={this.handleEditSession}
        sessionRemovalList={sessionRemovalList}
        handleChangeName={this.handleChangeNameOfProgramme}
        handleChangeHTMLTitle={this.handleChangeHTMLTitle}
        handleOptionsChange={this.handleProgrammeOptionsChange}
        onChangeTheatreImage={this.handleChangeTheatreImage}
        dataFeedOfThisProgramme={dataFeedOfThisProgramme}
        backRoute={backRoute}
      />
    );
  }
}

ProgrammeDetailsViewContainer.propTypes = {
  handleOpenSessionModal: PropTypes.func.isRequired,
  currentTheatre: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    options: PropTypes.shape({
      sync: PropTypes.bool.isRequired,
    }),
    ownerId: PropTypes.number.isRequired,
  }),
  changeTheatreName: PropTypes.func.isRequired,
  changeTheatreHTMLTitle: PropTypes.func.isRequired,
  changeTheatreOptions: PropTypes.func.isRequired,
  sortedSessionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      planingDay: PropTypes.string.isRequired,
      sessionListMatches: PropTypes.arrayOf(
        PropTypes.shape({
          day: PropTypes.string.isRequired,
          description: PropTypes.string,
          start: PropTypes.string.isRequired,
          end: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
          programmeId: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  changeTheatreImage: PropTypes.func.isRequired,
  removeSession: PropTypes.func.isRequired,
  sessionRemovalList: PropTypes.arrayOf(PropTypes.number).isRequired,
  removeSessionsByDate: PropTypes.func.isRequired,
  dataFeedOfThisProgramme: PropTypes.object,
  backRoute: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  const currentProgramme = getCurrentTheatre(state, ownProps); // getCurrentTheatre is wrong naming
  return {
    currentTheatre: currentProgramme, // currentTheatre is wrong name
    sessionList: getSessionList(state, ownProps),
    sortedSessionList: getSessionListByDate(state, ownProps),
    sessionRemovalList: state.sessions.sessionRemovalList,
    dataFeedOfThisProgramme: getDataFeedById(state, safeGet(() => currentProgramme.dataFeedId, {})),
  };
};

const mapDispatchToProps = {
  changeTheatreName: fetchChangeTheatreName,
  changeTheatreHTMLTitle: fetchChangeTheatreHTMLTitle,
  removeSession: fetchRemoveSession,
  removeSessionsByDate: fetchRemoveSessionsByDate,
  changeTheatreImage,
  changeTheatreOptions: fetchChangeTheatreOptions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgrammeDetailsViewContainer);
