import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './ScreenSizeCard.module.scss';

import { getScreenDimensions, getScreenOrientationIcon } from '../../../../../utils';
import Icons from '../../../../common/components/UI/Icons';

function ScreenSizeCard({ className, id, screenSizes, onEdit }) {
  const screen = screenSizes.find(sc => sc.id === id);
  if (!screen) {
    // eslint-disable-next-line
    console.warn('ScreenSizeCard: screenSize was not found');
    return null;
  }

  return (
    <div className={cn(styles.container, className && className)}>
      {onEdit && (
        <button className={styles.editIcon} onClick={onEdit}>
          <Icons icon="editIcon" widthIcons={16} />
        </button>
      )}
      {getScreenOrientationIcon(screen.id, screenSizes, { size: 40, color: '#8A8A8F' })}
      <div className={styles.textContainer}>
        <span className={styles.title}>{screen.name}</span>
        <span className={styles.subtitle}>{getScreenDimensions(screen.resolutionWidth, screen.resolutionHeight)}</span>
      </div>
    </div>
  );
}

const screenShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  resolutionWidth: PropTypes.number.isRequired,
  resolutionHeight: PropTypes.number.isRequired,
});

ScreenSizeCard.defaultProps = {
  onEdit: true,
};

ScreenSizeCard.propTypes = {
  onEdit: PropTypes.func,
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  screenSizes: PropTypes.arrayOf(screenShape).isRequired,
};

export default ScreenSizeCard;
