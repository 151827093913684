import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Select from '../../../../../common/components/UI/Select';
import PropTypes from 'prop-types';
import styles from './ProgrammeDetailsView.module.scss';
import TheatreNameForm from '../../TheatreNameForm';
import ButtonDigital from '../../../../../common/components/UI/ButtonDigital';
import SessionTheatreList from '../../SessionTheatreList';
import AddSponsorLogoForm from '../AddSponsorLogoForm';
import AddHeaderColumnImageForm from '../AddHeaderColumnImageForm';
import ProgrammeOptionsForm from '../ProgrammeOptionsForm/ProgrammeOptionsForm';
import { safeGet } from '../../../../../../utils';
import TheatreHTMLTitleForm from '../../TheatreHTMLTitleForm';
import Icons from '../../../../../common/components/UI/Icons';

const COLUMN_TYPES = {
  CLOCK: 'CLOCK',
  HTML: 'HTML',
  IMAGE: 'IMAGE',
  BLANK: 'BLANK',
}

const MIN_COLUMN_WIDTH = 20;

const DEFAULT_HEADER_COLUMNS = [
  {
    type: COLUMN_TYPES.CLOCK,
    width: 20,
    content: ''
  },
  {
    type: COLUMN_TYPES.HTML,
    width: 80,
    content: 'HTML Placeholder',
  },
];

const COLUMN_OPTIONS = [
  { label: COLUMN_TYPES.CLOCK, value: COLUMN_TYPES.CLOCK },
  { label: COLUMN_TYPES.HTML, value: COLUMN_TYPES.HTML },
  { label: COLUMN_TYPES.IMAGE, value: COLUMN_TYPES.IMAGE },
  { label: COLUMN_TYPES.BLANK, value: COLUMN_TYPES.BLANK }
];
export default class ProgrammeDetailsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerColumns: DEFAULT_HEADER_COLUMNS,
      isResizing: false,
      startX: 0,
      resizingElementIndex: 0,
      resizer: '',
      hoveringIndex: undefined,
      headerHeight: 150,
    };
  }

  columnGridRef = React.createRef();
  refsArray = [];

  componentDidMount() {
    // Your code here
    const { theatre } = this.props;
    if (theatre) {
      const columns = theatre.options && theatre.options.headerColumns || DEFAULT_HEADER_COLUMNS;
      this.refsArray = Array(columns.length).fill().map(() => React.createRef());
      this.setState({
        ...this.state,
        headerColumns: columns,
        headerHeight: this.columnGridRef.current && (this.columnGridRef.current.offsetWidth / 4),
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { theatre } = this.props;
    if (theatre && theatre.id !== prevProps.theatre.id) {
      const columns = theatre.options && theatre.options.headerColumns || DEFAULT_HEADER_COLUMNS;
      this.refsArray = Array(columns.length).fill().map(() => React.createRef());
      this.setState({
        ...this.state,
        headerColumns: columns,
        headerHeight: this.columnGridRef.current && (this.columnGridRef.current.offsetWidth / 4),
      })
    }
  }

  handleMouseDown = (e, index) => {
    this.setState({ isResizing: true, startX: e.clientX, resizingElementIndex: index, resizer: e.target.id });
  };

  handleMouseUp = () => {
    const { headerColumns } = this.state;
    const updatedColumns = headerColumns.map((column) => { return { ...column, width: Math.round(column.width) } });
    this.updateOptions(updatedColumns);
    this.setState({
      isResizing: false,
      headerColumns: updatedColumns
    });
  };

  handleMouseMove = (e) => {
    const { isResizing, startX, resizingElementIndex, headerColumns, resizer } = this.state;
    if (!isResizing) return;
    const { clientX } = e;
    const movement = clientX - startX;

    const totalWidth = this.columnGridRef.current.offsetWidth;
    const adjacentColumnIndex = resizer.startsWith('right') ? resizingElementIndex + 1 : resizingElementIndex - 1;
    const resizingColumnWidth = resizer.startsWith('right') ? this.refsArray[resizingElementIndex].current.offsetWidth + movement : this.refsArray[resizingElementIndex].current.offsetWidth - movement;
    const adjacentColumnWidth = resizer.startsWith('right') ? this.refsArray[adjacentColumnIndex].current.offsetWidth - movement : this.refsArray[adjacentColumnIndex].current.offsetWidth + movement;

    const convertToPercentageResizingColumnWidth = (resizingColumnWidth / totalWidth) * 100;
    const convertToPercentageAdjacentColumnWidth = (adjacentColumnWidth / totalWidth) * 100;

    if (convertToPercentageResizingColumnWidth < MIN_COLUMN_WIDTH || convertToPercentageAdjacentColumnWidth < MIN_COLUMN_WIDTH) return;

    this.setState({
      startX: clientX,
      headerColumns: headerColumns.map((column, i) => {
        if (i === resizingElementIndex)
          return {
            ...column,
            width: convertToPercentageResizingColumnWidth
          }
        else if (i === adjacentColumnIndex)
          return {
            ...column,
            width: convertToPercentageAdjacentColumnWidth
          }

        else return column;
      })
    });
  };

  getColumnDistribution = (headerColumns) => {
    const distribution = headerColumns.map(column => `${column.width}%`).join(' ');

    return distribution;
  }

  updateOptions = (headerColumns) => {
    const { handleOptionsChange, theatre } = this.props;
    handleOptionsChange({ ...theatre.options, headerColumns });
  }

  resetOptions = () => {
    const { handleOptionsChange } = this.props;
    handleOptionsChange({
      sync: false,
      color: {
        hex: '#E5E5E5',
        rgb: { r: 229, g: 229, b: 229 },
      },
      autoShortenDescription: false,
      descriptionLines: 0,
      headerColumns: DEFAULT_HEADER_COLUMNS,
    },);
  }

  handleChangeColumnType = (newType, headerColumns, index) => {
    headerColumns[index] = {
      ...headerColumns[index],
      type: newType,
      content: '',
    };

    this.updateOptions(headerColumns);
    this.setState({
      headerColumns,
    });
  }


  removeColumn = (headerColumns, index) => {
    const removedColumn = headerColumns[index];
    const adjacentColumns = headerColumns.slice(Math.max(index - 1, 0), index + 2);
    const leftAdjacentColumnWidth = headerColumns[index - 1] ? Math.floor(removedColumn.width / (adjacentColumns.length - 1)) : 0;
    const rightAdjacentColumnWidth = leftAdjacentColumnWidth ? removedColumn.width - leftAdjacentColumnWidth : Math.floor(removedColumn.width / (adjacentColumns.length - 1));

    const updatedColumns = headerColumns.map((column, i) => {
      if (i === index - 1)
        return {
          ...column,
          width: column.width + leftAdjacentColumnWidth
        }
      else if (i === index + 1)
        return {
          ...column,
          width: column.width + rightAdjacentColumnWidth
        }
      else return column
    })

    // focus

    updatedColumns.splice(index, 1);

    this.updateOptions(updatedColumns);
    this.setState({
      headerColumns: updatedColumns,
    });
  }

  addColumnLeft = (headerColumns) => {
    let hasSpace = headerColumns.reduce((acc, curr) => acc += curr.width - 20, 0);
    let leftOverWidth = MIN_COLUMN_WIDTH;
    console.log(hasSpace);
    if (!hasSpace) return;

    const modifiedColumns = headerColumns.map((column) => {
      if (!leftOverWidth) return column;
      const canContainFullNewColumn = column.width - leftOverWidth >= MIN_COLUMN_WIDTH;
      if (canContainFullNewColumn) {
        const newWidth = column.width - leftOverWidth;
        leftOverWidth = 0;
        hasSpace = true;
        return { ...column, width: newWidth }
      }

      const canContainPartOfNewColumn = column.width > MIN_COLUMN_WIDTH;
      if (canContainPartOfNewColumn) {
        const diff = column.width - MIN_COLUMN_WIDTH;
        leftOverWidth = leftOverWidth - diff;
        return { ...column, width: column.width - diff }
      }

      return column;
    })


    if (hasSpace) {
      modifiedColumns.unshift({
        type: COLUMN_TYPES.BLANK,
        content: '',
        width: MIN_COLUMN_WIDTH,
      })
      this.updateOptions(modifiedColumns);
      this.setState({
        headerColumns: modifiedColumns,
      })
    }
  }

  addColumnRight = (headerColumns) => {
    let hasSpace = headerColumns.reduce((acc, curr) => acc += curr.width - 20, 0) >= 20;
    let leftOverWidth = MIN_COLUMN_WIDTH;
    if (!hasSpace) return;

    const modifiedColumns = headerColumns.reverse().map((column) => {
      if (!leftOverWidth) return column;
      const canContainFullNewColumn = column.width - leftOverWidth >= MIN_COLUMN_WIDTH;
      if (canContainFullNewColumn) {
        const newWidth = column.width - leftOverWidth;
        leftOverWidth = 0;
        hasSpace = true;
        return { ...column, width: newWidth }
      }

      const canContainPartOfNewColumn = column.width > MIN_COLUMN_WIDTH;
      if (canContainPartOfNewColumn) {
        const diff = column.width - MIN_COLUMN_WIDTH;
        leftOverWidth = leftOverWidth - diff;
        return { ...column, width: column.width - diff }
      }

      return column;
    }).reverse()


    if (hasSpace) {
      modifiedColumns.push({
        type: COLUMN_TYPES.BLANK,
        content: '',
        width: MIN_COLUMN_WIDTH,
      })
      this.updateOptions(modifiedColumns);
      this.setState({
        headerColumns: modifiedColumns,
      })
    }
  }



  renderColumn = (column, index) => {
    const { type } = column;
    switch (type) {
      case COLUMN_TYPES.CLOCK: return this.renderClockColumn(column);
      case COLUMN_TYPES.HTML: return this.renderHtmlColumn(column);
      case COLUMN_TYPES.IMAGE: return this.renderImageColumn(column, index);
      case COLUMN_TYPES.BLANK: return this.renderBlankColumn(column);
    }
  }

  renderClockColumn = (column) => {
    const now = new Date();
    const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const dayOfWeek = now.toLocaleDateString(undefined, { weekday: 'long' });

    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '2em', textAlign: 'center' }}>{time}</div>
          <div style={{ fontSize: '1em', textAlign: 'center' }}>{dayOfWeek}</div>
        </div>
      </div>);
  }

  renderHtmlColumn = (column) => {
    const {
      theatre,
      handleChangeHTMLTitle,
      refHTMLTitle,
      dataFeedOfThisProgramme,
    } = this.props;
    const canEdit = safeGet(() => !dataFeedOfThisProgramme.options.sync || !theatre.options.sync, true);

    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TheatreHTMLTitleForm
          refTitleName={refHTMLTitle}
          onChangeName={(e) => {
            this.handleChangeColumnContent(column, e.target.value);
            handleChangeHTMLTitle(e);
          }}
          initialValues={{ htmlTitle: column.content }}
          isEditable={canEdit}
        />
      </div>
    );
  }

  renderImageColumn = (column, columnIndex) => {
    const { hoveringIndex } = this.state;
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {column.content && <img src={column.content} style={{ width: '100%' }} />}
        {hoveringIndex === columnIndex && <div style={{ position: 'absolute' }}>
          <AddHeaderColumnImageForm canEdit={true} onChangeTheatreImage={(e) => {
            const imageObj = JSON.parse(e);
            const content = imageObj ? imageObj.content : '';
            this.handleChangeColumnContent(column, content);
          }} initialValues={column} />
        </div>}
      </div>);
  }

  renderBlankColumn = (column) => {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'repeating-linear-gradient(-45deg, #ffffff, #ffffff 10px, #e5e5e5 10px, #e5e5e5 20px)' }}>

      </div>);
  }

  handleChangeColumnContent = (column, content) => {
    column.content = content;
    console.log(content);
    this.updateOptions(this.state.headerColumns);
    this.setState({
      headerColumns: this.state.headerColumns,
    })
  }

  moveColumnLeft = (headerColumns, index) => {
    if (index > 0) {
      const columnToMove = headerColumns[index];
      headerColumns.splice(index, 1);
      headerColumns.splice(index - 1, 0, columnToMove);
    }
    this.updateOptions(headerColumns);
    this.setState({ headerColumns });
  }

  moveColumnRight = (headerColumns, index) => {
    if (index < headerColumns.length - 1) {
      const columnToMove = headerColumns[index];
      headerColumns.splice(index, 1);
      headerColumns.splice(index + 1, 0, columnToMove);
    }
    this.updateOptions(headerColumns);
    this.setState({ headerColumns });
  }

  shouldRenderRemoveColumn = (headerColumns, index, hoveringIndex) => {
    return headerColumns.length > 1 && index == hoveringIndex;
  }

  shouldRenderMoveColumnLeft = (headerColumns, index, hoveringIndex) => {
    return headerColumns.length > 1 && index != 0 && index == hoveringIndex;
  }

  shouldRenderMoveColumnRight = (headerColumns, index, hoveringIndex) => {
    return headerColumns.length > 1 && index != headerColumns.length - 1 && index == hoveringIndex;
  }

  shouldRenderAddColumnLeft = (headerColumns, index, hoveringIndex) => {
    return index === 0 && index == hoveringIndex;
  }

  shouldRenderAddColumnRight = (headerColumns, index, hoveringIndex) => {
    return headerColumns.length - 1 == index && index == hoveringIndex;
  }

  render() {
    const {
      theatre,
      handleChangeName,
      handleOptionsChange,
      sessionList,
      handleOpenSessionModal,
      refTitleName,
      handleRemoveSession,
      handleEditSession,
      sessionRemovalList,
      handleAddSessionToDate,
      handleRemoveSessionByDate,
      onChangeTheatreImage,
      dataFeedOfThisProgramme,
      backRoute,
    } = this.props;

    const { headerColumns, hoveringIndex, headerHeight } = this.state;

    if (!theatre) {
      return <Redirect to={backRoute} />;
    }

    const canEdit = safeGet(() => !dataFeedOfThisProgramme.options.sync || !theatre.options.sync, true);
    const initialValues = {
      ...theatre,
      options: {
        sync: safeGet(() => dataFeedOfThisProgramme.options.sync || theatre.options.sync, false),
        color: theatre.options && theatre.options.color ? theatre.options.color : {
          hex: '#E5E5E5',
          rgb: { r: 229, g: 229, b: 229 },
        },
        autoShortenDescription: safeGet(() => theatre.options.autoShortenDescription, false),
        descriptionLines: safeGet(() => theatre.options.descriptionLines, 0),
        headerColumns: DEFAULT_HEADER_COLUMNS,
      },
    };

    const lastDate = (sessionList[sessionList.length - 1] || {}).planingDay || null;
    const removeHtmlFromText = (text) => {
      return text.replace(/(<([^>]+)>)/ig, '');
    }

    return (
      <div className={styles.container} onMouseUp={this.handleMouseUp}>
        <Link to={backRoute} className={styles.closeButton} />
        <TheatreNameForm
          refTitleName={refTitleName}
          onChangeName={handleChangeName}
          initialValues={{ name: `${removeHtmlFromText(theatre.title)}` }}
          isEditable={canEdit}
        />
        <div id="header-columns-distribution" ref={this.columnGridRef} style={{ display: 'grid', gridTemplateColumns: this.getColumnDistribution(headerColumns), width: '100%', marginTop: 24 }}>
          {headerColumns.map((column) => (
            <div style={{ width: '100%', borderLeft: '1px solid #EFEFF4', borderRight: '1px solid #EFEFF4', overflow: 'hidden', textAlign: 'center' }}>{Math.round(column.width)}%</div>
          ))}
        </div>
        <div id="header-columns" ref={this.columnGridRef} style={{ display: 'grid', gridTemplateColumns: this.getColumnDistribution(headerColumns), width: '100%', minHeight: headerHeight, height: headerHeight }} onMouseMove={this.handleMouseMove} >
          {headerColumns.map((column, index) => (
            <div ref={this.refsArray[index]} style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', border: '1px solid #EFEFF4', overflow: 'hidden', borderRadius: '3px' }} onMouseEnter={() => this.setState({ hoveringIndex: index })} onMouseLeave={() => this.setState({ hoveringIndex: undefined })}>
              {this.shouldRenderRemoveColumn(headerColumns, index, hoveringIndex) && (<div
                style={{ width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0, top: 0, cursor: 'pointer', padding: '2px', border: '1px solid #e5e5e5', borderRadius: '3px', backgroundColor: '#ffffff' }}
                onClick={(e) => { e.preventDefault(); this.removeColumn(headerColumns, index) }}
              >
                x
              </div>)}

              {index !== 0 && <div id={`left-${index}`}
                style={{ width: '5px', height: '100%', position: 'absolute', left: 0, cursor: 'ew-resize' }}
                onMouseDown={(e) => this.handleMouseDown(e, index)}
                onMouseUp={(e) => this.handleMouseUp(e)}
                onMouseMove={(e) => this.handleMouseMove(e)}
              />}
              {this.renderColumn(column, index)}
              {index !== headerColumns.length - 1 && <div id={`right-${index}`}
                style={{ width: '5px', height: '100%', position: 'absolute', right: 0, top: 0, cursor: 'ew-resize' }}
                onMouseDown={(e) => this.handleMouseDown(e, index)}
                onMouseUp={(e) => this.handleMouseUp(e)}
                onMouseMove={(e) => this.handleMouseMove(e)}
              />}
              {this.shouldRenderAddColumnLeft(headerColumns, index, hoveringIndex) && <div id="add-column-left-button"
                style={{ width: '25px', borderRadius: 3, height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: '25%', cursor: 'pointer', padding: '2px', border: '1px solid #e5e5e5', backgroundColor: '#ffffff' }}
                onMouseDown={() => this.addColumnLeft(headerColumns)}
              >+</div>}
              {this.shouldRenderMoveColumnLeft(headerColumns, index, hoveringIndex) && (<div
                style={{ width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, bottom: 0, cursor: 'pointer', padding: '2px', border: '1px solid #e5e5e5', borderRadius: '3px', backgroundColor: '#ffffff' }}
                onClick={(e) => { e.preventDefault(); this.moveColumnLeft(headerColumns, index) }}
              >
                <Icons icon={'moveLeft'} widthIcons={14} />
              </div>)}
              {this.shouldRenderMoveColumnRight(headerColumns, index, hoveringIndex) && (<div
                style={{ width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0, bottom: 0, cursor: 'pointer', padding: '2px', border: '1px solid #e5e5e5', borderRadius: '3px', backgroundColor: '#ffffff' }}
                onClick={(e) => { e.preventDefault(); this.moveColumnRight(headerColumns, index) }}
              >
                <Icons icon={'moveRight'} widthIcons={14} />
              </div>)}
              {this.shouldRenderAddColumnRight(headerColumns, index, hoveringIndex) && <div id="add-column-right-button"
                style={{ width: '25px', borderRadius: 3, height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0, top: '25%', cursor: 'pointer', padding: '2px', border: '1px solid #e5e5e5', backgroundColor: '#ffffff' }}
                onMouseDown={() => this.addColumnRight(headerColumns)}
              >+</div>}
            </div>
          ))}
        </div>
        <div id="header-columns-type-selection" ref={this.columnGridRef} style={{ display: 'grid', gridTemplateColumns: this.getColumnDistribution(headerColumns), width: '100%', marginBottom: '24px' }} >
          {headerColumns.map((column, i) => (
            <Select
              key={i}
              value={{ label: column.type, value: column.type }}
              options={COLUMN_OPTIONS}
              onChange={selectedOption => this.handleChangeColumnType(selectedOption.value, headerColumns, i)}
            />
          ))}
        </div>
        <AddSponsorLogoForm canEdit={canEdit} onChangeTheatreImage={onChangeTheatreImage} initialValues={theatre} />
        <ProgrammeOptionsForm onOptionChange={handleOptionsChange} initialValues={initialValues} />
        <SessionTheatreList
          sessionRemovalList={sessionRemovalList}
          handleRemoveSession={handleRemoveSession}
          handleEditSession={handleEditSession}
          sessionList={sessionList}
          handleAddSessionToDate={handleAddSessionToDate}
          handleRemoveSessionByDate={handleRemoveSessionByDate}
          canEdit={canEdit}
        />

        {
          canEdit && (
            <ButtonDigital
              className={styles.addSessionButton}
              isConfirmStyle={true}
              type="button"
              onClick={() => handleOpenSessionModal('new', theatre.id, lastDate)}
            >
              Add Session
            </ButtonDigital>
          )
        }
      </div >
    );
  }
}

ProgrammeDetailsView.defaultProps = {
  backRoute: '/programmes',
};

ProgrammeDetailsView.propTypes = {
  backRoute: PropTypes.string,
  refTitleName: PropTypes.shape({ current: PropTypes.object }).isRequired,
  theatre: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    options: PropTypes.shape({
      sync: PropTypes.bool.isRequired,
    }),
    ownerId: PropTypes.number.isRequired,
  }),
  onChangeTheatreImage: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeHTMLTitle: PropTypes.func.isRequired,
  handleOptionsChange: PropTypes.func.isRequired,
  handleOpenSessionModal: PropTypes.func.isRequired,
  sessionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      planingDay: PropTypes.string.isRequired,
      sessionListMatches: PropTypes.arrayOf(
        PropTypes.shape({
          day: PropTypes.string.isRequired,
          description: PropTypes.string,
          start: PropTypes.string.isRequired,
          end: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
          programmeId: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  handleRemoveSession: PropTypes.func.isRequired,
  handleEditSession: PropTypes.func.isRequired,
  sessionRemovalList: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleAddSessionToDate: PropTypes.func.isRequired,
  handleRemoveSessionByDate: PropTypes.func.isRequired,
  dataFeedOfThisProgramme: PropTypes.object,
};
