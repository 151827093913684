import React from 'react';
import SvgIcoOrientation from '../../../../../assets/svg/SvgIcoOrientation/SvgIcoOrientation';
import SvgIcoNotDefault from '../../../../../assets/svg/SvgIcoNotDefault/SvgIcoNotDefault';
import SvgIcoSquare from '../../../../../assets/svg/SvgIcoSquare/SvgIcoSquare';

const getScreenOrientationIcon = (screenSizeId, screenSizesList, ops = {}) => {
  const { color = '#bcbebf', size = 24 } = ops;
  const screenSize = screenSizesList.find(item => item.id === screenSizeId);
  if (!screenSize) return null;

  const isDefault = screenSize.default;
  const isSquare = screenSize.resolutionWidth === screenSize.resolutionHeight;

  if (isDefault) return <SvgIcoOrientation orientation={screenSize.name} setColor={color} iconSize={size} />;

  if (isSquare) return <SvgIcoSquare setColor={color} iconSize={size} />;

  return (
    <SvgIcoNotDefault
      orientation={screenSize.resolutionWidth < screenSize.resolutionHeight}
      setColor={color}
      iconSize={size}
    />
  );
};

export default getScreenOrientationIcon;
