import reducer from './reducers';
import * as actions from './actions'; // actions
import * as selectors from './selectors';
import sagas from './sagas'; // sagas
import * as types from './types';

export const wsActions = actions;
export const wsSagas = sagas;
export const wsReducer = reducer;
export const wsSelectors = selectors;
export const wsTypes = types;
