import React from 'react';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';
import styles from './TimeField.module.scss';

function timeMask(value) {
  const chars = value.split('');

  const firstCharReg = /[3-9]/g;
  const hours = [firstCharReg.test(chars[0]) ? '0' : /[0-2]/, chars[0] === '2' ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];

  return hours.concat(':').concat(minutes);
}

const renderField = ({
  id,
  name,
  label,
  input,
  innerRef,
  placeholder,
  className,
  handleOnKeyPress,
  customLabelClass,
  meta: { touched, error },
  ...rest
}) => (
  <div className={styles.container}>
    {label && (
      <label className={cn(styles.label, customLabelClass && customLabelClass)} htmlFor={`${name}${id}`}>
        {label}
      </label>
    )}
    <div className={styles.controlContainer}>
      <MaskedInput
        id={`${name}${id}`}
        name={name}
        className={cn(styles.input, className && className)}
        mask={timeMask}
        autoComplete="off"
        placeholder={placeholder}
        onKeyDown={handleOnKeyPress}
        {...input}
        onChange={e => {
          input.onChange(e);
        }}
        onFocus={e => {
          input.onFocus(e);
        }}
        onBlur={e => {
          input.onBlur(e);
        }}
        ref={innerRef}
        {...rest}
      />
      {touched && error && <div className={styles.error}>{error}</div>}
    </div>
  </div>
);

export default renderField;
