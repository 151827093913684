import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import { Rss } from '../../../../../../../../common/components/UI/Svg';

import styles from './FeedProgramme.module.scss';
import { safeGet } from '../../../../../../../../../utils';

function FeedProgramme({ title, options, onToggleSync, history, ...props }) {
  const handleToggleSync = e => {
    e.stopPropagation();
    const { id } = props;
    onToggleSync(id);
  };

  const programmePathname = `/programmes/data-feed/${props.id}`;
  const sync = safeGet(() => options.sync, false);

  const rssIconFill = sync ? '#EC812B' : '#C8C7CC';
  const rootClassName = cn({
    [styles.root]: true,
    [styles.rootIsSyncing]: sync,
    [styles.rootIsActive]: history.location.pathname === programmePathname,
  });

  const removeHtmlFromText = (text) => {
    return text.replace( /(<([^>]+)>)/ig, '');
  }

  return (
    <div className={rootClassName} onClick={() => history.push(`/programmes/data-feed/${props.id}`)}>
      <span dangerouslySetInnerHTML={{ __html: removeHtmlFromText(title) }} />
      <button className={styles.syncBtn} onClick={handleToggleSync}>
        <Rss width="16px" height="16px" fill={rssIconFill} />
        <span>{sync ? 'On' : 'Off'}</span>
      </button>
    </div>
  );
}

FeedProgramme.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  onToggleSync: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  options: PropTypes.shape({
    sync: PropTypes.bool,
  }),
};

export default withRouter(FeedProgramme);
