import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContentItem.module.scss';
import getScreenOrientationIcon from './getScreenOrientationIcon';
import { getScreenDimensions } from '../../../../../../../utils';
import Icons from '../../../Icons';
import ContentIcons from '../../../ContentIcons';
import UploadSpinner from '../../../UploadSpinner';

class ContentItem extends React.PureComponent {
  handleDelete = () => {
    const { onDelete, contentGroupId, id } = this.props;
    onDelete(contentGroupId, id);
  };

  render() {
    const { name, type, isAllSizes, screenSize, screenSizes, onClick, onEdit, onDelete, isInProgress } = this.props;

    return (
      <div className={styles.container} onClick={onClick}>
        <div className={styles.sizeCard}>
          {isAllSizes ? (
            <span className={styles.sizeCardText}>All sizes</span>
          ) : (
            <>
              <span className={styles.sizeCardIcon}>
                {getScreenOrientationIcon(screenSize.id, screenSizes, { color: '#8A8A8F' })}
              </span>
              <span className={styles.sizeCardText}>
                {screenSize.name} {getScreenDimensions(screenSize.resolutionWidth, screenSize.resolutionHeight)}
              </span>
            </>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.contentIcon}>
            <ContentIcons type={type} />
          </div>
          <span className={styles.text}>{name}</span>
          {onEdit && (
            <button type="button" className={styles.edit} onClick={onEdit}>
              <Icons icon="editIcon" widthIcons={24} />
              <span>Edit message</span>
            </button>
          )}
          {onDelete && (
            <button type="button" className={styles.delete} onClick={this.handleDelete}>
              <Icons icon="deleteTrash" widthIcons={24} />
            </button>
          )}
        </div>
        {isInProgress && <UploadSpinner />}
      </div>
    );
  }
}

ContentItem.defaultProps = {
  onClick: () => {},
  isAllSizes: false,
};

ContentItem.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  onClick: PropTypes.func,
  screenSize: PropTypes.object,
  screenSizes: PropTypes.array,
  onDelete: PropTypes.func,
  isAllSizes: PropTypes.bool,
  isInProgress: PropTypes.bool,
  contentGroupId: PropTypes.number.isRequired,
};

export default ContentItem;
