import React from 'react';
import { formValueSelector } from 'redux-form';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AddScreenForm from '../AddScreenForm';
import styles from './AddScreenFormContainer.module.scss';
import { screenSizesActions, screenSizesSelectors } from '../../../../../state/ducks/screenSizes';
import ScreenSizeAddScreen from '../ScreenSizeAddScreen';
import { screensActions, screensSelectors } from '../../../../../state/ducks/screens';
import { contentActions } from '../../../../../state/ducks/content';
import generateEntityName from '../../../../../utils/generateName';

import ScreenSizeCard from '../ScreenSizeCard';
import SelectedPlaylistIndicator from '../SelectedPlaylistIndicator';
import ChoosePlaylistModal from '../../../../common/components/ChoosePlaylistModal';
import DropZoneContainer from '../../../../common/components/UI/DropZoneContainer';
import { contentGroupActions } from '../../../../../state/ducks/contentGroup';
import DropZone from './DropZone';
import AddFilesBtn from '../../../../common/components/UI/AddFilesBtn/AddFilesBtn';
import UploadSpinner from '../../../../common/components/UI/UploadSpinner';

const { fetchCreateScreenSizes } = screenSizesActions;
const { setPlaylistsContents, setMediaLibraryModal } = contentActions;
const { currentScreen } = screensSelectors;
const { getScreenSizes } = screenSizesSelectors;
const { setContentTypeFilter } = contentGroupActions;

const {
  fetchUpdateScreen,
  fetchScreenUpdate,
  fetchCreateScreenWithRelationOfPlaylist,
  fetchCreateScreenWithEmptyPlaylist,
} = screensActions;

class AddScreenFormContainer extends React.Component {
  state = {
    isSelectContent: 'addContent',
    showDeleteButton: false,
    delId: null,
    createdScreenName: null,
    isOpenChoosePlaylistModal: false,
  };

  handleOpenChoosePlaylistModal = () => this.setState({ isOpenChoosePlaylistModal: true });

  handleCloseChoosePlaylistModal = () => this.setState({ isOpenChoosePlaylistModal: false });

  selectContent = e => {
    this.setState({ isSelectContent: e.target.id });
    if (e.target.id === 'addContent') {
      this.props.setPlaylistsContents([]);
    }
  };

  render() {
    const {
      location: { search },
      screenSizes,
      updateScreenItem,
      createScreenSize,
      screens,
      isEditMode,
      playlists,
      newScreenName,
      currentScreen,
      setMediaLibraryModal,
      setContentTypeFilter,
      handleClickOpenEdit,
      handleClickCloseEdit,
      customSizeBtnIsActive,
      handleCloseAddCustomSize,
      handleClickAddCustomSize,
      selectedScreenSizeId,
      createScreenWithEmptyPlaylist,
      isScreenLoading,
    } = this.props;

    const queryRetrieve = queryString.parse(search);
    const createdScreenId = queryRetrieve.screenid ? Number(queryRetrieve.screenid) : false;

    return (
      <DropZone newScreenName={newScreenName} selectedScreenSizeId={selectedScreenSizeId}>
        {({ getRootProps, isDragActive, isDragReject }) => (
          <DropZoneContainer
            className={styles.container}
            isDragReject={isDragReject}
            isDragActive={isDragActive}
            getRootProps={getRootProps}
          >
            <ScreenSizeAddScreen
              createdScreenId={createdScreenId}
              screenSizeList={screenSizes}
              initialValues={{ screenSizeId: selectedScreenSizeId }}
              isEditMode={isEditMode}
              handleClickOpenEdit={handleClickOpenEdit}
              handleClickCloseEdit={handleClickCloseEdit}
              handleClickAddCustomSize={handleClickAddCustomSize}
              handleCloseAddCustomSize={handleCloseAddCustomSize}
              customSizeBtnIsActive={customSizeBtnIsActive}
              createScreenSize={createScreenSize}
              currentScreen={currentScreen}
              updateScreenItem={updateScreenItem}
            />
            <AddScreenForm
              createScreenWithEmptyPlaylist={createScreenWithEmptyPlaylist}
              isEditMode={isEditMode}
              updateScreenItem={updateScreenItem}
              createdScreenId={createdScreenId}
              initialValues={{ name: newScreenName }}
              checkOnExistingName={this.checkOnExistingName}
            />
            {!isEditMode && (
              <>
                <div className={styles.screenInfo}>
                  <ScreenSizeCard id={selectedScreenSizeId} screenSizes={screenSizes} onEdit={handleClickOpenEdit} />
                </div>
                <SelectedPlaylistIndicator
                  title={`Playlist ${playlists.length + 1}`}
                  onOpenChoosePlaylistModal={this.handleOpenChoosePlaylistModal}
                />
                <AddFilesBtn
                  onClick={() => {
                    setMediaLibraryModal(true, {
                      id: null,
                      newScreenName,
                      orientationSelect: selectedScreenSizeId,
                    });
                    setContentTypeFilter('multiply');
                  }}
                />
                <ChoosePlaylistModal
                  playlists={playlists}
                  isOpen={this.state.isOpenChoosePlaylistModal}
                  onClose={this.handleCloseChoosePlaylistModal}
                  onChoosePlaylist={newSelectedPlaylistId => {
                    this.props.createNewScreenAndSetExistPlaylist(newSelectedPlaylistId);
                  }}
                />
              </>
            )}
            {isScreenLoading && <UploadSpinner />}
          </DropZoneContainer>
        )}
      </DropZone>
    );
  }
}

AddScreenFormContainer.propTypes = {
  // updatePlaylist: PropTypes.func.isRequired,
  createNewScreenAndSetExistPlaylist: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { screens } = state.screens;
  const screenSizes = getScreenSizes(state);
  const selectedScreenSizeId = formValueSelector('sizeForm')(state, 'screenSizeId') || screenSizes[1].id;
  const newScreenName = formValueSelector('addScreens')(state, 'name') || generateEntityName(screens, 'Screen');

  return {
    screens,
    screenSizes,
    newScreenName,
    selectedScreenSizeId,
    playlists: state.playlists.playlists,
    playlistsContents: state.content.playlistsContents,
    currentScreen: currentScreen(state, ownProps),
    isScreenLoading: state.screens.isLoading,
  };
};

const mapDispatchToProps = {
  fetchUpdateScreen,
  setPlaylistsContents,
  setMediaLibraryModal,
  setContentTypeFilter,
  updateScreenItem: fetchScreenUpdate,
  createScreenSize: fetchCreateScreenSizes,
  createNewScreenAndSetExistPlaylist: fetchCreateScreenWithRelationOfPlaylist,
  createScreenWithEmptyPlaylist: fetchCreateScreenWithEmptyPlaylist,
};

const AddScreenFormContainerWithRouter = withRouter(AddScreenFormContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddScreenFormContainerWithRouter);
