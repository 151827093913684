import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styles from './LayoutPreviewModal.module.scss';
import { appActions } from '../../../state/ducks/app';
import SvgIcons from '../../../assets/svg';
import { adjustElementBySize } from '../../../utils';
import { fontFaceData } from '../../../utils/editorContentCssText';

const { SvgClose } = SvgIcons;
const { setPreviewModal } = appActions;

class LayoutPreviewModal extends Component {
  constructor() {
    super();
    this.iframeContainerRef = React.createRef();
    this.iframeRef = React.createRef();
  }

  handleCloseConfirmModal = () => {
    const { actionShowPreviewModal } = this.props;
    actionShowPreviewModal(false, {});
  };

  componentDidUpdate() {
    const {
      screenSizes,
      previewModalData: { screenSizeId },
    } = this.props;

    const screenSize = screenSizes.find(size => size.id === screenSizeId);

    if (this.iframeContainerRef.current && this.iframeRef.current && screenSize) {
      adjustElementBySize({
        container: this.iframeContainerRef.current,
        element: this.iframeRef.current,
        width: screenSize.resolutionWidth,
        height: screenSize.resolutionHeight,
      });
    }
  }

  render() {
    const {
      showPreviewModal,
      previewModalData: { content },
    } = this.props;

    let normalizedContent = content || '';

    // add default fonts to content for iFrame
    if (content) {
      const htmlElement = document.createElement('html');
      htmlElement.innerHTML = content;
      const headElement = htmlElement.getElementsByTagName('head')[0];
      const styleElement = document.createElement('style');
      styleElement.type = 'text/css';
      styleElement.innerHTML = fontFaceData;
      styleElement.setAttribute('id', 'font-faces');
      headElement.appendChild(styleElement);
      normalizedContent = htmlElement.outerHTML;
    }

    return (
      <div className={showPreviewModal ? styles.containerActive : styles.containerHide}>
        <button className={styles.closeButton} type="button" onClick={this.handleCloseConfirmModal}>
          <SvgClose />
        </button>
        <div ref={this.iframeContainerRef} className={styles.mainContainer}>
          <iframe ref={this.iframeRef} className={styles.iframeBoard} frameBorder="0" srcDoc={normalizedContent} />
        </div>
        <div role="presentation" onClick={this.handleCloseConfirmModal} className={styles.background} />
      </div>
    );
  }
}

LayoutPreviewModal.propTypes = {
  actionShowPreviewModal: PropTypes.func.isRequired,
  showPreviewModal: PropTypes.bool.isRequired,
  previewModalData: PropTypes.object.isRequired,
  screenSizes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      default: PropTypes.bool.isRequired,
      resolutionWidth: PropTypes.number.isRequired,
      resolutionHeight: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  showPreviewModal: state.app.showPreviewModal,
  previewModalData: state.app.previewModalData,
  screenSizes: state.screenSizes.screenSizes,
});

const mapDispatchToProps = dispatch => bindActionCreators({ actionShowPreviewModal: setPreviewModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutPreviewModal);
