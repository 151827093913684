import { put, call, takeLatest, select } from 'redux-saga/effects';
import { fetchCreateContentGroupFlow } from '../contentGroup/sagas';
import { getFileType, getProcessedFile } from '../../utils/create-file-helper';
import { sentryReport, getErrorMessage } from '../../utils';
import { showNotification } from '../app/actions';
import * as types from './types';
import { getContents } from '../content/selectors';
import * as actions from './actions';

function* uploadNewImagesFlow({ payload: { files, multiple = true } }) {
  yield put(actions.uploadNewImagesRequest());
  try {
    yield files.map(function* gen(imageFile) {
      const fileType = getFileType(imageFile.type);
      const processedFile = getProcessedFile(imageFile);

      try {
        const uploadedContentGroup = yield call(fetchCreateContentGroupFlow, {
          payload: { fileData: processedFile, fileType, isInsertImage: true },
        });

        const contents = yield select(getContents);
        const uploadedImage = contents.find(c => c.contentGroupId === uploadedContentGroup.id);
        yield put(actions.selectImage({ image: uploadedImage, multiple }));
      } catch (e) {
        //
      }
    });

    yield put(actions.uploadNewImagesSuccess());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 1, message }));
    yield put(actions.uploadNewImagesFailure());
  }
}

export default [takeLatest(types.UPLOAD_NEW_IMAGES, uploadNewImagesFlow)];
