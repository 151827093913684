import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProgrammesView from '../components/ProgrammesView';
import { programmesActions } from '../../../../state/ducks/programmes';
import { sessionsActions } from '../../../../state/ducks/sessions';
import { appActions } from '../../../../state/ducks/app';
import { getTheatrePlaylists } from '../../../../state/ducks/playlists/selectors';
import { getPureProgrammes } from '../../../../state/ducks/programmes/selectors';
import { getOptions } from '../../../../utils';

const { setShowConfirmModalPlaylistScreens } = appActions;
const { createNewTheatreProgram, removeTheatreProgram, fetchCreateTheatreOpenSession } = programmesActions;
const { setSessionModal } = sessionsActions;

class ProgrammesViewContainer extends Component {
  handleOpenSessionModal = (type, id, date) => {
    const { sessionModalAction } = this.props;
    sessionModalAction(true, type, id, date);
  };

  handleCloseSessionModal = () => {
    const { sessionModalAction } = this.props;
    sessionModalAction(false, null, null);
  };

  handleCreateNewTheatre = theatreName => {
    const { createNewTheatre } = this.props;
    createNewTheatre(theatreName);
  };

  handleRemoveTheatre = programId => {
    const { showConfirmModal, playlists } = this.props;

    const usedThisProgrammePlaylists = playlists.filter(playlist => {
      const { selectedProgrammeId } = getOptions(playlist.options);
      return selectedProgrammeId === programId;
    });

    const canDeleteThisProgramme = !usedThisProgrammePlaylists.length;
    const usedThisProgrammePlaylistNames = usedThisProgrammePlaylists.map(({ name }) => `"${name}"`).join(', ');

    const title = canDeleteThisProgramme
      ? 'Are you sure you want to delete this programme?'
      : `Theatre is being used by ${usedThisProgrammePlaylistNames}. Please assign another Theatre to ${usedThisProgrammePlaylistNames}, before deleting this Theatre.`;

    showConfirmModal(true, {
      title,
      canDelete: canDeleteThisProgramme,
      fetchAction: 'removeTheatreProgram',
      id: programId,
    });
  };

  render() {
    const {
      sessionModal,
      programmes,
      createTheatreOpenSession,
      location: { pathname },
    } = this.props;
    return (
      <ProgrammesView
        programmes={programmes}
        handleOpenSessionModal={this.handleOpenSessionModal}
        handleCloseSessionModal={this.handleCloseSessionModal}
        handleCreateNewTheatre={this.handleCreateNewTheatre}
        handleRemoveTheatre={this.handleRemoveTheatre}
        createTheatreOpenSession={createTheatreOpenSession}
        sessionModal={sessionModal}
        currentLocation={pathname}
      />
    );
  }
}

ProgrammesViewContainer.propTypes = {
  programmes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      ownerId: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  sessionModal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    type: PropTypes.string,
  }).isRequired,
  sessionModalAction: PropTypes.func.isRequired,
  createNewTheatre: PropTypes.func.isRequired,
  removeTheatre: PropTypes.func.isRequired,
  createTheatreOpenSession: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.func.isRequired,
  playlists: PropTypes.array.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

const mapStateToProps = state => ({
  playlists: getTheatrePlaylists(state),
  programmes: getPureProgrammes(state),
  sessionModal: state.sessions.sessionModal,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sessionModalAction: setSessionModal,
      createNewTheatre: createNewTheatreProgram,
      removeTheatre: removeTheatreProgram,
      createTheatreOpenSession: fetchCreateTheatreOpenSession,
      showConfirmModal: setShowConfirmModalPlaylistScreens,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgrammesViewContainer);
