import React from "react";
import { Link } from "react-router-dom";
import {
  put,
  call,
  takeLatest,
  takeEvery,
  select,
  all,
  cancel,
  fork,
  take,
} from "redux-saga/effects";
import axios from "axios";
import * as types from "./types";
import * as actions from "./actions";
import {
  storage,
  fetch,
  apiRoutes,
  s3upload,
  sentryReport,
  getErrorMessage,
  mixpanel,
} from "../../utils";
import { showNotification } from "../app/actions";
import { contentActions } from "../content";
import { contentMetaActions } from "../contentMeta";
import { playlistsActions } from "../playlists";
import { screensActions } from "../screens";
import getDurationVideo from "../../utils/get-duration-video";
import {
  getFileType,
  getProcessedFile,
  getScreenSize,
  getExtensionOfUrl,
  getCustomHtmlName,
  cutExt,
  cutHash,
} from "../../utils/create-file-helper";
import history from "../../utils/history";
import delay from "../../utils/dalay-for-sagas";
import ButtonUndo from "../../../views/common/components/UI/ButtonUndo";
import generateEntityName from "../../../utils/generateName";
import { contentGroupSelectors } from "../insertImage";
import {
  getContentGroupMediaFiles,
  getPlaylistMediaFiles,
} from "../../../utils";
import { createPlaylist, updatePlaylistFlow } from "../playlists/sagas";
import { getPlaylists } from "../playlists/selectors";
import { fetchDeletePlaylist } from "../playlists/actions";
import { templateTypes } from "../../../constants";
import { getProgrammes } from "../programmes/selectors";
// import { createPlaylistStart, createPlaylistEnd } from '../playlists/actions';

const {
  setProgressToContent,
  removeProgressFromContent,
  setContents,
} = contentActions;
const {
  addContentMeta,
  setContentMeta,
  setLinkedContentMetaIds,
} = contentMetaActions;
const {
  fetchAddPlaylistToScreen,
  setScreens,
  fetchScreenStart,
  fetchScreenFinished,
} = screensActions;
const {
  setPlaylists,
  createPlaylistStart,
  createPlaylistEnd,
} = playlistsActions;
const {
  setContentGroup,
  setContentGroupToCheckList,
  fetchCreateContentGroup,
  fetchDeleteContentGroup,
  fetchUpdatePlaylistContentGroup,
  fetchCreateContentGroupSetToPlaylist,
  fetchContentGroupStart,
  fetchContentGroupFinished,
  fetchReorderContentGroupMeta,
  setScreenSizeId,
  addFullProgrammeToPlaylistStart,
  addFullProgrammeToPlaylistEnd,
} = actions;

function* fetchS3SignedUrlFlow(data) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.getSignedUrl;
  const response = yield call(fetch, url(user.userId), method, { data });
  return response;
}

function* fetchRequestCreateContentGroup(data) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.createContentGroup;
  const response = yield call(fetch, url(user.userId), method, {
    ...data,
    ownerId: user.userId,
  });
  return response;
}

function* fetchRequestAddContentsToContentGroup(data) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.addContentToContentGroup;
  const response = yield call(fetch, url(data.contentGroupId), method, {
    ...data,
    ownerId: user.userId,
  });
  return response;
}

function* fetchRequestCreateContent(data) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.createContents;
  const response = yield call(fetch, url(user.userId), method, { ...data });
  return response;
}

function* fetchRequestDeleteContentGroup(contentGroupId) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.removeContentGroup;
  const response = yield call(fetch, url(user.userId, contentGroupId), method);
  return response;
}

function* fetchRequestCreateNewPlaylist(name) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.createPlaylist;
  // const getName = `${window.location.pathname === '/screens' ? 'Playlist: ' : ''}${name}`;
  const response = yield call(fetch, url(user.userId), method, {
    name,
    ownerId: user.userId,
  });
  return response;
}

function* fetchRequestUpdatePlaylistContentGroup(playlistId, contentGroupId) {
  const { url, method } = apiRoutes.updatePlaylistContentGroup;
  const response = yield call(fetch, url(playlistId, contentGroupId), method);
  return response;
}

function* createContentMeta(playlistId, metaData) {
  const { url, method } = apiRoutes.addPlaylistContentMeta;
  const response = yield call(fetch, url(playlistId), method, metaData);
  return response;
}

function* fetchRequestGetContentMetaOfPlaylist(playlistId) {
  const { url, method } = apiRoutes.getContentMetaOfPlaylist;
  const response = yield call(fetch, url(playlistId), method);
  return response;
}

function* fetchRequestCreateScreen(formData) {
  const { url, method } = apiRoutes.createScreen;
  const user = storage.getUser();
  const response = yield call(fetch, url(user.userId), method, formData);

  mixpanel.track("Create screen via my files", response);

  return response;
}

function* fetchGetHeaders(urlData) {
  const { url, method } = apiRoutes.getHeaders;
  const response = yield call(fetch, url(), method, { data: urlData });
  return response.data;
}

function* addFullProgrammeToPlaylistFlow({
  payload: { playlistId, programmeId, order, duration },
}) {
  yield put(addFullProgrammeToPlaylistStart());
  try {
    const allContentsMeta = yield select(
      (state) => state.contentMeta.contentMeta
    );
    const playlistContentsMeta = allContentsMeta.filter(
      (cm) => cm.playlistId === playlistId
    );
    const user = storage.getUser();
    const contentMetaData = {
      order: order || playlistContentsMeta.length,
      duration: duration || 15,
      playlistId,
      _playtime: [],
      ownerId: user.userId,
      entityType: "programme",
      entityId: programmeId,
    };
    const createdContentMeta = yield call(
      createContentMeta,
      playlistId,
      contentMetaData
    );
    yield put(addContentMeta(createdContentMeta));
    yield put(addFullProgrammeToPlaylistEnd(playlistId));
  } catch (error) {
    yield put(showNotification(getErrorMessage(error)));
  }
}

export function* fetchUpdatePlaylistContentGroupFlow(action) {
  try {
    const { playlistId, entityId, order, duration } = action.payload;
    console.log(action.payload);
    const user = storage.getUser();
    // get video duration
    let _duration = duration || 15;
    const allContent = yield select((state) => state.content.contents);
    const findCurrentContent = allContent.find(
      (content) => content.contentGroupId === entityId
    );

    if (findCurrentContent.type === "video") {
      _duration = yield call(getDurationVideo, findCurrentContent.content);
    }

    // Add a related playlist id for contentGroups
    // yield call(fetchRequestUpdatePlaylistContentGroup, playlistId, contentGroupId);

    // Create contentMeta for playlist
    const contentMetaData = {
      _playtime: [],
      ownerId: user.userId,
      order,
      duration: _duration,
      playlistId,
      entityType: "contentGroup",
      entityId,
    };

    const resCreateContentMeta = yield call(
      createContentMeta,
      playlistId,
      contentMetaData
    );
    yield put(addContentMeta(resCreateContentMeta));

    // set linked content meta id to playlist few seconds ago
    const getLinkedContentMeta = yield select(
      (state) => state.contentMeta.linkedContentMetaIds
    );
    yield put(
      setLinkedContentMetaIds([
        ...getLinkedContentMeta,
        resCreateContentMeta.id,
      ])
    );
    yield put({
      type: types.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_END,
      payload: { playlistId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

// create content/contentGroup without relations (for ML)
export function* fetchCreateContentGroupFlow(action) {
  let progressHashName;
  try {
    const {
      fileData,
      fileType,
      setToPlaylist = false,
      playlistId = null,
      isCustomHtml,
      isInsertImage = false,
    } = action.payload;
    const screenSizes = yield select((state) => state.screenSizes.screenSizes);

    const fileName = isCustomHtml
      ? getCustomHtmlName(fileData.name)
      : fileData.name;
    // request to S3 amazon
    const resOfS3 = yield call(fetchS3SignedUrlFlow, {
      type: fileType,
      fileName,
    });
    const { urls, ContentType, path } = resOfS3.data;
    [progressHashName] = urls;

    // set progress bar
    const fileProgressData = {
      uniqueKey: progressHashName,
      name: cutHash(cutExt(fileData.name)),
      flagField: !setToPlaylist ? "content" : "contentToPlaylist",
      contentType: fileType,
      playlistId,
    };

    yield put(setProgressToContent(progressHashName, 1, 0, fileProgressData));

    // upload content to S3 amazon
    yield call(
      s3upload,
      progressHashName,
      "PUT",
      fileData,
      {
        "Content-Type": ContentType,
      },
      progressHashName,
      fileProgressData
    );

    // check existing file on s3
    const getHeaders = yield call(fetchGetHeaders, encodeURI(path));
    const retrieveContentLength = getHeaders["content-length"];
    if (!retrieveContentLength) {
      yield put(removeProgressFromContent(progressHashName));
      yield put(
        showNotification({
          level: "error",
          message: "An error occurred in the upload. Please try again",
        })
      );
      return null;
    }

    // create contentGroup
    const resContentGroup = yield call(fetchRequestCreateContentGroup, {
      type: fileType,
      name: cutHash(cutExt(fileData.name)),
      isCustomHtml: !!isCustomHtml,
    });

    const { id: contentGroupId, type, ownerId } = resContentGroup;

    // const defineScreenSizeId = getScreenSize(path, screenSizes);
    const defineScreenSizeId = yield call(
      getScreenSize,
      path,
      screenSizes,
      type
    );

    // create content
    const resContent = yield call(fetchRequestCreateContent, {
      content: path,
      screenSizeId: defineScreenSizeId,
      fileName,
      type,
      contentGroupId,
      ownerId,
    });

    // remove progress bar
    yield put(removeProgressFromContent(progressHashName));

    // set contentGroup to store
    const getAllContentGroup = yield select(
      (state) => state.contentGroup.contentGroup
    );

    yield put(setContentGroup([resContentGroup, ...getAllContentGroup]));
    // set content to store
    const getAllContents = yield select((state) => state.content.contents);
    yield put(setContents([resContent, ...getAllContents]));

    if (!setToPlaylist && !isInsertImage) {
      const checklist = yield select((state) => state.contentGroup.checkList);
      yield put(
        setContentGroupToCheckList([
          ...checklist,
          { type: "contentGroup", id: resContentGroup.id },
        ])
      );
    }
    return resContentGroup;
  } catch (error) {
    if (axios.isCancel(error)) {
      const isAbort = true;
      yield put(removeProgressFromContent(progressHashName, isAbort));
    } else {
      sentryReport(error);
      const messageError = getErrorMessage(error);
      const { level, message } = messageError;
      yield put(showNotification({ level, message }));
      yield put(removeProgressFromContent(progressHashName));
    }
  }
}

function* fetchAbortUploadFileFlow({ payload }) {
  const { uniqueKey } = payload;
  yield axios.cancel(uniqueKey);
}

// multiple create content/contentGroup without relations (for ML)
export function* fetchMultipleCreateContentGroupFlow(action) {
  try {
    const { uploadFiles, isCustomHtml } = action.payload;

    yield all(
      uploadFiles.map(function* gen(file) {
        const fileType = getFileType(file.type);
        const processedFile = getProcessedFile(file);
        yield put(
          fetchCreateContentGroup({
            fileData: processedFile,
            fileType,
            isCustomHtml,
          })
        );
      })
    );
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

// delete content/contentGroup without relations (for ML)
function* fetchDeleteContentGroupFlow(action) {
  try {
    const { contentGroupId } = action.payload;

    // request delete content group
    yield call(fetchRequestDeleteContentGroup, contentGroupId);

    // update contentGroup in store
    const contentGroup = yield select(
      (state) => state.contentGroup.contentGroup
    );
    const removeContentGroup = contentGroup.filter(
      (cg) => cg.id !== contentGroupId
    );
    yield put(setContentGroup(removeContentGroup));

    // update content in store
    const contents = yield select((state) => state.content.contents);
    const removeContent = contents.filter(
      (ct) => ct.contentGroupId !== contentGroupId
    );
    yield put(setContents(removeContent));

    // update content in store
    const contentMeta = yield select((state) => state.contentMeta.contentMeta);
    const removeContentMeta = contentMeta.filter(
      (cm) =>
        !(cm.entityType === "contentGroup" && cm.entityId === contentGroupId)
    );
    yield put(setContentMeta(removeContentMeta));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

// delete array of content/contentGroup without relations (for ML)
function* fetchArrDeleteContentGroupFlow(action) {
  try {
    const mediaFiles = action.payload;
    const contentGroupIdsMediaFiles = getContentGroupMediaFiles(mediaFiles).map(
      ({ id }) => id
    );
    const playlistIdsMediaFiles = getPlaylistMediaFiles(mediaFiles).map(
      ({ id }) => id
    );

    yield all(
      contentGroupIdsMediaFiles.map((id) => put(fetchDeleteContentGroup(id)))
    );
    yield all(playlistIdsMediaFiles.map((id) => put(fetchDeletePlaylist(id))));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

export function* addPlaylistToPlaylist({ entityId, playlistId, order }) {
  const user = storage.getUser();
  const ownerId = user.userId;
  const entityType = "playlist";

  const contentMetaData = { order, playlistId, ownerId, entityId, entityType };

  const createdContentMeta = yield call(
    createContentMeta,
    playlistId,
    contentMetaData
  );
  const linkedContentMeta = yield select(
    (state) => state.contentMeta.linkedContentMetaIds
  );
  yield put(
    setLinkedContentMetaIds([...linkedContentMeta, createdContentMeta.id])
  );

  return createdContentMeta;
}

/* ******** ADD CONTENT GROUP TO PLAYLIST FROM MEDIA LIBRARY ************* */
function* fetchAddContentGroupsToNotExistPlaylistFromMediaFlow(action) {
  yield put(fetchScreenStart());
  yield put(createPlaylistStart());
  try {
    yield put(createPlaylistStart());
    const { acceptedFiles, name, screenSizeId } = action.payload;

    const orderedAcceptedFiles = acceptedFiles.map((item, idx) => ({
      ...item,
      order: idx,
    }));
    const contentGroupsMediaFiles = getContentGroupMediaFiles(
      orderedAcceptedFiles
    );
    const playlistsMediaFiles = getPlaylistMediaFiles(orderedAcceptedFiles);

    const isScreen = yield select((state) => state.screens.isScreen);
    const playlists = yield select((state) => state.playlists.playlists);
    const defineName =
      name === "" ? generateEntityName(playlists, "Playlist") : name;
    const changeName = isScreen
      ? generateEntityName(playlists, "Playlist")
      : defineName;
    // create new playlist
    const resNewPlaylist = yield call(
      fetchRequestCreateNewPlaylist,
      changeName
    );

    const contentMeta = yield select((state) => state.contentMeta.contentMeta);
    const contentMetaArr = yield all(
      playlistsMediaFiles.map(({ id: entityId, order }) =>
        addPlaylistToPlaylist({
          entityId,
          playlistId: resNewPlaylist.id,
          order,
        })
      )
    );
    yield put(setContentMeta([...contentMeta, ...contentMetaArr]));

    // const allContentsMeta = yield select(state => state.contentMeta.contentMeta);
    // yield put(setContentMeta([...allContentsMeta, createdContentMeta]));

    // @TODO implement promise all logic (remove setTimeout)
    // add contentGroup to playlist
    yield all(
      contentGroupsMediaFiles.map(({ id: entityId, order }) =>
        put(fetchUpdatePlaylistContentGroup(resNewPlaylist.id, entityId, order))
      )
    );
    yield call(delay, 1500);

    // show notification with capability remove added files
    const getLinkedContentMeta = yield select(
      (state) => state.contentMeta.linkedContentMetaIds
    );

    yield put(
      showNotification({
        level: "info",
        autoDismiss: 5,
        message: `You have successfully added ${acceptedFiles.length} file${acceptedFiles.length > 1 ? "s" : ""
          }`,
        children: (
          <ButtonUndo
            playlistId={resNewPlaylist.id}
            getLinkedContentMeta={getLinkedContentMeta}
          />
        ),
      })
    );

    // if create from screen then create screen too
    if (isScreen) {
      const resNewScreen = yield call(fetchRequestCreateScreen, {
        screenSizeId,
        name,
      });
      const getScreens = yield select((state) => state.screens.screens);
      yield put(fetchAddPlaylistToScreen(resNewScreen.id, resNewPlaylist.id));
      yield put(setPlaylists([...playlists, resNewPlaylist]));
      yield put(setScreens([...getScreens, resNewScreen]));
      if (history.location.pathname === "/screens/new") {
        const to = `/screens/${resNewScreen.id}`;
        yield call(history.push, `${process.env.PUBLIC_URL}${to}`);
      }
    } else {
      yield put(setPlaylists([...playlists, resNewPlaylist]));
      if (history.location.pathname === "/playlists/new") {
        const to = `/playlists/${resNewPlaylist.id}`;
        yield call(history.push, `${process.env.PUBLIC_URL}${to}`);
      }
    }

    if (!isScreen) {
      const to = `/playlists/${resNewPlaylist.id}`;
      history.push(`${process.env.PUBLIC_URL}${to}`);
    }

    yield put(fetchScreenFinished());
    yield put(createPlaylistEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchScreenFinished());
    yield put(createPlaylistEnd());
  }
}

function* fetchAddContentGroupsToExistPlaylistFromMediaFlow(action) {
  try {
    const { acceptedFiles, playlistId } = action.payload;
    mixpanel.track("Add content to playlist from media library", {
      acceptedFiles,
      playlistId,
    });

    const playlists = yield select(getPlaylists);
    const programmes = yield select(getProgrammes);
    const currentPlaylist = playlists.find(({ id }) => playlistId === id);

    if (currentPlaylist.type === templateTypes.THEATRE) {
      const options = JSON.parse(currentPlaylist.options);
      const { selectedPlaylistId, selectedProgrammeId } = options;
      const selectedPlaylist = playlists.find(
        ({ id }) => selectedPlaylistId === id
      );

      const selectedProgramme = programmes.find(
        ({ id }) => selectedProgrammeId === id
      );
      if (!selectedProgramme) {
        yield put(
          showNotification({
            level: "info",
            autoDismiss: 5,
            message:
              "You need to choose a theatre programme before adding content",
            children: (
              <Link
                style={{ color: "#fff", marginLeft: 10 }}
                to={`/playlists/${currentPlaylist.id}`}
              >
                Edit
              </Link>
            ),
          })
        );

        return;
      }

      let payload;
      if (!selectedPlaylist) {
        const newPlaylist = yield* createPlaylist({
          type: "default",
          name: generateEntityName(playlists, "Playlist"),
        });
        yield put(setPlaylists([...playlists, newPlaylist]));

        const updatedOptions = {
          ...options,
          selectedPlaylistId: newPlaylist.id,
        };

        yield* updatePlaylistFlow({
          payload: {
            playlistId,
            formData: { options: updatedOptions },
            withNotification: false,
          },
        });

        payload = { acceptedFiles, playlistId: newPlaylist.id };
      } else {
        payload = { acceptedFiles, playlistId: selectedPlaylistId };
      }

      yield* fetchAddContentGroupsToExistPlaylistFromMediaFlow({ payload });
      return;
    }

    const orderedAcceptedFiles = acceptedFiles.map((item, idx) => ({
      ...item,
      order: idx,
    }));
    const contentGroupsMediaFiles = getContentGroupMediaFiles(
      orderedAcceptedFiles
    );
    const playlistsMediaFiles = getPlaylistMediaFiles(orderedAcceptedFiles);

    // get length contentGroups of playlist for make order
    const resGetContentMetaOfPlaylist = yield call(
      fetchRequestGetContentMetaOfPlaylist,
      playlistId
    );
    const getLengthOfContentMeta = resGetContentMetaOfPlaylist.length;

    // add contentGroup from media library
    // @TODO implement promise all logic (remove setTimeout)
    yield all(
      contentGroupsMediaFiles.map(({ id: entityId, order }) =>
        call(fetchUpdatePlaylistContentGroupFlow, {
          type: types.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP,
          payload: {
            playlistId,
            entityId,
            order: order + getLengthOfContentMeta,
          },
        })
      )
    );

    const contentMeta = yield select((state) => state.contentMeta.contentMeta);
    const contentMetaArr = yield all(
      playlistsMediaFiles.map(({ id: entityId, order }) =>
        addPlaylistToPlaylist({
          entityId,
          playlistId,
          order: order + getLengthOfContentMeta,
        })
      )
    );

    yield put(setContentMeta([...contentMeta, ...contentMetaArr]));
    yield call(delay, 500);

    // reorder for right order (not repeated number of order)
    const finalContentMeta = yield select(
      (state) => state.contentMeta.contentMeta
    );
    const currentPlaylistMeta = finalContentMeta.filter(
      (meta) => meta.playlistId === playlistId
    );
    const sortContentMeta = currentPlaylistMeta.sort(
      (a, b) => a.order - b.order
    );
    const playlistReorder = sortContentMeta.map((meta, index) => ({
      ...meta,
      order: index,
    }));
    const removeOldPlaylistMeta = finalContentMeta.filter(
      (meta) => meta.playlistId !== playlistId
    );
    yield put(setContentMeta([...removeOldPlaylistMeta, ...playlistReorder]));
    const restructData = playlistReorder.map((mt) => ({
      order: mt.order,
      contentMetaId: mt.id,
    }));
    yield put(fetchReorderContentGroupMeta(playlistId, restructData));

    // show notification with capability remove added files
    const getLinkedContentMeta = yield select(
      (state) => state.contentMeta.linkedContentMetaIds
    );

    yield put(
      showNotification({
        level: "info",
        autoDismiss: 5,
        message: `You have successfully added ${acceptedFiles.length} file${acceptedFiles.length > 1 ? "s" : ""
          }`,
        children: (
          <ButtonUndo
            playlistId={playlistId}
            getLinkedContentMeta={getLinkedContentMeta}
          />
        ),
      })
    );

    yield put({
      type: types.FETCH_ADD_CONTENT_GROUPS_EXIST_PLAYLIST_FROM_MEDIA_END,
      payload: { playlistId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

/* ******** CREATE CONTENT GROUP AND SET TO PLAYLIST (WITH OUT MEDIA LIBRARY) ************* */
function* fetchCreateContentGroupSetToNotExistPlaylistFlow(action) {
  yield put(fetchScreenStart());
  yield put(createPlaylistStart());
  try {
    yield put(createPlaylistStart());
    const { uploadFiles, name, screenSizeId } = action.payload;
    // define playlist name
    const screenSizes = yield select((state) => state.screenSizes.screenSizes);
    const isScreen = yield select((state) => state.screens.isScreen);
    const getAllPlaylists = yield select((state) => state.playlists.playlists);
    const changeName = isScreen
      ? `Playlist ${getAllPlaylists.length + 1}`
      : name;
    // create new playlist
    const resNewPlaylist = yield call(
      fetchRequestCreateNewPlaylist,
      changeName
    );

    if (resNewPlaylist)
      yield put(
        showNotification({
          level: "info",
          autoDismiss: 1,
          message: "The playlist was created",
        })
      );

    yield all(
      uploadFiles.map(function* gen(file, index) {
        const fileType = getFileType(file.type);
        const processedFile = getProcessedFile(file);

        const order = index;
        yield put(
          fetchCreateContentGroupSetToPlaylist({
            fileData: processedFile,
            fileType,
            order,
            playlistId: resNewPlaylist.id,
          })
        );
      })
    );

    if (isScreen) {
      const resNewScreen = yield call(fetchRequestCreateScreen, {
        screenSizeId,
        name,
      });
      const getScreens = yield select((state) => state.screens.screens);
      yield put(fetchAddPlaylistToScreen(resNewScreen.id, resNewPlaylist.id));
      yield put(setScreens([...getScreens, resNewScreen]));
      if (history.location.pathname === "/screens/new") {
        const to = `/screens/${resNewScreen.id}`;
        yield call(history.push, `${process.env.PUBLIC_URL}${to}`);
      }
    } else {
      const to = `/playlists/${resNewPlaylist.id}`;
      yield call(history.push, `${process.env.PUBLIC_URL}${to}`);
    }

    if (!isScreen) {
      const to = `/playlists/${resNewPlaylist.id}`;
      history.push(`${process.env.PUBLIC_URL}${to}`);
    }

    yield put(setPlaylists([...getAllPlaylists, resNewPlaylist]));
    yield put(fetchScreenFinished());
    yield put(createPlaylistEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchScreenFinished());
    yield put(createPlaylistEnd());
  }
}

function* fetchCreateContentGroupSetToPlaylistFlow(action) {
  try {
    const {
      fileData,
      fileType,
      screenSizeId,
      order,
      playlistId,
    } = action.payload;
    // create content group
    const resContentGroup = yield call(fetchCreateContentGroupFlow, {
      payload: {
        fileData,
        fileType,
        screenSizeId,
        setToPlaylist: true,
        playlistId,
      },
    });

    // if we have forbidden file we receive null in resp
    if (resContentGroup === null) return null;

    if (resContentGroup) {
      yield put(
        fetchUpdatePlaylistContentGroup(playlistId, resContentGroup.id, order)
      );
    }
    // add contentGroup to playlist (with relation)
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchMultipleCreateContentGroupSetToPlaylistFlow(action) {
  try {
    const { uploadFiles, playlistId } = action.payload;
    // get length contentGroups of playlist for make order

    const playlists = yield select(getPlaylists);
    const programmes = yield select(getProgrammes);

    const currentPlaylist = playlists.find(({ id }) => playlistId === id);

    if (currentPlaylist.type === templateTypes.THEATRE) {
      const options = JSON.parse(currentPlaylist.options);
      const { selectedPlaylistId, selectedProgrammeId } = options;

      const selectedProgramme = programmes.find(
        ({ id }) => selectedProgrammeId === id
      );
      if (!selectedProgramme) {
        yield put(
          showNotification({
            level: "info",
            autoDismiss: 5,
            message:
              "You need to choose a theatre programme before adding content",
            children: (
              <Link
                style={{ color: "#fff", marginLeft: 10 }}
                to={`/playlists/${currentPlaylist.id}`}
              >
                Edit
              </Link>
            ),
          })
        );
        return;
      }

      const selectedPlaylist = playlists.find(
        ({ id }) => selectedPlaylistId === id
      );

      let payload;
      if (!selectedPlaylist) {
        const newPlaylist = yield* createPlaylist({
          type: "default",
          name: generateEntityName(playlists, "Playlist"),
        });
        yield put(setPlaylists([...playlists, newPlaylist]));

        const updatedOptions = {
          ...options,
          selectedPlaylistId: newPlaylist.id,
        };

        yield* updatePlaylistFlow({
          payload: {
            playlistId,
            formData: { options: updatedOptions },
            withNotification: false,
          },
        });

        payload = { uploadFiles, playlistId: newPlaylist.id };
      } else {
        payload = { uploadFiles, playlistId: selectedPlaylistId };
      }

      yield* fetchMultipleCreateContentGroupSetToPlaylistFlow({ payload });
      return;
    }

    const screenSizes = yield select((state) => state.screenSizes.screenSizes);
    const resGetContentMetaOfPlaylist = yield call(
      fetchRequestGetContentMetaOfPlaylist,
      playlistId
    );
    const getLengthOfContentMeta = resGetContentMetaOfPlaylist.length;

    yield all(
      uploadFiles.map(function* gen(file, index) {
        const fileType = getFileType(file.type);
        const processedFile = getProcessedFile(file);

        const order = index + getLengthOfContentMeta;

        yield put(
          fetchCreateContentGroupSetToPlaylist({
            fileData: processedFile,
            fileType,
            order,
            playlistId,
          })
        );
      })
    );
    yield call(delay, 500);

    // reorder for right order (not repeated number of order)
    const getContentMeta = yield select(
      (state) => state.contentMeta.contentMeta
    );
    const sortContentMeta = getContentMeta
      .slice()
      .sort((a, b) => a.order - b.order);
    // const reorderOfMeta = getContentMeta.map((contentMeta, index) => ({ ...contentMeta, order: index }));

    // fetch update meta on back end server
    const composForBackEnd = sortContentMeta.map((mt) => ({
      order: mt.order,
      contentMetaId: mt.id,
    }));
    yield put(fetchReorderContentGroupMeta(playlistId, composForBackEnd));
    // yield put(setContentMeta(reorderOfMeta));
    yield put({
      type: types.FETCH_MULTIPLE_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST_END,
      payload: { playlistId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchReorderContentGroupMetaFlow(action) {
  console.log("fetchReorderContentGroupMetaFlow");

  try {
    const { playlistId, newMetaOrder } = action.payload;
    const { url, method } = apiRoutes.updateContentMetaOrder;
    const getContentMeta = yield select(
      (state) => state.contentMeta.contentMeta
    );
    const updateOrderOfMeta = getContentMeta.map((cm) => {
      const findChangedMeta = newMetaOrder.find(
        (cmo) => cmo.contentMetaId === cm.id
      );
      return findChangedMeta ? { ...cm, order: findChangedMeta.order } : cm;
    });
    yield put(setContentMeta(updateOrderOfMeta));
    yield call(fetch, url(playlistId), method, { data: newMetaOrder });
    yield put({
      type: types.FETCH_REORDER_CONTENT_GROUP_META_END,
      payload: { playlistId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

let counter = 0;
// ADD child content to content group
function* fetchCreateRelationForContentWithContentGroupFlow(action) {
  let progressHashName;
  const i = counter++;
  try {
    console.log(`1 ${i}`);
    const {
      currContentGroupId,
      fileData,
      fileType,
      screenSizeId,
      setToPlaylist = false,
    } = action.payload;
    // request to S3 amazon
    const resOfS3 = yield call(fetchS3SignedUrlFlow, {
      type: fileType,
      fileName: fileData.name,
    });
    const { urls, ContentType, path } = resOfS3.data;
    [progressHashName] = urls;

    // set progress bar
    const fileProgressData = {
      uniqueKey: progressHashName,
      name: fileData.name,
      flagField: !setToPlaylist ? "content" : "contentToPlaylist",
      contentType: fileType,
      currContentGroupId,
      screenSizeId,
    };
    yield put(setProgressToContent(progressHashName, 1, 0, fileProgressData));

    // upload content to S3 amazon
    yield call(
      s3upload,
      progressHashName,
      "PUT",
      fileData,
      {
        "Content-Type": ContentType,
      },
      progressHashName,
      fileProgressData
    );

    const type = fileType;
    const screenSizes = yield select((state) => state.screenSizes.screenSizes);
    const defineScreenSizeId = yield call(
      getScreenSize,
      path,
      screenSizes,
      type
    );
    // Add Content to content Group
    if (screenSizeId === defineScreenSizeId) {
      const resContentGroup = yield call(
        fetchRequestAddContentsToContentGroup,
        {
          content: path,
          contentGroupId: currContentGroupId,
          fileName: fileData.name,
          screenSizeId,
          type: fileType,
        }
      );

      const getAllContents = yield select((state) => state.content.contents);
      yield put(setContents([...getAllContents, resContentGroup]));
      yield put(removeProgressFromContent(progressHashName));
      yield put(setScreenSizeId(null));
      yield put(
        showNotification({
          level: "success",
          message: "You have successfully added 1 file",
          autoDismiss: 4,
        })
      );
    } else {
      const resContentGroup = yield call(
        fetchRequestAddContentsToContentGroup,
        {
          content: path,
          contentGroupId: currContentGroupId,
          fileName: fileData.name,
          screenSizeId,
          type: fileType,
        }
      );

      const getAllContents = yield select((state) => state.content.contents);
      yield put(setContents([...getAllContents, resContentGroup]));
      yield put(removeProgressFromContent(progressHashName));
      yield put(setScreenSizeId(null));

      if (fileType === "html") {
        yield put(
          showNotification({
            level: "success",
            message: "You have successfully added 1 file",
            autoDismiss: 4,
          })
        );
      } else {
        yield put(
          showNotification({
            level: "warning",
            message:
              "Please note that uploaded content size doesn’t exactly match the the slot dimensions",
            autoDismiss: 4,
          })
        );
      }
    }

    yield put({
      type: types.FETCH_CREATE_RELATION_FOR_CONTENT_WITH_CONTENT_GROUP_END,
      payload: { contentGroupId: currContentGroupId },
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      const isAbort = true;
      yield put(removeProgressFromContent(progressHashName, isAbort));
      yield put(setScreenSizeId(null));
    } else {
      sentryReport(error);
      const messageError = getErrorMessage(error);
      const { level, message } = messageError;
      yield put(showNotification({ level, message }));
      yield put(removeProgressFromContent(progressHashName));
      yield put(setScreenSizeId(null));
    }
  }
}

function* fetchAddContentToContentGroupFlow(action) {
  try {
    yield put(fetchContentGroupStart());
    // const { contentGroupId, currentScreenSize, uploadFiles } = action.payload;
    const { entityId, currentScreenSize, uploadFile } = action.payload;

    yield put(setScreenSizeId(currentScreenSize));

    const file = uploadFile;
    const fileType = getFileType(file.type);
    const processedFile = getProcessedFile(file);
    yield call(fetchCreateRelationForContentWithContentGroupFlow, {
      payload: {
        fileData: processedFile,
        fileType,
        screenSizeId: currentScreenSize,
        currContentGroupId: entityId,
      },
    });

    yield put(fetchContentGroupFinished());
    yield put({
      type: types.FETCH_ADD_CONTENT_TO_CONTENT_GROUP_END,
      payload: { contentGroupId: entityId },
    });
    console.log("finish");
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchContentGroupFinished());
    yield put(setScreenSizeId(null));
    console.log("error");
  }
}

function* fetchRemoveContentFromContentGroupFlow(action) {
  try {
    const { contentGroupId, contentId } = action.payload;
    const getAllContents = yield select((state) => state.content.contents);
    const updateContent = getAllContents.filter(
      (item) => item.id !== contentId
    );
    yield put(setContents(updateContent));

    const { url, method } = apiRoutes.removeContentFromContentGroup;
    yield call(fetch, url(contentGroupId, contentId), method);
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchCreateUrlContentGroupFlow(action) {
  let progressHashName;
  try {
    const { urlData, urlName } = action.payload;
    const fileType = "url";

    let url = urlData;

    const parsScheme = url.match(/https:/) || url.match(/http:/);
    if (!parsScheme) {
      url = `https://${urlData}`;
    }

    // set progress bar
    progressHashName = url;

    const fileProgressData = {
      name: urlName,
      flagField: "content",
      uniqueKey: progressHashName,
      contentType: fileType,
    };
    yield put(setProgressToContent(progressHashName, 1, 0, fileProgressData));

    // create contentGroup
    const resContentGroup = yield call(fetchRequestCreateContentGroup, {
      type: fileType,
      name: urlName,
    });
    const { id: contentGroupId, type, ownerId } = resContentGroup;
    yield put(setProgressToContent(progressHashName, 1, 0.5, fileProgressData));

    // define content type by url
    const getHeaders = yield call(fetchGetHeaders, encodeURI(url));

    // retrieve content type

    const retrieveContentTypeDataObj = getExtensionOfUrl(
      getHeaders["content-type"]
    );

    if (!retrieveContentTypeDataObj) {
      // remove progress bar
      yield put(setProgressToContent(progressHashName, 1, 1, fileProgressData));
      yield put(removeProgressFromContent(progressHashName));
      // show notification
      yield put(
        showNotification({
          level: "error",
          message: "This website doesn't exist! Please check the URL!",
        })
      );
      return null;
    }

    const { extension } = retrieveContentTypeDataObj;
    // retrieve x-frame-options
    const retrieveXFrameOption = getHeaders["x-frame-options"];
    if (retrieveXFrameOption) {
      // remove progress bar
      yield put(setProgressToContent(progressHashName, 1, 1, fileProgressData));
      yield put(removeProgressFromContent(progressHashName));
      // show notification
      yield put(
        showNotification({
          level: "error",
          message: "This website has X frame option, please change URL!",
        })
      );
      return null;
    }

    // define screen size id
    const screenSizes = yield select((state) => state.screenSizes.screenSizes);
    const getDefaultScreenSize = screenSizes
      .filter((screenSize) => screenSize.default)
      .find((item) => item.name === "Landscape");

    // create content
    const resContent = yield call(fetchRequestCreateContent, {
      content: url,
      screenSizeId: getDefaultScreenSize.id,
      fileName: `${+new Date()}.${extension}`,
      type,
      contentGroupId,
      ownerId,
    });
    yield put(setProgressToContent(progressHashName, 1, 1, fileProgressData));

    // remove progress bar
    yield put(removeProgressFromContent(progressHashName));
    // set content to checklist for checked in media library
    const checklist = yield select((state) => state.contentGroup.checkList);
    yield put(
      setContentGroupToCheckList([
        ...checklist,
        { id: resContentGroup.id, type: "contentGroup" },
      ])
    );
    // set contentGroup to store
    const getAllContentGroup = yield select(
      (state) => state.contentGroup.contentGroup
    );
    yield put(setContentGroup([resContentGroup, ...getAllContentGroup]));
    // set content to store
    const getAllContents = yield select((state) => state.content.contents);
    yield put(setContents([resContent, ...getAllContents]));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(removeProgressFromContent(progressHashName));
  }
}

// function* addPlaylistToPlaylistFlow({ payload: { entityId, playlistId } }) {
//   yield put(addPlaylistToPlaylistStart());
//   try {
//     const allContentsMeta = yield select(state => state.contentMeta.contentMeta);
//     const playlistContentsMeta = allContentsMeta.filter(cm => cm.playlistId === playlistId);

//     const user = storage.getUser();
//     const ownerId = user.userId;
//     const order = playlistContentsMeta.length;
//     const entityType = 'playlist';

//     const contentMetaData = { order, playlistId, ownerId, entityId, entityType };

//     const createdContentMeta = yield call(createContentMeta, playlistId, contentMetaData);
//     yield put(setContentMeta([...allContentsMeta, createdContentMeta]));
//   } catch (error) {
//     yield put(showNotification(getErrorMessage(error)));
//   } finally {
//     yield put(addPlaylistToPlaylistEnd());
//   }
// }

function* createPlaylistAndAddContentMetaFlow({
  payload: { formData, mediaFiles },
}) {
  yield put(createPlaylistStart());
  try {
    const newPlaylist = yield call(createPlaylist, formData);

    const orderedMediaFiles = mediaFiles.map((item, idx) => ({
      ...item,
      order: idx,
    }));
    const contentGroupsMediaFiles = getContentGroupMediaFiles(
      orderedMediaFiles
    );
    const playlistsMediaFiles = getPlaylistMediaFiles(orderedMediaFiles);

    const contentMeta = yield select((state) => state.contentMeta.contentMeta);
    const contentMetaArr = yield all(
      playlistsMediaFiles.map(({ id: entityId, order }) =>
        addPlaylistToPlaylist({ entityId, playlistId: newPlaylist.id, order })
      )
    );

    yield put(setContentMeta([...contentMeta, ...contentMetaArr]));

    yield all(
      contentGroupsMediaFiles.map(({ id: entityId, order }) =>
        put(fetchUpdatePlaylistContentGroup(newPlaylist.id, entityId, order))
      )
    );
    yield call(delay, 1500);

    const playlists = yield select(getPlaylists);
    yield put(setPlaylists([...playlists, newPlaylist]));

    const to = `/playlists/${newPlaylist.id}`;
    history.push(`${process.env.PUBLIC_URL}${to}`);

    yield put(createPlaylistEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(createPlaylistEnd());
  }
}

export default [
  // takeEvery(types.ADD_PLAYLIST_TO_PLAYLIST, addPlaylistToPlaylistFlow),
  takeEvery(
    types.CREATE_PLAYLIST_AND_ADD_CONTENT_META,
    createPlaylistAndAddContentMetaFlow
  ),
  takeEvery(types.FETCH_CREATE_CONTENT_GROUP, fetchCreateContentGroupFlow),
  takeEvery(
    types.FETCH_MULTIPLE_CREATE_CONTENT_GROUP,
    fetchMultipleCreateContentGroupFlow
  ),
  takeEvery(types.FETCH_DELETE_CONTENT_GROUP, fetchDeleteContentGroupFlow),
  takeEvery(
    types.FETCH_ARR_DELETE_CONTENT_GROUP,
    fetchArrDeleteContentGroupFlow
  ),
  takeLatest(
    types.FETCH_ADD_CONTENT_GROUPS_NOTEXIST_PLAYLIST_FROM_MEDIA,
    fetchAddContentGroupsToNotExistPlaylistFromMediaFlow
  ),
  takeEvery(
    types.FETCH_ADD_CONTENT_GROUPS_EXIST_PLAYLIST_FROM_MEDIA,
    fetchAddContentGroupsToExistPlaylistFromMediaFlow
  ),
  takeEvery(
    types.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP,
    fetchUpdatePlaylistContentGroupFlow
  ),
  takeEvery(
    types.FETCH_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST,
    fetchCreateContentGroupSetToPlaylistFlow
  ),
  takeEvery(
    types.FETCH_MULTIPLE_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST,
    fetchMultipleCreateContentGroupSetToPlaylistFlow
  ),
  takeLatest(
    types.FETCH_CREATE_CONTENT_GROUP_SET_TO_NOTEXIST_PLAYLIST,
    fetchCreateContentGroupSetToNotExistPlaylistFlow
  ),
  takeLatest(
    types.FETCH_REORDER_CONTENT_GROUP_META,
    fetchReorderContentGroupMetaFlow
  ),
  takeEvery(
    types.FETCH_ADD_CONTENT_TO_CONTENT_GROUP,
    fetchAddContentToContentGroupFlow
  ),
  takeEvery(
    types.FETCH_CREATE_RELATION_FOR_CONTENT_WITH_CONTENT_GROUP,
    fetchCreateRelationForContentWithContentGroupFlow
  ),
  takeEvery(
    types.FETCH_REMOVE_CONTENT_FROM_CONTENT_GROUP,
    fetchRemoveContentFromContentGroupFlow
  ),
  takeEvery(types.FETCH_ABORT_UPLOAD_FILE, fetchAbortUploadFileFlow),
  takeEvery(
    types.FETCH_CREATE_URL_CONTENT_GROUP,
    fetchCreateUrlContentGroupFlow
  ),
  takeEvery(
    types.ADD_FULL_PROGRAMME_TO_PLAYLIST,
    addFullProgrammeToPlaylistFlow
  ),
];
