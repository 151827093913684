import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import styles from './PlaylistNameForm.module.scss';
import EntityNameField from '../../../../common/components/UI/EntityNameField/EntityNameField';
import { normalizeExtraSpaces } from '../../../../../utils/validation';
import { validation } from '../../../../../utils';

function PlaylistNameForm({ onChangeName }) {
  return (
    <form className={styles.container}>
      <div style={{ marginBottom: '24px' }}>
        <Field
          name="name"
          type="text"
          component={EntityNameField}
          placeholder="Enter playlist name"
          validate={[validation.required, validation.isSpaceChar]}
          onBlur={onChangeName}
          normalize={normalizeExtraSpaces}
        />
      </div>
      <Field hidden name="id" component="input" />
      <Field hidden name="ownerId" component="input" />
    </form>
  );
}

PlaylistNameForm.propTypes = {
  onChangeName: PropTypes.func,
};

export default reduxForm({
  form: 'detailedForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: false,
})(PlaylistNameForm);
