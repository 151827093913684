export const getFormatedPlaylist = ({ options, ...playlist }) => {
  const normalizedOptions = options instanceof Object ? JSON.stringify(options) : options;

  return {
    options: normalizedOptions,
    ...playlist,
  };
};

export const getFormatedPlaylists = playlists => playlists.map(getFormatedPlaylist);
