import {
  put,
  all,
  call,
  select,
  takeLatest,
  takeEvery,
  fork,
} from "redux-saga/effects";
import { delay } from "redux-saga";
import * as types from "./types";
import {
  fetch,
  storage,
  apiRoutes,
  sentryReport,
  getErrorMessage,
  history,
  mixpanel,
} from "../../utils";
import { showNotification } from "../app/actions";
import {
  setProgrammes,
  createNewTheatreStart,
  createNewTheatreEnd,
  updateProgrammeSuccess,
} from "./actions";
import { sessionsActions } from "../sessions";
import { speakersActions } from "../speakers";
import { getProgrammes, getProgrammesWithDataFeed } from "./selectors";
import handleError from "../../utils/handle-error";
import insertItem from "../../utils/insert-item";
import updateItemBy from "../../utils/update-item-by";
import { getIsAuthenticated, getUser } from "../auth/selectors";

const { setSessions, setSessionModal } = sessionsActions;
const { setSpeakers } = speakersActions;

const COLUMN_TYPES = {
  CLOCK: 'CLOCK',
  HTML: 'HTML',
  IMAGE: 'IMAGE',
  BLANK: 'BLANK',
}

const DEFAULT_HEADER_COLUMNS = [
  {
    type: COLUMN_TYPES.CLOCK,
    width: 20,
    content: ''
  },
  {
    type: COLUMN_TYPES.HTML,
    width: 80,
    content: 'HTML Placeholder',
  },
]

function* createNewTheatreProgramFlow(action) {
  try {
    yield put(createNewTheatreStart());

    const { title } = action.payload;
    const user = storage.getUser();
    const { url, method } = apiRoutes.createTheatreProgram;
    const requestBody = {
      title,
      htmlTitle: title,
      image: null,
      externalId: null,
      options: JSON.stringify({
        sync: false,
        color: {
          hex: '#E5E5E5',
          rgb: { r: 229, g: 229, b: 229 },
        },
        autoShortenDescription: false,
        descriptionLines: 0,
        headerColumns: DEFAULT_HEADER_COLUMNS,
      }),
      dataFeedId: null,
    };
    const createdProgramme = yield call(
      fetch,
      url(user.userId),
      method,
      requestBody
    );
    mixpanel.track("Create new theatre", { theatreTitle: title });

    const programmes = yield select((state) => state.programmes.programmes);
    yield put(setProgrammes([...programmes, createdProgramme]));
    yield call(history.push, `/programmes/${createdProgramme.id}`);
    yield put(createNewTheatreEnd());
    return createdProgramme;
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: "error", message: messageError }));
    yield put(createNewTheatreEnd());
    return {};
  }
}

function* removeTheatreProgramFlow(action) {
  try {
    const { programmeId } = action.payload;

    const user = storage.getUser();
    const { url, method } = apiRoutes.removeTheatreProgram;
    yield call(fetch, url(user.userId, programmeId), method);

    const programmes = yield select((state) => state.programmes.programmes);
    const removeProgram = programmes.filter((pr) => pr.id !== programmeId);
    yield put(setProgrammes(removeProgram));
    yield put(
      showNotification({
        level: "info",
        autoDismiss: 1,
        message: "The programme was deleted",
      })
    );

    const sessions = yield select((state) => state.sessions.sessions);
    yield put(
      setSessions(
        sessions.filter((session) => session.programmeId !== programmeId)
      )
    );

    const speakers = yield select((state) => state.speakers.speakers);
    const removedSessions = sessions.filter(
      (session) => session.programmeId === programmeId
    );
    const removeSpeaker = speakers.filter(
      (speaker) =>
        !removedSessions.find((session) => session.id === speaker.sessionId)
    );
    yield put(setSpeakers(removeSpeaker));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: "error", message: messageError }));
  }
}

function* updateProgramme({ payload: { programmeId, body } }) {
  const programmes = yield select(getProgrammes);
  const currentProgrammeIndex = programmes.findIndex(
    ({ id }) => id === programmeId
  );
  console.log('body', body);
  const currentProgramme = programmes[currentProgrammeIndex];

  try {
    const updatedProgramme = {
      ...currentProgramme,
      ...body,
    };

    const updatedProgrammes = updateItemBy(programmes, "id", updatedProgramme);
    yield put(setProgrammes(updatedProgrammes));

    const user = storage.getUser();
    const { url, method } = apiRoutes.updateTheatreProgram;
    yield call(fetch, url(user.userId, programmeId), method, body);

    yield put(updateProgrammeSuccess({ programmeId }));
  } catch (error) {
    yield put(setProgrammes(programmes));
    throw error;
  }
}

function* fetchChangeTheatreNameFlow(action) {
  try {
    const { title, programmeId } = action.payload;
    yield* updateProgramme({ payload: { programmeId, body: { title } } });
    yield put({
      type: types.FETCH_CHANGE_THEATRE_NAME_END,
      payload: { programmeId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: "error", message: messageError }));
  }
}

function* fetchChangeTheatreHTMLTitleFlow(action) {
  try {
    console.log('UPDATING...', action.payload);
    const { htmlTitle, programmeId } = action.payload;
    yield* updateProgramme({ payload: { programmeId, body: { htmlTitle } } });
    yield put({
      type: types.FETCH_CHANGE_THEATRE_NAME_END,
      payload: { programmeId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: "error", message: messageError }));
  }
}

function* changeTheatreOptionsFlow({ payload }) {
  try {
    const { options, programmeId } = payload;
    yield* updateProgramme({ payload: { programmeId, body: { options } } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: "error", message: messageError }));
  }
}

function* changeTheatreImageFlow({ payload }) {
  try {
    const { image, programmeId } = payload;
    yield* updateProgramme({ payload: { programmeId, body: { image } } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: "error", message: messageError }));
  }
}

function* fetchCreateTheatreOpenSessionFlow(action) {
  try {
    const { title } = action.payload;
    const createTheatre = yield call(createNewTheatreProgramFlow, {
      payload: { title },
    });

    yield put(setSessionModal(true, "new", createTheatre.id));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: "error", message: messageError }));
  }
}

function* toggleSyncOfProgrammeFlow(action) {
  const { payload: programmeId } = action;
  try {
    const programmes = yield select(getProgrammesWithDataFeed);
    const currentProgramme = programmes.find(({ id }) => id === programmeId);
    const currentOptions = currentProgramme.options || {};
    const options = JSON.stringify({
      ...currentOptions,
      sync: !currentOptions.sync,
    });
    yield* updateProgramme({ payload: { programmeId, body: { options } } });
  } catch (e) {
    handleError(e);
  }
}

export function* toggleSyncOfProgrammes(programmeIds) {
  yield all(programmeIds.map(toggleSyncOfProgrammeFlow));
}

export function* fetchProgrammesFlow() {
  try {
    const { url, method } = apiRoutes.getProgrammes;
    const { id: userId } = yield select(getUser);
    const programmes = yield call(fetch, url(userId), method);
    yield put(setProgrammes(programmes));
  } catch (e) {
    // \_/)
    // (o.o)
    // (___)0
  }
}

function* watchProgrammesChanges() {
  const minutes = (minCount) => 1000 * (60 * minCount);

  while (true) {
    yield delay(minutes(0.8));

    const isAuthenticated = yield select(getIsAuthenticated);
    if (isAuthenticated) {
      yield* fetchProgrammesFlow();
    }
  }
}

export default [
  fork(watchProgrammesChanges),
  takeEvery(types.TOGGLE_SYNC_OF_PROGRAMME, toggleSyncOfProgrammeFlow),
  takeEvery(types.CHANGE_THEATRE_IMAGE, changeTheatreImageFlow),
  takeEvery(types.FETCH_CHANGE_THEATRE_OPTIONS, changeTheatreOptionsFlow),
  takeLatest(types.CREATE_NEW_THEATRE, createNewTheatreProgramFlow),
  takeEvery(types.REMOVE_THEATRE, removeTheatreProgramFlow),
  takeLatest(types.FETCH_CHANGE_THEATRE_NAME, fetchChangeTheatreNameFlow),
  takeLatest(types.FETCH_CHANGE_THEATRE_HTML_TITLE, fetchChangeTheatreHTMLTitleFlow),
  takeLatest(
    types.FETCH_CREATE_THEATRE_OPEN_SESSION,
    fetchCreateTheatreOpenSessionFlow
  ),
];
