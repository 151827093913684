import React, { Component } from 'react';
import ReactList from 'react-list';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import { compose } from 'redux';
import styles from './ScheduleView.module.scss';
import { getFlatPlaylist, getCurrentScreen } from '../../../../../state/ducks/screens/selectors';
import ContentIcons from '../../../../common/components/UI/ContentIcons';
import TimeField from '../../../../common/components/UI/TimeField';
import DatePicker from '../../../../common/components/UI/DatePicker';

class ScheduleView extends Component {
  state = {
    isFocused: false,
  };

  getTitle = () => {
    const { screens, match } = this.props;
    const currentScreen = screens.find(s => +s.id === +match.params.id);
    return currentScreen ? currentScreen.name : 'Screen';
  };

  renderItem = (index, key, playlist) => {
    const item = playlist[index];
    const { name, type, date, timeStart, timeEnd } = item;
    return (
      <div className={styles.root} key={key}>
        <div className={styles.container}>
          <div className={styles.contentIcon}>
            <ContentIcons type={type} />
          </div>
          <span className={styles.name}>{name}</span>
          <span className={styles.duration}>{`${date} ${timeStart} - ${timeEnd}`}</span>
        </div>
      </div>
    );
  };

  renderScheduleItems = playlists => (
    <div className={styles.itemsContainersWrapper}>
      {playlists.map((playlist, plIndex) => (
        <div key={plIndex} className={cn(styles.itemsContainer, styles.itemsContainerBordered)}>
          {!playlist.length && <span className={styles.noContent}>No content</span>}
          <ReactList
            itemRenderer={(index, key) => this.renderItem(index, key, playlist)}
            length={playlist.length}
            type="uniform"
          />
        </div>
      ))}
    </div>
  );

  handleFocus = () => this.setState({ isFocused: true });

  handleBlur = () => this.setState({ isFocused: false });

  render() {
    if (!this.props.hasCurrentScreen) {
      return <Redirect to="/screens" />;
    }

    const { playlists, isFilterTime, reset } = this.props;
    const fieldProps = { onBlur: this.handleBlur, onFocus: this.handleFocus };

    const { isFocused } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>{this.getTitle()}</div>
        <div className={styles.subheader}>
          Schedule
          <div className={cn(styles.timeRange, isFocused && styles.containerIsFocused)}>
            {isFilterTime && (
              <span onClick={reset} className={styles.clearBtn}>
                Clear
              </span>
            )}
            <Field
              name="from"
              id="add-time-start-session"
              className={styles.item}
              component={TimeField}
              type="text"
              placeholder="00:01"
              customLabelClass={styles.customLabel}
              autoComplete="off"
              {...fieldProps}
            />
            <span className={styles.separator}>to</span>
            <Field
              name="to"
              id="add-time-start-session"
              className={styles.item}
              component={TimeField}
              type="text"
              placeholder="23:00"
              customLabelClass={styles.customLabel}
              autoComplete="off"
              {...fieldProps}
            />
            <Field
              name="day"
              minDate={new Date()}
              className={styles.dateField}
              inputClassName={styles.item}
              component={DatePicker}
              customFormat="dddd Do MMM"
              {...fieldProps}
            />
          </div>
        </div>
        {this.renderScheduleItems(playlists)}
      </div>
    );
  }
}

ScheduleView.propTypes = {
  playlists: PropTypes.array,
  screens: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  hasCurrentScreen: PropTypes.bool.isRequired,
};

ScheduleView.defaultProps = {
  playlists: [],
};

const selector = formValueSelector('schedule');

const mapStateToProps = (state, ownProps) => {
  const currentScreen = getCurrentScreen(state, ownProps);

  if (!currentScreen) {
    return {
      playlists: [],
      hasCurrentScreen: false,
      isFilterTime: false,
    };
  }

  return {
    hasCurrentScreen: true,
    playlists: getFlatPlaylist(state, ownProps),
    isFilterTime: !!selector(state, 'from') || !!selector(state, 'to') || !!selector(state, 'day'),
  };
};

const mapDispatchToProps = {};

export default compose(
  reduxForm({
    form: 'schedule',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ScheduleView);
