export default function getDurationVideo(url) {
  return new Promise(resolve => {
    const video = document.createElement('video');
    video.src = url;

    video.onloadedmetadata = function(event) {
      const { duration } = event.target;
      resolve(Math.floor(duration));
    };
  });
}
