import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './DataFeed.module.scss';
import Feeds from './components/Feeds';

import CreateFeedModal from './components/CreateFeedModal';
import EditFeedModal from './components/EditFeedModal';
import { dataFeedsActions, dataFeedsSelectors } from '../../../../../state/ducks/dataFeeds';
import {
  getEditFeedId,
  getIsOpenCreateFeedModal,
  getIsCreatingDataFeed,
} from '../../../../../state/ducks/dataFeeds/selectors';
import { programmesActions } from '../../../../../state/ducks/programmes';

const { getDataFeedApis, getDataFeedList } = dataFeedsSelectors;

class DataFeed extends React.Component {
  render() {
    const {
      editFeedId,
      isOpenCreateFeedModal,
      dataFeedApis,
      dataFeedList,
      deleteDataFeedConfirm,
      ...rest
    } = this.props;
    const {
      isCreatingDataFeed,
      closeEditModal,
      openEditModal,
      openCreateModal,
      closeCreateModal,
      toggleSyncOfProgramme,
      onWrapperClick,
      wrapperId,
    } = rest;

    return (
      <React.Fragment>
        <Feeds
          className={styles.root}
          dataFeedList={dataFeedList}
          onOpenCreateModal={openCreateModal}
          onOpenEditModal={openEditModal}
          onDeleteDataFeed={deleteDataFeedConfirm}
          onToggleSyncOfProgramme={toggleSyncOfProgramme}
          onWrapperClick={onWrapperClick}
          wrapperId={wrapperId}
        />
        {isOpenCreateFeedModal && (
          <CreateFeedModal
            isOpen={isOpenCreateFeedModal}
            dataFeedList={dataFeedList}
            onClose={closeCreateModal}
            dataFeedApis={dataFeedApis}
            isLoading={isCreatingDataFeed}
          />
        )}
        {Boolean(editFeedId) && (
          <EditFeedModal
            editFeedId={editFeedId}
            isOpen={Boolean(editFeedId)}
            onClose={closeEditModal}
            dataFeedApis={dataFeedApis}
          />
        )}
      </React.Fragment>
    );
  }
}

DataFeed.propTypes = {
  dataFeedApis: PropTypes.array.isRequired,
  dataFeedList: PropTypes.array.isRequired,
  deleteDataFeedConfirm: PropTypes.func.isRequired,
  closeEditModal: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  openCreateModal: PropTypes.func.isRequired,
  closeCreateModal: PropTypes.func.isRequired,
  toggleSyncOfProgramme: PropTypes.func.isRequired,
  editFeedId: PropTypes.number,
  isOpenCreateFeedModal: PropTypes.bool,
  onWrapperClick: PropTypes.func.isRequired,
  wrapperId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  dataFeedApis: getDataFeedApis(state),
  dataFeedList: getDataFeedList(state),
  editFeedId: getEditFeedId(state),
  isOpenCreateFeedModal: getIsOpenCreateFeedModal(state),
  isCreatingDataFeed: getIsCreatingDataFeed(state),
});

export default connect(
  mapStateToProps,
  { ...dataFeedsActions, ...programmesActions },
)(DataFeed);
