import * as types from './types';

// sync
export const setContentMeta = contentMeta => ({ type: types.SET_CONTENT_META, payload: contentMeta });
export const addContentMeta = contentMeta => ({ type: types.ADD_CONTENT_META, payload: contentMeta });
export const setLinkedContentMetaIds = linkedContentMetaIds => ({
  type: types.SET_LINKED_CONTENT_META_IDS,
  payload: linkedContentMetaIds,
});

// async
