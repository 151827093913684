import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import styles from "./ScreensView.module.scss";
import AddScreenFormContainer from "../AddScreenFormContainer";
import ScreenDetailsView from "../ScreenDetailsView";
import ScheduleView from "../ScheduleView";
import generateEntityName from "../../../../../utils/generateName";
import ScreenCard from "../ScreenCard";
import NewScreenBtn from "../NewScreenBtn";
import OpenInPlayerBtn from "../../../../common/components/UI/OpenInPlayerBtn";

import getScreenOrientationIcon from "./getScreenOrientationIcon";
import ScreensPreview from "../ScreensPreview/ScreensPreview";

const REACT_APP_PLAYER_URL = process.env.REACT_APP_PLAYER_URL;
console.log('REACT_APP_PLAYER_URL:', REACT_APP_PLAYER_URL);
class ScreensView extends React.Component {
  state = {
    toScreens: false,
    filteredScreens: [],
  };

  handleClickOnCreateNewScreen = () => {
    const { handleClickCloseEdit, changeReduxForm, screenSizeList, screens } =
      this.props;
    const defaultScreenSize = screenSizeList.find(
      (ss) => ss.name === "Portrait"
    );
    changeReduxForm(
      "addScreens",
      "name",
      generateEntityName(screens, "Screen")
    );
    changeReduxForm(
      "sizeForm",
      "screenSizeId",
      defaultScreenSize ? defaultScreenSize.id : 0
    );
    handleClickCloseEdit();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.toScreens === false && this.state.toScreens === true) {
      this.setState({ toScreens: false });
    }
    if (prevProps.screens.length !== this.props.screens.length) {
      this.setState({ filteredScreens: this.props.screens });
    }
  }

  componentDidMount() {
    this.setState({ filteredScreens: this.props.screens });
  }

  handleFilterScreens = (e) => {
    const keyword = e.target.value;
    const { screens } = this.props;
    const filteredScreens = screens.filter((screen) =>
      screen.name.toLowerCase().includes(keyword.toLowerCase())
    );
    this.setState({ filteredScreens });
  };

  handleCloseScreen = (e) => {
    e.preventDefault();
    if (e.target.id !== "closeScreenId") return;
    const { currentLocation } = this.props;
    if (currentLocation !== "/screens") {
      this.setState({ toScreens: true });
    }
  };

  render() {
    const {
      screens,
      currentLocation,
      removeScreen,
      wsInit,
      dataFetched,
      user,
    } = this.props;
    if (!dataFetched) {
      return (
        <div className={styles.container}>
          <div className={styles.screensList} />
        </div>
      );
    }

    // const isOpenAnyScreen = currentLocation.split('/').length === 3;
    const { filteredScreens } = this.state;
    const hasScreens = screens.length > 0 || currentLocation === "/screens/new";
    return (
      <div className={styles.container}>
        <div
          className={styles.screensList}
          onClick={this.handleCloseScreen}
          id="closeScreenId"
        >
          <div className={styles.wrapper}>
            <input placeholder='Search screens' onChange={this.handleFilterScreens} className={styles.inputSearch} type='text' />
          </div>
          <hr className={styles.screenSearchHr} />
          {!hasScreens ? (
            <ScreensPreview
              onCreateNewScreen={this.handleClickOnCreateNewScreen}
            />
          ) : (
            <>
              <NewScreenBtn
                linkTo={"/screens/new"}
                onClick={this.handleClickOnCreateNewScreen}
                isActive={currentLocation === "/screens/new"}
              />
              {filteredScreens.map((screen) => {
                const screenLocation = `/screens/${screen.id}`;
                const isActive = screenLocation === currentLocation;

                return (
                  <ScreenCard
                    key={screen.id}
                    name={screen.name}
                    screen={screen}
                    linkTo={isActive ? `/screens` : screenLocation}
                    desktopStatus={screen.desktopStatus.status}
                    wsInit={wsInit}
                    onClick={this.props.handleClickCloseEdit}
                    onDelete={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeScreen(screen.id);
                    }}
                    isActive={isActive}
                    screenOrientationIcon={getScreenOrientationIcon(
                      screen.screenSizeId,
                      this.props.screenSizeList,
                      {
                        color: "#000",
                      }
                    )}
                    user={user}
                  />
                );
              })}

            </>
          )}
        </div>
        {this.state.toScreens && <Redirect to="/screens" />}
        <Switch>
          <Route
            path="/screens/new"
            render={(props) => (
              <div className={styles.screenDetails}>
                <Link to="/screens" className={styles.closeButton} />
                <AddScreenFormContainer {...props} {...this.props} />
              </div>
            )}
          />
          <Route
            path="/screens/:id/schedule"
            render={(props) => (
              <div className={styles.screenDetails}>
                <Link to="/screens" className={styles.closeButton} />
                <ScheduleView {...props} {...this.props} />
              </div>
            )}
          />
          <Route
            path="/screens/:id"
            render={(props) => (
              <div className={styles.screenDetails}>
                <Link to="/screens" className={styles.closeButton} />
                <OpenInPlayerBtn
                  disabled={!user.accessCode}
                  size={28}
                  className={styles.anotherCloseButton}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(
                      `${REACT_APP_PLAYER_URL}/${user.accessCode}/screen/${props.match.params.id}`,
                      "_blank"
                    );
                  }}
                />
                <ScreenDetailsView {...props} {...this.props} />
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}

ScreensView.propTypes = {
  screenSizeList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      default: PropTypes.bool.isRequired,
      resolutionWidth: PropTypes.number.isRequired,
      resolutionHeight: PropTypes.number.isRequired,
      ownerId: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  isEditMode: PropTypes.bool.isRequired,
  handleClickCloseEdit: PropTypes.func.isRequired,
  updateScreenItem: PropTypes.func.isRequired,
  handleClickOpenEdit: PropTypes.func.isRequired,
  removeScreen: PropTypes.func,
  playlists: PropTypes.array,
  screens: PropTypes.array,
  onSubmitCreateScreen: PropTypes.func,
  selectContent: PropTypes.func,
  toggleNotificationConfirm: PropTypes.func,
  changeReduxForm: PropTypes.func,
  wsInit: PropTypes.bool,
  dataFetched: PropTypes.bool,
};

export default ScreensView;
