import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { screensActions } from '../../../../state/ducks/screens';
import { appActions } from '../../../../state/ducks/app';
import { screenSizesActions } from '../../../../state/ducks/screenSizes';
import ScreensView from '../components/ScreensView';
import { getScreensWithDesktopStatus } from '../../../../state/ducks/screens/selectors';

const { fetchCreateScreenSizes } = screenSizesActions;
const { setScreenPageStatus, fetchScreenUpdate } = screensActions;
const { setShowConfirmModalPlaylistScreens } = appActions;

class ScreensViewContainer extends React.Component {
  state = {
    isSelectContent: 'addContent',
    createdScreenName: null,

    isEditMode: false,
    customSizeBtnIsActive: false,
  };

  componentDidMount = () => {
    // need for define which is page
    const { updateScreenPageStatus } = this.props;
    updateScreenPageStatus(true);
  };

  componentWillUnmount = () => {
    // need for define which is page
    const { updateScreenPageStatus } = this.props;
    updateScreenPageStatus(false);
  };

  removeScreen = id => {
    this.props.setShowConfirmModalPlaylistScreens(true, {
      title: 'Are you sure you want to delete this screen?',
      fetchAction: 'fetchDeleteScreen',
      id,
    });
  };

  handleClickOpenEdit = () => {
    this.setState({ isEditMode: true });
  };

  handleClickCloseEdit = () => {
    this.setState({ isEditMode: false, customSizeBtnIsActive: false });
  };

  handleClickAddCustomSize = () => {
    this.setState({
      customSizeBtnIsActive: true,
    });
  };

  handleCloseAddCustomSize = () => {
    this.setState({
      customSizeBtnIsActive: false,
    });
  };

  render() {
    const { isSelectContent, customSizeBtnIsActive, isEditMode } = this.state;
    const {
      location: { pathname },
    } = this.props;

    const {
      screenSizes,
      updateScreenItem,
      createScreenSize,
      screens,
      changeReduxForm,
      wsInit,
      dataFetched,
      user,
    } = this.props;
    return (
      <ScreensView
        dataFetched={dataFetched}
        screens={screens}
        currentLocation={pathname}
        isSelectContent={isSelectContent}
        customSizeBtnIsActive={customSizeBtnIsActive}
        createdScreenName={this.state.createdScreenName}
        removeScreen={this.removeScreen}
        toggleNotificationConfirm={this.toggleNotificationConfirm}
        screenSizeList={screenSizes}
        handleClickAddCustomSize={this.handleClickAddCustomSize}
        handleCloseAddCustomSize={this.handleCloseAddCustomSize}
        isEditMode={isEditMode}
        handleClickOpenEdit={this.handleClickOpenEdit}
        handleClickCloseEdit={this.handleClickCloseEdit}
        updateScreenItem={updateScreenItem}
        createScreenSize={createScreenSize}
        changeReduxForm={changeReduxForm}
        wsInit={wsInit}
        user={user}
      />
    );
  }
}

ScreensViewContainer.propTypes = {
  screens: PropTypes.array,
  playlists: PropTypes.array,
  updateScreenPageStatus: PropTypes.func,
  setShowConfirmModalPlaylistScreens: PropTypes.func,
  location: PropTypes.object,
  wsInit: PropTypes.bool,
  dataFetched: PropTypes.bool,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setShowConfirmModalPlaylistScreens,
      updateScreenPageStatus: setScreenPageStatus,
      updateScreenItem: fetchScreenUpdate,
      createScreenSize: fetchCreateScreenSizes,
      changeReduxForm: change,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  screens: getScreensWithDesktopStatus(state),
  dataFetched: state.screens.dataFetched,
  playlists: state.playlists.playlists,
  screenSizes: state.screenSizes.screenSizes,
  wsInit: state.ws.initialized,
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScreensViewContainer);
