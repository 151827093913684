import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './LayoutConfirmationModal.module.scss';
import { appActions } from '../../../state/ducks/app';
import { playlistsActions } from '../../../state/ducks/playlists';
import { screensActions } from '../../../state/ducks/screens';
import { contentActions } from '../../../state/ducks/content';
import { contentGroupActions } from '../../../state/ducks/contentGroup';
import { getPlaylists } from '../../../state/ducks/playlists/selectors';

const { setShowConfirmModal } = appActions;
const { fetchDeletePlaylist } = playlistsActions;
const { fetchDeleteContent } = contentActions;
const { fetchDeleteScreen } = screensActions;
const { fetchArrDeleteContentGroup } = contentGroupActions;

class LayoutConfirmationModal extends React.Component {
  state = {
    isOpenMore: false,
  };

  getActionType = () => {
    const { setCheckboxFunction, setShowConfirmModal, confirmModal } = this.props;
    const fetchAction = this.props[confirmModal.fetchAction];
    fetchAction(confirmModal.id);
    setShowConfirmModal(false, {});
    setCheckboxFunction();
    this.setState({ isOpenMore: false });
  };

  render() {
    const { showConfirmModal, confirmModal, setShowConfirmModal, playlists } = this.props;
    const { isOpenMore } = this.state;
    const firstPartOfContent =
      confirmModal.listOfContentGroups && confirmModal.listOfContentGroups.slice(0, 5).join(', ');
    const secondPartOfContent = confirmModal.listOfContentGroups && confirmModal.listOfContentGroups.slice(5);

    if (confirmModal.isDeletingPlaylist) {
      const playlist = playlists.find(({ id }) => id === confirmModal.playlistId);
      return (
        <div className={styles.deletingPlaylistContainer}>
          <div className={styles.opacityBackground} onClick={() => setShowConfirmModal(false, {})} />
          <div className={styles.confirmWindowScreenSizes}>
            <div className={styles.messageBlock}>
              <p className={styles.warningStyle}>
                Playlist {`"${playlist && playlist.name}"`} is being used by <b>{confirmModal.screenNames}</b>. Please
                assign another playlist to <b>{confirmModal.screenNames}</b>, before deleting this playlist.
              </p>
            </div>
            <div className={styles.buttonGroup}>
              <button type="button" className={styles.buttonStyle} onClick={() => setShowConfirmModal(false, {})}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={showConfirmModal ? styles.containerActive : styles.container}>
          <div className={styles.opacityBackground} onClick={() => this.props.setShowConfirmModal(false, {})} />
          <div className={styles.confirmWindow}>
            <div className={styles.warnBody}>
              <p className={styles.warningStyle}>{this.props.confirmModal.title}</p>
            </div>
            <div className={styles.buttonGroup}>
              <button type="button" className={styles.buttonStyleCancel} onClick={() => setShowConfirmModal(false, {})}>
                No
              </button>
              <button type="button" className={styles.buttonStyle} onClick={this.getActionType}>
                Yes
              </button>
            </div>
          </div>
        </div>

        {/* Detailed Form */}
        <div className={showConfirmModal && confirmModal.removeUsedContent ? styles.containerActive : styles.container}>
          <div className={styles.opacityBackground} onClick={() => setShowConfirmModal(false, {})} />
          <div className={styles.confirmWindow}>
            <div className={styles.warnBody}>
              <p className={styles.warningStyle}>
                {`The playlists below are still using ${firstPartOfContent && firstPartOfContent}.`}
              </p>
              {secondPartOfContent && secondPartOfContent.length !== 0 && (
                <>
                  <span className={styles.hideMessage}>
                    Show{' '}
                    <button onClick={() => this.setState({ isOpenMore: !isOpenMore })} style={{ color: '#EC812B' }}>
                      {!isOpenMore ? 'more' : 'less'}
                    </button>{' '}
                    files ({secondPartOfContent.length})
                  </span>
                  <div className={styles.hiddenContents}>
                    {isOpenMore &&
                      secondPartOfContent.map((item, id) => (
                        <span className={styles.hiddenContent} key={id}>{`${id + 1}. ${item}`}</span>
                      ))}
                  </div>
                </>
              )}
              <p className={styles.warningStyle}>
                {confirmModal.listOfContentGroups && confirmModal.listOfContentGroups.length === 1
                  ? confirmModal.title
                  : 'Are you sure you want to delete them?'}
              </p>
            </div>
            <div className={styles.playlistsWrapper}>
              {confirmModal.listOfPlaylists &&
                confirmModal.listOfPlaylists.map((item, id) => (
                  <div key={id} className={styles.playlistName}>
                    {item}
                  </div>
                ))}
            </div>
            <div className={styles.buttonGroup}>
              <button
                type="button"
                className={styles.buttonStyleCancel}
                onClick={() => {
                  this.props.setShowConfirmModal(false, {});
                  this.setState({ isOpenMore: false });
                }}
              >
                No
              </button>
              <button type="button" className={styles.buttonStyle} onClick={this.getActionType}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

LayoutConfirmationModal.propTypes = {
  confirmToggler: PropTypes.bool,
  confirmStatus: PropTypes.bool,
  notificationContent: PropTypes.string,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setShowConfirmModal,
      fetchDeletePlaylist,
      fetchDeleteScreen,
      fetchDeleteContent,
      fetchArrDeleteContentGroup,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  playlists: getPlaylists(state),
  showConfirmModal: state.app.showConfirmModal,
  confirmModal: state.app.confirmModal,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutConfirmationModal);
