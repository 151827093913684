import React, { Component } from 'react';
import styles from './SpeakersSection.module.scss';
import SpeakersAddForm from '../SpeakersAddForm';
import SpeakersEditForm from '../SpeakersEditForm';
import SpeakersListContainer from '../SpeakersListContainer';

class SpeakersSection extends Component {
  state = {
    isOpen: false,
    isEditOrder: null,
  };

  handleOpenForm = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen, isEditOrder: null });
  };

  onEdit = order => {
    const { change, speakers } = this.props;
    this.setState({ isEditOrder: order, isOpen: true });
    const editedSpeaker = speakers.find(speaker => speaker.order === order);
    const { speakerCompany, speakerJob, speakerName } = editedSpeaker;
    change('speakerCompany', speakerCompany);
    change('speakerName', speakerName);
    change('speakerJob', speakerJob);
  };

  onRemove = orderOfSpeaker => {
    const { speakers, change } = this.props;

    const reorderSpeakers = speakers
      .filter(item => item.order !== orderOfSpeaker)
      .map((speaker, order) => ({ ...speaker, order }))
      .sort((a, b) => a.order - b.order);

    change('speakers', reorderSpeakers);

    this.resetEditOrder();
  };

  resetEditOrder = () => {
    this.setState({ isEditOrder: null, isOpen: false });
  };

  render() {
    const { isOpen, isEditOrder } = this.state;
    const { fields, speakerCompany, speakerName, speakerJob, speakers, change } = this.props;

    return (
      <div className={styles.container}>
        {isEditOrder !== null ? (
          <SpeakersEditForm
            handleOpenForm={this.handleOpenForm}
            resetEditOrder={this.resetEditOrder}
            {...{ speakerCompany, speakerName, speakerJob, speakers, change, fields, isOpen, isEditOrder }}
          />
        ) : (
          <SpeakersAddForm
            handleOpenForm={this.handleOpenForm}
            {...{ speakerCompany, speakerName, speakerJob, speakers, change, fields, isOpen }}
          />
        )}
        <SpeakersListContainer
          isOpen={isOpen}
          onRemove={this.onRemove}
          onEdit={this.onEdit}
          {...{ speakers, change }}
        />
      </div>
    );
  }
}

export default SpeakersSection;
