import changeNameRegexp from './change-name-regexp';
import createNewFile from './create-new-file';

export function getFilenameFromUrl(url) {
  const getName = url.substring(url.lastIndexOf('/') + 1);

  return getName;
}

export function getFileType(fileTypeName) {
  const fileType = fileTypeName.substring(0, fileTypeName.indexOf('/'));
  return fileType === 'text' ? 'html' : fileType;
}

export function getExtensionOfUrl(fileType) {
  if (!fileType) return null;

  const removeCharset = fileType.substring(0, fileType.indexOf(';'));
  const splitType = removeCharset ? removeCharset.split('/') : fileType.split('/');
  const [type, extension] = splitType;
  return { type, extension };
}

export function getProcessedFile(file) {
  let fileName = file.name;
  const regExp = /((\.){2,}),?|(#),?|(%),?|(\+)/g;
  const findRegexp = regExp.test(fileName);
  if (findRegexp) {
    fileName = changeNameRegexp(file.name, regExp, findRegexp);
    file = createNewFile(file, fileName);
    return file;
  }

  file = createNewFile(file, fileName);
  return file;
}

export function getImageRatio(file) {
  return new Promise(resolve => {
    // const reader = new FileReader();
    // reader.onload = () => {
    // const fileAsBinaryString = reader.result;
    const img = new Image();
    img.src = file;
    img.onload = () => {
      const imgRatio = (img.width / img.height).toFixed(3);
      resolve(imgRatio);
    };
    // };
    // reader.readAsDataURL(file);
  });
}

export function getVideoRatio(file) {
  return new Promise(resolve => {
    // const reader = new FileReader();
    // reader.onload = () => {
    // const fileAsBinaryString = reader.result;

    console.log('video file', file);
    const video = document.createElement('video');
    // console.log('Video ready state', video.readyState);
    video.src = file;
    // };

    // video.addEventListener('loadedmetadata', function(event) {
    video.addEventListener('loadedmetadata', function(event) {
      // video.addEventListener('load', function() {

      const width = this.videoWidth;
      const height = this.videoHeight;
      const videoRatio = (width / height).toFixed(3);
      resolve(videoRatio);
    });

    // reader.readAsDataURL(file);
  });
}
// export function getVideoRatio(file) {
//   // const file = 'https://s3-us-west-2.amazonaws.com/digital-signage-2018/2/video/horisontal.mp4';
//   return new Promise(resolve => {
//     const reader = new FileReader();
//     reader.onload = () => {
//       const fileAsBinaryString = reader.result;
//       const video = document.createElement('video');

//       // video.addEventListener('loadedmetadata', function(event) {
//       video.addEventListener('loadedmetadata', function(event) {
//         // video.addEventListener('load', function() {

//         console.log('getVideoRatio -> video ratio 1');
//         const width = this.videoWidth;
//         const height = this.videoHeight;
//         const videoRatio = (width / height).toFixed(3);
//         resolve(videoRatio);
//       });

//       // console.log('Video ready state', video.readyState);
//       video.src = fileAsBinaryString;
//     };

//     reader.readAsDataURL(file);
//   });
// }

export function compareScreenSizeId(imgRatio, sortedScreenSize) {
  let minDiff = Math.abs(sortedScreenSize[0].ratio - imgRatio);
  let currSortedScreenSize = sortedScreenSize[0];
  // console.log('minDiff', minDiff);

  sortedScreenSize.forEach(element => {
    const diff = Math.abs(element.ratio - imgRatio);
    // console.log('diff', diff);
    if (diff <= minDiff) {
      minDiff = diff;
      currSortedScreenSize = element;
    }
  });
  // console.log(imgRatio, '<== imgRatio', currSortedScreenSize, 'Selected optimal', sortedScreenSize);
  return currSortedScreenSize;
}
// export function compareScreenSizeId(imgRatio, sortedScreenSize) {
//   const arrLength = sortedScreenSize.length;
//   // console.log(sortedScreenSize);

//   let counter = 0;
//   for (counter; counter < arrLength; counter += 1) {
//     if (imgRatio < sortedScreenSize[counter].ratio) {
//       const prevElemDiffer = imgRatio - sortedScreenSize[counter - 1].ratio;
//       const currElemDiffer = sortedScreenSize[counter].ratio - imgRatio;
//       if (prevElemDiffer < currElemDiffer) return sortedScreenSize[counter - 1];
//       if (prevElemDiffer > currElemDiffer) return sortedScreenSize[counter];
//     }
//     if (imgRatio === sortedScreenSize[counter].ratio) {
//       return sortedScreenSize[counter];
//     }
//     // console.log(counter, ' ==== !!!!', arrLength);
//     if (imgRatio >= sortedScreenSize[counter].ratio && counter === arrLength - 1) {
//       // console.log(imgRatio, ' ==== !!!!', sortedScreenSize[counter].ratio);
//       // return sortedScreenSize[counter];
//       return sortedScreenSize[counter];
//     }
//   }
// }

export async function getScreenSize(file, screenSizes, type) {
  const screenSizeResolution = screenSizes.map(item => ({
    id: item.id,
    ratio: (item.resolutionWidth / item.resolutionHeight).toFixed(3),
  }));
  let imgRatio = null;
  let returnImgResolution = null;
  let videoRatio = null;
  let returnVidResolution = null;

  const defaultScreenSizeId = screenSizes.find(item => item.name === 'Landscape' && item.default === true && item.id);
  const sortedScreenSize = screenSizeResolution.sort((a, b) => a.ratio - b.ratio);

  switch (type) {
    case 'image':
      imgRatio = await getImageRatio(file);
      returnImgResolution = compareScreenSizeId(imgRatio, sortedScreenSize);
      return returnImgResolution.id;
    case 'image/jpeg':
      imgRatio = await getImageRatio(file);
      returnImgResolution = compareScreenSizeId(imgRatio, sortedScreenSize);
      return returnImgResolution.id;
    case 'image/webp':
      imgRatio = await getImageRatio(file);
      returnImgResolution = compareScreenSizeId(imgRatio, sortedScreenSize);
      return returnImgResolution.id;
    case 'image/jpg':
      imgRatio = await getImageRatio(file);
      returnImgResolution = compareScreenSizeId(imgRatio, sortedScreenSize);
      return returnImgResolution.id;
    case 'image/png':
      imgRatio = await getImageRatio(file);
      returnImgResolution = compareScreenSizeId(imgRatio, sortedScreenSize);
      return returnImgResolution.id;
    case 'video':
      videoRatio = await getVideoRatio(file);
      returnVidResolution = compareScreenSizeId(videoRatio, sortedScreenSize);
      return returnVidResolution.id;
    case 'video/mp4':
      videoRatio = await getVideoRatio(file);
      returnVidResolution = compareScreenSizeId(videoRatio, sortedScreenSize);
      return returnVidResolution.id;
    default:
      return defaultScreenSizeId.id;
  }
}

export function getScreenDimention(width, height) {
  const gcd = function greatestCommonDivisor(a, b) {
    if (!b) {
      return a;
    }
    return gcd(b, a % b);
  };
  const newGcd = gcd(width, height);
  const dimension = `${width / newGcd}:${height / newGcd}`;
  return dimension;
}

export function createHtmlFile(content, fileName) {
  return new File([content], `${fileName}.html`, { type: 'text/htm' });
}

export const cutHash = fileName => {
  const regHashUuid = /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}_/g;
  return fileName.replace(regHashUuid, '');
};

export const cutExt = fileName => {
  const dotIndex = fileName.lastIndexOf('.');
  const substrEndIndex = dotIndex === -1 ? fileName.length : dotIndex;

  return fileName.substring(0, substrEndIndex);
};

export const getCustomHtmlName = fileName => {
  const cutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
  const nameStamp = `${cutExtension}_${+new Date()}.html`;
  return nameStamp;
};

export const getNameWithTimestamp = name => {
  let fileName = name;
  const timestamp = +new Date();
  const matchedUnderscore = fileName.lastIndexOf('_');
  if (matchedUnderscore) {
    const retrievedTimestamp = fileName.substring(matchedUnderscore, fileName.lastIndexOf('.'));
    if (retrievedTimestamp.length === 14) {
      const nameWithStamp = `${fileName.substring(0, fileName.lastIndexOf('_'))}_${timestamp}`;
      fileName = `${nameWithStamp}.html`;
    } else {
      const cleanName = fileName.substring(0, fileName.lastIndexOf('.'));
      const nameWithStamp = `${cleanName}_${timestamp}`;
      fileName = `${nameWithStamp}.html`;
    }
  } else {
    const cleanName = fileName.substring(0, fileName.lastIndexOf('.'));
    const nameWithStamp = `${cleanName}_${timestamp}`;
    fileName = `${nameWithStamp}.html`;
  }
  return fileName;
};
