import * as types from './types';
import { createReducer } from '../../utils';

export default createReducer({
  notification: {},
  contentFormId: null,

  showConfirmModal: false,
  confirmModal: {},
  showConfirmModalPlaylistScreens: false,
  confirmModalPlaylistScreens: {},
  showConfirmModalScreenSizes: false,
  confirmModalScreenSizesData: {},
  showConfirmModalPlaylist: false,
  confirmModalPlaylistData: {},
  showConfirmDuplicateModal: false,
  confirmDuplicateModalPlaylist: {},
  showPreviewModal: false,
  previewModalData: {},
  timeFromMidnight: 0,
  appTime: +new Date(),
})({
  [types.SHOW_NOTIFICATION]: (state, action) => ({ ...state, notification: action.payload }),
  [types.SET_CONTENT_FORM_ID]: (state, action) => ({ ...state, contentFormId: action.payload }),
  [types.SET_TIME_FROM_MIDNIGHT]: (state, action) => ({
    ...state,
    timeFromMidnight: action.payload,
    appTime: +new Date(),
  }),
  [types.SET_SHOW_CONFIRM_MODAL]: (state, action) => ({
    ...state,
    showConfirmModal: action.payload.status,
    confirmModal: action.payload.data,
  }),
  [types.SET_SHOW_CONFIRM_DUPLICATE_MODAL]: (state, action) => ({
    ...state,
    showConfirmDuplicateModal: action.payload.status,
    confirmDuplicateModalPlaylist: action.payload.data,
  }),
  [types.SET_SHOW_CONFIRM_MODAL_PLAYLIST_SCREENS]: (state, action) => ({
    ...state,
    showConfirmModalPlaylistScreens: action.payload,
    confirmModalPlaylistScreens: action.data,
  }),
  [types.SET_SHOW_CONFIRM_MODAL_SCREEN_SIZES]: (state, action) => ({
    ...state,
    showConfirmModalScreenSizes: action.payload.status,
    confirmModalScreenSizesData: action.payload.data,
  }),
  [types.SET_SHOW_CONFIRM_MODAL_PLAYLIST]: (state, action) => ({
    ...state,
    showConfirmModalPlaylist: action.payload.status,
    confirmModalPlaylistData: action.payload.data,
  }),
  [types.SET_PREVIEW_MODAL]: (state, action) => ({
    ...state,
    showPreviewModal: action.payload.status,
    previewModalData: action.payload.data,
  }),
});
