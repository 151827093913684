// sync types
export const SET_SESSIONS = 'sessions/SET_SESSIONS';
export const SET_SESSION_MODAL = 'session/SET_SESSION_MODAL';
export const SESSION_REMOVE_START = 'session/SESSION_REMOVE_START';
export const SESSION_REMOVE_END = 'session/SESSION_REMOVE_END';
export const UPDATE_EXTERNAL_SESSIONS = 'session/UPDATE_EXTERNAL_SESSIONS';

// async types
export const FETCH_CREATE_SESSION = 'session/FETCH_CREATE_SESSION';
export const FETCH_CREATE_SESSION_END = 'session/FETCH_CREATE_SESSION_END';
export const FETCH_REMOVE_SESSION = 'session/FETCH_REMOVE_SESSION';
export const FETCH_EDIT_SESSION = 'session/FETCH_EDIT_SESSION';
export const FETCH_EDIT_SESSION_END = 'session/FETCH_EDIT_SESSION_END';
export const FETCH_REMOVE_SESSION_BY_DATE = 'session/FETCH_REMOVE_SESSION_BY_DATE';
