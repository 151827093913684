import { createSelector } from 'reselect';
import { getFlatDate } from '../../utils';

const selectorTheatreId = (
  state,
  {
    match: {
      params: { theatreId },
    },
  },
) => Number(theatreId);
export const selectorSessions = state => state.sessions.sessions;
const selectorSpeakers = state => state.speakers.speakers;

export const getSessionList = createSelector(
  [selectorSessions, selectorSpeakers],
  (sessions, speakers) => {
    const sessionList = sessions.map(session => {
      const foundSpeakers = speakers.filter(speaker => speaker.sessionId === session.id);
      return { ...session, speakers: foundSpeakers };
    });
    return sessionList;
  },
);

export const getSessionListByDate = createSelector(
  [getSessionList, selectorTheatreId],
  (sessionList, theatreId) => {
    const sortByDate = sessionList
      .filter(session => session.programmeId === theatreId)
      .sort((a, b) => new Date(a.day) - new Date(b.day));

    const reduceSessionList = sortByDate.reduce((acc, el) => {
      const lastItem = acc[acc.length - 1];

      if (!lastItem) return [{ id: 0, planingDay: el.day, sessionListMatches: [el] }];

      if (lastItem && getFlatDate(lastItem.planingDay) === getFlatDate(el.day)) {
        const sessionListMatches = [...lastItem.sessionListMatches, el];
        return [...acc.slice(0, -1), { ...lastItem, sessionListMatches }];
      }
      return [...acc, { id: acc.length, planingDay: el.day, sessionListMatches: [el] }];
    }, []);

    return reduceSessionList.map(dayItem => {
      dayItem.sessionListMatches.sort((a, b) => {
        const aa = a.start.split(':').join();
        const bb = b.start.split(':').join();
        return aa < bb ? -1 : aa > bb ? 1 : 0;
      });
      return dayItem;
    });
  },
);
