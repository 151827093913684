import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './PlaylistDetailsWrapper.module.scss';

function PlaylistDetailsWrapper({ children, className, ...rest }) {
  return (
    <div className={cn(styles.root, className)} {...rest}>
      {children}
    </div>
  );
}

PlaylistDetailsWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default PlaylistDetailsWrapper;
