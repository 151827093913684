import * as $ from 'jquery';
import { fontFaceData } from '../../../../../../../utils/editorContentCssText';

export default ({ getElements, getState, setState, setEditorSize }) => {
  const imgFocus = () => {
    const { isImageSelected, isFullscreen } = getState();

    if (!isImageSelected) {
      if (!isFullscreen) {
        // eslint-disable-next-line
        document.body.classList.add('froala-popup-hidden');
      }
      setState({ isImageSelected: true });
    }
  };

  const imgBlur = () => {
    const { isImageSelected, isFullscreen } = getState();
    if (isImageSelected) {
      if (!isFullscreen) {
        // eslint-disable-next-line
        document.body.classList.remove('froala-popup-hidden');
      }
      setState({ isImageSelected: false });
    }
  };

  const fullScreenToggle = editor => {
    const { froalaContainer, froalaIframeWrapper, froalaIframe } = getElements();
    const { isFullscreen } = getState();

    // eslint-disable-next-line
    const fullscreenBtn = document.querySelector('#fullscreen');

    if (isFullscreen) {
      // document.body.classList.add('froala-popup-hidden');
      fullscreenBtn.style = '';
      froalaContainer.classList.add('froala-no-fullscreen');
      setEditorSize(getState().selectedScreenSize);
    } else {
      fullscreenBtn.style = 'position: fixed; z-index: 50000000000000000;';
      froalaIframeWrapper.style = '';
      froalaIframe.style = `height: ${froalaIframe.clientHeight};`;
      // document.body.classList.remove('froala-popup-hidden');
      froalaContainer.classList.remove('froala-no-fullscreen');
    }

    editor.events.focus();
    editor.events.trigger('keydown', [{}], true);

    // froalaBox.classList.toggle('full-screen');
    setState({ isFullscreen: !isFullscreen });
  };

  return {
    key: 'UA7D9D7D5dC3E3G3A7B6B5D5E4A2F2C-7KC1KXDF1INBh1KPe2TK==',
    theme: 'custom',
    zIndex: 2001,
    charCounterCount: false,
    placeholderText: 'Edit Your Content Here!',
    width: '100%',
    quickInsertTags: [''],
    pasteDeniedAttrs: [],
    pasteDeniedTags: [],
    // fontFamilySelection: true,
    // tabSpaces: 2,
    // tabSpaces: true,
    fullPage: true,
    colorsHEXInput: false,
    // codeMirror: true,
    // codeMirrorOptions: {
    //   indentWithTabs: true,
    //   lineNumbers: true,
    //   lineWrapping: true,
    //   mode: 'text/html',
    //   tabMode: 'indent',
    //   tabSize: 1,
    // },
    htmlRemoveTags: [],
    toolbarButtons: [
      'formatting',
      'bold',
      'italic',
      'underline',
      'fontSize',
      'align',
      'color',
      'fontFamily',
      'insertImage',
      'alert',
      // 'insertImage',
      // 'html',
      'codeView',
      'fullscreen',
      // 'insertSection',
    ],
    imageEditButtons: [
      'imageAlign',
      'imageRemove',
      // 'imageCaption',
      // '|',
      // 'imageLink',
      // 'linkOpen',
      // 'linkEdit',
      // 'linkRemove',
      // '-',
      // 'imageDisplay',
      // 'imageStyle',
      // 'imageAlt',
      // 'imageSize',
    ],
    fontFamilyDefaultSelection: 'Open Sans,sans-serif',
    toolbarSticky: false,
    fontSizeSelection: false,
    fontSizeDefaultSelection: '150',
    fontSizeUnit: 'px',
    fontSize: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '40',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
      '49',
      '50',
      '51',
      '52',
      '53',
      '54',
      '55',
      '56',
      '57',
      '58',
      '59',
      '60',
      '61',
      '62',
      '63',
      '64',
      '65',
      '66',
      '67',
      '68',
      '69',
      '70',
      '71',
      '72',
      '73',
      '74',
      '75',
      '76',
      '77',
      '78',
      '79',
      '80',
      '81',
      '82',
      '83',
      '84',
      '85',
      '86',
      '87',
      '88',
      '89',
      '90',
      '91',
      '92',
      '93',
      '94',
      '95',
      '96',
      '97',
      '98',
      '99',
      '100',
      '101',
      '102',
      '103',
      '104',
      '105',
      '106',
      '107',
      '108',
      '109',
      '110',
      '111',
      '112',
      '113',
      '114',
      '115',
      '116',
      '117',
      '118',
      '119',
      '120',
      '121',
      '122',
      '123',
      '124',
      '125',
      '126',
      '127',
      '128',
      '129',
      '130',
      '131',
      '132',
      '133',
      '134',
      '135',
      '136',
      '137',
      '138',
      '139',
      '140',
      '141',
      '142',
      '143',
      '144',
      '145',
      '146',
      '147',
      '148',
      '149',
      '150',
      '151',
      '152',
      '153',
      '154',
      '155',
      '156',
      '157',
      '158',
      '159',
      '160',
      '161',
      '162',
      '163',
      '164',
      '165',
      '166',
      '167',
      '168',
      '169',
      '170',
      '171',
      '172',
      '173',
      '174',
      '175',
      '176',
      '177',
      '178',
      '179',
      '180',
      '181',
      '182',
      '183',
      '184',
      '185',
      '186',
      '187',
      '188',
      '189',
      '190',
      '191',
      '192',
      '193',
      '194',
      '195',
      '196',
      '197',
      '198',
      '199',
      '200',
      '201',
      '202',
      '203',
      '204',
      '205',
      '206',
      '207',
      '208',
      '209',
      '210',
      '211',
      '212',
      '213',
      '214',
      '215',
      '216',
      '217',
      '218',
      '219',
      '220',
      '221',
      '222',
      '223',
      '224',
      '225',
      '226',
      '227',
      '228',
      '229',
      '230',
      '231',
      '232',
      '233',
      '234',
      '235',
      '236',
      '237',
      '238',
      '239',
      '240',
      '241',
      '242',
      '243',
      '244',
      '245',
      '246',
      '247',
      '248',
      '249',
      '250',
      '251',
      '252',
      '253',
      '254',
      '255',
      '256',
      '257',
      '258',
      '259',
      '260',
      '261',
      '262',
      '263',
      '264',
      '265',
      '266',
      '267',
      '268',
      '269',
      '270',
      '271',
      '272',
      '273',
      '274',
      '275',
      '276',
      '277',
      '278',
      '279',
      '280',
      '281',
      '282',
      '283',
      '284',
      '285',
      '286',
      '287',
      '288',
      '289',
      '290',
      '291',
      '292',
      '293',
      '294',
      '295',
      '296',
      '297',
      '298',
      '299',
      '300',
    ],
    // 'core.injectStyle': 'body{background:red}',
    // editorClass: 'custom-class',
    // EVENT HANDLERS
    // iframe: true,
    iframeDefaultStyle: '',
    events: {
      'froalaEditor.initialized': function init(_, editor) {
        editor.events.bindClick($('body'), '#align-image-left', () => {
          if (editor.image.get()) {
            editor.image.align('left');
          }
        });

        editor.events.bindClick($('body'), '#align-image-center', () => {
          if (editor.image.get()) {
            editor.image.align('center');
          }
        });

        editor.events.bindClick($('body'), '#align-image-right', () => {
          if (editor.image.get()) {
            editor.image.align('right');
          }
        });

        editor.events.bindClick($('body'), '#image-trash', () => {
          if (editor.image.get()) {
            editor.image.remove();
            imgBlur();
          }
        });

        editor.events.bindClick($('body'), '#fullscreen', () => {
          editor.fullscreen.toggle();
          fullScreenToggle(editor);
        });

        editor.$el.on('click', 'img', imgFocus);
        editor.$el.on('keydown', ({ key }) => {
          if (key === 'Backspace') {
            imgBlur();
          }
        });
      },
      'froalaEditor.image.inserted': imgFocus,
      'froalaEditor.click': imgBlur,
      'froalaEditor.blur': imgBlur,
      'froalaEditor.commands.before': function cmbBefore(e, editor, cmd) {
        switch (cmd) {
          case 'fullscreen':
            fullScreenToggle(editor);
            break;
          default:
            break;
        }
      },
      'froalaEditor.commands.after': function cmbAfter(e, editor, cmd) {
        switch (cmd) {
          case 'html':
            {
              const { froalaIframeWrapper, froalaBox } = getElements();
              const { isCodeView, isFullscreen } = getState();
              const codeMirror = froalaIframeWrapper.querySelector('.CodeMirror');
              const codeMirrorScrollContainer = codeMirror.querySelector('.CodeMirror-scroll');

              if (!isFullscreen) {
                if (!isCodeView) {
                  froalaIframeWrapper.style = '';
                  // codeMirror.classList.add('visible');
                  const width = froalaBox.clientWidth;
                  const height = froalaBox.clientHeight;
                  const style = `
                        width: ${width}px;
                        max-height: ${height}px;
                        min-height: ${height}px;
                      `;
                  codeMirror.style = `${style} height: 100%;`;
                  codeMirrorScrollContainer.style = style;
                } else {
                  codeMirror.style = '';
                  codeMirrorScrollContainer.style = '';
                  setEditorSize(getState().selectedScreenSize);
                }
              }

              // let codeViewHtml;
              // let froalaViewHtml;
              // // event handler of changes in code view
              // if (editor.codeView.isActive()) {
              //   const mirror = editor.$box.find('.CodeMirror')[0].CodeMirror;
              //   mirror.on('change', () => {
              //     codeViewHtml = editor.codeView.get();
              //     froalaViewHtml = editor.html.get();
              //     console.log('1codeViewHtml', codeViewHtml);
              //     console.log('2froalaViewHtml', froalaViewHtml);
              //     if (codeViewHtml !== froalaViewHtml) {
              //       console.log('CHANGE', codeViewHtml);
              //     }
              //   });
              //   // Turn off the commands so we don't keep binding it.
              //   // $area.off('froalaEditor.commands.after');
              // }

              // const codeMirror = froalaIframeWrapper.querySelector('.CodeMirror');
              // if (codeMirror) {
              //   codeMirror.classList.remove('visible');
              // }
              // if (isCodeView && !isFullscreen) {

              // }

              setState({ isCodeView: !isCodeView });
            }
            break;
          default:
            break;
        }
      },
    },
    useClasses: true,
    codeViewKeepActiveButtons: ['check'],
    // codeMirror: window.CodeMirror,
    // codeMirror: false,
    codeMirrorOptions: {
      indentWithTabs: true,
      lineNumbers: true,
      lineWrapping: true,
      mode: 'text/html',
      tabMode: 'indent',
      tabSize: 2,
    },
    fontFamily: {
      'Arial,Helvetica,sans-serif': 'Arial',
      'Georgia,serif': 'Georgia',
      'Impact,Charcoal,sans-serif': 'Impact',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      "'Times New Roman',Times,serif": 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana',
      'DIN,sans-serif': 'DIN',
      'Helvetica Neue,sans-serif': 'HelveticaNeue',
      'Open Sans,sans-serif': 'OpenSans',
      'Montserrat,sans-serif': 'Montserrat',
      'Dosis,sans-serif': 'Dosis',
      'Gotham Pro, serif': 'Gotham Pro',
      'Roboto, sans-serif': 'Roboto',
      'AlfaSlabOne, sans-serif': 'AlfaSlabOne',
      'Myriad Pro, sans-serif': 'Myriad Pro',
      'Blood Orange, sans-serif': 'Blood Orange',
    },
    iframeStyle: fontFaceData,
  };
};
