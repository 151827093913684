import React from 'react';
import PropTypes from 'prop-types';
import ContentIcons from '../../../ContentIcons';
import styles from './MediaLibrary.module.scss';
import Icon from '../../../Icon';

// function MediaElement({ item, deleteContent, addContentToPlaylist, playlistsContents, selectContent, mediaFiles }) // may be will be useful
function MediaElement({ item, selectContent, addContent, isSelected }) {
  return (
    <label htmlFor={item.id} className={styles.contentItem} id={item.id} onClick={() => selectContent(item.id)}>
      <input
        type="checkbox"
        id={item.id}
        style={styles.inputStyle}
        onChange={() => selectContent(item.id)}
        checked={isSelected}
      />
      <span className={styles.checkmark} />
      <span id={item.id} className={styles.contentItemName} title={item.name}>
        <ContentIcons type={item.type} iconPosition={styles.iconPosition} />
        <span className={styles.contentItemNameWrapper}>{item.name}</span>
      </span>
      <button
        type="button"
        className={styles.contentItemAddBtn}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          addContent();
        }}
      >
        <Icon name="plusOrange" width={20} height={21} marginRight={8} />
        Add&nbsp;to&nbsp;Playlist
      </button>
    </label>
  );
}

MediaElement.propTypes = {
  item: PropTypes.object,
  mediaFiles: PropTypes.array,
  selectContent: PropTypes.func,
  addContent: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default MediaElement;
