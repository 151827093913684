import * as types from './types';
import { createAction } from '../../utils';

export const setPlaylists = playlists => ({ type: types.SET_PLAYLISTS, payload: playlists });

export const setCurrentPlaylistMeta = currentMeta => ({ type: types.SET_CURRENT_PLAYLIST_META, payload: currentMeta });
export const setCurrentPlaylistContent = content => ({ type: types.SET_CURRENT_PLAYLIST_CONTENT, payload: content });

export const contentRemovalStart = id => ({ type: types.CONTENT_REMOVAL_START, payload: id });
export const contentRemovalEnd = id => ({ type: types.CONTENT_REMOVAL_END, payload: id });

export const createPlaylistStart = createAction(types.CREATE_PLAYLIST_START);
export const createPlaylistEnd = createAction(types.CREATE_PLAYLIST_END);
export const createPlaylist = createAction(types.CREATE_PLAYLIST);
export const duplicatePlaylist = createAction(types.DUPLICATE_PLAYLIST);

export const fetchPlaylistsStart = createAction(types.FETCH_PLAYLIST_START);
export const fetchPlaylistsEnd = createAction(types.FETCH_PLAYLIST_END);

export const createPlaylistAndContentGroup = createAction(types.CREATE_PLAYLIST_AND_CONTENT_GROUP);
// export const createPlaylistAndAddContentGroup = createAction(types.CREATE_PLAYLIST_AND_ADD_CONTENT_GROUP);

export const updatePlaylist = createAction(types.UPDATE_PLAYLIST);

export const fetchDeletePlaylist = playlistId => ({
  type: types.FETCH_DELETE_PLAYLIST,
  payload: playlistId,
});

export const fetchAddPlaylistToScreen = (playlistId, contentId) => ({
  type: types.FETCH_ADD_CONTENT_TO_PLAYLIST,
  payload: {
    playlistId,
    contentId,
  },
});

export const fetchRemoveContentGroupFromPlaylist = (playlistId, contentGroupMetaId) => ({
  type: types.FETCH_REMOVE_CONTENT_GROUP_FROM_PLAYLIST,
  payload: {
    playlistId,
    contentGroupMetaId,
  },
});

export const fetchUpdatePlaylistContentGroupMeta = contentGroupData => ({
  type: types.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_META,
  payload: {
    contentGroupData,
  },
});

export const fetchMultipleRemoveContentGroupFromPlaylist = (playlistId, metaIds) => ({
  type: types.FETCH_MULTIPLE_REMOVE_CONTENT_GROUP_FROM_PLAYLIST,
  payload: {
    playlistId,
    metaIds,
  },
});
