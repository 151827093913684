import * as types from './types';
import { createReducer } from '../../utils';
import parseEntityField from '../../utils/parse-entity-field';
import { DELETE_DATA_FEED_SUCCESS } from '../dataFeeds/types';

function normalizeProgrammeImage(programme) {
  const imageIsJSON = programme.image && typeof programme.image === 'string';
  const image = imageIsJSON ? JSON.parse(programme.image) : programme.image;

  return { ...programme, image };
}

export default createReducer({
  programmes: [],
  isCreating: false,
})({
  [types.SET_PROGRAMMES]: (state, { payload: programmes }) => ({
    ...state,
    programmes: programmes.map(programme => normalizeProgrammeImage(parseEntityField(programme))),
  }),
  [DELETE_DATA_FEED_SUCCESS]: (state, { payload: dataFeedId }) => ({
    ...state,
    programmes: state.programmes.filter(p => p.dataFeedId !== dataFeedId),
  }),
  [types.CREATE_NEW_THEATRE_START]: state => ({ ...state, isCreating: true }),
  [types.CREATE_NEW_THEATRE_END]: state => ({ ...state, isCreating: false }),
});
