import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './PlaylistTemplateCard.module.scss';

import Icon from '../../../../common/components/UI/Icon';
import Tooltip from '../../../../common/components/UI/Tooltip/Tooltip';
import SvgSplitScreen from '../../../../../assets/svg/SvgSplitScreen';

import { templateTypes } from '../../../../../constants';
import { cleanObject } from '../../../../../utils';
import SvgTheatreProgramme from '../../../../../assets/svg/SvgTheatreProgramme';

const templateTitles = {
  [templateTypes.DEFAULT]: 'No template',
  [templateTypes.SPLIT]: 'Split screen',
  [templateTypes.THEATRE]: 'Theatre Programme',
};

class PlaylistTemplateCard extends React.Component {
  state = {
    isHovered: false,
  };

  handleMouseEnter = () => this.setState({ isHovered: true });

  handleMouseLeave = () => this.setState({ isHovered: false });

  render() {
    const { className, tooltip, type, onEdit, isActive: isActiveProp, isLocked, ...other } = this.props;
    const { margin, marginTop, marginLeft, marginRight, marginBottom, style: styleProp, ...rest } = other;

    const { isHovered } = this.state;

    const isActive = isActiveProp || isHovered;

    const templateIcons = {
      [templateTypes.DEFAULT]: null,
      [templateTypes.SPLIT]: <SvgSplitScreen color={isActive ? '#fc7300' : '#8A8A8F'} />,
      [templateTypes.THEATRE]: <SvgTheatreProgramme color={isActive ? '#fc7300' : '#8A8A8F'} />,
    };

    const onHoverProps = isLocked ? {} : { onMouseEnter: this.handleMouseEnter, onMouseLeave: this.handleMouseLeave };
    const style = cleanObject({ margin, marginTop, marginLeft, marginRight, marginBottom, ...styleProp });

    return (
      <div
        style={style}
        className={cn(styles.container, isActive && styles.containerIsActive, className && className)}
        {...onHoverProps}
        {...rest}
      >
        <div className={styles.corner}>
          {tooltip && (
            <>
              <Icon name="help" size="16px" data-tip={tooltip} />
              <Tooltip className={styles.tooltipTip} />
            </>
          )}
          {isLocked && <Icon name="lock" size="16px" style={{ cursor: 'default' }} />}
          {onEdit && (
            <button type="button" onClick={onEdit}>
              <Icon name="editIcon" size="16px" />
            </button>
          )}
        </div>
        <div className={styles.iconContainer}>{templateIcons[type]}</div>
        <div className={styles.textContainer}>
          <span className={styles.title}>{templateTitles[type]}</span>
        </div>
      </div>
    );
  }
}

PlaylistTemplateCard.defaultProps = {
  tooltip: null,
  isActive: false,
  isLocked: false,
  onEdit: null,
};

const numOrStr = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
PlaylistTemplateCard.propTypes = {
  icon: PropTypes.element,
  type: PropTypes.oneOf(Object.keys(templateTitles)),
  tooltip: PropTypes.func,
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  isLocked: PropTypes.bool,
  onEdit: PropTypes.func,
  margin: numOrStr,
  marginTop: numOrStr,
  marginLeft: numOrStr,
  marginRight: numOrStr,
  marginBottom: numOrStr,
};

export default PlaylistTemplateCard;
