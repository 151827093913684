import React from 'react';
import Select from '../Select';

function SelectAdapter({ input, options = [], ...rest }) {
  const value = options.find(option => input.value === option.value);
  const onChange = option => input.onChange(option.value);

  return <Select value={value} onChange={onChange} options={options} {...rest} />;
}

export default SelectAdapter;
