import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../../../../../../common/components/UI/Icon';
import { ChevronLeft } from '../../../../../../common/components/UI/Svg';
import { history } from '../../../../../../../state/utils/history';

import styles from './Feeds.module.scss';

import Feed from './components/Feed';

function Feeds({
  className,
  dataFeedList,
  onOpenEditModal,
  onOpenCreateModal,
  onDeleteDataFeed,
  onToggleSyncOfProgramme,
  onWrapperClick,
  wrapperId,
}) {
  const renderHeader = (
    <div className={styles.header}>
      <button className={styles.back} onClick={() => history.push('/programmes')}>
        <ChevronLeft />
        <span>Back</span>
      </button>
    </div>
  );

  const renderList = (
    <div className={styles.list}>
      {dataFeedList.map(({ name, programmes, id }) => (
        <Feed
          key={id}
          id={id}
          title={name}
          programmes={programmes}
          onOpenEditModal={onOpenEditModal}
          onDeleteDataFeed={onDeleteDataFeed}
          onToggleSyncOfProgramme={onToggleSyncOfProgramme}
        />
      ))}
    </div>
  );

  const renderFooter = (
    <div className={styles.footer}>
      <button className={styles.add} onClick={onOpenCreateModal}>
        <Icon name="plusDark" size="24px" marginRight="8px" />
        <span>Add Data Feed</span>
      </button>
    </div>
  );

  return (
    <div className={cn(styles.root, className)} id={wrapperId} onClick={onWrapperClick}>
      {renderHeader}
      <div className={styles.body}>
        {renderList}
        {renderFooter}
      </div>
    </div>
  );
}

Feeds.propTypes = {
  className: PropTypes.string,
  wrapperId: PropTypes.string.isRequired,
  onWrapperClick: PropTypes.func.isRequired,
  dataFeedList: PropTypes.array.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onOpenCreateModal: PropTypes.func.isRequired,
  onDeleteDataFeed: PropTypes.func.isRequired,
  onToggleSyncOfProgramme: PropTypes.func.isRequired,
};

export default Feeds;
