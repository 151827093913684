import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import styles from './ProgrammeOptionsForm.module.scss';
import ColorPicker from '../../../../../common/components/UI/ColorPicker';
import Switch from '../../../../../common/components/UI/Switch/Switch';
import { validation } from '../../../../../../utils';
import Input from '../../../../../common/components/UI/Input';

class ProgrammeOptionsForm extends React.Component {
  fontSizeInput = props => (
    <div className={styles.fontSizeInput}>
      <Input {...props} />
      <div className={styles.pt}>lines</div>
    </div>
  );

  fixWidthOfColumns = (headerColumns, updatedColumn) => {
    const firstColumnEnabled = headerColumns.first.enabled;
    const secondColumnEnabled = headerColumns.second.enabled;
    const thirdColumnEnabled = headerColumns.third.enabled;
    if (firstColumnEnabled && secondColumnEnabled && thirdColumnEnabled)
      return {
        firstColumnWidth: 20,
        secondColumnWidth: 60,
        thirdColumnWidth: 20
      }
    if (firstColumnEnabled && secondColumnEnabled)
      return {
        firstColumnWidth: 20,
        secondColumnWidth: 80,
        thirdColumnWidth: 0
      }
    if (firstColumnEnabled && thirdColumnEnabled)
      return {
        firstColumnWidth: 20,
        secondColumnWidth: 0,
        thirdColumnWidth: 80
      }
    if (secondColumnEnabled && thirdColumnEnabled)
      return {
        firstColumnWidth: 0,
        secondColumnWidth: 80,
        thirdColumnWidth: 20
      }
    if (firstColumnEnabled)
      return {
        firstColumnWidth: 100,
        secondColumnWidth: 0,
        thirdColumnWidth: 0
      }
    if (secondColumnEnabled)
      return {
        firstColumnWidth: 0,
        secondColumnWidth: 100,
        thirdColumnWidth: 0
      }
    if (thirdColumnEnabled)
      return {
        firstColumnWidth: 0,
        secondColumnWidth: 0,
        thirdColumnWidth: 100
      }
    return {
      firstColumnWidth: 0,
      secondColumnWidth: 0,
      thirdColumnWidth: 0
    }


  }

  render() {
    const { onOptionChange, initialValues } = this.props;


    return (
      <>
        {initialValues.options &&
          <div className={styles.root}>
            <div className={styles.control}>
              <span className={styles.controlLabel}>Theatre color:</span>
              <Field name="options.color" component={ColorPicker} onBlur={(e) => { onOptionChange({ ...initialValues.options, color: e }) }} />
            </div>
            <div className={styles.control}>
              <span className={styles.controlLabel}>Auto shorten description:</span>
              <Field name="options.autoShortenDescription" type="checkbox" component={Switch} onChange={() => { onOptionChange({ ...initialValues.options, autoShortenDescription: !initialValues.options.autoShortenDescription }) }} />
            </div>
            <div className={styles.control}>
              <span className={styles.controlLabel}>Description lines:</span>
              <Field normalize={validation.normalizeNumber} name="options.descriptionLines" type="number" component={this.fontSizeInput} onBlur={(e) => onOptionChange({ ...initialValues.options, descriptionLines: Number(e.target.value) })} />
            </div>
          </div>}
      </>
    );
  }
}

ProgrammeOptionsForm.propTypes = {
  onOptionChange: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'theatre-options',
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: false,
})(ProgrammeOptionsForm);
