export default function changeNameRegexp(fileName, regExp, testRegexp) {
  let target = fileName;
  const findRegexp = regExp.test(target);

  if (testRegexp || findRegexp) {
    target = target.replace(regExp, '');
    return changeNameRegexp(target, regExp);
  }
  return target;
}
