import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import iconsMap from './iconsMap';

import styles from './Icon.module.scss';
import { cleanObject } from '../../../../../utils';

function Icon({
  name,
  size,
  width,
  height,
  margin,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  style: styleProp,
  className,
  ...rest
}) {
  const iconPath = iconsMap[name];

  if (!iconPath) {
    // eslint-disable-next-line
    console.error(`Icon '${name}' was not found (in Icon component)`);
    return null;
  }

  const style = cleanObject({ margin, marginTop, marginLeft, marginRight, marginBottom, ...styleProp });

  return (
    <div
      style={{
        width: width || size,
        height: height || size,
        backgroundImage: `url(${iconPath})`,
        ...style,
      }}
      className={cn(styles.container, className && className)}
      {...rest}
    />
  );
}

Icon.defaultProps = {
  size: '16px',
  width: null,
  height: null,
};

const numOrStr = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(iconsMap)).isRequired,
  size: numOrStr,
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  margin: numOrStr,
  marginTop: numOrStr,
  marginLeft: numOrStr,
  marginRight: numOrStr,
  marginBottom: numOrStr,
};

export default Icon;
