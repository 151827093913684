import React, { Component } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import styles from './SpeakersListContainer.module.scss';
import SpeakersList from '../SpeakersList';

class SpeakersListContainer extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { speakers, change } = this.props;

    const reorderList = arrayMove(speakers, oldIndex, newIndex).map((content, order) => ({
      ...content,
      order,
    }));
    change('speakers', reorderList);
  };

  render() {
    const { speakers, onEdit, onRemove, isOpen } = this.props;

    return (
      <div className={styles.container}>
        <SpeakersList
          onEdit={onEdit}
          onRemove={onRemove}
          useDragHandle
          helperClass={styles.draggingItem}
          items={speakers || []}
          onSortEnd={this.onSortEnd}
          isOpen={isOpen}
        />
      </div>
    );
  }
}

SpeakersListContainer.propTypes = {
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number.isRequired,
      speakerCompany: PropTypes.string.isRequired,
      speakerJob: PropTypes.string.isRequired,
      speakerName: PropTypes.string.isRequired,
    }),
  ),
  onEdit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SpeakersListContainer;
