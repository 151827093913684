import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './NavigationTabs.module.scss';

const tabs = ['session', 'speakers'];

export default function NavigationTabs({ handleSelectTab, selectedTab }) {
  return (
    <div className={styles.navigationTabs}>
      {tabs.map(tab => (
        <label
          key={tab}
          id={tab}
          onClick={handleSelectTab}
          className={cn(styles.tab, selectedTab === tab && styles.isActive)}
        >
          {tab}
        </label>
      ))}
    </div>
  );
}

NavigationTabs.propTypes = {
  handleSelectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
};
