const isTimeCorrect = timeValue => (timeValue ? timeValue.indexOf('_') === -1 : false);

export default function validate(values) {
  const errors = {};

  /* eslint-disable */
  const playtimes = values._playtime.slice(0, -1);
  const playtimesLen = values._playtime.length;
  const lastPlaytime = values._playtime[playtimesLen - 1];
  /* eslint-enable */

  // eslint-disable-next-line
  if (values._playtime.length) {
    const playtimesArrayErrors = [];
    playtimes.forEach((playtime, playtimeIndex) => {
      const playtimeErrors = {};

      if (!playtime || !isTimeCorrect(playtime.start)) {
        playtimeErrors.start = 'Incorrect';
      }

      if (!playtime || !playtime.start) {
        playtimeErrors.start = 'Required';
      }

      if (!playtime || !isTimeCorrect(playtime.end)) {
        playtimeErrors.end = 'Incorrect';
      }

      if (!playtime || !playtime.end) {
        playtimeErrors.end = 'Required';
      }

      if (!playtime || !playtime.date) {
        playtimeErrors.date = 'Required';
      }

      playtimesArrayErrors[playtimeIndex] = playtimeErrors;
    });

    if (playtimesArrayErrors.length) {
      // eslint-disable-next-line
      errors._playtime = playtimesArrayErrors;
    }

    let lastPlaytimeErrors = {};
    if (lastPlaytime) {
      const isEmptyField = field => (field || '') === '';
      if (!isEmptyField(lastPlaytime.start) || !isEmptyField(lastPlaytime.end) || !isEmptyField(lastPlaytime.date)) {
        if (!isTimeCorrect(lastPlaytime.start)) {
          lastPlaytimeErrors.start = 'Incorrect';
        }

        if (!lastPlaytime.start) {
          lastPlaytimeErrors.start = 'Required';
        }

        if (!isTimeCorrect(lastPlaytime.end)) {
          lastPlaytimeErrors.end = 'Incorrect';
        }

        if (!lastPlaytime.end) {
          lastPlaytimeErrors.end = 'Required';
        }

        if (!lastPlaytime.date) {
          lastPlaytimeErrors.date = 'Required';
        }
      } else {
        lastPlaytimeErrors = {};
      }
    }

    // eslint-disable-next-line
    errors._playtime = [...playtimesArrayErrors, lastPlaytimeErrors];
  }

  return errors;
}
