export const CREATE_PLAYLIST_AND_ADD_CONTENT_META = 'contentGroup/CREATE_PLAYLIST_AND_ADD_CONTENT_META';
export const FETCH_CONTENT_GROUP_START = 'contentGroup/FETCH_CONTENT_GROUP_START';
export const FETCH_CONTENT_GROUP_FINISHED = 'contentGroup/FETCH_CONTENT_GROUP_FINISHED';
export const SET_CONTENT_GROUP = 'contentGroup/SET_CONTENT_GROUP';
export const SET_CONTENT_TYPE_FILTER = 'contentGroup/SET_CONTENT_TYPE_FILTER';
export const SET_CONTENT_GROUP_TO_CHECK_LIST = 'contentGroup/SET_CONTENT_GROUP_TO_CHECK_LIST';
export const SET_SCREEN_SIZE_ID = 'contentGroup/SET_SCREEN_SIZE_ID';

export const ADD_PLAYLIST_TO_PLAYLIST_START = 'contentGroup/ADD_PLAYLIST_TO_PLAYLIST_START';
export const ADD_PLAYLIST_TO_PLAYLIST_END = 'contentGroup/ADD_PLAYLIST_TO_PLAYLIST_END';
export const ADD_PLAYLIST_TO_PLAYLIST = 'contentGroup/ADD_PLAYLIST_TO_PLAYLIST';

export const ADD_FULL_PROGRAMME_TO_PLAYLIST_START = 'contentGroup/ADD_FULL_PROGRAMME_TO_PLAYLIST_START';
export const ADD_FULL_PROGRAMME_TO_PLAYLIST_END = 'contentGroup/ADD_FULL_PROGRAMME_TO_PLAYLIST_END';
export const ADD_FULL_PROGRAMME_TO_PLAYLIST = 'contentGroup/ADD_FULL_PROGRAMME_TO_PLAYLIST';

export const FETCH_CREATE_CONTENT_GROUP = 'contentGroup/FETCH_CREATE_CONTENT_GROUP';
export const FETCH_MULTIPLE_CREATE_CONTENT_GROUP = 'contentGroup/FETCH_MULTIPLE_CREATE_CONTENT_GROUP';
export const FETCH_DELETE_CONTENT_GROUP = 'contentGroup/FETCH_DELETE_CONTENT_GROUP';
export const FETCH_ARR_DELETE_CONTENT_GROUP = 'contentGroup/FETCH_ARR_DELETE_CONTENT_GROUP';
export const FETCH_ADD_CONTENT_GROUPS_NOTEXIST_PLAYLIST_FROM_MEDIA =
  'contentGroup/FETCH_ADD_CONTENT_GROUPS_NOTEXIST_PLAYLIST_FROM_MEDIA';
export const FETCH_ADD_CONTENT_GROUPS_EXIST_PLAYLIST_FROM_MEDIA =
  'contentGroup/FETCH_ADD_CONTENT_GROUPS_EXIST_PLAYLIST_FROM_MEDIA';
export const FETCH_ADD_CONTENT_GROUPS_EXIST_PLAYLIST_FROM_MEDIA_END =
  'contentGroup/FETCH_ADD_CONTENT_GROUPS_EXIST_PLAYLIST_FROM_MEDIA_END';
export const FETCH_UPDATE_PLAYLIST_CONTENT_GROUP = 'contentGroup/FETCH_UPDATE_PLAYLIST_CONTENT_GROUP';
export const FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_END = 'contentGroup/FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_END';
export const FETCH_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST = 'contentGroup/FETCH_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST';
export const FETCH_MULTIPLE_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST =
  'contentGroup/FETCH_MULTIPLE_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST';
export const FETCH_MULTIPLE_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST_END =
  'contentGroup/FETCH_MULTIPLE_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST_END';
export const FETCH_CREATE_CONTENT_GROUP_SET_TO_NOTEXIST_PLAYLIST =
  'contentGroup/FETCH_CREATE_CONTENT_GROUP_SET_TO_NOTEXIST_PLAYLIST';
export const FETCH_REORDER_CONTENT_GROUP_META = 'contentGroup/FETCH_REORDER_CONTENT_GROUP_META';
export const FETCH_REORDER_CONTENT_GROUP_META_END = 'contentGroup/FETCH_REORDER_CONTENT_GROUP_META_END';
export const FETCH_ADD_CONTENT_TO_CONTENT_GROUP = 'contentGroup/FETCH_ADD_CONTENT_TO_CONTENT_GROUP';
export const FETCH_ADD_CONTENT_TO_CONTENT_GROUP_END = 'contentGroup/FETCH_ADD_CONTENT_TO_CONTENT_GROUP_END';
export const FETCH_CREATE_RELATION_FOR_CONTENT_WITH_CONTENT_GROUP =
  'contentGroup/FETCH_CREATE_RELATION_FOR_CONTENT_WITH_CONTENT_GROUP';
export const FETCH_CREATE_RELATION_FOR_CONTENT_WITH_CONTENT_GROUP_END =
  'contentGroup/FETCH_CREATE_RELATION_FOR_CONTENT_WITH_CONTENT_GROUP_END';
export const FETCH_REMOVE_CONTENT_FROM_CONTENT_GROUP = 'contentGroup/FETCH_REMOVE_CONTENT_FROM_CONTENT_GROUP';

export const FETCH_ABORT_UPLOAD_FILE = 'content/FETCH_ABORT_UPLOAD_FILE';
export const FETCH_CREATE_URL_CONTENT_GROUP = 'contentGroup/FETCH_CREATE_URL_CONTENT_GROUP';
