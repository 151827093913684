import React from 'react';
import PropTypes from 'prop-types';
import ReactList from 'react-list';
import { Field } from 'redux-form';
// import cn from 'classnames';
import EntityNameField from '../../../../../../common/components/UI/EntityNameField';
import SelectAdapter from '../../../../../../common/components/UI/SelectAdapter';
import Switch from '../../../../../../common/components/UI/Switch';

import Fields from './components/Fields';
import Request from './components/Request';

import styles from './Form.module.scss';
import { validation } from '../../../../../../../utils';
import FormValues from '../../prop-types/form-values';
import safeGet from '../../../../../../../utils/safeGet';
import Button from '../../../../../../common/components/UI/Button';
import DatePicker from '../../../../../../common/components/UI/DatePicker';

const normalizeFrequency = value => {
  if (!value) return '';
  if (value[0] === '0') return '';
  return value;
};

// eslint-disable-next-line react/prop-types
const Api = ({ dataFeedApis, formValues }) => {
  const selectedDataFeedApi = dataFeedApis.find(({ id }) => id === safeGet(() => formValues.dataFeedApiId));
  const hasOptions = !!safeGet(() => selectedDataFeedApi.options.length);

  const renderOptions = formValues.dataFeedApiId && hasOptions && (
    <div className={styles.apiOptions}>
      <div className={styles.apiOptionsTitle}>{safeGet(() => selectedDataFeedApi.name)}</div>
      <Fields>
        {selectedDataFeedApi.options.map(option => {
          const name = ['options', option.fieldName].join('.');
          return (
            <Fields.Field key={option.label} label={`${option.label}:`} name={name} validate={option.required === false ? false : validation.required} />
          );
        })}
      </Fields>
    </div>
  );

  const apiSelectOptions = dataFeedApis.map(({ name: label, id: value }) => ({ label, value }));
  return (
    <div className={styles.api}>
      <Field className={styles.apiSelect} name="dataFeedApiId" component={SelectAdapter} options={apiSelectOptions} />
      {renderOptions}
    </div>
  );
};

const renderHistoryItem = (index, key, requestHistory) => {
  const item = requestHistory[index];
  return <Request key={key} {...item} />;
};

function Form({ dataFeedApis, formValues, submitText, handleSubmit, onSubmit, onCancel, requestHistory, isLoading }) {
  const renderOptions = (
    <div className={styles.options}>
      <Fields>
        <Fields.Field
          label="Frequency:"
          name="options.frequency"
          normalize={normalizeFrequency}
          validate={validation.required}
          fieldProps={{
            style: { width: 60, marginRight: 10 },
          }}
          rightSlot={(() => (
            <span style={{ display: 'block', paddingTop: 10 }}>Minutes</span>
          ))()}
        />
        <Fields.Field
          label="Synchronization:"
          name="options.sync"
          component={Switch}
          labelProps={{ style: { paddingTop: 0 } }}
        />
        <Fields.Field
          label="End date:"
          minDate={new Date()}
          defaultValue={new Date()}
          component={DatePicker}
          name="options.endDate"
          customFormat="dddd Do MMM"
          className={styles.dateField}
          inputClassName={styles.dateFieldInput}
        />
      </Fields>
    </div>
  );

  const renderHistory = !!requestHistory.length && (
    <div className={styles.history}>
      <div className={styles.historyTitle}>Request History:</div>
      <div className={styles.historyList}>
        <ReactList
          itemRenderer={(index, key) => renderHistoryItem(index, key, requestHistory)}
          length={requestHistory.length}
          type="uniform"
        />
      </div>
    </div>
  );

  const renderFooter = (
    <div className={styles.footer}>
      <Button type="submit" disabled={isLoading}>
        {isLoading ? 'Loading...' : submitText}
      </Button>
      <Button theme="invert" type="button" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field name="id" type="text" component="input" hidden />
      <div className={styles.header}>
        <Field
          name="name"
          component={EntityNameField}
          validate={validation.required}
          normalize={validation.normalizeExtraSpaces}
        />
      </div>
      <Api {...{ dataFeedApis, formValues }} />
      {renderOptions}
      {renderHistory}
      {renderFooter}
    </form>
  );
}

Form.defaultProps = {
  submitText: 'Create',
  requestHistory: [],
};

Form.propTypes = {
  formValues: FormValues,
  submitText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dataFeedApis: PropTypes.array.isRequired,
  requestHistory: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default Form;
