import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { updateDataFeed } from '../../../../../../../state/ducks/dataFeeds/actions';
import { getDataFeedById } from '../../../../../../../state/ducks/dataFeeds/selectors';
import Modal from '../../../../../../common/components/Modal';
import Form from '../Form';

const formName = 'edit-feed';
const EditFeedForm = compose(
  connect(
    (state, { editFeedId }) => {
      const { requestHistory, ...initialValues } = getDataFeedById(state, editFeedId);
      return {
        requestHistory,
        initialValues,
        formValues: getFormValues(formName)(state) || {},
      };
    },
    { onSubmit: updateDataFeed },
  ),
  reduxForm({ form: formName }),
)(Form);

function EditFeedModal({ isOpen, onClose, editFeedId, dataFeedApis }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scroll="modal">
      <EditFeedForm onCancel={onClose} editFeedId={editFeedId} dataFeedApis={dataFeedApis} submitText="Save" />
    </Modal>
  );
}

EditFeedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editFeedId: PropTypes.number.isRequired,
  dataFeedApis: PropTypes.array.isRequired,
};

export default EditFeedModal;
