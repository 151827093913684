import { put, call, takeLatest, select, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import * as types from './types';
import * as actions from './actions';
import { playlistsActions } from '../playlists';
import { fetch, apiRoutes, storage, s3upload, sentryReport, getErrorMessage } from '../../utils';
import { showNotification } from '../app/actions';
import { getFileType, getNameWithTimestamp } from '../../utils/create-file-helper';

const { setPlaylists, setContentMeta, setCurrentPlaylistMeta } = playlistsActions;

const {
  fetchPlaylistContents,
  setUploadedContent,
  setContents,
  fetchContentStart,
  fetchContentFinished,
  setPlaylistsContents,
  fetchCreateContentFinish,
  setPlaylistContentMeta,
  fetchContentMetaStart,
  fetchContentMetaFinished,
  setProgressToContent,
  removeProgressFromContent,
} = actions;

function* fetchS3SignedUrlFlow(data) {
  const { url, method } = apiRoutes.getSignedUrl;
  const user = storage.getUser();
  const response = yield call(fetch, url(user.userId), method, { data });
  return response;
}

function* fetchPlaylistContentsFlow(action) {
  try {
    const playlistId = action.payload;
    yield put(fetchContentStart());

    const { url, method } = apiRoutes.getPlaylistContentGroups;
    const res = yield call(fetch, url(playlistId), method);
    yield put(setPlaylistsContents(res));
    const playlists = yield select(state => state.playlists.playlists);

    const updatePlaylists = playlists.map(item => {
      if (item.id === playlistId) {
        item.contents = res;
        return item;
      }
      return item;
    });
    yield put(setPlaylists(updatePlaylists));
    yield put(fetchContentFinished());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchContentFinished());
  }
}

function* fetchCreatePlaylistContentFlow(action) {
  try {
    const { playlistId, formData } = action.payload;
    yield put(fetchContentStart());
    const response = yield call(fetchS3SignedUrlFlow, {
      type: formData.type,
      fileName: formData.fileData.file[0].name,
    });
    const signedUrl = response.data.urls[0];
    const s3ContentType = response.data.ContentType;
    const content = response.data.path;

    formData.content = content;
    formData.fileData.fileName = formData.fileData.file[0].name;
    yield call(s3upload, signedUrl, 'PUT', formData.fileData.file[0], {
      'Content-Type': s3ContentType,
    });
    const { url, method } = apiRoutes.createPlaylistContent;
    const res = yield call(fetch, url(playlistId), method, formData);

    const contents = yield select(state => state.content.contents);
    const updateContents = [...contents, res];
    yield put(setContents(updateContents));

    let order = 0;
    const playlistsContents = yield select(state => state.content.playlistsContents);

    if (playlistsContents && playlistsContents.length !== 0) {
      order = playlistsContents.length;
    }
    const { playTime } = formData;

    const contentMetaData = {
      duration: Number(formData.duration),
      contentId: res.id,
      ownerId: res.ownerId,
      playlistId,
      order,
      playTime,
    };

    yield put(setPlaylistContentMeta(contentMetaData));
    yield put(fetchCreateContentFinish());

    const updatedPlaylistContents = [...playlistsContents, res];
    yield put(setPlaylistsContents(updatedPlaylistContents));
    yield put(setUploadedContent(null));

    yield put(fetchContentFinished());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchCreateContentFinish());
    yield put(fetchContentFinished());
  }
}

function* fetchAddPlaylistContentMeta(action) {
  try {
    yield put(fetchContentMetaStart());
    const { playlistId } = action.payload;
    const { url, method } = apiRoutes.addPlaylistContentMeta;
    const res = yield call(fetch, url(playlistId), method, action.payload);
    const contentMeta = yield select(state => state.playlists.contentMeta);
    const connectNewMeta = [...contentMeta, res];
    yield put(setContentMeta(connectNewMeta));
    yield put(setCurrentPlaylistMeta(connectNewMeta.filter(meta => meta.playlistId === playlistId)));

    yield put(fetchContentMetaFinished());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchContentMetaFinished());
  }
}

function getDurationVideo(url) {
  return new Promise(resolve => {
    const video = document.createElement('video');
    video.src = url;

    video.onloadedmetadata = function(event) {
      const { duration } = event.target;
      resolve(Math.floor(duration));
    };
  });
}

function* fetchUpdatePlaylistContentFlow(action) {
  try {
    const { playlistId, contentId } = action.payload;
    yield put(fetchContentStart());

    let duration = 15;
    // get video duration
    const allContent = yield select(state => state.content.contents);
    const findCurrentContent = allContent.find(content => content.id === contentId);
    if (findCurrentContent.type === 'video') {
      duration = yield call(getDurationVideo, findCurrentContent.content);
    }

    const { url, method } = apiRoutes.updatePlaylistContentGroup;
    yield call(fetch, url(playlistId, contentId), method);
    yield put(fetchPlaylistContents(playlistId));

    const ownerId = yield select(state => state.auth.user.id);
    const playlistsContents = yield select(state => state.content.playlistsContents);

    const contentMetaData = {
      _playtime: [],
      order: playlistsContents.length,
      duration,
      ownerId,
      contentId,
      playlistId,
    };

    yield put(setPlaylistContentMeta(contentMetaData));
    yield put(fetchContentFinished());
    yield put(fetchCreateContentFinish());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchCreateContentFinish());
    yield put(fetchContentFinished());
  }
}

function* fetchDeleteContentFlow(action) {
  try {
    const contentId = action.payload;
    yield put(fetchContentStart());
    const { url, method } = apiRoutes.deleteContent;
    yield call(fetch, url(contentId), method);
    const updateContent = yield select(state => state.content.contents);
    yield put(setContents(updateContent.filter(item => item.id !== contentId)));

    const playlistsContents = yield select(state => state.content.playlistsContents);
    yield put(setPlaylistsContents(playlistsContents.filter(item => item.id !== contentId)));
    yield put(fetchContentFinished());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchContentFinished());
  }
}

function* fetchReplaceContentFlow({ payload }) {
  let progressHashName;
  try {
    const { contentId, fileData } = payload;
    const fileName = +new Date() + fileData.name;

    // request to S3 amazon
    const resOfS3 = yield call(fetchS3SignedUrlFlow, {
      type: getFileType(fileData.type),
      fileName,
    });

    const { urls, ContentType, path } = resOfS3.data;
    [progressHashName] = urls;

    // start pinner
    const fileProgressData = {
      uniqueKey: progressHashName,
      name: fileName,
      flagField: 'replace',
      contentId,
    };
    yield put(setProgressToContent(progressHashName, 1, 0, fileProgressData));

    // upload content to S3 amazon
    yield call(
      s3upload,
      progressHashName,
      'PUT',
      fileData,
      {
        'Content-Type': ContentType,
      },
      progressHashName,
      fileProgressData,
    );

    // replace content on back end
    const { url, method } = apiRoutes.replaceContent;
    const resReplaceContent = yield call(fetch, url(contentId), method, { data: path });

    // set to redux new content
    const getAllContents = yield select(state => state.content.contents);
    const updateContent = getAllContents.map(ct => (ct.id === contentId ? resReplaceContent.data : ct));
    yield put(setContents(updateContent));
    yield put(showNotification({ level: 'info', message: 'Content has been updated!', autoDismiss: 2 }));
    // end spinner
    yield put(removeProgressFromContent(progressHashName));

    yield put({ type: types.FETCH_REPLACE_CONTENT_END, payload: { contentId } });
  } catch (error) {
    if (axios.isCancel(error)) {
      const isAbort = true;
      yield put(removeProgressFromContent(progressHashName, isAbort));
      yield put(fetchContentFinished());
    } else {
      sentryReport(error);
      const messageError = getErrorMessage(error);
      const { level, message } = messageError;
      yield put(showNotification({ level, message }));
      yield put(removeProgressFromContent(progressHashName));
      yield put(fetchContentFinished());
    }
  }
}

function* fetchUploadAndReplaceUrlForContentFlow(action) {
  let progressHashName;
  try {
    const { file, contentId, contentGroupId } = action.payload;

    const fileName = getNameWithTimestamp(file.name);

    const resOfS3 = yield call(fetchS3SignedUrlFlow, {
      type: getFileType(file.type),
      fileName,
    });
    const { urls, ContentType, path } = resOfS3.data;
    [progressHashName] = urls;
    // set progress bar
    const fileProgressData = {
      name: fileName,
      flagField: 'editHtmlContent',
      contentType: ContentType,
      contentId,
    };

    yield put(setProgressToContent(progressHashName, 1, 0, fileProgressData));

    // upload content to S3 amazon
    yield call(
      s3upload,
      progressHashName,
      'PUT',
      file,
      {
        'Content-Type': ContentType,
      },
      progressHashName,
      fileProgressData,
    );

    const { url, method } = apiRoutes.replaceContent;
    const resReplaceContent = yield call(fetch, url(contentId), method, { data: path });
    // set to redux new content
    const getAllContents = yield select(state => state.content.contents);
    const updateContent = getAllContents.map(ct => (ct.id === contentId ? resReplaceContent.data : ct));
    yield put(setContents(updateContent));
    yield put(showNotification({ level: 'info', message: 'Content has been updated!', autoDismiss: 1 }));
    // end spinner
    yield put(removeProgressFromContent(progressHashName));
    yield put({ type: types.FETCH_EDIT_HTML_CONTENT_END, payload: { contentGroupId } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(removeProgressFromContent(progressHashName));
  }
}

export default [
  takeLatest(types.FETCH_PLAYLIST_CONTENTS, fetchPlaylistContentsFlow),
  takeEvery(types.SET_PLAYLIST_CONTENT_META, fetchAddPlaylistContentMeta),
  takeLatest(types.FETCH_CREATE_PLAYLIST_CONTENT, fetchCreatePlaylistContentFlow),
  takeEvery(types.FETCH_UPDATE_PLAYLIST_CONTENT, fetchUpdatePlaylistContentFlow),
  takeEvery(types.FETCH_DELETE_CONTENT, fetchDeleteContentFlow),
  takeEvery(types.FETCH_REPLACE_CONTENT, fetchReplaceContentFlow),
  takeEvery(types.FETCH_EDIT_HTML_CONTENT, fetchUploadAndReplaceUrlForContentFlow),
];
