import { createSelector } from 'reselect';
import { getContentsMeta } from '../contentMeta/selectors';
import { getScreens } from '../screens/selectors';

export const getIsCreatingPlaylist = state => state.playlists.isCreatingPlaylist;
export const getPlaylists = state => state.playlists.playlists;

const currentPlaylistId = (state, ownProps) => ownProps && Number(ownProps.match.params.id);

const contentMeta = state => state.playlists.contentMeta;
const contentGroup = state => state.contentGroup.contentGroup;
const getContents = state => state.contents.contents;
export const getPlaylistContents = createSelector([
  contentMeta,
  getPlaylists,
  currentPlaylistId,
  contentGroup,
  getContents,
]);

export const currentPlaylist = createSelector(
  [getPlaylists, currentPlaylistId],
  (play, currId) => play.find(item => item.id === currId),
);

export const getDefaultPlaylists = createSelector(
  getPlaylists,
  allPlaylists => allPlaylists.filter(({ type }) => type === 'default'),
);

export const getTheatrePlaylists = createSelector(
  getPlaylists,
  allPlaylists => allPlaylists.filter(({ type }) => type === 'theatre'),
);

export const getPlaylistsWithMeta = createSelector(
  [getPlaylists, getContentsMeta, getScreens],
  (allPlaylists, contentsMeta, screens) =>
    allPlaylists.map(playlist => {
      const playlistMeta = contentsMeta.filter(meta => meta.playlistId === playlist.id);
      const ownerScreens = screens.filter(
        screen => screen.playlists && !!screen.playlists.find(p => p.id === playlist.id),
      );
      return { ...playlist, ownerScreens, contentMeta: playlistMeta };
    }),
);

export const getPlaylistById = createSelector(
  [getPlaylistsWithMeta, (_, id) => id],
  (playlists, id) => playlists.find(p => p.id === id) || {},
);

export const getSortedPlaylist = createSelector(
  [getPlaylistsWithMeta],
  playlistsWithMeta =>
    playlistsWithMeta.sort((a, b) => {
      if (a.id < b.id) {
        return a.contentMeta.length === 0 ? 1 : -1;
      }

      return b.contentMeta.length === 0 ? -1 : 1;
    }),
);
