import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import styles from './ProgrammesView.module.scss';
import ProgrammeDetailsViewContainer from '../ProgrammeDetailsView';
import ProgrammesList from '../ProgrammesList';
import SessionModal from '../SessionModal';
import ProgrammeCreateView from '../ProgrammeCreateView';
import DataFeed from '../DataFeed';

class ProgrammesView extends Component {
  state = {
    toCloseProgramme: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.toCloseProgramme === false && this.state.toCloseProgramme === true) {
      this.setState({ toCloseProgramme: false });
    }
  }

  handleCloseProgramme = e => {
    e.preventDefault();
    if (e.target.id !== 'closeProgrammeId') return;
    const { currentLocation } = this.props;
    if (currentLocation !== '/programmes') {
      this.setState({ toCloseProgramme: true });
    }
  };

  buildRoutes = () => {
    const { programmes, handleCreateNewTheatre, handleRemoveTheatre } = this.props;

    const renderProgrammesList = (
      <ProgrammesList
        programmesList={programmes}
        handleRemoveTheatre={handleRemoveTheatre}
        handleCreateNewTheatre={handleCreateNewTheatre}
        onWrapperClick={this.handleCloseProgramme}
        wrapperId="closeProgrammeId"
      />
    );

    const routes = [
      {
        path: '/programmes/data-feed',
        render: props => [
          <DataFeed
            key="data-feed"
            onWrapperClick={this.handleCloseProgramme}
            wrapperId="closeProgrammeId"
            {...props}
          />,
          <Route
            key="details-view"
            path="/programmes/data-feed/:theatreId"
            render={programmeProps => (
              <ProgrammeDetailsViewContainer
                {...props}
                {...this.props}
                {...programmeProps}
                backRoute="/programmes/data-feed"
              />
            )}
          />,
        ],
      },
      {
        path: '/programmes/new',
        render: props => [renderProgrammesList, <ProgrammeCreateView key="create-view" {...props} {...this.props} />],
      },
      {
        path: '/programmes/:theatreId',
        render: props => [
          renderProgrammesList,
          <ProgrammeDetailsViewContainer key="details-view" {...props} {...this.props} />,
        ],
      },
      {
        path: '/programmes',
        render: () => renderProgrammesList,
      },
    ];

    return routes;
  };

  render() {
    const { handleCloseSessionModal, sessionModal, currentLocation } = this.props;

    if (this.state.toCloseProgramme) {
      const to = currentLocation.match('/programmes/data-feed') ? '/programmes/data-feed' : '/programmes';
      return <Redirect to={to} />;
    }

    return (
      <React.Fragment>
        <div className={styles.container}>
          <Switch>
            {this.buildRoutes().map(({ path, render }) => (
              <Route key={path} path={path} render={render} />
            ))}
          </Switch>
        </div>
        {sessionModal.isOpen && (
          <SessionModal handleCloseSessionModal={handleCloseSessionModal} sessionModal={sessionModal} />
        )}
      </React.Fragment>
    );
  }
}

ProgrammesView.propTypes = {
  sessionModal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    type: PropTypes.string,
  }).isRequired,
  handleOpenSessionModal: PropTypes.func.isRequired,
  handleCloseSessionModal: PropTypes.func.isRequired,
};

export default ProgrammesView;
