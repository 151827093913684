import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import styles from './Theatre.module.scss';
import { Field } from 'redux-form';
import PlaylistDetailsWrapper from '../../../PlaylistDetailsWrapper';
import PlaylistNameField from '../../../PlaylistNameField/PlaylistNameField';
import UploadSpinner from '../../../../../../common/components/UI/UploadSpinner';
import PlaylistTemplateCard from '../../../PlaylistTemplateCard';
import ConnectedAddFilesBtn from '../../../../../../common/components/ConnectedAddFilesBtn/ConnectedAddFilesBtn';
import styles from './Theatre.module.scss';
import Select from '../../../../../../common/components/UI/Select';
import Button from '../../../../../../common/components/UI/Button';
import OptionsButton from '../../../../../../common/components/UI/OptionsButton';
import ChoosePlaylistModal from '../../../../../../common/components/ChoosePlaylistModal';
import makeToggleable from '../../../../../../hocs/makeToggleable';
import Icon from '../../../../../../common/components/UI/Icon';
import MenuList from '../../../../../../common/components/UI/MenuList/MenuList';
import DotsButton from '../../../../../../common/components/UI/DotsButton';
import ContentSettingsWrapper from '../../../../../../common/components/ContentSettingsWrapper/ContentSettingsWrapper';
import PlaylistContents from '../../../../../Screens/components/PlaylistContents';
import OptionsModal from './OptionsModal';
import Tooltip from '../../../../../../common/components/UI/Tooltip/Tooltip';

const Playlist = makeToggleable(({ isOpen, open, rootRef, playlist, onOpenChoosePlaylist }) => (
  <div className={styles.playlist}>
    <div ref={rootRef} className={styles.playlistHeader}>
      <div className={styles.playlistName}>
        <Icon name="playlistIcon" size={24} marginRight={16} />
        <span>{playlist.name}</span>
      </div>
      <ConnectedAddFilesBtn
        size="medium"
        withTip={false}
        modalData={{
          id: playlist.id,
        }}
      />
      <DotsButton
        size={16}
        className={styles.playlistDots}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          open();
        }}
      />
      <MenuList isOpen={isOpen} top={8} right={8}>
        <MenuList.Item title="Choose playlist" onClick={onOpenChoosePlaylist} />
        <MenuList.Item title="Add full programme" />
      </MenuList>
    </div>
    <ContentSettingsWrapper>
      {({ onOpen }) => (
        <PlaylistContents
          bordered={false}
          playlistId={playlist.id}
          onEditItem={onOpen}
          className={styles.playlistContents}
          itemStyle={{ paddingLeft: 40 }}
        />
      )}
    </ContentSettingsWrapper>
  </div>
));

const PlaylistContainer = makeToggleable(
  ({ open, isOpen, close, playlists, playlist, programmes, onChoosePlaylist }) => {
    const hasProgrammes = programmes && programmes.length > 0;

    return (
      <>
        <div className={styles.playlistContainer}>
          <div className={styles.inner}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <div className={styles.now}>NOW</div>
                <div className={styles.next}>NEXT</div>
              </div>
              <div className={styles.headerRight}>
                {hasProgrammes ? (
                  <Field
                    name="options.selectedProgrammeId"
                    options={programmes.map(({ title: label, id: value }) => ({ label, value }))}
                    component={props => {
                      const { input } = props;
                      const selectedProgramme = programmes.find(({ id }) => id === input.value);
                      const selectValue = selectedProgramme
                        ? { label: selectedProgramme.title, value: selectedProgramme.id }
                        : null;

                      if (!selectedProgramme && hasProgrammes) {
                        const { id: newValue } = programmes[programmes.length - 1];
                        input.onChange(newValue);
                      }

                      return (
                        <Select
                          {...input}
                          value={selectValue}
                          onChange={selectedOption => input.onChange(selectedOption.value)}
                          {...props}
                        />
                      );
                    }}
                    onBlur={e => e.preventDefault()}
                  />
                ) : (
                  <Link to="/programmes/new" className={styles.createProgramme}>
                    Create programme
                  </Link>
                )}
              </div>
            </div>
            <div className={styles.body}>
              {playlist && <Playlist playlist={playlist} onOpenChoosePlaylist={open} />}
              {!playlist && (
                <Button className={styles.choosePlaylist} onClick={open} disabled={!hasProgrammes}>
                  <div className={styles.choosePlaylistInner} data-tip="First create a programme">
                    <span>Choose playlist</span>
                    {!hasProgrammes && <Tooltip className={styles.tooltip} />}
                  </div>
                </Button>
              )}
            </div>
          </div>
          <div className={styles.bottomBar}>#EventHashtag</div>
        </div>
        <ChoosePlaylistModal
          playlists={playlists}
          isOpen={isOpen}
          selectedPlaylistId={playlist ? playlist.id : null}
          onClose={close}
          onChoosePlaylist={onChoosePlaylist}
        />
      </>
    );
  },
);

class Theatre extends React.Component {
  state = {
    isOpenOptionsModal: false,
  };

  handleOpenOptionsModal = () => this.setState({ isOpenOptionsModal: true });

  handleCloseOptionsModal = () => this.setState({ isOpenOptionsModal: false });

  handleConfirmOptionsModal = options => {
    const { formValues, change } = this.props;
    change('options', { ...formValues.options, ...options });
    this.handleCloseOptionsModal();
  };

  handleChoosePlaylist = selectedPlaylistId => {
    const { createPlaylist, formValues } = this.props;
    const { options } = formValues;

    const finalOptions = { ...options, selectedPlaylistId };
    createPlaylist({ ...formValues, options: finalOptions });
  };

  render() {
    const { isOpenOptionsModal } = this.state;
    const { playlists, programmes, isCreatingPlaylist, formValues, onToggleEditMode, onChangeName } = this.props;
    // const playlist = playlists.find(p => p.id === 563);

    const {
      options: { selectedProgrammeId, selectedPlaylistId, ...options },
    } = formValues;

    return (
      <PlaylistDetailsWrapper>
        {isCreatingPlaylist && <UploadSpinner zIndex={100} />}
        <PlaylistNameField name="name" type="text" onBlur={onChangeName} />
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <PlaylistTemplateCard type={formValues.type} onEdit={onToggleEditMode} marginRight={10} />
          <OptionsButton onClick={this.handleOpenOptionsModal} />
        </div>
        <PlaylistContainer programmes={programmes} playlists={playlists} onChoosePlaylist={this.handleChoosePlaylist} />
        <OptionsModal
          isOpen={isOpenOptionsModal}
          initialValues={{ options }}
          onCancel={this.handleCloseOptionsModal}
          onConfirm={this.handleConfirmOptionsModal}
        />
      </PlaylistDetailsWrapper>
    );
  }
}

Theatre.propTypes = {
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  isCreatingPlaylist: PropTypes.bool.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onToggleEditMode: PropTypes.func.isRequired,
  playlists: PropTypes.array.isRequired,
  programmes: PropTypes.array.isRequired,
  createPlaylist: PropTypes.func.isRequired,
  createPlaylistAndContentGroup: PropTypes.func.isRequired,
};

export default Theatre;
