const style = obj =>
  `${Object.keys(obj)
    .map(key => `${key}: ${obj[key]}`)
    .join('; ')};`;

const px = size => `${size}px`;

const adjustElementBySize = ({ container, element, subElement, width, height }) => {
  if (!container && !element) {
    throw new Error('Missing container or element in adjustElementBySize');
  }

  const isPortraitOrientation = height >= width;
  const newScale = isPortraitOrientation ? container.clientHeight / height : container.clientWidth / width;

  // eslint-disable-next-line
  // container.style = `${container.style} ${style({ position: 'relative' })}`;
  const size = {
    width: px(width),
    height: px(height),
    'min-height': px(height),
    'max-Height': px(height),
  };

  // eslint-disable-next-line
  element.style = style({
    ...size,
    position: 'absolute',
    // top: `calc(50% + ${offsetHeight}px)`,
    top: '50%',
    left: '50%',
    transform: `scale(${newScale}) translate(-50%, -50%)`,
    'transform-origin': 'top left',
  });

  if (subElement) {
    // eslint-disable-next-line
    subElement.style = style(size);
  }
};

export default adjustElementBySize;
