import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import styles from './Theatre.module.scss';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PlaylistDetailsWrapper from '../../../PlaylistDetailsWrapper';
// import PlaylistNameField from '../../../PlaylistNameField/PlaylistNameField';
import * as contentGroupActions from '../../../../../../../state/ducks/contentGroup/actions';
import UploadSpinner from '../../../../../../common/components/UI/UploadSpinner';
import PlaylistTemplateCard from '../../../PlaylistTemplateCard';
import ConnectedAddFilesBtn from '../../../../../../common/components/ConnectedAddFilesBtn/ConnectedAddFilesBtn';
import styles from './Theatre.module.scss';
import Select from '../../../../../../common/components/UI/Select';
import Button from '../../../../../../common/components/UI/Button';
import OptionsButton from '../../../../../../common/components/UI/OptionsButton';
import ChoosePlaylistModal from '../../../../../../common/components/ChoosePlaylistModal';
import makeToggleable from '../../../../../../hocs/makeToggleable';
import Icon from '../../../../../../common/components/UI/Icon';
import MenuList from '../../../../../../common/components/UI/MenuList/MenuList';
import DotsButton from '../../../../../../common/components/UI/DotsButton';
import ContentSettingsWrapper from '../../../../../../common/components/ContentSettingsWrapper/ContentSettingsWrapper';
import PlaylistContents from '../../../../../Screens/components/PlaylistContents';
import OptionsModal from './OptionsModal';
import { getDefaultPlaylists } from '../../../../../../../state/ducks/playlists/selectors';
import { getProgrammes } from '../../../../../../../state/ducks/programmes/selectors';
import * as playlistActions from '../../../../../../../state/ducks/playlists/actions';
import PlaylistNameForm from '../../../PlaylistNameForm/PlaylistNameForm';
import DropZone from '../../../../../../common/components/DropZone/DropZone';
import Tooltip from '../../../../../../common/components/UI/Tooltip/Tooltip';

const { fetchMultipleCreateContentGroupSetToPlaylist } = contentGroupActions;

const Playlist = makeToggleable(
  ({
    isOpen,
    open,
    close,
    rootRef,
    playlist,
    hasProgrammes,
    onOpenContentSettings,
    onAddFullProgramme,
    onOpenChoosePlaylist,
  }) => (
    <div className={styles.playlist}>
      <div ref={rootRef} className={styles.playlistHeader}>
        <div className={styles.playlistName}>
          <Icon name="playlistIcon" size={24} marginRight={16} />
          <span className={styles.playlistNameText}>{playlist.name}</span>
        </div>
        <ConnectedAddFilesBtn
          size="medium"
          withTip={false}
          modalData={{
            id: playlist.id,
          }}
        />
        <DotsButton
          size={16}
          className={styles.playlistDots}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            open();
          }}
        />
        <MenuList isOpen={isOpen} top={8} right={8}>
          <MenuList.Item title="Choose playlist" onClick={onOpenChoosePlaylist} />
          {hasProgrammes && (
            <MenuList.Item
              title="Add full programme"
              onClick={() => {
                onAddFullProgramme();
                close();
              }}
            />
          )}
        </MenuList>
      </div>
      <PlaylistContents
        bordered={false}
        withFullProgramme
        playlistType="theatre"
        playlistId={playlist.id}
        onEditItem={onOpenContentSettings}
        className={styles.playlistContents}
        itemStyle={{ paddingLeft: 40 }}
      />
    </div>
  ),
);

const PlaylistContainer = makeToggleable(
  ({
    open,
    isOpen,
    close,
    playlists,
    playlist,
    programmes,
    onAcceptDrop,
    onChoosePlaylist,
    onAddFullProgramme,
    onChangeProgramme,
    selectedProgrammeId,
  }) => {
    const hasProgrammes = programmes && programmes.length > 0;
    const selectedProgramme = programmes.find(({ id }) => id === selectedProgrammeId);
    const removeHtmlFromText = (text) => {
      return text.replace( /(<([^>]+)>)/ig, '');
    }
    return (
      <ContentSettingsWrapper>
        {({ onOpen: onOpenContentSettings }) => (
          <div className={styles.playlistContainer}>
            <div className={styles.inner}>
              <div className={styles.header}>
                <div className={styles.headerLeft}>
                  <div className={styles.now}>NOW</div>
                  <div className={styles.next}>NEXT</div>
                </div>
                <div className={styles.headerRight}>
                  {hasProgrammes ? (
                    <Field
                      name="options.selectedProgrammeId"
                      options={programmes.map(({ title: label, id: value }) => ({ label: removeHtmlFromText(label), value }))}
                      component={props => {
                        const { input } = props;
                        const selectValue = selectedProgramme
                          ? { label: removeHtmlFromText(selectedProgramme.title), value: selectedProgramme.id }
                          : null;

                        if (!selectedProgramme && hasProgrammes) {
                          const { id: newValue } = programmes[programmes.length - 1];
                          onChangeProgramme(newValue);
                        }

                        return (
                          <Select
                            {...input}
                            value={selectValue}
                            onChange={selectedOption => {
                              onChangeProgramme(selectedOption.value);
                            }}
                            {...props}
                          />
                        );
                      }}
                      onBlur={e => e.preventDefault()}
                    />
                  ) : (
                    <Link to="/programmes/new" className={styles.createProgramme}>
                      Create programme
                    </Link>
                  )}
                </div>
              </div>
              {playlist && (
                <DropZone onAcceptDrop={onAcceptDrop} className={styles.body} disableClick={false}>
                  {() => (
                    <Playlist
                      playlist={playlist}
                      hasProgrammes={hasProgrammes}
                      onAddFullProgramme={onAddFullProgramme}
                      onOpenContentSettings={item => {
                        const finalItem =
                          item.type === 'programme'
                            ? { ...item, theatre: selectedProgramme && selectedProgramme.title }
                            : item;
                        onOpenContentSettings(finalItem);
                      }}
                      onOpenChoosePlaylist={open}
                    />
                  )}
                </DropZone>
              )}
              {!playlist && (
                <div className={styles.body}>
                  <Button className={styles.choosePlaylist} onClick={open} disabled={!hasProgrammes}>
                    <div className={styles.choosePlaylistInner} data-tip="First create a programme">
                      <span>Choose playlist</span>
                      {!hasProgrammes && <Tooltip className={styles.tooltip} />}
                    </div>
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.bottomBar}>#EventHashtag</div>
            <ChoosePlaylistModal
              playlists={playlists}
              isOpen={isOpen}
              selectedPlaylistId={playlist ? playlist.id : null}
              onClose={close}
              onChoosePlaylist={onChoosePlaylist}
            />
          </div>
        )}
      </ContentSettingsWrapper>
    );
  },
);

class Theatre extends React.Component {
  state = {
    isOpenOptionsModal: false,
  };

  handleOpenOptionsModal = () => this.setState({ isOpenOptionsModal: true });

  handleCloseOptionsModal = () => this.setState({ isOpenOptionsModal: false });

  handleConfirmOptionsModal = options => {
    const { updatePlaylist, currentPlaylist, formValues, change } = this.props;
    const finalOptions = { ...formValues.options, ...options };
    change('options', finalOptions);

    updatePlaylist({
      playlistId: currentPlaylist.id,
      formData: { options: finalOptions },
    });

    this.handleCloseOptionsModal();
  };

  handleChoosePlaylist = selectedPlaylistId => {
    const { currentPlaylist, updatePlaylist, formValues, change } = this.props;

    change('options.selectedPlaylistId', selectedPlaylistId);

    const { options } = formValues;
    const finalOptions = { ...options, selectedPlaylistId };
    updatePlaylist({
      playlistId: currentPlaylist.id,
      formData: { options: finalOptions },
    });
  };

  handleChangeProgramme = selectedProgrammeId => {
    const { currentPlaylist, updatePlaylist, formValues, change } = this.props;

    change('options.selectedProgrammeId', selectedProgrammeId);

    const { options } = formValues;
    const finalOptions = { ...options, selectedProgrammeId };
    updatePlaylist({
      playlistId: currentPlaylist.id,
      formData: { options: finalOptions },
    });
  };

  handleAddFullProgramme = () => {
    const {
      formValues: { options },
      addFullProgrammeToPlaylist,
    } = this.props;

    const { selectedPlaylistId: playlistId, selectedProgrammeId: programmeId } = options;
    addFullProgrammeToPlaylist({ playlistId, programmeId });
  };

  handleAcceptDrop = acceptedFiles => {
    const {
      createContentGroupAndAddToPlaylist,
      formValues: {
        options: { selectedPlaylistId },
      },
    } = this.props;

    createContentGroupAndAddToPlaylist(acceptedFiles, selectedPlaylistId);
  };

  render() {
    const { playlists, programmes, isCreatingPlaylist, formValues, onChangeName } = this.props;

    const { selectedProgrammeId, selectedPlaylistId, ...options } = formValues.options;

    const { isOpenOptionsModal } = this.state;
    const playlist = playlists.find(p => p.id === selectedPlaylistId);

    return (
      <PlaylistDetailsWrapper>
        {isCreatingPlaylist && <UploadSpinner zIndex={100} />}
        {/* <PlaylistNameField name="name" type="text" onChange={onChangeName} /> */}
        <PlaylistNameForm {...{ onChangeName }} initialValues={{ name: formValues.name }} />
        <div style={{ display: 'flex', marginBottom: 24 }}>
          <PlaylistTemplateCard type={formValues.type} isLocked marginRight={10} />
          <OptionsButton onClick={this.handleOpenOptionsModal} />
        </div>
        <PlaylistContainer
          playlist={playlist}
          programmes={programmes}
          playlists={playlists}
          selectedProgrammeId={selectedProgrammeId}
          onAcceptDrop={this.handleAcceptDrop}
          onAddFullProgramme={this.handleAddFullProgramme}
          onChangeProgramme={this.handleChangeProgramme}
          onChoosePlaylist={this.handleChoosePlaylist}
        />
        <OptionsModal
          isOpen={isOpenOptionsModal}
          initialValues={{ options }}
          onCancel={this.handleCloseOptionsModal}
          onConfirm={this.handleConfirmOptionsModal}
        />
      </PlaylistDetailsWrapper>
    );
  }
}

Theatre.propTypes = {
  change: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  isCreatingPlaylist: PropTypes.bool.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onToggleEditMode: PropTypes.func.isRequired,
  playlists: PropTypes.array.isRequired,
  programmes: PropTypes.array.isRequired,
  updatePlaylist: PropTypes.func.isRequired,
  currentPlaylist: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  playlists: getDefaultPlaylists(state),
  programmes: getProgrammes(state),
});

const mapDispatchToProps = {
  ...playlistActions,
  ...contentGroupActions,
  createContentGroupAndAddToPlaylist: fetchMultipleCreateContentGroupSetToPlaylist,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'theatreDetailsView',
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
  })(Theatre),
);
