import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import PlaylistContents from './PlaylistContents';
import styles from './PlaylistContents.module.scss';
import { getProgressItemsForPlaylist } from '../../../../../utils';
import { getCombinedPlaylistContent } from '../../../../../state/ducks/content/selectors';
import { playlistsActions } from '../../../../../state/ducks/playlists';
import { contentGroupActions } from '../../../../../state/ducks/contentGroup';

const { fetchRemoveContentGroupFromPlaylist } = playlistsActions;
const { fetchReorderContentGroupMeta, fetchAbortUploadFile } = contentGroupActions;

class PlaylistContentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.rootRef = React.createRef();
  }

  handleRemoveItem = itemId => {
    const { playlistId } = this.props;
    this.props.deleteItem(playlistId, itemId);
  };

  handleSortEnd = ({ oldIndex, newIndex }) => {
    const { selectedPlaylistContents, reorderItems, playlistId } = this.props;

    const updatedContents = arrayMove(selectedPlaylistContents, oldIndex, newIndex).map((content, order) => ({
      ...content,
      order,
    }));

    const contentOrder = updatedContents.map(c => ({ order: c.order, contentMetaId: c.id }));
    reorderItems(playlistId, contentOrder);

    // sometimes after sort end some items become hidden. Code bellow solve it
    if (this.rootRef && this.rootRef.current) {
      const { children } = this.rootRef.current;
      Array.from(children).forEach(el => {
        el.setAttribute('style', '');
      });
    }
  };

  render() {
    const { selectedPlaylistContents, progressItems, contentRemovalList, abortUploadFile, ...rest } = this.props;
    const isEmpty = progressItems.length === 0 && selectedPlaylistContents.length === 0;

    if (isEmpty) {
      return null;
    }

    return (
      <PlaylistContents
        useDragHandle
        helperClass={styles.draggingItem}
        rootRef={this.rootRef}
        onSortEnd={this.handleSortEnd}
        items={selectedPlaylistContents}
        progressItems={progressItems}
        onDeleteItem={this.handleRemoveItem}
        contentRemovalList={contentRemovalList}
        onAbortUploadFile={abortUploadFile}
        {...rest}
      />
    );
  }
}

PlaylistContentsContainer.defaultProps = {
  withFullProgramme: false,
  deep: 0,
};

PlaylistContentsContainer.propTypes = {
  playlistId: PropTypes.number.isRequired,
  progressItems: PropTypes.array,
  selectedPlaylistContents: PropTypes.array,
  contentRemovalList: PropTypes.array,
  deleteItem: PropTypes.func,
  reorderItems: PropTypes.func,
  abortUploadFile: PropTypes.func,
  deep: PropTypes.number,
};

const mapStateToProps = (state, { withFullProgramme = false, playlistId, playlistType = 'default' }) => {
  const { contentsProgress } = state.content;
  const playlistContents = getCombinedPlaylistContent(state, playlistId);

  const selectedPlaylistContents = withFullProgramme
    ? playlistContents
    : playlistContents.filter(({ type }) => type !== 'programme');

  const progressItems = getProgressItemsForPlaylist(contentsProgress, playlistId);

  return {
    playlistId,
    playlistType,
    progressItems,
    withFullProgramme,
    selectedPlaylistContents,
    contentRemovalList: state.playlists.contentRemovalList,
  };
};

const mapDispatchToProps = {
  deleteItem: fetchRemoveContentGroupFromPlaylist,
  reorderItems: fetchReorderContentGroupMeta,
  abortUploadFile: fetchAbortUploadFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlaylistContentsContainer);
