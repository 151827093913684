import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FroalaEditor from 'react-froala-wysiwyg';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import PropTypes from 'prop-types';
import * as $ from 'jquery';
import CodeMirror from 'codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/markdown/markdown';
import jsBeautify from 'js-beautify';
import _ from 'lodash';
import cn from 'classnames';
import styles from './DescriptionField.module.scss';
import InsertImageModal from '../../../../common/components/UI/CommonMediaLibrary/components/FroalaEditor/InsertImageModal';
import { fontFaceData } from '../../../../../utils/editorContentCssText';
import './DescriptionField.scss';
import getIframeWindow from '../../../../../utils/get-iframe-window';

export default class DescriptionField extends Component {
  state = {
    isOpenInsertImageModal: false,
    isCodeView: false,
  };

  constructor(props) {
    super(props);
    this.froalaRef = React.createRef();
    this.codeMirrorRef = React.createRef();
    this.throttleHandleCodeMirrorChangeContent = _.throttle(this.handleCodeMirrorChangeContent, 500);
  }

  componentDidMount() {
    this.editor = this.froalaRef.current.$element;

    $.FroalaEditor.RegisterCommand('insertImage', {
      title: 'Insert image',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: () => {
        this.handleOpenInsertImageModal();
      },
    });

    // init codeView button
    $.FroalaEditor.DefineIcon('codeView', { NAME: 'code' });
    $.FroalaEditor.RegisterCommand('codeView', {
      title: 'Code View',
    });

    // code mirror init
    this.codeMirror = CodeMirror(
      elt => {
        this.codeMirrorRef.current.parentNode.replaceChild(elt, this.codeMirrorRef.current);
      },
      {
        lineNumbers: true,
        lineWrapping: true,
        mode: 'htmlmixed',
        theme: 'neo',
      },
    );

    this.codeMirror.setValue(this.getFormattedCode());
    this.codeMirror.on('change', this.throttleHandleCodeMirrorChangeContent);

    this.registerCodeView();
  }

  componentDidUpdate() {
    const { description } = this.props;

    $.FroalaEditor.RegisterCommand('backgroundColor', {
      title: 'Background Color',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      callback: (cmd, backgroundColor) => {
        const bgColor = backgroundColor === 'REMOVE' ? 'white' : backgroundColor;

        const bodyOpenTag = '<body';
        const bodyStartIndex = description.indexOf(bodyOpenTag);
        const firstPartHtml = description.substring(0, bodyStartIndex);
        const secondPartHtml = description.substring(bodyStartIndex + bodyOpenTag.length);
        const newHtml = `${firstPartHtml}<body style="background: ${bgColor};" ${secondPartHtml}`;
        this.handleChangeContent(newHtml);
      },
    });

    this.registerCodeView();
  }

  registerCodeView = () => {
    const { description } = this.props;
    const { isCodeView } = this.state;

    $.FroalaEditor.RegisterCommand('codeView', {
      title: 'Code View',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        if (isCodeView) {
          this.editor.froalaEditor('toolbar.enable');
          const cleanedHtml = this.editor.froalaEditor('clean.html', description);
          this.handleChangeContent(cleanedHtml);
        } else {
          this.codeMirror.setValue(this.getFormattedCode());
          this.editor.froalaEditor('toolbar.disable');
        }
        this.setState({ isCodeView: !isCodeView });
      },
      refresh($btn) {
        $btn[0].classList.remove('fr-disabled');
      },
    });
  };

  getFormattedCode = () => {
    const { description } = this.props;
    return description ? jsBeautify.html(description, { indent_size: 2, preserve_newlines: false }) : '';
  };

  config = () => ({
    key: 'UA7D9D7D5dC3E3G3A7B6B5D5E4A2F2C-7KC1KXDF1INBh1KPe2TK==',
    placeholderText: 'Paste in session description here',
    charCounterCount: false,
    quickInsertTags: [],
    heightMin: 280,
    zIndex: 0,
    toolbarSticky: false,
    colorsHEXInput: false,
    fullPage: true,
    htmlUntouched: true,
    toolbarButtons: [
      'formatting',
      'bold',
      'italic',
      'underline',
      'fontSize',
      'align',
      'color',
      'fontFamily',
      'insertImage',
      'alert',
      'codeView',
      'fullscreen',
    ],
    fontSizeUnit: 'px',
    fontSize: [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '40',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
      '49',
      '50',
      '51',
      '52',
      '53',
      '54',
      '55',
      '56',
      '57',
      '58',
      '59',
      '60',
      '61',
      '62',
      '63',
      '64',
      '65',
      '66',
      '67',
      '68',
      '69',
      '70',
      '71',
      '72',
      '73',
      '74',
      '75',
      '76',
      '77',
      '78',
      '79',
      '80',
      '81',
      '82',
      '83',
      '84',
      '85',
      '86',
      '87',
      '88',
      '89',
      '90',
      '91',
      '92',
      '93',
      '94',
      '95',
      '96',
      '97',
      '98',
      '99',
      '100',
      '101',
      '102',
      '103',
      '104',
      '105',
      '106',
      '107',
      '108',
      '109',
      '110',
      '111',
      '112',
      '113',
      '114',
      '115',
      '116',
      '117',
      '118',
      '119',
      '120',
      '121',
      '122',
      '123',
      '124',
      '125',
      '126',
      '127',
      '128',
      '129',
      '130',
      '131',
      '132',
      '133',
      '134',
      '135',
      '136',
      '137',
      '138',
      '139',
      '140',
      '141',
      '142',
      '143',
      '144',
      '145',
      '146',
      '147',
      '148',
      '149',
      '150',
      '151',
      '152',
      '153',
      '154',
      '155',
      '156',
      '157',
      '158',
      '159',
      '160',
      '161',
      '162',
      '163',
      '164',
      '165',
      '166',
      '167',
      '168',
      '169',
      '170',
      '171',
      '172',
      '173',
      '174',
      '175',
      '176',
      '177',
      '178',
      '179',
      '180',
      '181',
      '182',
      '183',
      '184',
      '185',
      '186',
      '187',
      '188',
      '189',
      '190',
      '191',
      '192',
      '193',
      '194',
      '195',
      '196',
      '197',
      '198',
      '199',
      '200',
      '201',
      '202',
      '203',
      '204',
      '205',
      '206',
      '207',
      '208',
      '209',
      '210',
      '211',
      '212',
      '213',
      '214',
      '215',
      '216',
      '217',
      '218',
      '219',
      '220',
      '221',
      '222',
      '223',
      '224',
      '225',
      '226',
      '227',
      '228',
      '229',
      '230',
      '231',
      '232',
      '233',
      '234',
      '235',
      '236',
      '237',
      '238',
      '239',
      '240',
      '241',
      '242',
      '243',
      '244',
      '245',
      '246',
      '247',
      '248',
      '249',
      '250',
      '251',
      '252',
      '253',
      '254',
      '255',
      '256',
      '257',
      '258',
      '259',
      '260',
      '261',
      '262',
      '263',
      '264',
      '265',
      '266',
      '267',
      '268',
      '269',
      '270',
      '271',
      '272',
      '273',
      '274',
      '275',
      '276',
      '277',
      '278',
      '279',
      '280',
      '281',
      '282',
      '283',
      '284',
      '285',
      '286',
      '287',
      '288',
      '289',
      '290',
      '291',
      '292',
      '293',
      '294',
      '295',
      '296',
      '297',
      '298',
      '299',
      '300',
    ],
    fontFamily: {
      'Arial,Helvetica,sans-serif': 'Arial',
      'Georgia,serif': 'Georgia',
      'Impact,Charcoal,sans-serif': 'Impact',
      'Tahoma,Geneva,sans-serif': 'Tahoma',
      "'Times New Roman',Times,serif": 'Times New Roman',
      'Verdana,Geneva,sans-serif': 'Verdana',
      'DIN,sans-serif': 'DIN',
      'Helvetica Neue,sans-serif': 'HelveticaNeue',
      'Open Sans,sans-serif': 'OpenSans',
      'Montserrat,sans-serif': 'Montserrat',
      'Dosis,sans-serif': 'Dosis',
      'Gotham Pro, serif': 'Gotham Pro',
      'Roboto, sans-serif': 'Roboto',
      'AlfaSlabOne, sans-serif': 'AlfaSlabOne',
      'Myriad Pro, sans-serif': 'Myriad Pro',
      'Blood Orange, sans-serif': 'Blood Orange',
      'Museo, sans-serif': 'Museo',
    },
    iframeStyle: fontFaceData,
    htmlRemoveTags: [],
    useClasses: true,
    codeView: false,
    // imageAltButtons: ['imageBack'],
    imageEditButtons: ['imageAlign', 'imageRemove'],
    pasteDeniedAttrs: [],
  });

  handleCodeMirrorChangeContent = doc => {
    const { change } = this.props;
    const value = doc.getValue();
    change('description', value || '');
  };

  handleChangeContent = content => {
    const { change } = this.props;

    change('description', content || '');
  };

  handleOpenInsertImageModal = () => this.setState({ isOpenInsertImageModal: true });

  handleCloseInsertImageModal = () => this.setState({ isOpenInsertImageModal: false });

  handleSubmitInsertImage = images => {
    if (this.editor) {
      images.forEach(({ id, content: url }) => {
        this.editor.froalaEditor('image.insert', url, true, { id });
      });
    }
  };

  render() {
    const { description } = this.props;
    const { isCodeView } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.subTitle}>Description:</div>
        <div className={cn(styles.wrapFroala, 'description__froala')}>
          <FroalaEditor
            ref={this.froalaRef}
            tag="textarea"
            config={this.config()}
            model={description || ''}
            onModelChange={this.handleChangeContent}
          />
          <div
            className={cn(
              styles.codeMirrorWrapper,
              this.editor && this.editor.froalaEditor('fullscreen.isActive') && styles.codeMirrorFullScreen,
            )}
            style={
              !isCodeView
                ? {
                  position: 'absolute !important',
                  clip: 'rect(1px, 1px, 1px, 1px)',
                  padding: '0 !important',
                  border: '0 !important',
                  height: '0px !important',
                  width: '0px !important',
                  overflow: 'hidden',
                }
                : {}
            }
          >
            <textarea defaultValue={description} ref={this.codeMirrorRef} autoComplete="off" />
          </div>
        </div>
        <InsertImageModal
          isOpen={this.state.isOpenInsertImageModal}
          onClose={this.handleCloseInsertImageModal}
          onSubmit={this.handleSubmitInsertImage}
        />
      </div>
    );
  }
}

DescriptionField.propTypes = {
  description: PropTypes.string,
  change: PropTypes.func.isRequired,
};
