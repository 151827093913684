import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './MediaLibrary.module.scss';

const FilterBtn = ({ name, title, isActive, onClick }) => (
  <span id={name} className={cn(styles.filterBtn, isActive && styles.filterBtnActive)} onClick={onClick}>
    {title}
  </span>
);

FilterBtn.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default FilterBtn;
