import React from 'react';
// import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactSelect from 'react-select';
import styles from './Select.module.scss';
import Icons from '../Icons';

function SingleValue({ innerRef, innerProps, children }) {
  return (
    <div
      className={styles.singleValue}
      ref={innerRef}
      {...innerProps}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
}

const Option = ({ label, isSelected, innerRef, innerProps }) => (
  <div
    className={cn(styles.option, isSelected && styles.optionIsSelected)}
    dangerouslySetInnerHTML={{
      __html: label,
    }}
    ref={innerRef}
    {...innerProps}
  />
);

const Menu = ({ children, innerRef, innerProps }) => (
  <div className={styles.menu} ref={innerRef} {...innerProps}>
    {children}
  </div>
);

const DropdownIndicator = () => (
  <div className={styles.dropdownIndicator}>
    <Icons icon="arrowTopGray" widthIcons={10} />
  </div>
);

const IndicatorSeparator = () => null;

function Select(props) {
  return (
    <ReactSelect
      isSearchable={false}
      onMenuClose={false}
      styles={{
        container: base => ({
          ...base,
          width: '100%',
        }),
        control: (base, state) => ({
          ...base,
          width: '100%',
          fontSize: 14,
          fontFamily: 'Nunito Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          border: '1px solid #EFEFF4!important',
          boxShadow: state.isFocused ? 0 : 0,
          '&:hover': {
            border: state.isFocused ? 0 : 0,
          },
        }),
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 3,
      })}
      components={{ Option, Menu, IndicatorSeparator, DropdownIndicator, SingleValue }}
      {...props}
    />
  );
}

export default Select;
