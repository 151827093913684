import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';

import styles from './NestedPlaylistBoxes.module.scss';
import makeToggleable from '../../../hocs/makeToggleable';
import { getDefaultPlaylists, getPlaylists } from '../../../../state/ducks/playlists/selectors';
import ChoosePlaylistModal from '../ChoosePlaylistModal';
import { noop } from '../../../../state/ducks/insertImage/selectors';
import ContentSettingsWrapper from '../ContentSettingsWrapper/ContentSettingsWrapper';
import { updatePlaylist } from '../../../../state/ducks/playlists/actions';
import { fetchMultipleCreateContentGroupSetToPlaylist } from '../../../../state/ducks/contentGroup/actions';
import NestedPlaylistBox from '../NestedPlaylistBox';

const isOpenChoosePlaylistModal = state => {
  const { isOpenFirstChoosePlaylistModal, isOpenSecondChoosePlaylistModal } = state;
  return isOpenFirstChoosePlaylistModal || isOpenSecondChoosePlaylistModal;
};

const getSelectedPlaylistIdOfModal = (state, props) => {
  const { isOpenFirstChoosePlaylistModal, isOpenSecondChoosePlaylistModal } = state;
  const { firstPlaylistId, secondPlaylistId } = props;

  let selectedPlaylistIdOfModal = null;
  if (isOpenFirstChoosePlaylistModal) {
    selectedPlaylistIdOfModal = firstPlaylistId;
  } else if (isOpenSecondChoosePlaylistModal) {
    selectedPlaylistIdOfModal = secondPlaylistId;
  }

  return selectedPlaylistIdOfModal;
};

class NestedPlaylistBoxes extends React.Component {
  state = {
    isOpenFirstChoosePlaylistModal: false,
    isOpenSecondChoosePlaylistModal: false,
  };

  handleOpenOptionsModal = () => this.setState({ isOpenOptionsModal: true });

  handleCloseOptionsModal = () => this.setState({ isOpenOptionsModal: false });

  handleOpenFirstChoosePlaylistModal = () => this.setState({ isOpenFirstChoosePlaylistModal: true });

  handleOpenSecondChoosePlaylistModal = () => this.setState({ isOpenSecondChoosePlaylistModal: true });

  handleCloseChoosePlaylistModal = () =>
    this.setState({ isOpenSecondChoosePlaylistModal: false, isOpenFirstChoosePlaylistModal: false });

  handleChoosePlaylist = playlistId => {
    const { isOpenFirstChoosePlaylistModal, isOpenSecondChoosePlaylistModal } = this.state;
    const { selectedPlaylistId, playlists, onChoosePlaylistCb } = this.props;

    const selectedPlaylist = playlists.find(p => p.id === selectedPlaylistId);
    const options = JSON.parse(selectedPlaylist.options);

    let extraOptionsFields;
    if (isOpenFirstChoosePlaylistModal) {
      extraOptionsFields = { firstPlaylistId: playlistId };
    } else if (isOpenSecondChoosePlaylistModal) {
      extraOptionsFields = { secondPlaylistId: playlistId };
    }

    const finalOptions = { ...options, ...extraOptionsFields };
    this.props.updatePlaylist({ playlistId: selectedPlaylistId, formData: { options: finalOptions } });
    onChoosePlaylistCb(this.state);
  };

  render() {
    const {
      className,
      firstPlaylistId,
      defaultPlaylists,
      secondPlaylistId,
      firstPlaylistProps,
      secondPlaylistProps,
      createContentGroupAndAddToPlaylist,
    } = this.props;

    const firstPlaylist = defaultPlaylists.find(p => p.id === firstPlaylistId);
    const secondPlaylist = defaultPlaylists.find(p => p.id === secondPlaylistId);

    return (
      <ContentSettingsWrapper>
        {({ onOpen }) => (
          <>
            <div className={cn(styles.root, className)}>
              <NestedPlaylistBox
                playlist={firstPlaylist}
                placeholder="First Playlist"
                onAcceptDrop={acceptedFiles => createContentGroupAndAddToPlaylist(acceptedFiles, firstPlaylist.id)}
                onOpenChoosePlaylistModal={this.handleOpenFirstChoosePlaylistModal}
                onOpenContentSettingsModal={onOpen}
                isMain
                {...firstPlaylistProps}
              />
              <NestedPlaylistBox
                playlist={secondPlaylist}
                placeholder="Second Playlist"
                onAcceptDrop={acceptedFiles => createContentGroupAndAddToPlaylist(acceptedFiles, secondPlaylist.id)}
                onOpenContentSettingsModal={onOpen}
                onOpenChoosePlaylistModal={this.handleOpenSecondChoosePlaylistModal}
                {...secondPlaylistProps}
              />
            </div>
            <ChoosePlaylistModal
              playlists={defaultPlaylists}
              isOpen={isOpenChoosePlaylistModal(this.state)}
              onChoosePlaylist={this.handleChoosePlaylist}
              selectedPlaylistId={getSelectedPlaylistIdOfModal(this.state, this.props)}
              onClose={this.handleCloseChoosePlaylistModal}
            />
          </>
        )}
      </ContentSettingsWrapper>
    );
  }
}

NestedPlaylistBoxes.defaultProps = {
  isMain: false,
  title: null,
  onChoosePlaylistCb: noop,
};

NestedPlaylistBoxes.propTypes = {
  selectedPlaylistId: PropTypes.number,
  firstPlaylistId: PropTypes.number,
  secondPlaylistId: PropTypes.number,
  firstPlaylistProps: PropTypes.object,
  secondPlaylistProps: PropTypes.object,
  onChoosePlaylistCb: PropTypes.func,
  updatePlaylist: PropTypes.func.isRequired,
  ...makeToggleable.propTypes,
};

const mapStateToProps = state => ({
  playlists: getPlaylists(state),
  defaultPlaylists: getDefaultPlaylists(state),
});

const mapDispatchToProps = {
  updatePlaylist,
  createContentGroupAndAddToPlaylist: fetchMultipleCreateContentGroupSetToPlaylist,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(makeToggleable(NestedPlaylistBoxes));
