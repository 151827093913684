import React from 'react';
import PropTypes from 'prop-types';

const SvgPlus = ({ setColor, iconSize }) => (
  <svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.4896 20.4896C25.1701 15.8091 25.1701 8.19094 20.4896 3.5104C15.8091 -1.17013 8.19094 -1.17013 3.5104 3.5104C-1.17013 8.19094 -1.17013 15.8091 3.5104 20.4896C8.19094 25.1701 15.8091 25.1701 20.4896 20.4896ZM4.3574 4.3574C8.57283 0.141983 15.4272 0.141983 19.6426 4.3574C23.858 8.57283 23.858 15.4272 19.6426 19.6426C15.4272 23.858 8.57283 23.858 4.3574 19.6426C0.141983 15.4272 0.146879 8.57283 4.3574 4.3574Z"
      fill={setColor}
    />
    <path
      d="M12.0004 16.4945C12.1668 16.4945 12.3137 16.426 12.4263 16.3183C12.534 16.2106 12.6026 16.0588 12.6026 15.8923V12.5973H15.8976C16.064 12.5973 16.2109 12.5288 16.3235 12.4211C16.4312 12.3134 16.4998 12.1616 16.4998 11.9951C16.4998 11.6622 16.2305 11.3929 15.9025 11.3978H12.6075V8.10286C12.6075 7.76994 12.3382 7.50066 12.0102 7.50556C11.6772 7.50556 11.408 7.77484 11.4129 8.10286V11.3978H8.11788C7.78496 11.3978 7.51568 11.6671 7.52057 11.9951C7.52057 12.3281 7.78985 12.5974 8.11788 12.5925H11.4129V15.8874C11.3982 16.2253 11.6675 16.4945 12.0004 16.4945Z"
      fill={setColor}
    />
  </svg>
);

SvgPlus.propTypes = {
  setColor: PropTypes.string.isRequired,
  iconSize: PropTypes.number.isRequired,
};

export default SvgPlus;
