import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import styles from './BottomBarField.module.scss';
import InsertImageModal from '../../../../common/components/UI/CommonMediaLibrary/components/FroalaEditor/InsertImageModal';
import Icon from '../../../../common/components/UI/Icon';

class BottomBarField extends React.Component {
  state = {
    isOpenInsertImageModal: false,
  };

  handleSubmitInsertImage = ([selectedImage]) => {
    this.props.change('options.bottomBarImg', selectedImage);
  };

  handleOpenInsertImageModal = () => this.setState({ isOpenInsertImageModal: true });

  handleCloseInsertImageModal = () => this.setState({ isOpenInsertImageModal: false });

  handleDelete = () => this.props.change('options.bottomBarImg', null);

  render() {
    const { formValues } = this.props;
    const { bottomBarImg } = formValues.options;
    return (
      <>
        <div className={styles.root}>
          {bottomBarImg && (
            <div className={styles.bottomBarImg}>
              <span className={styles.text}>{bottomBarImg.fileName}</span>
              <button type="button" className={styles.delete} onClick={this.handleDelete}>
                <Icon name="xCircle" size={16} />
              </button>
            </div>
          )}
          <Field className={styles.input} name="options.bottomBarText" type="text" component="input" />
          {!bottomBarImg && (
            <Icon
              className={styles.insertImage}
              name="imageIconButton"
              size={24}
              onClick={this.handleOpenInsertImageModal}
            />
          )}
        </div>
        <Field name="options.bottomBarImg" hidden component="input" />
        <InsertImageModal
          multiple={false}
          isOpen={this.state.isOpenInsertImageModal}
          onClose={this.handleCloseInsertImageModal}
          onSubmit={this.handleSubmitInsertImage}
        />
      </>
    );
  }
}

BottomBarField.propTypes = {
  change: PropTypes.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default BottomBarField;
