import React from "react";
import PropTypes from "prop-types";
import styles from "./PlayerButton.module.scss";
import Icons from "../../../common/components/UI/Icons";

const REACT_APP_PLAYER_URL = process.env.REACT_APP_PLAYER_URL;
console.log('REACT_APP_PLAYER_URL:', REACT_APP_PLAYER_URL);
function PlayerButton({ showNotification, accessCode }) {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `${REACT_APP_PLAYER_URL}/${accessCode}`
      );
      showNotification({
        level: "success",
        autoDismiss: 3,
        message: "Player URL successfully copied to clipboard",
      });
    } catch (err) {
      showNotification({
        level: "error",
        autoDismiss: 3,
        message: "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <div className={`${styles.container} ${accessCode ? "" : styles.tooltip}`}>
      {accessCode ? (
        ""
      ) : (
        <span className={styles.tooltiptext}>ACCESS CODE not assigned!</span>
      )}
      <button
        disabled={!accessCode}
        onClick={() => {
          window.open(
            `${REACT_APP_PLAYER_URL}/${accessCode}`,
            "_blank"
          );
        }}
        className={`${styles.buttonPlayer} ${
          accessCode ? "" : styles.buttonDisabled
        }`}
      >
        <Icons icon="playIcon" widthIcons={20} marginRight={5} />
        <span className={styles.text}>Open Player</span>
      </button>

      <button
        disabled={!accessCode}
        className={`${styles.buttonCopy} ${
          accessCode ? "" : styles.buttonDisabled
        }`}
        onClick={copyToClipboard}
      >
        <Icons
          icon="copyToClipboard"
          widthIcons={22}
          marginRight={5}
          marginLeft={5}
        />
      </button>
    </div>
  );
}

PlayerButton.propTypes = {
  accessCode: PropTypes.string.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default PlayerButton;
