import { put, take, call, fork, apply, race, select, all, cancel } from 'redux-saga/effects';
import { eventChannel, delay, takeEvery } from 'redux-saga';
import io from 'socket.io-client';
import _ from 'lodash';
import * as types from './types';
import { appActions } from '../app';
import { storage } from '../../utils';
import { getDesktopStatuses } from './selectors';
import { receiveDesktopStatus, screenIsUpdated, wsInit } from './actions';
import { SET_USER, LOG_OUT } from '../auth/types';
import allTypes from '../allTypes';
import getDependentScreen from '../../utils/get-affected-screen';
// import { getNestedContentMeta, getScreens } from '../screens/selectors';
import { updateScreenLastUpdatedField } from '../screens/actions';
import { getPlaylistsWithMeta } from '../playlists/selectors';

const { showNotification } = appActions;
const { wsSubscribe } = types;
const isProduction = process.env.REACT_APP_BUILD === 'production';

function createEventChannel(socket, token, id) {
  return eventChannel(emit => {
    socket.on('connect', () => {
      socket.emit('authentication', {
        id: token,
        userId: id,
      });

      socket.on('authenticated', () => {
        // use the socket as usual
        wsSubscribe.forEach(type => {
          const userType = `${type}_${id}`;
          socket.on(userType, payload => emit({ type, payload }));
        });
      });
    });

    return () => {
      socket.close();
    };
  });
}

function* emitMessage(socket, action) {
  yield apply(socket, socket.emit, [action.wsType, action.wsPayload]);
}

function* defaultInitializer() {
  yield delay(8000);
  yield put(wsInit());
}

const getBaseUrl = () => {
  return process.env.REACT_APP_SOCKET_URL;
};

const baseUrl = getBaseUrl();

function* initFlow() {
  try {
    while (true) {
      const userData = yield take(SET_USER);
      const {
        payload: { id },
      } = userData;

      const token = storage.getToken();
      let socket = yield call(io, baseUrl);
      let channel = yield call(createEventChannel, socket, token, id);

      // ws will be set as initialized after delay or when first desktop status received
      yield fork(defaultInitializer);

      while (true) {
        const { receiver, emitter, logout } = yield race({
          receiver: take(channel),
          emitter: take(types.WS_CMS_EMIT),
          logout: take(LOG_OUT),
        });
        if (receiver) yield put(receiver);
        if (emitter) yield call(emitMessage, socket, emitter);
        if (logout) {
          socket.disconnect();
          socket = null;
          channel = null;
          break;
        }
      }
    }
  } catch (error) {
    yield put(showNotification({ level: 'error', autoDismiss: 1, message: `Can't send information via websocket` }));
  }
}

function* watchDesktopsStatuses() {
  const secondsOfLife = 10;

  while (true) {
    yield delay(secondsOfLife * 1000);
    const desktopStatuses = yield select(getDesktopStatuses);

    yield all(
      Object.values(desktopStatuses)
        .filter(ds => {
          if (ds.status === 'offline') {
            return false;
          }

          const { timestamp } = ds;
          const now = Math.floor(Date.now() / 1000); // sec

          return timestamp + secondsOfLife <= now;
        })
        .map(ds => put(receiveDesktopStatus({ ...ds, status: 'offline' }))),
    );
  }
}

function* emitUpdateScreens(screenIds) {
  yield delay(2000);

  function* update(screenId) {
    yield put(screenIsUpdated({ screenId }));
  }

  yield all(screenIds.map(update));
}

function* watchForScreenChanges() {
  let task;
  try {
    while (true) {
      const action = yield take([
        allTypes.FETCH_SCREEN_UPDATE_END,
        allTypes.FETCH_ADD_PLAYLIST_TO_SCREEN_END,
        allTypes.FETCH_DELETE_SCREEN_END,
        allTypes.FETCH_REORDER_CONTENT_GROUP_META_END,
        allTypes.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_META_END,
        allTypes.FETCH_ADD_CONTENT_GROUPS_EXIST_PLAYLIST_FROM_MEDIA_END,
        allTypes.FETCH_MULTIPLE_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST_END,
        allTypes.FETCH_EDIT_HTML_CONTENT_END,
        allTypes.FETCH_CREATE_RELATION_FOR_CONTENT_WITH_CONTENT_GROUP_END,
        allTypes.FETCH_REMOVE_CONTENT_GROUP_FROM_PLAYLIST_END,
        allTypes.FETCH_REPLACE_CONTENT_END,
        allTypes.FETCH_DELETE_CONTENT_GROUP,
        allTypes.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_END,
        allTypes.UPDATE_PLAYLIST_END,
        allTypes.FETCH_CHANGE_THEATRE_NAME_END,
        allTypes.FETCH_CREATE_SESSION_END,
        allTypes.SESSION_REMOVE_END,
        allTypes.FETCH_EDIT_SESSION_END,
        allTypes.ADD_FULL_PROGRAMME_TO_PLAYLIST_END,
        allTypes.UPDATE_PROGRAMME_SUCCESS,
        allTypes.UPDATE_EXTERNAL_SESSIONS,
      ]);

      // console.log('action', action);
      if (task) {
        yield cancel(task);
      }

      const screens = yield select(state => state.screens.screens);
      const playlists = yield select(getPlaylistsWithMeta);
      const contentMeta = yield select(state => state.contentMeta.contentMeta);
      const contents = yield select(state => state.content.contents);
      const screenIds = getDependentScreen(action.payload, screens, contentMeta, contents, playlists);
      console.log('screenIds', screenIds);

      const lastUpdated = `${+new Date()}`;
      if (action.type !== allTypes.FETCH_DELETE_SCREEN_END) {
        yield all(screenIds.map(screenId => put(updateScreenLastUpdatedField({ screenId, lastUpdated }))));
      }

      task = yield fork(emitUpdateScreens, screenIds);
    }
  } catch (error) {
    yield put(showNotification({ level: 'error', autoDismiss: 4, message: `Something went wrong` }));
  }
}

// function* checkLastUpdated() {
//   // console.log('RECEIVE_DESKTOP_STATUS payload', payload);

//   const screens = yield select(getScreens);
//   const desktopStatuses = yield select(getDesktopStatuses);
//   const desktopStatusesValues = Object.values(desktopStatuses).filter(({ lastUpdated }) => !!lastUpdated);

//   const screensWhichContainsInDs = screens.filter(screen =>
//     Boolean(desktopStatusesValues.find(({ screenId }) => screenId === screen.id)),
//   );

//   if (!screensWhichContainsInDs.length) return;

//   const screensIdsForUpdate = desktopStatusesValues
//     .filter(ds => {
//       const screen = screens.find(s => s.id === ds.screenId);
//       return ds.lastUpdated !== screen.lastUpdated;
//     })
//     .map(({ screenId, uuid }) => ({
//       screenId,
//       deviceUuid: uuid,
//       lastUpdated: (screens.find(s => s.id === screenId) || {}).lastUpdated,
//     }));

//   // console.log('checkLastUpdated');
//   // console.log('screensIdsForUpdate', screensIdsForUpdate);

//   if (!screensIdsForUpdate.length) return;
//   // console.log('update');
//   yield* emitUpdateScreens(screensIdsForUpdate);
// }

export default [
  // takeEvery(types.RECEIVE_DESKTOP_STATUS, checkLastUpdated),
  // TODO: Jasmin, Make sure this below is not needed(as you commented it out), this might be leftover from when there was desktop app. 
  // fork(initFlow),
  // fork(watchDesktopsStatuses),
  // fork(watchForScreenChanges),
];
