import React from 'react';
import PropTypes from 'prop-types';
import { Field as ReduxField } from 'redux-form';
import cn from 'classnames';

import Input from '../../../../../../../../common/components/UI/Input';

import styles from './Fields.module.scss';

function Field({ className, label, component, labelProps, rightSlot, fieldProps, ...rest }) {
  return (
    <div className={cn(styles.field, className)}>
      <label className={styles.fieldLabel} {...labelProps}>
        {label}
      </label>
      <ReduxField component={component} {...fieldProps} {...rest} />
      {rightSlot}
    </div>
  );
}

Field.defaultProps = {
  component: Input,
};

Field.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  labelProps: PropTypes.object,
  fieldProps: PropTypes.object,
  component: PropTypes.any,
};

function Fields({ className, children }) {
  return <div className={cn(styles.root, className)}>{children}</div>;
}

Fields.Field = Field;

Fields.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType(PropTypes.array, PropTypes.element),
};

export default Fields;
