import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import styles from './ScreenNameForm.module.scss';
import { validation } from '../../../../../utils';
import EntityNameField from '../../../../common/components/UI/EntityNameField/EntityNameField';

function ScreenNameForm({ onChangeName, isEditMode }) {
  return (
    <form className={styles.form}>
      <Field
        name="name"
        type="text"
        disabled={isEditMode}
        component={EntityNameField}
        className={styles.input}
        placeholder="Enter screen name"
        validate={[validation.required, validation.isSpaceChar]}
        onBlur={onChangeName}
        normalize={validation.normalizeExtraSpaces}
      />
    </form>
  );
}

ScreenNameForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  onChangeName: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'screenName',
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: false,
})(ScreenNameForm);
