import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ImageItem.module.scss';
import ContentIcons from '../../../../../ContentIcons';

function ImageItem({ name, type, isSelected, onClick }) {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={cn(styles.checkbox, isSelected && styles.checkboxIsChecked)}>
        <div className={styles.checkboxCheckMark} />
      </div>
      <div className={styles.content}>
        <ContentIcons type={type} iconPosition={styles.icon} />
        <h3 className={styles.name}>{name}</h3>
      </div>
    </div>
  );
}

ImageItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default ImageItem;
