import React from 'react';
import { templateTypes } from '../../../../../../constants';

import Default from './Default';
import Split from './Split';
import Theatre from './Theatre';

const renderDefault = props => <Default {...props} />;
const renderTheatre = props => <Theatre {...props} />;
const renderSplit = props => <Split {...props} />;

const renderers = {
  [templateTypes.DEFAULT]: renderDefault,
  [templateTypes.THEATRE]: renderTheatre,
  [templateTypes.SPLIT]: renderSplit,
};

export default type => {
  const renderPlaylistType = renderers[type];
  if (!renderPlaylistType) {
    return () => null;
  }

  return renderPlaylistType;
};
