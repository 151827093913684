import React from 'react';
import { Field } from 'redux-form';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import styles from './RenderDisplayTimeFields.module.scss';
import DatePicker from '../DatePicker';
import TimeField from '../TimeField';
import Icons from '../Icons';
import { validation, isTimeCorrect } from '../../../../../utils';
import Button from '../Button';
import FieldItem from './FieldItem/FieldItem';

const isEmptyFields = fields => {
  let isEmpty = false;

  fields.getAll().forEach(field => {
    const { start, end, date } = field;
    if (start === '' || end === '' || date === '') {
      isEmpty = true;
    }
  });

  return isEmpty;
};

const isEmpty = value => (value ? value === '' : true);
const isFullFiled = field =>
  !isEmpty(field.start) &&
  isTimeCorrect(field.start) &&
  !isEmpty(field.end) &&
  isTimeCorrect(field.end) &&
  !isEmpty(field.date);

class RenderDisplayTimeFields extends React.Component {
  componentDidMount = () => {
    if (this.props.fields.length === 0 || !isEmptyFields(this.props.fields)) {
      this.addNewField();
    }
  };

  componentDidUpdate = () => {
    if (this.props.fields.length === 0) {
      this.addNewField();
    }
  };

  addNewField = (newField = { start: '', end: '', date: '' }) => {
    const { fields } = this.props;
    fields.push(newField);
  };

  render() {
    const { fields, removeCallback, changeFormValue } = this.props;

    return (
      <div className={`${styles.container}`}>
        {fields.map((period, index) => {
          const field = fields.get(index);
          const isLastField = fields.length - 1 === index;

          return (
            <FieldItem
              key={index}
              index={index}
              field={field}
              period={period}
              fields={fields}
              onAddNewField={() => {
                if (isLastField && isFullFiled(field)) {
                  this.addNewField({ start: '', end: '', date: '' });
                }
              }}
              onAddFieldDate={() => {
                if (isLastField && isEmpty(field.date)) {
                  const date = fields.get(index - 1) ? fields.get(index - 1).date : '';
                  changeFormValue(`${period}.date`, date);
                }
              }}
              isLastField={isLastField}
              onRemove={() => {
                // if (isLastField) {
                //   changeFormValue(`${period}.start`, '');
                //   changeFormValue(`${period}.end`, '');
                //   changeFormValue(`${period}.date`, '');
                // } else {
                fields.remove(index);
                // }

                removeCallback(fields);
              }}
            />
          );
        })}
      </div>
    );
  }
}

RenderDisplayTimeFields.defaultProps = {
  removeCallback: () => {},
};

RenderDisplayTimeFields.propTypes = {
  fields: PropTypes.object,
  removeCallback: PropTypes.func,
  changeFormValue: PropTypes.func,
};

export default RenderDisplayTimeFields;
