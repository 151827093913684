import * as types from './types';
import { createReducer } from '../../utils';

export default createReducer({
  sessions: [],
  sessionModal: {
    isOpen: false,
    type: null, // new | edit
  },
  sessionRemovalList: [],
})({
  [types.SESSION_REMOVE_START]: (state, action) => ({
    ...state,
    sessionRemovalList: [...state.sessionRemovalList, action.payload],
  }),
  [types.SESSION_REMOVE_END]: (state, action) => ({
    ...state,
    sessionRemovalList: state.sessionRemovalList.filter(session => session !== action.payload.sessionId),
  }),
  [types.SET_SESSIONS]: (state, action) => ({ ...state, sessions: action.payload }),
  [types.SET_SESSION_MODAL]: (state, action) => ({
    ...state,
    sessionModal: {
      isOpen: action.payload.modalToggler,
      type: action.payload.modalType,
      id: action.payload.id,
      date: action.payload.date,
    },
  }),
});
