import * as types from './types';

// sync
export const setContentFormId = id => ({ type: types.SET_CONTENT_FORM_ID, payload: id });
export const setTimeFromMidnight = time => ({ type: types.SET_TIME_FROM_MIDNIGHT, payload: time });

export const setShowConfirmModal = (status, data) => ({
  type: types.SET_SHOW_CONFIRM_MODAL,
  payload: { status, data },
});

export const setShowConfirmDuplicateModal = (status, data) => ({
  type: types.SET_SHOW_CONFIRM_DUPLICATE_MODAL,
  payload: { status, data },
});

export const setShowConfirmModalScreenSizes = (status, data) => ({
  type: types.SET_SHOW_CONFIRM_MODAL_SCREEN_SIZES,
  payload: { status, data },
});

export const setShowConfirmModalPlaylist = (status, data) => ({
  type: types.SET_SHOW_CONFIRM_MODAL_PLAYLIST,
  payload: { status, data },
});

export const setShowConfirmModalPlaylistScreens = (status, data) => ({
  type: types.SET_SHOW_CONFIRM_MODAL_PLAYLIST_SCREENS,
  payload: status,
  data,
});

export const setPreviewModal = (status, data) => ({
  type: types.SET_PREVIEW_MODAL,
  payload: { status, data },
});

// async
export const fetchDataStart = () => ({ type: types.FETCH_DATA_START });

export const fetchDataFinished = () => ({ type: types.FETCH_DATA_FINISHED });

export const showNotification = notification => ({
  type: types.SHOW_NOTIFICATION,
  payload: notification,
});

export const fetchData = () => ({
  type: types.FETCH_DATA,
});

export const fetchUpdatedData = () => ({
  type: types.FETCH_UPDATED_DATA,
});

export const fetchGeneratePasscode = () => ({
  type: types.FETCH_GENERATE_PASSCODE,
});
