import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './DropZoneContainer.module.scss';

function DropZoneContainer({ className, isDragActive, children, getRootProps }) {
  return (
    <div className={cn(styles.container, className && className)} {...getRootProps({ disableClick: false })}>
      <div className={cn(styles.dropZoneOverlay, isDragActive && styles.dropZoneOverlayIsVisible)} />
      {children}
    </div>
  );
}

DropZoneContainer.defaulProps = {
  children: null,
  className: null,
  isDragActive: false,
};

DropZoneContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isDragActive: PropTypes.bool,
};

export default DropZoneContainer;
