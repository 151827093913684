import * as types from './types';
import { createReducer } from '../../utils';

export default createReducer({
  contentGroup: [],
  contentTypeFilter: null,
  checkList: [],
  isUploadingContentGroup: false,
  screenSizeId: null,
})({
  [types.SET_CONTENT_GROUP]: (state, action) => ({
    ...state,
    contentGroup: action.payload,
  }),
  [types.SET_CONTENT_TYPE_FILTER]: (state, action) => ({ ...state, contentTypeFilter: action.payload }),
  [types.SET_CONTENT_GROUP_TO_CHECK_LIST]: (state, action) => ({ ...state, checkList: action.payload }),
  [types.FETCH_CONTENT_GROUP_START]: state => ({ ...state, isUploadingContentGroup: true }),
  [types.FETCH_CONTENT_GROUP_FINISHED]: state => ({ ...state, isUploadingContentGroup: false }),
  [types.SET_SCREEN_SIZE_ID]: (state, action) => ({ ...state, screenSizeId: action.payload }),
});
