import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import styles from './OnDropZone.module.scss';
import { contentActions } from '../../../../../state/ducks/content';
import { contentGroupActions } from '../../../../../state/ducks/contentGroup';
import { showNotification } from '../../../../../state/ducks/app/actions';

const { setUploadedContent, setContentsFilter, setMediaLibraryModal } = contentActions;

const {
  fetchMultipleCreateContentGroup,
  fetchMultipleCreateContentGroupSetToPlaylist,
  fetchCreateContentGroupSetToNotExistPlaylist,
  setContentTypeFilter,
} = contentGroupActions;

class OnDropZone extends React.Component {
  checkFileType = files => {
    if (!files.length) {
      return;
    }

    let flagSingleFileType = 0;
    let flagType = '';
    let contentTypeTab = 'multiply';

    files.map(item => {
      const itemTypeSplit = item.type.split('/')[0];
      const flagTypeSplit = flagType.split('/')[0];
      if (itemTypeSplit !== flagTypeSplit) {
        flagSingleFileType += 1;
        flagType = item.type;
      }
      return flagTypeSplit;
    });

    if (flagSingleFileType > 1) {
      contentTypeTab = 'multiply';
    } else {
      const updatecontentTypeTab = flagType.split('/')[0];
      contentTypeTab = updatecontentTypeTab;
      if (contentTypeTab === 'text') contentTypeTab = 'html';
    }
    this.props.setContentTypeFilter(contentTypeTab);
  };

  onDropFile = (acceptedFiles, rejectedFiles) => {
    if (this.props.isMediaLibrary) this.checkFileType(acceptedFiles);
    const {
      playlistId,
      requestType,
      multipleUploadContentToPlaylist,
      multipleUploadContent,
      showNotify,
      addContentGroupToNotExistPlaylist,
    } = this.props;

    if (rejectedFiles.length) {
      rejectedFiles.map(file => showNotify({ level: 'error', message: `The format of ${file.name} is not supported` }));
    }

    if (playlistId) {
      if (requestType === 'playlist') {
        multipleUploadContentToPlaylist(acceptedFiles, playlistId);
      }
      if (requestType === 'content') {
        multipleUploadContent(acceptedFiles);
      }
    } else {
      if (requestType === 'content') {
        multipleUploadContent(acceptedFiles);
        return null;
      }
      addContentGroupToNotExistPlaylist(acceptedFiles, this.props.newScreenName, this.props.orientationSelect);
    }
  };

  defineType = () => {
    const { acceptType } = this.props;

    switch (acceptType) {
      case 'multiply':
        return 'image/jpeg, image/png, image/webp, video/mp4';
      case 'image':
        return 'image/jpeg, image/png, image/webp';
      case 'video':
        return 'video/mp4';
      default:
        return ['image/jpeg', 'image/png', 'image/webp', 'video/mp4'];
    }
  };

  render() {
    return (
      <Dropzone mediaStyles={styles.dropZoneStyle} onDrop={this.onDropFile} disableClick accept={this.defineType()}>
        {props => this.props.children && this.props.children({ ...this.props, ...props })}
      </Dropzone>
    );
  }
}

OnDropZone.propTypes = {
  setUploadedContent: PropTypes.func,
  currentPlaylistId: PropTypes.number,
  contentType: PropTypes.string,
  contentName: PropTypes.string,
  isScreen: PropTypes.bool,
  children: PropTypes.any,
  newScreenName: PropTypes.string,
  acceptType: PropTypes.string,
  orientationSelect: PropTypes.number,
  setContentTypeFilter: PropTypes.any,
  isMediaLibrary: PropTypes.any,
  playlistId: PropTypes.any,
  requestType: PropTypes.any,
  multipleUploadContentToPlaylist: PropTypes.any,
  multipleUploadContent: PropTypes.any,
  showNotify: PropTypes.any,
  addContentGroupToNotExistPlaylist: PropTypes.any,
};

OnDropZone.defineType = {
  isScreen: false,
};

const mapStateToProps = state => ({
  currentPlaylistId: state.playlists.currentPlaylistId,
  playlists: state.playlists.playlists,
  contentsProgress: state.content.contentsProgress,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUploadedContent,
      multipleUploadContent: fetchMultipleCreateContentGroup,
      multipleUploadContentToPlaylist: fetchMultipleCreateContentGroupSetToPlaylist,
      addContentGroupToNotExistPlaylist: fetchCreateContentGroupSetToNotExistPlaylist,
      showNotify: showNotification,
      setContentsFilter,
      setMediaLibraryModal,
      setContentTypeFilter,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnDropZone);
