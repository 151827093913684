import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './AddHeaderColumnImageInput.module.scss';
import InsertImageModal from '../../../../../../common/components/UI/CommonMediaLibrary/components/FroalaEditor/InsertImageModal';
import Icon from '../../../../../../common/components/UI/Icon';

class AddHeaderColumnImageInput extends React.Component {
  state = { isOpen: false };

  handleChange = ([selectedImage]) => {
    const {
      input: { onChange },
      onChangeCallback,
    } = this.props;

    onChange(selectedImage);
    onChangeCallback(JSON.stringify(selectedImage));
  };

  handleOpen = () => {
    const { canEdit } = this.props;
    if (!canEdit) return;

    this.setState({ isOpen: true });
  };

  handleClose = () => this.setState({ isOpen: false });

  handleDelete = e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      input: { onChange },
      onChangeCallback,
    } = this.props;

    onChange(null);
    onChangeCallback(JSON.stringify(null));
  };

  render() {
    const { input, canEdit } = this.props;
    return (
      <>
        <div className={styles.root} onClick={this.handleOpen}>
          {!input.value && canEdit && <button className={styles.btn}>Add Image</button>}
          {input.value && (
            <React.Fragment>
              <div className={styles.selectedImage}>
                <span className={styles.text}>{input.value.fileName}</span>
                {canEdit && (
                  <div className={styles.controls}>
                    <button className={cn(styles.btn, styles.btnChange)} onClick={this.handleDelete}>
                      <Icon name="deleteGray" size={24} />
                    </button>
                    <button onClick={this.handleOpen} className={cn(styles.btn, styles.btnChange)}>
                      <Icon name="editPencil" size={24} />
                    </button>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <InsertImageModal
          multiple={false}
          isOpen={this.state.isOpen}
          onClose={this.handleClose}
          onSubmit={this.handleChange}
        />
      </>
    );
  }
}

AddHeaderColumnImageInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.func.isRequired,
  }).isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default AddHeaderColumnImageInput;
