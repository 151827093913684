import { put, call, takeLatest, select } from "redux-saga/effects";
import isEqual from "lodash/isEqual";
import * as types from "./types";
import * as actions from "./actions";
import { setPlaylists } from "../playlists/actions";
import { getPlaylists } from "../playlists/selectors";
import { getFormatedPlaylists } from "../playlists/utils";
import { setScreens } from "../screens/actions";
import { getScreens } from "../screens/selectors";
import { setContents } from "../content/actions";
import { getContents } from "../content/selectors";
import { setUser } from "../auth/actions";
import { setScreenSizes } from "../screenSizes/actions";
import { screenSizes } from "../screenSizes/selectors";
import { setContentMeta } from "../contentMeta/actions";
import { contentMetaSelector } from "../contentMeta/selectors";
import { setProgrammes } from "../programmes/actions";
import { getProgrammes } from "../programmes/selectors";
import { setSessions } from "../sessions/actions";
import { selectorSessions } from "../sessions/selectors";
import { setSpeakers } from "../speakers/actions";
import { getSpeakers } from "../speakers/selectors";
import {
  setContentGroup,
  fetchArrDeleteContentGroup,
} from "../contentGroup/actions";
import { contentGroup$ } from "../contentGroup/selectors";
import {
  storage,
  fetch,
  apiRoutes,
  sentryReport,
  getErrorMessage,
} from "../../utils";
import { setDataFeedList, setDataFeedHistoryList } from "../dataFeeds/actions";
import handleError from "../../utils/handle-error";
import { safeGet } from "../../../utils";

const {
  showNotification,
  fetchDataStart,
  fetchDataFinished,
  setTimeFromMidnight,
} = actions;

function* setAppData(data) {
  const {
    playlists,
    screens,
    contents,
    id: userId,
    username,
    contentMeta,
    ...rest
  } = data;
  const {
    screenSize,
    programmes,
    sessions,
    speakers,
    dataFeed,
    dataFeedHistories,
  } = rest;
  console.log(data);
  yield put(
    setUser({
      id: userId,
      name: username,
      "pass-code": safeGet(() => data["pass-code"]),
      accessCode: safeGet(() => data.accessCode),
    })
  );
  yield put(setDataFeedHistoryList(dataFeedHistories));
  yield put(setDataFeedList(dataFeed));
  yield put(setContentMeta(contentMeta));
  yield put(setScreenSizes(screenSize));
  yield put(setProgrammes(programmes));
  yield put(setPlaylists(playlists));
  yield put(setSessions(sessions));
  yield put(setSpeakers(speakers));
  yield put(setContents(contents));
}

function* fetchDataFlow() {
  yield put(fetchDataStart());
  try {
    const { url, method } = apiRoutes.getAppData;
    const user = storage.getUser();
    const { data, timeFromMidnight } = yield call(
      fetch,
      url(user.userId),
      method
    );
    const { screens, contentGroup, contents, id: userId } = data;

    yield put(setTimeFromMidnight(timeFromMidnight));
    yield* setAppData(data);

    // @TODO made for bug with attach more than one playlist to screen (alien playlist)
    const filteredPlaylistsInScreen = screens
      .filter((s) => s.playlists)
      .map((screen) => ({
        ...screen,
        playlists: (screen.playlists || []).filter(
          (playlist) => playlist.ownerId === userId
        ),
      }));

    yield put(setScreens(filteredPlaylistsInScreen));

    // for wix bug with wrong when upload from media library contentGroup
    const contentGroupsWithContent = contentGroup.filter((cg) =>
      contents.find((ct) => ct.contentGroupId === cg.id)
    );
    yield put(setContentGroup(contentGroupsWithContent));

    // find and remove content group without content
    const contentGroupIdsWithoutContent = contentGroup
      .filter((cg) => !contents.find((ct) => ct.contentGroupId === cg.id))
      .map((cg) => cg.id);
    yield put(fetchArrDeleteContentGroup(contentGroupIdsWithoutContent));

    yield put(fetchDataFinished());
  } catch (error) {
    handleError(error);
    yield put(fetchDataFinished());
  }
}

function* fetchUpdatedDataFlow() {
  try {
    yield put(fetchDataStart());
    const { url, method } = apiRoutes.getAppData;
    const user = storage.getUser();
    const res = yield call(fetch, url(user.userId), method);
    const {
      playlists,
      screens,
      contents,
      id,
      contentMeta,
      screenSize,
      contentGroup,
      programmes,
      sessions,
      speakers,
    } = res.data;
    // @TODO made for bug with attach more than one playlist to screen (alien playlist)
    const filteredPlaylistsInScreen = screens
      .filter((s) => s.playlists)
      .map((screen) => ({
        ...screen,
        playlists: (screen.playlists || []).filter(
          (playlist) => playlist.ownerId === id
        ),
      }));

    const prevPlaylists = yield select(getPlaylists);
    if (!isEqual(prevPlaylists, getFormatedPlaylists(playlists))) {
      yield put(setPlaylists(playlists));
    }
    const prevScreens = yield select(getScreens);
    if (!isEqual(prevScreens, filteredPlaylistsInScreen)) {
      yield put(setScreens(filteredPlaylistsInScreen)); // (screens)
    }
    const prevContents = yield select(getContents);
    if (!isEqual(prevContents, contents)) {
      yield put(setContents(contents));
    }
    const prevScreenSizes = yield select(screenSizes);
    if (!isEqual(prevScreenSizes, screenSize)) {
      yield put(setScreenSizes(screenSize));
    }
    yield put(setTimeFromMidnight(res.timeFromMidnight));
    // // for wix bug with wrong when upload from media library contentGroup
    const filterContentGroupWithoutContent = contentGroup.filter((cg) =>
      contents.find((ct) => ct.contentGroupId === cg.id)
    );
    const prevContentGroup = yield select(contentGroup$);
    if (!isEqual(prevContentGroup, filterContentGroupWithoutContent)) {
      yield put(setContentGroup(filterContentGroupWithoutContent));
    }
    const prevContentMeta = yield select(contentMetaSelector);
    if (!isEqual(prevContentMeta, contentMeta)) {
      yield put(setContentMeta(contentMeta));
    }
    const prevProgrammes = yield select(getProgrammes);
    if (!isEqual(prevProgrammes, programmes)) {
      console.log(programmes);
      yield put(setProgrammes(programmes));
    }
    const prevSessions = yield select(selectorSessions);
    if (!isEqual(prevSessions, sessions)) {
      yield put(setSessions(sessions));
    }
    const prevSpeakers = yield select(getSpeakers);
    if (!isEqual(prevSpeakers, speakers)) {
      yield put(setSpeakers(speakers));
    }

    // // find and remove content group without content
    // const contentGroupsWithoutContent = contentGroup
    //   .filter(cg => !contents.find(ct => ct.contentGroupId === cg.id))
    //   .map(cg => cg.id);
    // yield put(fetchArrDeleteContentGroup(contentGroupsWithoutContent));
    yield put(fetchDataFinished());
  } catch (error) {
    console.log("error", error);
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 1, message }));
    yield put(fetchDataFinished());
  }
}

function* fetchGeneratePasscodeFlow() {
  try {
    const { url, method } = apiRoutes.generatePasscode;
    const storageUser = storage.getUser();
    const res = yield call(fetch, url(storageUser.userId), method);
    const user = select((state) => state.auth.user);
    yield put(setUser({ ...user, ...res.user }));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 1, message }));
  }
}

export default [
  takeLatest(types.FETCH_DATA, fetchDataFlow),
  takeLatest(types.FETCH_UPDATED_DATA, fetchUpdatedDataFlow),
  takeLatest(types.FETCH_GENERATE_PASSCODE, fetchGeneratePasscodeFlow),
];
