import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import styles from './ProgrammeCreateView.module.scss';
import TheatreCreateNameForm from '../TheatreCreateNameForm';
import ButtonDigital from '../../../../common/components/UI/ButtonDigital';
import generateEntityName from '../../../../../utils/generateName';

class ProgrammeCreateView extends Component {
  constructor(props) {
    super(props);
    this.refTitleName = React.createRef();
  }

  state = {
    isChangeName: false,
  };

  handleChangeName = event => {
    const { value } = event.target;
    const { handleCreateNewTheatre } = this.props;

    const removeExtraSpaces = value.replace(/\s+/g, ' ').trim();
    if (removeExtraSpaces.length !== 0) {
      handleCreateNewTheatre(removeExtraSpaces);
      this.refTitleName.current.getRenderedComponent().inputRef.current.blur();
    }
  };

  debounceHandleChangeName = event => {
    this.setState({ isChangeName: true });

    clearInterval(this.startTime);
    this.startTime = setTimeout(() => {
      this.handleChangeName(event);
    }, 1000);
  };

  render() {
    const { programmes, createTheatreOpenSession, theatreName } = this.props;
    const { isChangeName } = this.state;

    return (
      <div className={styles.container}>
        <Link to="/programmes" className={styles.closeButton} />
        <TheatreCreateNameForm
          isTouched
          refTitleName={this.refTitleName}
          onChangeName={this.debounceHandleChangeName}
          initialValues={{ name: generateEntityName(programmes, 'Theatre') }}
        />

        <ButtonDigital
          className={styles.addSessionButton}
          disable={isChangeName}
          isConfirmStyle={true}
          type="button"
          onClick={() => createTheatreOpenSession(theatreName)}
        >
          Add Session
        </ButtonDigital>
      </div>
    );
  }
}

ProgrammeCreateView.propTypes = {
  programmes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      ownerId: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  createTheatreOpenSession: PropTypes.func.isRequired,
  handleCreateNewTheatre: PropTypes.func.isRequired,
  theatreName: PropTypes.string,
};

const mapStateToProps = state => {
  const { name } = getFormValues('createTheatreName')(state) || {};
  return {
    theatreName: name,
    programmes: state.programmes.programmes,
    sessionModal: state.sessions.sessionModal,
  };
};

export default connect(mapStateToProps)(ProgrammeCreateView);
