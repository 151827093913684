import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Input.module.scss';

function Input({ disableAutoComplete, customLabelClass, id, label, input, meta, ...rest }) {
  const { name } = input;
  const { touched, error, warning } = meta;

  return (
    <div className={styles.inputContainer}>
      {label && (
        <label className={cn(styles.label, customLabelClass && customLabelClass)} htmlFor={`${name}${id}`}>
          {label}
        </label>
      )}
      <div className={styles.controlContainer}>
        <input
          className="my-form-control"
          id={`${name}${id}`}
          name={name}
          autoComplete={disableAutoComplete ? 'off' : 'on'}
          {...rest}
          {...input}
        />
        {touched && error && <div className={styles.error}>{error}</div>}
        {touched && warning && <div className={styles.warning}>{warning}</div>}
      </div>
    </div>
  );
}

Input.defaultProps = {
  label: null,
};

Input.propTypes = {
  id: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    warning: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.isRequired,
    value: PropTypes.isRequired,
    onBlur: PropTypes.isRequired,
    onChange: PropTypes.isRequired,
  }).isRequired,
  label: PropTypes.string,
};

export default Input;
