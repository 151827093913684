import { wsTypes } from './websockets';
import { playlistsTypes } from './playlists';
import { screensTypes } from './screens';
import { contentTypes } from './content';
import { screenSizesTypes } from './screenSizes';
import { contentGroupTypes } from './contentGroup';
import { contentMetaTypes } from './contentMeta';
import { programmesTypes } from './programmes';
import { sessionsTypes } from './sessions';
import { speakersTypes } from './speakers';

export default {
  ...wsTypes,
  ...playlistsTypes,
  ...screensTypes,
  ...contentTypes,
  ...screenSizesTypes,
  ...contentGroupTypes,
  ...contentMetaTypes,
  ...programmesTypes,
  ...sessionsTypes,
  ...speakersTypes,
};
