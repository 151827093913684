import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop, getAcceptFilesTypes, isImage } from '../../../../../../../../utils';

import Button from '../../../../Button';
import Modal from '../../../../../Modal';
import ImageItem from './ImageItem/ImageItem';
import ProgressItems from '../../../../../ProgressItems';

import { getAllImages, getImagesWhichUploading } from '../../../../../../../../state/ducks/content/selectors';
import { getSelectedImages, getIsLoading } from '../../../../../../../../state/ducks/insertImage/selectors';

import {
  selectImage,
  uploadNewImages,
  resetSelectedImages,
} from '../../../../../../../../state/ducks/insertImage/actions';
import { showNotification } from '../../../../../../../../state/ducks/app/actions';

import styles from './InsertImageModal.module.scss';

class InsertImageModal extends React.Component {
  handleUploadNewImage = event => {
    const files = Array.from(event.target.files);
    const { multiple } = this.props;

    files.forEach(file => {
      if (!isImage(file)) {
        this.props.showNotify({ level: 'error', message: `The file ${file.name} is not an image` });
      }
    });

    const imageFiles = files.filter(isImage);
    this.props.uploadNewImages({ files: imageFiles, multiple });
  };

  handleClose = () => {
    this.props.resetSelectedImages();
    this.props.onClose();
  };

  handleSubmit = () => {
    this.props.onSubmit(this.props.selectedImages);
    this.handleClose();
  };

  renderImages = () => {
    const { images, imagesWhichUploading, selectedImages, multiple } = this.props;

    const isEmpty = images.length === 0 && imagesWhichUploading.length === 0;

    return (
      <div className={styles.list}>
        {isEmpty && <p style={{ padding: '24px', margin: '0' }}>Empty</p>}
        <ProgressItems items={imagesWhichUploading} />
        {images.map(image => {
          const { id, fileName, type } = image;
          const isSelected = selectedImages.findIndex(item => item && item.id === id) !== -1;
          return (
            <ImageItem
              key={id}
              onClick={() => this.props.selectImage({ image, multiple })}
              {...{ name: fileName, type, isSelected }}
            />
          );
        })}
      </div>
    );
  };

  render() {
    const { isOpen, isLoading, multiple, selectedImages } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.handleClose}
        containerClassName={styles.modalContainer}
        modalClassName={styles.modal}
      >
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <h2 className={styles.title}>Insert Image</h2>
            <h3 className={styles.subtitle}>select existing image or upload a new one</h3>
          </div>
          <label className={styles.uploadNewImage}>
            <span>Upload New Image</span>
            <input
              multiple={multiple}
              type="file"
              accept={getAcceptFilesTypes('image')}
              style={{ display: 'none' }}
              onChange={this.handleUploadNewImage}
            />
          </label>
        </div>
        {this.renderImages()}
        <div className={styles.footer}>
          <Button onClick={this.handleClose} theme="invert">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} disabled={isLoading || !selectedImages.length}>
            Confirm
          </Button>
        </div>
      </Modal>
    );
  }
}

InsertImageModal.defaultProps = {
  onSubmit: noop,
  multiple: true,
};

InsertImageModal.propTypes = {
  ...Modal.propTypes,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  multiple: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  uploadNewImages: PropTypes.func.isRequired,
  resetSelectedImages: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const images = getAllImages(state);
  const selectedImages = getSelectedImages(state);
  const isLoading = getIsLoading(state);

  return {
    images,
    isLoading,
    selectedImages,
    imagesWhichUploading: getImagesWhichUploading(state),
  };
};

const mapDispatchToProps = {
  selectImage,
  uploadNewImages,
  resetSelectedImages,
  showNotify: showNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InsertImageModal);
