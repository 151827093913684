import { registerSelectors } from 'reselect-tools';
import reducer from './reducers';
import * as actions from './actions';
import sagas from './sagas';
import * as selectors from './selectors';
import * as types from './types';

export const contentGroupActions = actions;
export const contentGroupSagas = sagas;
export const contentGroupReducer = reducer;
export const contentGroupSelectors = selectors;
export const contentGroupTypes = types;
registerSelectors(selectors);
