import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './SessionDayList.module.scss';
import SettingsButtons from '../../../../common/components/UI/SettingsButtons';

const SessionDayListItem = ({ title, time, speakers, onRemove, onEdit, idRemoving, canEdit }) => (
  <div className={cn(styles.item, canEdit && styles.itemIsEditable)}>
    <div className={styles.row}>
      <div className={styles.time}>{time}</div>
      <div className={styles.sectionGroup}>
        <div className={styles.name} dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className={styles.buttons}>
        <SettingsButtons handleOnEdit={onEdit} handleOnRemove={idRemoving ? () => ({}) : onRemove} />
      </div>
    </div>
    <div
      className={styles.speakers}
      dangerouslySetInnerHTML={{
        __html:
          speakers &&
          speakers
            .sort((a, b) => a.order - b.order)
            .map(({ name, job, company }) => `${name}${job && `, ${job}`}${company && `, ${company}`}`)
            .join(' | '),
      }}
    />
  </div>
);

const SessionDayList = ({ canEdit, listSessionDays, handleRemoveSession, handleEditSession, sessionRemovalList }) =>
  listSessionDays.map((dayItem, index) => (
    <SessionDayListItem
      key={index}
      title={dayItem.title}
      time={`${dayItem.start} - ${dayItem.end}`}
      speakers={dayItem.speakers}
      handleRemoveSession={handleRemoveSession}
      onRemove={() => handleRemoveSession(dayItem.id)}
      onEdit={() => handleEditSession(dayItem.id)}
      idRemoving={!!sessionRemovalList.find(sessionId => sessionId === dayItem.id)}
      canEdit={canEdit}
    />
  ));

SessionDayListItem.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  speakers: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  idRemoving: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

SessionDayList.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  listSessionDays: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      programmeId: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleRemoveSession: PropTypes.func.isRequired,
  handleEditSession: PropTypes.func.isRequired,
  sessionRemovalList: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SessionDayList;
