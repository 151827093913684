// sync
export const SHOW_NOTIFICATION = 'app/SHOW_NOTIFICATION';
export const SET_SHOW_CONFIRM_MODAL = 'app/SET_SHOW_CONFIRM_MODAL';
export const SET_SHOW_CONFIRM_DUPLICATE_MODAL = 'app/SET_SHOW_CONFIRM_DUPLICATE_MODAL';
export const SET_SHOW_CONFIRM_MODAL_PLAYLIST_SCREENS = 'app/SET_SHOW_CONFIRM_MODAL_PLAYLIST_SCREENS';
export const SET_CONTENT_FORM_ID = 'app/SET_CONTENT_FORM_ID';
export const SET_SHOW_CONFIRM_MODAL_SCREEN_SIZES = 'app/SET_SHOW_CONFIRM_MODAL_SCREEN_SIZES';
export const SET_SHOW_CONFIRM_MODAL_PLAYLIST = 'app/SET_SHOW_CONFIRM_MODAL_PLAYLIST';
export const SET_PREVIEW_MODAL = 'app/SET_PREVIEW_MODAL';
export const SET_TIME_FROM_MIDNIGHT = 'app/SET_TIME_FROM_MIDNIGHT';

// async
export const FETCH_DATA = 'app/FETCH_DATA';
export const FETCH_DATA_START = 'app/FETCH_DATA_START';
export const FETCH_DATA_FINISHED = 'app/FETCH_DATA_FINISHED';
export const FETCH_GENERATE_PASSCODE = 'app/FETCH_GENERATE_PASSCODE';
export const FETCH_UPDATED_DATA = 'app/FETCH_UPDATED_DATA';
