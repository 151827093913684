import reducer from './reducers';
import * as actions from './actions';
import sagas from './sagas'; // sagas
import * as types from './types';
import * as selectors from './selectors';

export const contentGroupSelectors = selectors;
export const insertImageActions = actions;
export const insertImageReducer = reducer;
export const insertImageSagas = sagas;
export const insertImageTypes = types;
