import React from 'react';
import { Field } from 'redux-form';
import { validation } from '../../../../../utils';
import EntityNameField from '../../../../common/components/UI/EntityNameField';
import styles from './PlaylistNameField.module.scss';

function PlaylistNameField(props) {
  return (
    <div className={styles.root}>
      <Field
        name="name"
        type="text"
        component={EntityNameField}
        placeholder="Enter playlist name"
        validate={[validation.required, validation.isSpaceChar]}
        normalize={validation.normalizeExtraSpaces}
        {...props}
      />
    </div>
  );
}

export default PlaylistNameField;
