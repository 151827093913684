export const normalizeCss = `
  body {
    margin: 0!important;
    padding: 0!important;
    overflow: hidden;
    background-color: white;
    font-family: 'Open Sans', sans-serif,
  }
  p,img{
    margin-bottom: 0!important;
    margin-top: 0!important;
  }
  img.fr-fil {
    margin-right: auto;
  }
  img.fr-fir {
    margin-left: auto;
  }
  .clearfix::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }
  .hide-by-clipping {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  img.fr-rounded,
  .fr-img-caption.fr-rounded img {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  img.fr-bordered,
  .fr-img-caption.fr-bordered img {
    border: solid 5px #CCC;
  }
  img.fr-bordered {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .fr-img-caption.fr-bordered img {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  img.fr-shadow,
  .fr-img-caption.fr-shadow img {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px 1px rgba(0, 0, 0, 0.16);
  }
  body {
    word-wrap: break-word;
  }
  body span[style~="color:"] a {
    color: inherit;
  }
  body strong {
    font-weight: 700;
  }
  body table {
    border: none;
    border-collapse: collapse;
    empty-cells: show;
    max-width: 100%;
  }
  body table td {
    min-width: 5px;
  }
  body table.fr-dashed-borders td,
  body table.fr-dashed-borders th {
    border-style: dashed;
  }
  body table.fr-alternate-rows tbody tr:nth-child(2n) {
    background: #f5f5f5;
  }
  body table td,
  body table th {
    border: 1px solid #dddddd;
  }
  body table td:empty,
  body table th:empty {
    height: 20px;
  }
  body table td.fr-highlighted,
  body table th.fr-highlighted {
    border: 1px double red;
  }
  body table td.fr-thick,
  body table th.fr-thick {
    border-width: 2px;
  }
  body table th {
    background: #e6e6e6;
  }
  body hr {
    clear: both;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    page-break-after: always;
  }
  body .fr-file {
    position: relative;
  }
  body .fr-file::after {
    position: relative;
    content: "\\1F4CE";
    font-weight: normal;
  }
  body pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: visible;
  }
  body[dir="rtl"] blockquote {
    border-left: none;
    border-right: solid 2px #5e35b1;
    margin-right: 0;
    padding-right: 5px;
    padding-left: 0px;
  }
  body[dir="rtl"] blockquote blockquote {
    border-color: #00bcd4;
  }
  body[dir="rtl"] blockquote blockquote blockquote {
    border-color: #43a047;
  }
  body blockquote {
    border-left: solid 2px #5e35b1;
    margin-left: 0;
    padding-left: 5px;
    color: #5e35b1;
  }
  body blockquote blockquote {
    border-color: #00bcd4;
    color: #00bcd4;
  }
  body blockquote blockquote blockquote {
    border-color: #43a047;
    color: #43a047;
  }
  body span.fr-emoticon {
    font-weight: normal;
    font-family: "Apple Color Emoji", "NotoColorEmoji", "Android Emoji", "EmojiSymbols";
    display: inline;
    line-height: 0;
  }
  body span.fr-emoticon.fr-emoticon-img {
    background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -0.1em 0.1em 0.1em;
    line-height: 1;
    vertical-align: middle;
  }
  body .fr-text-gray {
    color: #AAA !important;
  }
  body .fr-text-bordered {
    border-top: solid 1px #222;
    border-bottom: solid 1px #222;
    padding: 10px 0;
  }
  body .fr-text-spaced {
    letter-spacing: 1px;
  }
  body .fr-text-uppercase {
    text-transform: uppercase;
  }
  body .fr-class-highlighted {
    background-color: #ffff00;
  }
  body .fr-class-code {
    border-color: #cccccc;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: #f5f5f5;
    padding: 10px;
    font-family: "Courier New", Courier, monospace;
  }
  body .fr-class-transparency {
    opacity: 0.5;
  }
  body img {
    position: relative;
    max-width: 100%;
  }
  body img.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  body img.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
  }
  body img.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
  }
  body img.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  body img.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  body img.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
  body span.fr-img-caption {
    position: relative;
    max-width: 100%;
  }
  body span.fr-img-caption.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  body span.fr-img-caption.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
  }
  body span.fr-img-caption.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
  }
  body span.fr-img-caption.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  body span.fr-img-caption.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  body span.fr-img-caption.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
  body .fr-video {
    text-align: center;
    position: relative;
  }
  body .fr-video.fr-rv {
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  body .fr-video.fr-rv > iframe,
  body .fr-video.fr-rv object,
  body .fr-video.fr-rv embed {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  body .fr-video > * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    max-width: 100%;
    border: none;
  }
  body .fr-video.fr-dvb {
    display: block;
    clear: both;
  }
  body .fr-video.fr-dvb.fr-fvl {
    text-align: left;
  }
  body .fr-video.fr-dvb.fr-fvr {
    text-align: right;
  }
  body .fr-video.fr-dvi {
    display: inline-block;
  }
  body .fr-video.fr-dvi.fr-fvl {
    float: left;
  }
  body .fr-video.fr-dvi.fr-fvr {
    float: right;
  }
  body a.fr-strong {
    font-weight: 700;
  }
  body a.fr-green {
    color: green;
  }
  body .fr-img-caption {
    text-align: center;
  }
  body .fr-img-caption .fr-img-wrap {
    padding: 0px;
    display: inline-block;
    margin: auto;
    text-align: center;
    width: 100%;
  }
  body .fr-img-caption .fr-img-wrap img {
    display: block;
    margin: auto;
    width: 100%;
  }
  body .fr-img-caption .fr-img-wrap > span {
    margin: auto;
    display: block;
    padding: 5px 5px 10px;
    font-size: 14px;
    font-weight: initial;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-opacity: 0.9;
    -moz-opacity: 0.9;
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    width: 100%;
    text-align: center;
  }
  body button.fr-rounded,
  body input.fr-rounded,
  body textarea.fr-rounded {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
  body button.fr-large,
  body input.fr-large,
  body textarea.fr-large {
    font-size: 24px;
  }
  /**
   * Image style.
   */
  a.fr-view.fr-strong {
    font-weight: 700;
  }
  a.fr-view.fr-green {
    color: green;
  }
  /**
   * Link style.
   */
  imgbody {
    position: relative;
    max-width: 100%;
  }
  img.fr-view.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  img.fr-view.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
  }
  img.fr-view.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
  }
  img.fr-view.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  img.fr-view.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  img.fr-view.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
  span.fr-img-captionbody {
    position: relative;
    max-width: 100%;
  }
  span.fr-img-caption.fr-view.fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
  }
  span.fr-img-caption.fr-view.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
  }
  span.fr-img-caption.fr-view.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
  }
  span.fr-img-caption.fr-view.fr-dii {
    display: inline-block;
    float: none;
    vertical-align: bottom;
    margin-left: 5px;
    margin-right: 5px;
    max-width: calc(100% - (2 * 5px));
  }
  span.fr-img-caption.fr-view.fr-dii.fr-fil {
    float: left;
    margin: 5px 5px 5px 0;
    max-width: calc(100% - 5px);
  }
  span.fr-img-caption.fr-view.fr-dii.fr-fir {
    float: right;
    margin: 5px 0 5px 5px;
    max-width: calc(100% - 5px);
  }
`;

export const fontFaceData = `
  /* Open Sans (Regular & Bold) */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Regular/OpenSans-Regular.eot');
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Regular/OpenSans-Regular.woff2') format('woff2'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Regular/OpenSans-Regular.woff') format('woff'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Regular/OpenSans-Regular.ttf') format('truetype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Regular/OpenSans-Regular.svg#Lato') format('svg');
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Bold/OpenSans-Bold.eot');
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Bold/OpenSans-Bold.woff2') format('woff2'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Bold/OpenSans-Bold.woff') format('woff'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Bold/OpenSans-Bold.ttf') format('truetype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/OpenSans-Bold/OpenSans-Bold.svg#Lato') format('svg');
  }

  /* Helvetica Neue (Regular & Bold) */
  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.eot');
    src: local('Helvetica Neue'), local('HelveticaNeue'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.woff2') format('woff2'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.woff') format('woff'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.ttf') format('truetype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/HelveticaNeue-Medium/HelveticaNeueMedium.svg#Lato') format('svg');
  }

  /* DIN (Regular & Bold) */
  @font-face {
    font-family: 'DIN';
    font-style: normal;
    font-weight: 400;
    src: url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Regular/DIN-Regular.eot');
    src: local('DIN Regular'), local('DIN-Regular'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Regular/DIN-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Regular/DIN-Regular.woff2') format('woff2'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Regular/DIN-Regular.woff') format('woff'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Regular/DIN-Regular.ttf') format('truetype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Regular/DIN-Regular.svg#Lato') format('svg');
  }

  @font-face {
    font-family: 'DIN';
    font-style: normal;
    font-weight: 700;
    src: url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Bold/DIN-Bold.eot');
    src: local('DIN Bold'), local('DIN-Bold'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Bold/DIN-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Bold/DIN-Bold.woff2') format('woff2'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Bold/DIN-Bold.woff') format('woff'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Bold/DIN-Bold.ttf') format('truetype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/DIN-Bold/DIN-Bold.svg#Lato') format('svg');
  }


  /* Montserrat (Regular & Bold) */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Regular/Montserrat-Regular.eot');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Regular/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Regular/Montserrat-Regular.woff2') format('woff2'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Regular/Montserrat-Regular.ttf') format('truetype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Regular/Montserrat-Regular.svg#Lato') format('svg');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Bold/Montserrat-Bold.eot');
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Bold/Montserrat-Bold.woff2') format('woff2'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Bold/Montserrat-Bold.woff') format('woff'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Bold/Montserrat-Bold.ttf') format('truetype'),
        url('https://s3-us-west-2.amazonaws.com/digital-signage-2018/fonts/Montserrat-Bold/Montserrat-Bold.svg#Lato') format('svg');
  }

  /* Dosis (Regular & SemiBold) */
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    src: local('Dosis Regular'), local('Dosis-Regular'),
        url('https://digital-signage-2018.s3-us-west-2.amazonaws.com/fonts/Dosis-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 600;
    src: local('Dosis SemiBold'), local('Dosis-SemiBold'),
        url('https://digital-signage-2018.s3-us-west-2.amazonaws.com/fonts/Dosis-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 400;
    src:
      url('https://digital-signage-2018.s3-us-west-2.amazonaws.com/fonts/GothamPro-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Gotham Pro';
    font-style: normal;
    font-weight: 700;
    src:
      url('https://digital-signage-2018.s3-us-west-2.amazonaws.com/fonts/GothamPro-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src:
      url('https://digital-signage-2018.s3-us-west-2.amazonaws.com/fonts/Roboto-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'AlfaSlabOne';
    font-style: normal;
    font-weight: 400;
    src:
      url('https://digital-signage-2018.s3-us-west-2.amazonaws.com/fonts/AlfaSlabOne-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Myriad Pro';
    font-style: normal;
    font-weight: 400;
    src:
      url('https://digital-signage-2018.s3-us-west-2.amazonaws.com/fonts/MYRIADPRO-REGULAR.OTF');
  }

  @font-face {
    font-family: 'Blood Orange';
    font-style: normal;
    font-weight: 400;
    src:
      url('https://digital-signage-2018.s3-us-west-2.amazonaws.com/fonts/BloodOrange.otf');
  }
`;
