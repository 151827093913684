import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setContentFormId } from '../../../../state/ducks/app/actions';
import ContentSettingsModal from '../UI/ModalAddContent';

class ContentSettingsWrapper extends React.Component {
  state = {
    isOpen: false,
    values: {},
  };

  handleOpen = contentGroup => {
    this.setState({
      isOpen: true,
      values: contentGroup,
    });

    this.props.setContentFormId(contentGroup.type);
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen, values } = this.state;
    const { children, ...rest } = this.props;

    return (
      <>
        {children && children({ isOpen, onOpen: this.handleOpen, values, ...rest })}
        <ContentSettingsModal
          // TODO: rename props
          modalToggler={this.handleClose}
          modalScreens={isOpen}
          values={values}
        />
      </>
    );
  }
}

ContentSettingsWrapper.propTypes = {
  children: PropTypes.node,
  setContentFormId: PropTypes.func.isRequired,
};

const mapDispatchToProps = { setContentFormId };

export default connect(
  null,
  mapDispatchToProps,
)(ContentSettingsWrapper);
