import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { upperFirst } from 'lodash';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styles from './ScreenCard.module.scss';
import makeToggleable from '../../../../hocs/makeToggleable';
import DotsButton from '../../../../common/components/UI/DotsButton';
import OpenInPlayerBtn from '../../../../common/components/UI/OpenInPlayerBtn';
import MenuList from '../../../../common/components/UI/MenuList/MenuList';
import { cutText } from '../../../../../utils';

const REACT_APP_PLAYER_URL = process.env.REACT_APP_PLAYER_URL;
console.log('REACT_APP_PLAYER_URL:', REACT_APP_PLAYER_URL);
class ScreenCard extends React.Component {
  wrapAction = action => e => {
    action(e);
    this.props.toggle();
  };

  render() {
    const {
      name,
      screen,
      isOpen,
      toggle,
      linkTo,
      wsInit,
      rootRef,
      onClick,
      onDelete,
      isActive,
      desktopStatus,
      screenOrientationIcon,
      user,
    } = this.props;

    const { wrapAction } = this;
    const extraProps = onClick ? { onClick } : {};

    return (
      <Link
        innerRef={el => {
          rootRef.current = el;
        }}
        to={linkTo}
        className={cn(styles.container, isActive && styles.containerIsActive, isOpen && styles.containerIsOpen)}
        {...extraProps}
      >
        <div className={styles.header}>
          {screenOrientationIcon && screenOrientationIcon}
          <div className={styles.desktopStatusArea}>
          </div>
          <OpenInPlayerBtn
            disabled={!user.accessCode}
            size={24}
            className={styles.dotsButton}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              window.open(
                `${REACT_APP_PLAYER_URL}/${user.accessCode}/screen/${screen.id}`,
                "_blank"
              );
            }}
          />
          <DotsButton
            size={16}
            className={styles.dotsButton}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              toggle();
            }}
          />
        </div>
        <h3 className={styles.name}>
          {cutText(name)}
          {/* <div style={{ color: 'red' }}>{screen.id}</div> */}
        </h3>
        <MenuList className={styles.actionsMenu}>
          <MenuList.Link title="Schedule" to={`/screens/${screen.id}/schedule`} toggle={toggle} />
          <MenuList.Item title="Delete" onClick={wrapAction(onDelete)} color="#EB5757" />
        </MenuList>
      </Link>
    );
  }
}

ScreenCard.defaultProps = {
  isActive: false,
  desktopStatus: 'offline',
  wsInit: false,
};

ScreenCard.propTypes = {
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  rootRef: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  wsInit: PropTypes.bool,
  desktopStatus: PropTypes.oneOf(['online', 'offline']),
  screenOrientationIcon: PropTypes.element,
};

export default makeToggleable(ScreenCard);
