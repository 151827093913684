import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styles from './SessionAddForm.module.scss';
import SessionSection from '../SessionSection';
import NavigationTabs from '../NavigationTabs';
import ButtonDigital from '../../../../common/components/UI/ButtonDigital';
import SpeakersSection from '../SpeakersSection';
import { sessionsActions } from '../../../../../state/ducks/sessions';
import { getDataFeedList } from '../../../../../state/ducks/dataFeeds/selectors';

const { fetchCreateSession } = sessionsActions;

class SessionAddForm extends Component {
  constructor(props) {
    super(props);
    this.sessionFormRef = React.createRef();
  }

  state = {
    selectedTab: 'session',
  };

  componentDidMount() {
    const { change, programmes, theatreId, planingDay } = this.props;
    const { title: label, id: value } = programmes.find(item => item.id === theatreId) || programmes[0];
    change('theatre', { label, value });
    change('day', planingDay || new Date());
    change(
      'description',
      '<!DOCTYPE html><html><head><title></title><style>body {font-family:"Open Sans",sans-serif;} *{padding:0;margin:0;box-sizing:border-box;}ul{padding-left: 1.2em;}</style></head><body></body></html>',
    );
  }

  handleSelectTab = event => {
    const { id } = event.target;
    this.setState({ selectedTab: id });
  };

  renderContentTab = () => {
    const { selectedTab } = this.state;
    const {
      dataFeedList,
      speakerCompany,
      speakerName,
      speakerJob,
      speakers,
      change,
      description,
      programmes,
    } = this.props;

    switch (selectedTab) {
      case 'session':
        return <SessionSection {...{ change, description, programmes, dataFeedList }} />;
      case 'speakers':
        return (
          <FieldArray
            {...{ speakerCompany, speakerName, speakerJob, speakers, change }}
            name="speakers"
            component={SpeakersSection}
          />
        );
      default:
        return null;
    }
  };

  handleAddSession = (values, isAddAnother) => {
    const { createSession, handleCloseSessionModal, isValidFields } = this.props;

    if (!isValidFields) {
      return this.setState({ selectedTab: 'session' }, () => this.sessionFormRef.current.click());
    }

    createSession(values, isAddAnother);
    handleCloseSessionModal();
  };

  render() {
    const { selectedTab } = this.state;
    const { handleCloseSessionModal, handleSubmit } = this.props;
    const isAddAnother = true;
    return (
      <form className={styles.sessionContainer}>
        <div className={styles.title}>
          <span>Add Session</span>
        </div>
        <NavigationTabs selectedTab={selectedTab} handleSelectTab={this.handleSelectTab} />
        {this.renderContentTab()}
        <div className={styles.bntGroup}>
          <ButtonDigital onClick={handleCloseSessionModal}>Cancel</ButtonDigital>
          <ButtonDigital
            onClick={handleSubmit(value => this.handleAddSession(value, isAddAnother))}
            className={styles.saveBtn}
            type="submit"
            isConfirmStyle
          >
            Save and Add Another
          </ButtonDigital>
          <ButtonDigital
            btnRef={this.sessionFormRef}
            onClick={handleSubmit(value => this.handleAddSession(value))}
            type="submit"
            isConfirmStyle
          >
            Save
          </ButtonDigital>
        </div>
      </form>
    );
  }
}

SessionAddForm.propTypes = {
  programmes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      ownerId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  handleCloseSessionModal: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

const selector = formValueSelector('sessionAdd');

const mapStateToProps = state => {
  const title = selector(state, 'title');
  const start = selector(state, 'start');
  const end = selector(state, 'end');
  const speakers = selector(state, 'speakers');

  const isValidFields = !!(!!title && start && start.indexOf('_') === -1 && end && end.indexOf('_') === -1);

  const theatreId = state.sessions.sessionModal.type === 'new' && state.sessions.sessionModal.id;
  const planingDay = state.sessions.sessionModal.type === 'new' && state.sessions.sessionModal.date;

  return {
    theatreId,
    planingDay,
    isValidFields,
    programmes: state.programmes.programmes,
    speakerName: selector(state, 'speakerName'),
    speakerJob: selector(state, 'speakerJob'),
    speakerCompany: selector(state, 'speakerCompany'),
    speakers: speakers && speakers.sort((a, b) => a.order - b.order),
    description: selector(state, 'description'),
    dataFeedList: getDataFeedList(state),
  };
};

const mapDispatchToProps = {
  createSession: fetchCreateSession,
};

export default compose(
  reduxForm({
    form: 'sessionAdd',
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SessionAddForm);
