import React from 'react';
import { Link } from 'react-router-dom';
import styles from './DataFeedBtn.module.scss';
import Icon from '../../../../common/components/UI/Icon';

function DataFeedBtn() {
  return (
    <Link to="/programmes/data-feed" className={styles.container}>
      <Icon name="rssSymbol" size="24px" marginRight="8px" />
      <span>Data Feed</span>
    </Link>
  );
}

export default DataFeedBtn;
