import { createSelector } from 'reselect';

export const getContents = createSelector(
  state => state.content.contents,
  contents => contents,
);

export const getAllImages = createSelector(
  state => state.content.contents,
  contents => contents.filter(({ type }) => type === 'image'),
);

export const getContentsProgress = state => Object.values(state.content.contentsProgress);
export const getImagesWhichUploading = createSelector(
  getContentsProgress,
  contentsProgress =>
    contentsProgress
      .filter(({ fileProgressData }) => fileProgressData.contentType === 'image')
      .sort((a, b) => a.fileProgressData.uniqueKey > b.fileProgressData.uniqueKey),
);

const getContentsMeta = state => state.contentMeta.contentMeta;
const getContentGroups = state => state.contentGroup.contentGroup;
const getPlaylists = state => state.playlists.playlists;
const getPlaylistId = (_, playlistId) => playlistId;

const shapePlaylistContent = (...args) => {
  const [contentsMeta, contentGroups, playlists, contents, currentPlaylistId, deep] = args;
  const playlistMeta = contentsMeta.filter(cm => cm.playlistId === currentPlaylistId);

  const result = playlistMeta.map(cm => {
    const shapeContentGroup = () => {
      const contentGroup = contentGroups.find(cg => cg.id === cm.entityId);
      const contentsLenOfContentGroup = contents.filter(
        content => contentGroup && content.contentGroupId === contentGroup.id,
      );

      const contentItem = contents.find(c => c.contentGroupId === (contentGroup && contentGroup.id)) || {};
      const mergedObject = {
        ...contentItem,
        ...contentGroup,
        ...cm,
        hasContentsLength: contentsLenOfContentGroup.length,
      };

      return mergedObject;
    };

    const shapeProgramme = () => ({ ...cm, type: 'programme', name: 'Full Programme' });

    const shapePlaylist = () => {
      const playlist = playlists.find(p => p.id === cm.entityId) || {};
      const playlistContentGroups = deep > 0 ? [] : shapePlaylistContent(...args.slice(0, -2), cm.entityId, deep + 1);

      return {
        ...playlist,
        ...cm,
        type: 'playlist',
        contentGroups: playlistContentGroups,
      };
    };

    const shapers = {
      contentGroup: shapeContentGroup,
      programme: shapeProgramme,
      playlist: shapePlaylist,
    };

    const shape = shapers[cm.entityType];

    if (!shape) return { empty: 'empty' };

    return shape();
  });

  return result.sort((a, b) => a.order - b.order);
};

export const getCombinedPlaylistContent = createSelector(
  [getContentsMeta, getContentGroups, getPlaylists, getContents, getPlaylistId],
  (...args) => {
    const deep = 0;
    return shapePlaylistContent(...args, deep);
  },
);

export const getCombinedPlaylistContentWithoutFullprogramme = createSelector(
  [getCombinedPlaylistContent],
  contents => contents.filter(({ type }) => type !== 'programme'),
);
