import withAuthentication from '../views/hocs/withAuthentication';
import { Home, Auth, Screens, PlayLists, Programmes } from '../views/pages';

const routes = [
  {
    path: '/',
    component: withAuthentication(Home),
    exact: true,
  },
  {
    path: '/auth',
    component: withAuthentication(Auth),
    exact: true,
  },
  {
    path: '/screens',
    component: withAuthentication(Screens),
  },
  {
    path: '/playlists',
    component: withAuthentication(PlayLists),
  },
  {
    path: '/programmes',
    component: withAuthentication(Programmes),
  },
];

export default routes;
