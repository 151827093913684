import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';

import styles from './Tooltip.module.scss';

/**
 * example:
 *  <div data-tip="Text of tip">
 *    Something, no matter what
 *    <Tooltip />
 *  <div>
 */

function Tooltip({ className, ...rest }) {
  return <ReactTooltip className={cn(styles.container, className && className)} type="light" {...rest} />;
}

Tooltip.propTypes = {
  className: PropTypes.string,
};

export default Tooltip;
