import React from 'react';
import { connect } from 'react-redux';
import ReactDropZone from 'react-dropzone';
import PropTypes from 'prop-types';
import { contentGroupActions } from '../../../../../../state/ducks/contentGroup';
import { showNotification } from '../../../../../../state/ducks/app/actions';
import { getAcceptFilesTypes } from '../../../../../../utils';

const { fetchCreateContentGroupSetToNotExistPlaylist } = contentGroupActions;

class DropZone extends React.Component {
  onDropFile = (acceptedFiles, rejectedFiles) => {
    const { addContentGroupToNotExistPlaylist, newScreenName, selectedScreenSizeId, showNotify } = this.props;

    if (acceptedFiles.length) {
      addContentGroupToNotExistPlaylist(acceptedFiles, newScreenName, selectedScreenSizeId);
    }

    rejectedFiles.forEach(file =>
      showNotify({ level: 'error', message: `The format of ${file.name} is not supported` }),
    );
  };

  render() {
    const { children } = this.props;

    return (
      <ReactDropZone disableClick onDrop={this.onDropFile} accept={getAcceptFilesTypes()}>
        {props => children && children({ ...props, ...this.props })}
      </ReactDropZone>
    );
  }
}

DropZone.propTypes = {
  children: PropTypes.func.isRequired,
  showNotify: PropTypes.func.isRequired,
  newScreenName: PropTypes.string.isRequired,
  selectedScreenSizeId: PropTypes.number.isRequired,
  addContentGroupToNotExistPlaylist: PropTypes.func.isRequired,
};

DropZone.defineType = {
  isScreen: false,
};

const mapDispatchToProps = {
  showNotify: showNotification,
  addContentGroupToNotExistPlaylist: fetchCreateContentGroupSetToNotExistPlaylist,
};

export default connect(
  null,
  mapDispatchToProps,
)(DropZone);
