import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Range } from 'rc-slider';
import styles from './SizeRange.module.scss';

import 'rc-slider/assets/index.css';
import './RangeOverride.scss';

const min = 0;
const max = 100;

const getDefaultRangeValue = (hasBottomBar = false) => (hasBottomBar ? [0, 45, 90, 100] : [0, 50, 100]);

const p = value => `${value}%`;

const getComputedRangeValue = ({ firstPlaylist, bottomBar }, hasBottomBar) =>
  hasBottomBar ? [0, firstPlaylist, max - bottomBar, 100] : [0, firstPlaylist, 100];

const getComputedInputValue = (rangeValue, hasBottomBar) => {
  const result = {};

  if (hasBottomBar) {
    const [, firstValue, secondValue] = rangeValue;

    result.firstPlaylist = firstValue;
    result.secondPlaylist = secondValue - firstValue;
    result.bottomBar = max - secondValue;
  } else {
    const [, firstValue] = rangeValue;

    result.firstPlaylist = firstValue;
    result.secondPlaylist = max - firstValue;
    result.bottomBar = 0;
  }

  return result;
};

class SizeRange extends React.Component {
  componentDidUpdate(prevProps) {
    const isOffBottomBar = prevProps.hasBottomBar && !this.props.hasBottomBar;
    const isOnBottomBar = !prevProps.hasBottomBar && this.props.hasBottomBar;

    if (isOffBottomBar || isOnBottomBar) {
      this.handleReset();
    }
  }

  canChange = newValue => {
    const { hasBottomBar } = this.props;

    if (hasBottomBar) {
      const [, firstPlSize, secondPlSize] = newValue;
      return firstPlSize > 9 && secondPlSize - firstPlSize > 9 && secondPlSize < 100;
    }

    const [, firstPlSize] = newValue;
    return firstPlSize > 9 && firstPlSize < 91;
  };

  handleChange = newValue => {
    if (!this.canChange(newValue)) return;

    const {
      hasBottomBar,
      input: { onChange, onBlur },
    } = this.props;

    onChange(getComputedInputValue(newValue, hasBottomBar));
    onBlur();
  };

  handleReset = () => {
    const { hasBottomBar } = this.props;
    this.handleChange(getDefaultRangeValue(hasBottomBar));
  };

  renderRange() {
    const { hasBottomBar, input } = this.props;
    const { firstPlaylist, secondPlaylist, bottomBar } = input.value;

    // const { id, hasBottomBar, input, meta, ...rest } = this.props;
    // const { name } = input;
    // const { touched, error, warning } = meta;

    const firstPlaylistPercent = p(firstPlaylist);
    const secondPlaylistPercent = p(secondPlaylist);
    const bottomBarPercent = p(bottomBar);

    const rangeValue = getComputedRangeValue(input.value, hasBottomBar);
    const handleStyle = rangeValue.map((_, idx) =>
      idx === 0 || idx === rangeValue.length - 1 ? { display: 'none' } : {},
    );

    return (
      <div className={styles.rangeRoot}>
        <div className={styles.rangeContainer}>
          <Range
            min={min}
            max={max}
            pushable={1}
            railStyle={{ height: 0 }}
            handleStyle={handleStyle}
            value={rangeValue}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.display}>
          <div style={{ width: firstPlaylistPercent }} className={cn(styles.displayItem, styles.firstPlaylist)}>
            <div className={styles.box}>First Playlist</div>
            <div className={styles.percent}>{firstPlaylistPercent}</div>
          </div>

          <div style={{ width: secondPlaylistPercent }} className={cn(styles.displayItem, styles.secondPlaylist)}>
            <div className={styles.box}>Second Playlist</div>
            <div className={styles.percent}>{secondPlaylistPercent}</div>
          </div>

          {hasBottomBar && (
            <div style={{ width: bottomBarPercent }} className={cn(styles.displayItem, styles.bottomBar)}>
              <div className={styles.box}>Bottom bar</div>
              <div className={styles.percent}>{bottomBarPercent}</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.size}>
        <div className={styles.sizeHeader}>
          <span className={styles.sizeLabel}>Size:</span>
          <button type="button" className={styles.sizeReset} onClick={this.handleReset}>
            Default
          </button>
        </div>
        <div className={styles.sizeControl}>{this.renderRange()}</div>
      </div>
    );
  }
}

SizeRange.defaultProps = {
  hasBottomBar: false,
};

SizeRange.propTypes = {
  id: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    warning: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.isRequired,
    value: PropTypes.isRequired,
    onBlur: PropTypes.isRequired,
    onChange: PropTypes.isRequired,
  }).isRequired,
  hasBottomBar: PropTypes.bool,
};

export default SizeRange;
