import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { SortableHandle, SortableElement } from 'react-sortable-hoc';
import styles from './NestedPlaylist.module.scss';

import ContentIcons from '../../../../../common/components/UI/ContentIcons';
import makeToggleable from '../../../../../hocs/makeToggleable';
import Icon from '../../../../../common/components/UI/Icon';
import PlaylistContentsContainer from '../PlaylistContentsContainer';
import { noop } from '../../../../../../utils';

const DragHandle = SortableHandle(() => <div className={styles.dragHandle} />);

const renderMenu = params => (
  <div className={styles.menu}>
    <button className={cn(styles.menuButton, styles.menuButtonDelete)} onClick={params.onDelete}>
      {/* <Icons icon="deleteTrash" /> */}
    </button>
  </div>
);

function NestedPlaylist(props) {
  const { isInRemoving, entityId, type, name, style, toggle, isOpen, onEditItem, onDelete, ...other } = props;
  const { contentGroups, playlistType, deep } = other;

  const handleDelete = e => {
    if (!isInRemoving) {
      onDelete(e);
    }
  };

  const isEmpty = !contentGroups.length;
  const canShow = deep < 4;

  return (
    <div className={styles.root}>
      {isInRemoving && <div className={styles.removalSpinner} />}
      <div
        className={cn(styles.container, isEmpty && styles.containerIsEmpty)}
        style={style}
        onClick={isEmpty || !canShow ? noop : toggle}
      >
        <DragHandle />
        <div className={styles.contentIcon}>
          <ContentIcons type={type} className={styles.playlistIcon} />
        </div>
        <span className={styles.name}>
          {isEmpty && <span className={styles.empty}>Empty</span>}
          {name}
          {/* <span style={{ color: 'red' }}>{entityId}</span> */}
        </span>
        {renderMenu({ onDelete: handleDelete })}
        {canShow && !isEmpty && (
          <Icon name={isOpen ? 'arrowTopOrange' : 'arrowDownGray'} size={16} marginLeft={16} className={styles.arrow} />
        )}
      </div>
      {isOpen && canShow && (
        <PlaylistContentsContainer
          playlistId={entityId}
          onEditItem={onEditItem}
          bordered={false}
          deep={deep + 1}
          playlistType={playlistType}
          itemStyle={{ paddingLeft: (style.paddingLeft || 10) + 40 }}
        />
      )}
    </div>
  );
}

NestedPlaylist.defaultProps = {
  style: {},
};

NestedPlaylist.propTypes = {
  style: PropTypes.object,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  playlistType: PropTypes.string.isRequired,
  ...makeToggleable.propTypes,
};

export default SortableElement(makeToggleable(NestedPlaylist));
