import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { fetchMultipleRemoveContentGroupFromPlaylist } from '../../../../../state/ducks/playlists/actions';
import styles from './ButtonUndo.module.scss';

class ButtonUndo extends Component {
  handleClickOnUndo = () => {
    const { playlistId, getLinkedContentMeta, removeContentMetaFromPlaylist } = this.props;
    removeContentMetaFromPlaylist(playlistId, getLinkedContentMeta);
  };

  render() {
    return (
      <button onClick={this.handleClickOnUndo} className={styles.buttonUndo}>
        Undo
      </button>
    );
  }
}

ButtonUndo.propTypes = {
  playlistId: PropTypes.number.isRequired,
  getLinkedContentMeta: PropTypes.arrayOf(PropTypes.number).isRequired,
  removeContentMetaFromPlaylist: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeContentMetaFromPlaylist: fetchMultipleRemoveContentGroupFromPlaylist,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(ButtonUndo);
