import * as types from './types';
import { createReducer } from '../../utils';
import updateItemBy from '../../utils/update-item-by';
import insertItem from '../../utils/insert-item';
import parseEntityField from '../../utils/parse-entity-field';

const handleModals = {
  [types.OPEN_CREATE_MODAL]: state => ({ ...state, isOpenCreateFeedModal: true }),
  [types.CLOSE_CREATE_MODAL]: state => ({ ...state, isOpenCreateFeedModal: false }),

  [types.OPEN_EDIT_MODAL]: (state, { payload: editFeedId }) => ({ ...state, editFeedId }),
  [types.CLOSE_EDIT_MODAL]: state => ({ ...state, editFeedId: null }),
};

const handleCreateDataFeed = {
  [types.CREATE_DATA_FEED_REQUEST]: state => ({ ...state, isCreatingDataFeed: true }),
  [types.CREATE_DATA_FEED_SUCCESS]: (state, { payload: createdDataFeed }) => {
    const newDataFeedList = [...state.dataFeedList, parseEntityField(createdDataFeed)];
    return {
      ...state,
      dataFeedList: newDataFeedList,
      isCreatingDataFeed: false,
    };
  },
  [types.CREATE_DATA_FEED_FAILURE]: state => ({ ...state, isCreatingDataFeed: false }),
};

const handleUpdateDataFeed = {
  [types.UPDATE_DATA_FEED_REQUEST]: (state, { payload: updatedDataFeed }) => {
    const updatedDataFeedList = updateItemBy(state.dataFeedList, 'id', parseEntityField(updatedDataFeed));
    return {
      ...state,
      dataFeedList: updatedDataFeedList,
    };
  },
};

const handleDeleteDataFeed = {
  [types.DELETE_DATA_FEED_REQUEST]: (state, { payload: dataFeedId }) => ({
    ...state,
    dataFeedList: state.dataFeedList.filter(({ id }) => id !== dataFeedId),
  }),
  [types.DELETE_DATA_FEED_SUCCESS]: (state, { payload: dataFeedId }) => ({
    ...state,
    dataFeedHistoryList: state.dataFeedHistoryList.filter(history => history.dataFeedId !== dataFeedId),
  }),
  [types.DELETE_DATA_FEED_FAILURE]: (state, { payload: { index, deletedDataFeed } }) => ({
    ...state,
    dataFeedList: insertItem(state.dataFeedList, index, deletedDataFeed),
  }),
};

export default createReducer({
  dataFeedList: [],
  dataFeedApis: [],
  dataFeedHistoryList: [],
  editFeedId: null,
  isOpenCreateFeedModal: false,
  isCreatingDataFeed: false,
})({
  [types.SET_DATA_FEED_LIST]: (state, { payload: dataFeedList }) => ({
    ...state,
    dataFeedList: dataFeedList.map(parseEntityField),
  }),
  [types.SET_DATA_FEED_HISTORY_LIST]: (state, { payload: dataFeedHistoryList }) => ({
    ...state,
    dataFeedHistoryList: (dataFeedHistoryList || []).map(history => parseEntityField(history, 'data')),
  }),
  [types.FETCH_DATA_FEED_APIS_SUCCESS]: (state, { payload: dataFeedApis }) => ({
    ...state,
    dataFeedApis,
  }),

  ...handleModals,
  ...handleCreateDataFeed,
  ...handleUpdateDataFeed,
  ...handleDeleteDataFeed,
});
