import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import styles from './TheatreHTMLTitleForm.module.scss';
import { validation } from '../../../../../utils';
import ProgrammeHTMLTitleField from '../../../../common/components/UI/ProgrammeHTMLTitleField';
import { normalizeExtraSpaces } from '../../../../../utils/validation';

function TheatreHTMLTitleForm({ onChangeName, refTitleName, ...rest }) {
  return (
    <form className={styles.theaterNameForm}>
      <Field
        ref={refTitleName}
        withRef={true}
        name="htmlTitle"
        type="text"
        component={ProgrammeHTMLTitleField}
        // className={styles.input}
        placeholder="HTML Title"
        onBlur={onChangeName}
        normalize={normalizeExtraSpaces}
        {...rest}
      />
    </form>
  );
}

TheatreHTMLTitleForm.propTypes = {
  refTitleName: PropTypes.shape({ current: PropTypes.object }),
  onChangeName: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'theatrehtmlTitle',
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: false,
})(TheatreHTMLTitleForm);
