import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './MenuList.module.scss';
import { cleanObject } from '../../../../../utils';

function Item({ title, color, className, onClick }) {
  const extraProps = color ? { style: { color } } : {};

  return (
    <button className={cn(className && className, styles.item)} onClick={onClick} {...extraProps}>
      {title}
    </button>
  );
}

function InnerLink({ title, color, className, to, toggle }) {
  const extraProps = color ? { style: { color } } : {};

  return (
    <Link className={cn(className && className, styles.item)} {...extraProps} to={to} onClick={toggle}>
      {title}
    </Link>
  );
}

function MenuList(props) {
  const { top, left, right, bottom, isOpen, children, className, style: styleProp, ...rest } = props;
  const positionStyle = isOpen ? cleanObject({ top, left, right, bottom, position: 'absolute' }) : {};

  return (
    <div
      style={{ ...positionStyle, ...styleProp }}
      className={cn(className && className, styles.container, isOpen && styles.containerIsOpen)}
      {...rest}
    >
      {children}
    </div>
  );
}

MenuList.Item = Item;
MenuList.Link = InnerLink;

MenuList.defaulProps = {
  style: {},
  isOpen: false,
};

MenuList.propTypes = {
  style: PropTypes.object,
  isOpen: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MenuList;
