// import isomorphicFetch from 'isomorphic-fetch';
import axios from 'axios';
import { reduxStore } from '../../App';
import { SET_PROGRESS_TO_CONTENT } from '../ducks/content/types';

const s3upload = (url, method, body = null, headers, s3urlContent, fileProgressData) => {
  const path = url;

  const request = axios.put(path, body, {
    headers,
    requestId: fileProgressData.uniqueKey,
    onUploadProgress: progressEvent => {
      if (s3urlContent) {
        // @todo Do it right way
        const { total, loaded } = progressEvent;
        if (fileProgressData) {
          reduxStore.dispatch({
            type: SET_PROGRESS_TO_CONTENT,
            payload: { urlKey: s3urlContent, total, loaded, fileProgressData },
          });
        }
      }
    },
  });

  return request;
};

export default s3upload;
