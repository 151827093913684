import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./LayoutConfirmDuplicateModal.module.scss";
import { appActions } from "../../../state/ducks/app";
import { playlistsActions } from "../../../state/ducks/playlists";
import { screensActions } from "../../../state/ducks/screens";
import { contentActions } from "../../../state/ducks/content";
import { programmesActions } from "../../../state/ducks/programmes";
import { screenSizesActions } from "../../../state/ducks/screenSizes";
import { getPlaylists } from "../../../state/ducks/playlists/selectors";

const {
  setShowConfirmModalPlaylistScreens,
  setShowConfirmModalScreenSizes,
  setShowConfirmModalPlaylist,
  setShowConfirmDuplicateModal,
} = appActions;
const { fetchDeletePlaylist, duplicatePlaylist } = playlistsActions;
const { fetchDeleteContent } = contentActions;
const { fetchDeleteScreen } = screensActions;
const { fetchRemoveScreenSizes } = screenSizesActions;
const { removeTheatreProgram } = programmesActions;
class LayoutConfirmDuplicate extends React.Component {
  duplicatePlaylistObject = (playlist) => {
    return {
      name: `Copy of ${playlist.name}`,
      type: playlist.type,
      options: playlist.options,
      ownerId: playlist.ownerId,
      ownerScreens: [],
      contentMeta: playlist.contentMeta,
    };
  };

  getActionType = () => {
    const {
      setShowConfirmDuplicateModal,
      confirmDuplicateModalPlaylist,
    } = this.props;
    const fetchAction = this.props[confirmDuplicateModalPlaylist.fetchAction];
    console.log(confirmDuplicateModalPlaylist.payload);
    fetchAction(
      this.duplicatePlaylistObject(confirmDuplicateModalPlaylist.payload)
    );
    setShowConfirmDuplicateModal(false, {});
  };

  render() {
    const {
      showConfirmDuplicateModal,
      confirmDuplicateModalPlaylist,
      setShowConfirmDuplicateModal,
    } = this.props;

    return (
      <>
        {/* confirm modal for the rest */}
        <div
          className={
            showConfirmDuplicateModal
              ? styles.containerActive
              : styles.container
          }
        >
          <div
            className={styles.opacityBackground}
            onClick={() => setShowConfirmDuplicateModal(false, {})}
          />
          <div className={styles.confirmWindow}>
            <div className={styles.warnBody}>
              <p className={styles.warningStyle}>
                {confirmDuplicateModalPlaylist.title}
              </p>
            </div>
            <div className={styles.buttonGroup}>
              <button
                type="button"
                className={styles.buttonStyleCancel}
                onClick={() => setShowConfirmDuplicateModal(false, {})}
              >
                No
              </button>
              <button
                type="button"
                className={styles.buttonStyle}
                onClick={this.getActionType}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

LayoutConfirmDuplicate.propTypes = {
  confirmModalPlaylistData: PropTypes.shape({
    id: PropTypes.number,
    screenNames: PropTypes.string,
  }),
  confirmToggler: PropTypes.bool,
  confirmStatus: PropTypes.bool,
  notificationContent: PropTypes.string,
  setConfirmStatus: PropTypes.func,
  showConfirmModalPlaylist: PropTypes.bool,
  confirmModalScreenSizesData: PropTypes.object,
  setShowConfirmModalScreenSizes: PropTypes.func,
  setShowConfirmModalPlaylistScreens: PropTypes.func,
  setShowConfirmModalPlaylist: PropTypes.func,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setShowConfirmDuplicateModal,
      setShowConfirmModalPlaylistScreens,
      setShowConfirmModalScreenSizes,
      setShowConfirmModalPlaylist,
      fetchDeletePlaylist,
      fetchDeleteScreen,
      fetchDeleteContent,
      fetchRemoveScreenSizes,
      removeTheatreProgram,
      duplicatePlaylist,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  playlists: getPlaylists(state),
  showConfirmDuplicateModal: state.app.showConfirmDuplicateModal,
  confirmDuplicateModalPlaylist: state.app.confirmDuplicateModalPlaylist,
  showConfirmModal: state.app.showConfirmModalPlaylistScreens,
  showConfirmModalPlaylist: state.app.showConfirmModalPlaylist,
  showConfirmModalScreenSizes: state.app.showConfirmModalScreenSizes,
  confirmModal: state.app.confirmModalPlaylistScreens,
  confirmModalScreenSizesData: state.app.confirmModalScreenSizesData,
  confirmModalPlaylistData: state.app.confirmModalPlaylistData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutConfirmDuplicate);
