import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Slider from "rc-slider";
import styles from "./ScaleSlider.module.scss";

// const min = 0;
// const max = 100;

const p = (value) => `${value}%`;

class ScaleSlider extends React.Component {
    handleChange = (newValue) => {
        const {
            input: { onChange, onBlur },
        } = this.props;

        onChange(newValue);
        onBlur();
    };

    handleReset = () => {
        this.handleChange(this.props.defaultValue);
    };
    renderRange() {
        const { input, min, max } = this.props;
        // const insidePlaylistPercent = p(input.value);
        if (!input.value) this.handleReset();
        const value = input.value;

        return (
            <div className={styles.rangeRoot}>
                <div className={styles.rangeContainer}>
                    <Slider
                        min={min}
                        max={max}
                        railStyle={{ height: 0 }}
                        value={value}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.display}>
                    <div
                        style={{ width: '100%' }}
                        className={cn(
                            styles.displayItem,
                            styles.insidePlaylist
                        )}
                    >
                        <div className={styles.box}>{p(input.value)}</div>
                        <div className={styles.percent} />
                    </div>
                    <div className={styles.bottomLine} />
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className={styles.sizeControl}>{this.renderRange()}</div>
                <button
                    type="button"
                    className={styles.sizeReset}
                    onClick={this.handleReset}
                >
                    Default
                </button>
            </>
        );
    }
}

ScaleSlider.propTypes = {
    id: PropTypes.any,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        warning: PropTypes.string,
        error: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        name: PropTypes.isRequired,
        value: PropTypes.isRequired,
        onBlur: PropTypes.isRequired,
        onChange: PropTypes.isRequired,
    }).isRequired,
};

export default ScaleSlider;
