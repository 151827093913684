import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './LayoutMediaLibraryModal.module.scss';
import MediaLibrary from '../../common/components/UI/CommonMediaLibrary';
import { contentGroupActions } from '../../../state/ducks/contentGroup';

const { setContentGroupToCheckList } = contentGroupActions;

class LayoutMediaLibraryModal extends React.Component {
  render() {
    const { modalToggler, contentType } = this.props;
    return (
      <div className={modalToggler ? styles.containerActive : styles.container}>
        <MediaLibrary contentFilter={contentType} />
      </div>
    );
  }
}

LayoutMediaLibraryModal.propTypes = {
  modalToggler: PropTypes.bool,
  contentType: PropTypes.string,
};

const mapStateToProps = state => ({
  modalToggler: state.content.mediaLibraryModal.modalToggler,
  contentType: state.contentGroup.contentTypeFilter,
  setCheckList: setContentGroupToCheckList,
});

export default connect(mapStateToProps)(LayoutMediaLibraryModal);
