import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './NewTheatreBtn.module.scss';
import Icons from '../../../../common/components/UI/Icons';

const NewTheatreBtn = ({ handleOnClick, linkTo }) => (
  <Link to={linkTo} className={styles.container}>
    <Icons icon="plusDark" widthIcons={24} marginRight={8} />
    <span>New Theatre</span>
  </Link>
);

NewTheatreBtn.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default NewTheatreBtn;
