import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import styles from './TheatreNameForm.module.scss';
import { validation } from '../../../../../utils';
import EntityNameField from '../../../../common/components/UI/EntityNameField/EntityNameField';
import { normalizeExtraSpaces } from '../../../../../utils/validation';

function TheatreNameForm({ onChangeName, refTitleName, ...rest }) {
  return (
    <form className={styles.theaterNameForm}>
      <Field
        ref={refTitleName}
        withRef={true}
        name="name"
        type="text"
        component={EntityNameField}
        className={styles.input}
        placeholder="Enter screen name"
        validate={[validation.required, validation.isSpaceChar]}
        onBlur={onChangeName}
        normalize={normalizeExtraSpaces}
        {...rest}
      />
    </form>
  );
}

TheatreNameForm.propTypes = {
  refTitleName: PropTypes.shape({ current: PropTypes.object }),
  onChangeName: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'theatreName',
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: false,
})(TheatreNameForm);
