import { put, call, takeLatest, select, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import * as types from './types';
import * as actions from './actions';
import { storage, fetch, apiRoutes, sentryReport, getErrorMessage, mixpanel } from '../../utils';
import { showNotification } from '../app/actions';
import history from '../../utils/history';
import generateEntityName from '../../../utils/generateName';
import { playlistsActions } from '../playlists';
import { getPlaylists } from '../playlists/selectors';
import { getOptions } from '../../../utils';
import { getScreens } from './selectors';

const { setPlaylists } = playlistsActions;
const { fetchScreenStart, fetchScreenFinished, setScreens, fetchAddPlaylistToScreen, fetchCreateScreen } = actions;

// @TODO extract create screen logic to saga/func remove code duplication
function* fetchRequestCreateScreen(formData) {
  const { url, method } = apiRoutes.createScreen;
  const user = storage.getUser();
  const response = yield call(fetch, url(user.userId), method, formData);
  return response;
}

export function* fetchRequestCreateNewPlaylist(name) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.createPlaylist;
  const response = yield call(fetch, url(user.userId), method, { name, ownerId: user.userId });
  return response;
}

function* fetchScreensFlow() {
  try {
    yield put(fetchScreenStart());
    const { url, method } = apiRoutes.getScreens;
    const user = storage.getUser();
    const screens = yield call(fetch, url(user.userId), method);

    yield put(setScreens(screens));
    yield put(fetchScreenFinished());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchScreenFinished());
  }
}

function* fetchCreateScreenFlow(action) {
  try {
    const { formData, playlistId } = action.payload;
    yield put(fetchScreenStart());
    const { url, method } = apiRoutes.createScreen;
    const user = storage.getUser();
    const res = yield call(fetch, url(user.userId), method, formData);
    if (res) yield put(showNotification({ level: 'info', autoDismiss: 1, message: 'The screen was created' }));
    const screens = yield select(state => state.screens.screens);
    const updateScreen = [...screens, res];
    yield put(setScreens(updateScreen));
    if (res.playlist) {
      yield put(fetchAddPlaylistToScreen(res.id, res.playlist));
    }
    if (playlistId) {
      yield put(fetchAddPlaylistToScreen(res.id, playlistId));
    }

    yield put(fetchScreenFinished());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchScreenFinished());
  }
}

function* fetchDeleteScreenFlow(action) {
  try {
    const screenId = action.payload;
    yield put(fetchScreenStart());
    const { url, method } = apiRoutes.deleteScreen;
    const res = yield call(fetch, url(screenId), method);
    if (res) yield put(showNotification({ level: 'info', autoDismiss: 1, message: 'The screen was deleted' }));
    const screens = yield select(state => state.screens.screens);
    yield put(setScreens(screens.filter(item => item.id !== screenId)));

    yield put(fetchScreenFinished());
    yield put({ type: types.FETCH_DELETE_SCREEN_END, payload: { screenId } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchScreenFinished());
  }
}

function* fetchAddPlaylistToScreenFlow(action) {
  try {
    const { userId } = storage.getUser();
    const { screenId, playlistId } = action.payload;

    // check if playlist exist before attach to screen (extract to stand alone saga)
    const { url: playlistUrl, method: playlistMethod } = apiRoutes.getPlaylist;
    yield call(fetch, playlistUrl(userId, playlistId), playlistMethod);

    const { url, method } = apiRoutes.addPlaylistToScreen;
    yield call(fetch, url(screenId, playlistId), method);
    const screens = yield select(state => state.screens.screens);
    const playlists = yield select(getPlaylists);

    const screenPlaylist = playlists.find(p => p.id === playlistId);

    const updateScreens = screens.map(item =>
      item.id === screenId
        ? _.assign(item, {
            playlists: [
              {
                ...screenPlaylist,
                options: getOptions(screenPlaylist.options),
              },
            ],
          })
        : item,
    );
    yield put(setScreens(updateScreens));
    yield put({ type: types.FETCH_ADD_PLAYLIST_TO_SCREEN_END, payload: { screenId } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchRemovePlaylistFromScreenFlow(action) {
  try {
    const { screenId, playlistId } = action.payload;
    const { url, method } = apiRoutes.removePlaylistFromScreen;
    const res = yield call(fetch, url(screenId, playlistId), method);
    if (res) yield put(showNotification({ level: 'info', autoDismiss: 1, message: 'The screen was updated' }));
    const screens = yield select(state => state.screens.screens);
    const updateScreens = screens.map(item => (item.id === screenId ? _.omit(item, ['playlists']) : item));
    yield put(setScreens(updateScreens));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchRemoveAndSetPlaylistToScreenFlow(action) {
  try {
    const { currentScreenId, clickedPlaylistId } = action.payload;

    const screens = yield select(state => state.screens.screens);
    const currentScreen = screens.find(item => item.id === currentScreenId);
    const currentPlaylist = currentScreen.playlists[0];

    if (clickedPlaylistId === currentPlaylist.id) {
      return null;
    }

    const { url, method } = apiRoutes.replacePlaylistToScreen;
    yield call(fetch, url(currentScreenId, clickedPlaylistId), method);

    const updateScreens = screens.map(item =>
      item.id === currentScreenId ? _.assign(item, { playlists: [{ id: clickedPlaylistId }] }) : item,
    );
    yield put(setScreens(updateScreens));
    yield put({ type: types.FETCH_ADD_PLAYLIST_TO_SCREEN_END, payload: { currentScreenId } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchUpdateScreenPlaylistsFlow(action) {
  try {
    const { currentScreenId, clickedPlaylistId, selectedPlaylistId } = action.payload;
    const screens = yield select(state => state.screens.screens);
    const currentScreen = screens.find(item => item.id === currentScreenId);
    const currentScreenPlaylists = currentScreen.playlists.map(p => p.id);

    if (clickedPlaylistId === selectedPlaylistId) {
      return null;
    }

    const putPlaylists = currentScreenPlaylists.includes(clickedPlaylistId)
      ? currentScreenPlaylists
      : [...currentScreenPlaylists, clickedPlaylistId];

    if (selectedPlaylistId !== undefined) {
      const indexToReplace = putPlaylists.indexOf(selectedPlaylistId);
      if (indexToReplace !== -1) {
        putPlaylists[indexToReplace] = clickedPlaylistId;
      }
    }

    const { url, method } = apiRoutes.updateScreenPlaylists;

    yield call(fetch, url(currentScreenId), method, putPlaylists);

    const updateScreens = screens.map(item =>
      item.id === currentScreenId
        ? _.assign(item, { playlists: putPlaylists.map(playlistId => ({ id: playlistId })) })
        : item,
    );

    yield put(setScreens(updateScreens));
    yield put({ type: types.FETCH_ADD_PLAYLIST_TO_SCREEN_END, payload: { currentScreenId } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchAddPlaylistToNotExistScreenFlow(action) {
  try {
    const { playlistId, currentScreenId } = action.payload;
    const values = yield select(state => state.form.addScreens.values);
    if (currentScreenId === false) {
      yield put(fetchCreateScreen(values, playlistId));
    }
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchScreenUpdateFlow(action) {
  try {
    const { screenId, dataUpdate } = action.payload;
    const user = storage.getUser();
    const { url, method } = apiRoutes.updateScreenItem;

    if (dataUpdate.screenSizeId) mixpanel.track('Screen size was changed on existing screen', { screenId });

    // check on exist screen name
    const getScreens = yield select(state => state.screens.screens);

    const getCurrentUpdatedScreen = getScreens.find(el => el.id === screenId);
    const res = yield call(fetch, url(user.userId, screenId), method, dataUpdate);
    const updatedScreens = getScreens.map(el =>
      el.id === screenId
        ? getCurrentUpdatedScreen
          ? { ...res, playlists: getCurrentUpdatedScreen.playlists }
          : res
        : el,
    );
    yield put(setScreens(updatedScreens));
    yield put({ type: types.FETCH_SCREEN_UPDATE_END, payload: { screenId } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* updateScreenLastUpdatedFieldFlow({ payload }) {
  try {
    const { screenId, lastUpdated } = payload;

    const user = storage.getUser();
    const { url, method } = apiRoutes.updateScreenItem;

    const screens = yield select(getScreens);
    const updatedScreen = yield call(fetch, url(user.userId, screenId), method, { lastUpdated });

    const newScreens = screens.map(screen => {
      if (screen.id === screenId) {
        return { ...updatedScreen, playlists: screen.playlists };
      }

      return screen;
    });

    yield put(setScreens(newScreens));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchCreateScreenWithRelationOfPlaylistFlow(action) {
  try {
    const { playlistId } = action.payload;
    const { url, method } = apiRoutes.createScreen;
    const {
      addScreens: {
        values: { name },
      },
      sizeForm: {
        values: { screenSizeId },
      },
    } = yield select(state => state.form);

    mixpanel.track('Create screen via connecting playlist', { name, screenSizeId });

    const user = storage.getUser();

    // check if playlist exist before create screen with that playlist (extract to stand alone saga)
    const { url: playlistUrl, method: playlistMethod } = apiRoutes.getPlaylist;
    yield call(fetch, playlistUrl(user.userId, playlistId), playlistMethod);
    // const screenType =
    const res = yield call(fetch, url(user.userId), method, { name, screenSizeId, ownerId: user.userId });
    const getScreens = yield select(state => state.screens.screens);
    yield put(setScreens([...getScreens, res]));
    if (res) yield put(showNotification({ level: 'info', autoDismiss: 1, message: 'The screen was created!' }));
    yield put(fetchAddPlaylistToScreen(res.id, playlistId));

    if (history.location.pathname === '/screens/new') {
      const to = `/screens/${res.id}`;
      history.push(`${process.env.PUBLIC_URL}${to}`);
    }
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchCreateScreenWithEmptyPlaylistFlow(action) {
  yield put(fetchScreenStart());
  try {
    const { screenName } = action.payload;
    const screenSizeId = yield select(state => state.form.sizeForm.values.screenSizeId);
    const playlists = yield select(state => state.playlists.playlists);
    const playlistName = generateEntityName(playlists, 'Playlist');

    const respNewPlaylist = yield call(fetchRequestCreateNewPlaylist, playlistName);
    const respNewScreen = yield call(fetchRequestCreateScreen, { screenSizeId, name: screenName });

    mixpanel.track('Create screen without playlist', { screenSizeId, name: screenName });

    const screens = yield select(state => state.screens.screens);
    yield put(fetchAddPlaylistToScreen(respNewScreen.id, respNewPlaylist.id));
    yield put(setPlaylists([...playlists, respNewPlaylist]));
    yield put(setScreens([...screens, respNewScreen]));

    yield call(history.push, `/screens/${respNewScreen.id}`);

    yield put(fetchScreenFinished());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(fetchScreenFinished());
  }
}

export default [
  takeEvery(types.UPDATE_SCREEN_LATS_UPDATED_FIELD, updateScreenLastUpdatedFieldFlow),
  takeLatest(types.FETCH_SCREENS, fetchScreensFlow),
  takeLatest(types.FETCH_CREATE_SCREEN, fetchCreateScreenFlow),
  takeLatest(types.FETCH_DELETE_SCREEN, fetchDeleteScreenFlow),
  takeLatest(types.FETCH_ADD_PLAYLIST_TO_SCREEN, fetchAddPlaylistToScreenFlow),
  takeLatest(types.FETCH_ADD_PLAYLIST_TO_SCREEN, fetchAddPlaylistToScreenFlow),
  takeLatest(types.FETCH_REMOVE_PLAYLIST_FROM_SCREEN, fetchRemovePlaylistFromScreenFlow),
  takeLatest(types.FETCH_ADD_PLAYLIST_TO_NOT_EXIST_SCREEN, fetchAddPlaylistToNotExistScreenFlow),
  takeLatest(types.FETCH_SCREEN_UPDATE, fetchScreenUpdateFlow),
  takeLatest(types.FETCH_REMOVE_AND_SELECT_PLAYLIST_TO_SCREEN, fetchRemoveAndSetPlaylistToScreenFlow),
  takeLatest(types.FETCH_UPDATE_SCREEN_PLAYLISTS, fetchUpdateScreenPlaylistsFlow),
  takeLatest(types.FETCH_CREATE_SCREEN_WITH_RELATION_OF_PLAYLIST, fetchCreateScreenWithRelationOfPlaylistFlow),
  takeLatest(types.FETCH_CREATE_SCREEN_WITH_EMPTY_PLAYLIST, fetchCreateScreenWithEmptyPlaylistFlow),
];
