import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import * as types from './types';
import * as actions from './actions';
import { storage, fetch, apiRoutes, sentryReport, getErrorMessage } from '../../utils';
import { showNotification } from '../app/actions';
import { screensActions } from '../screens';
import { contentActions } from '../content';

const { setScreenSizes } = actions;
const { setScreens, fetchScreenUpdate } = screensActions;
const { setContents } = contentActions;

function* fetchRequestUpdateContentData(data, contentId) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.updateContentFk;
  const response = yield call(fetch, url(user.userId, contentId), method, data);
  return response;
}

function* fetchCreateScreenSizesFlow(action) {
  try {
    const { screenSize } = action.payload;
    const user = storage.getUser();
    const { url, method } = apiRoutes.createScreenSize;
    const res = yield call(fetch, url(user.userId), method, { ...screenSize, default: false, ownerId: user.userId });
    const getAllScreenSizes = yield select(state => state.screenSizes.screenSizes);
    yield put(setScreenSizes([...getAllScreenSizes, res]));

    // change redux form programmatically explicitly specify the created screenSizeId
    const getSizeFormEdit = yield select(state => state.form.sizeFormEdit);
    const defineForm = getSizeFormEdit ? 'sizeFormEdit' : 'sizeForm';
    yield put(change(defineForm, 'screenSizeId', res.id));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

function* fetchRemoveScreenSizesFlow(action) {
  try {
    const { screenSizeId } = action.payload;
    const user = storage.getUser();
    const { url, method } = apiRoutes.deleteScreenSize;
    yield call(fetch, url(user.userId, screenSizeId), method);
    // set new screenSizes in store
    const getAllScreenSizes = yield select(state => state.screenSizes.screenSizes);
    const removeScreenSize = getAllScreenSizes.filter(ss => ss.id !== screenSizeId);
    yield put(setScreenSizes(removeScreenSize));

    // ******* START REPLACE SCREEN SIZE OF SCREENS (REDUX/REMOTE) *******
    // replacement of matched occurrences
    const [defaultScreenSizeOne, defaultScreenSizeTwo] = getAllScreenSizes.filter(screenSize => screenSize.default);
    const defaultScreenSize =
      defaultScreenSizeOne.resolutionWidth === 1080 && defaultScreenSizeOne.resolutionHeight === 1920
        ? defaultScreenSizeOne
        : defaultScreenSizeTwo;

    const getAllScreens = yield select(state => state.screens.screens);

    const updateSizeOfScreens = yield all(
      getAllScreens.map(sc => {
        if (sc.screenSizeId === screenSizeId) {
          return {
            ...sc,
            screenSizeId: defaultScreenSize.id,
          };
        }
        return sc;
      }),
    );
    yield put(setScreens(updateSizeOfScreens));

    // change redux form programmatically for will be selected screenSize id
    const getSizeFormEdit = yield select(state => state.form.sizeFormEdit);
    const getSizeFrom = yield select(state => state.form.sizeForm);
    const getExistForm = getSizeFormEdit || getSizeFrom;
    const {
      values: { screenSizeId: selectedScreenSize },
    } = getExistForm;
    const findCurrentScreenSize = removeScreenSize.find(scs => scs.id === selectedScreenSize);
    yield put(
      change(
        getSizeFormEdit ? 'sizeFormEdit' : 'sizeForm',
        'screenSizeId',
        findCurrentScreenSize ? selectedScreenSize : defaultScreenSize.id,
      ),
    );

    // request on server change relation between screen and screenSizes
    const getScreensUsedRemoveScreenSizeId = getAllScreens.filter(screen => screen.screenSizeId === screenSizeId);
    yield all(
      getScreensUsedRemoveScreenSizeId.map(sc => put(fetchScreenUpdate(sc.id, { screenSizeId: defaultScreenSize.id }))),
    );
    // ******* END REPLACE SCREEN SIZE OF SCREENS (REDUX/REMOTE) *******

    // ******* START REPLACE SCREEN SIZE OF CONTENT IF IT NOT EXIST (REDUX/REMOTE) *******
    const getContents = yield select(state => state.content.contents);
    // replace screenSize of content in redux store
    const replaceRemovedScreenSizeOnDefault = getContents.map(content =>
      content.screenSizeId === screenSizeId ? { ...content, screenSizeId: defaultScreenSize.id } : content,
    );
    yield put(setContents(replaceRemovedScreenSizeOnDefault));
    // replace screenSize of content in back end
    const getContentUsedRemoveScreenSizeId = getContents.filter(content => content.screenSizeId === screenSizeId);
    yield all(
      getContentUsedRemoveScreenSizeId.map(content =>
        call(fetchRequestUpdateContentData, { screenSizeId: defaultScreenSize.id }, content.id),
      ),
    );
    // ******* END REPLACE SCREEN SIZE OF CONTENT IF IT NOT EXIST (REDUX/REMOTE) *******
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
  }
}

export default [
  takeLatest(types.FETCH_CREATE_SCREEN_SIZES, fetchCreateScreenSizesFlow),
  takeLatest(types.FETCH_REMOVE_SCREEN_SIZES, fetchRemoveScreenSizesFlow),
];
