import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PlayListView from "../components/PlayListsView";
import {
  playlistsActions,
  playlistSelectors,
} from "../../../../state/ducks/playlists";
import { screensActions } from "../../../../state/ducks/screens";
import { contentActions } from "../../../../state/ducks/content";
import { appActions } from "../../../../state/ducks/app";

const { setPlaylistsContents } = contentActions;
const { fetchRemovePlaylistToScreen } = screensActions;
const {
  setShowConfirmModalPlaylist,
  setShowConfirmModalPlaylistScreens,
  setShowConfirmDuplicateModal,
} = appActions;
const { getSortedPlaylist } = playlistSelectors;
const { createPlaylist } = playlistsActions;

class PlayListViewContainer extends React.Component {
  deletePlaylist = (playlistId) => {
    const { showConfirmModal, showConfirmModalPlaylist, screens } = this.props;
    // const { setShowConfirmModalPlaylist, setShowConfirmModalPlaylistScreens, screens } = this.props;
    const getScreenUsedPlaylist = screens.filter(
      (screen) =>
        screen.playlists &&
        screen.playlists.find((playlist) => playlist.id === playlistId)
    );
    const getArrOfScreenNames = getScreenUsedPlaylist.reduce(
      (accumulate, curr) => [...accumulate, curr.name],
      []
    );
    const stringOfScreenNames = getArrOfScreenNames.join(", ");
    if (getScreenUsedPlaylist.length !== 0) {
      showConfirmModalPlaylist(true, {
        screenNames: stringOfScreenNames,
        fetchAction: "fetchDeletePlaylist",
        id: playlistId,
      });
    } else {
      showConfirmModal(true, {
        title: "Are you sure you want to delete this playlist?",
        fetchAction: "fetchDeletePlaylist",
        id: playlistId,
      });
    }
  };

  duplicatePlaylist = (playlist) => {
    const { showConfirmDuplicateModal } = this.props;
    showConfirmDuplicateModal(true, {
      title: `Are you sure you want to duplicate this playlist: ${playlist.name}?`,
      fetchAction: "duplicatePlaylist",
      payload: playlist,
    });
  };

  render() {
    const {
      location: { pathname },
      sortedPlaylist,
    } = this.props;

    return (
      <PlayListView
        sortedPlaylist={sortedPlaylist}
        currentLocation={pathname}
        deletePlaylist={this.deletePlaylist}
        duplicatePlaylist={this.duplicatePlaylist}
        {...this.props}
      />
    );
  }
}

PlayListViewContainer.propTypes = {
  sortedPlaylist: PropTypes.array,
  showPlaylistFormDetail: PropTypes.bool,
  fetchUpdatePlaylist: PropTypes.func,
  fetchPlaylistContents: PropTypes.func,
  setShowConfirmModalPlaylist: PropTypes.func,
};

const mapStateToProps = (state) => ({
  sortedPlaylist: getSortedPlaylist(state),
  screens: state.screens.screens,
  confirmStatus: state.app.confirmStatus,
});

const mapDispatchToProps = {
  createPlaylist,
  setPlaylistsContents,
  fetchRemovePlaylistToScreen,
  showConfirmModal: setShowConfirmModalPlaylistScreens,
  showConfirmModalPlaylist: setShowConfirmModalPlaylist,
  showConfirmDuplicateModal: setShowConfirmDuplicateModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayListViewContainer);
