import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './SometimesModal.module.scss';
import Modal from '../../../../Modal';
import Icons from '../../../Icons';

function SometimesModal({ isOpen, children, onClose }) {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      modalClassName={styles.modal}
      containerClassName={cn(styles.modalContainer, !isOpen && styles.modalContainerIsHidden)}
    >
      <div className={styles.close} onClick={onClose}>
        <Icons icon="closeDark" widthIcons={24} />
      </div>
      <h2 className={styles.title}>Show During Certain Times Only</h2>
      {children}
    </Modal>
  );
}

SometimesModal.propTypes = {
  children: PropTypes.element,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SometimesModal;
