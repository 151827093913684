import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./OpenInPlayerBtn.module.scss";
import Icons from "../Icons";

function OpenInPlayerBtn({ className, size, ...rest }) {
  return (
    <button
      className={cn(styles.container, className && className)}
      style={{ width: `${size}px`, height: `${size}px` }}
      {...rest}
    >
      <Icons icon="openInNewTab" widthIcons={size} />
    </button>
  );
}

OpenInPlayerBtn.defaultProps = {
  size: 24,
  className: null,
};

OpenInPlayerBtn.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default OpenInPlayerBtn;
