// async types
export const FETCH_PLAYLIST_START = 'playlists/FETCH_PLAYLIST_START';
export const FETCH_PLAYLIST_END = 'playlists/FETCH_PLAYLIST_END';
export const FETCH_PLAYLISTS = 'playlists/FETCH_PLAYLISTS';

export const CREATE_PLAYLIST_START = 'playlists/CREATE_PLAYLIST_START';
export const CREATE_PLAYLIST_END = 'playlists/CREATE_PLAYLIST_END';
export const CREATE_PLAYLIST = 'playlists/CREATE_PLAYLIST';
export const DUPLICATE_PLAYLIST = 'playlists/DUPLICATE_PLAYLIST';

export const CREATE_PLAYLIST_AND_CONTENT_GROUP = 'playlists/CREATE_PLAYLIST_AND_CONTENT_GROUP';
export const CREATE_PLAYLIST_AND_ADD_CONTENT_GROUP = 'playlists/CREATE_PLAYLIST_AND_ADD_CONTENT_GROUP';

export const UPDATE_PLAYLIST_START = 'playlists/UPDATE_PLAYLIST_START';
export const UPDATE_PLAYLIST_END = 'playlists/UPDATE_PLAYLIST_END';
export const UPDATE_PLAYLIST = 'playlists/UPDATE_PLAYLIST';

export const FETCH_DELETE_PLAYLIST = 'playlists/FETCH_DELETE_PLAYLIST';

export const FETCH_ADD_CONTENT_TO_PLAYLIST = 'screens/FETCH_ADD_CONTENT_TO_PLAYLIST';

export const FETCH_REMOVE_CONTENT_GROUP_FROM_PLAYLIST = 'playlists/FETCH_REMOVE_CONTENT_GROUP_FROM_PLAYLIST';
export const FETCH_REMOVE_CONTENT_GROUP_FROM_PLAYLIST_END = 'playlists/FETCH_REMOVE_CONTENT_GROUP_FROM_PLAYLIST_END';

export const FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_META = 'playlists/FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_META';
export const FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_META_END = 'playlists/FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_META_END';

export const FETCH_MULTIPLE_REMOVE_CONTENT_GROUP_FROM_PLAYLIST =
  'playlists/FETCH_MULTIPLE_REMOVE_CONTENT_GROUP_FROM_PLAYLIST';

export const SET_PLAYLISTS = 'playlists/SET_PLAYLISTS';

export const CONTENT_REMOVAL_START = 'screens/CONTENT_REMOVAL_START';
export const CONTENT_REMOVAL_END = 'screens/CONTENT_REMOVAL_END';

export const SET_CURRENT_PLAYLIST = 'playlists/SET_CURRENT_PLAYLIST';
export const SET_CURRENT_PLAYLIST_META = 'playlists/SET_CURRENT_PLAYLIST_META';
export const SET_CURRENT_PLAYLIST_CONTENT = 'playlists/SET_CURRENT_PLAYLIST_CONTENT';
