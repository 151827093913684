import React, { Component } from 'react';
import styles from './DurationInputField.module.scss';

export default class DurationInputField extends Component {
  constructor() {
    super();
    this.textInput = null;
    this.state = {
      focused: false,
    };
    this.setTextInputRef = element => {
      this.textInput = element;
    };
  }

  onClick = () => {
    this.setState({ focused: true }, () => {
      if (this.textInput) this.textInput.focus();
    });
  };

  onBlur = () => {
    this.setState({ focused: false }, () => {
      if (this.textInput) this.textInput.blur();
    });
  };

  render() {
    const {
      input,
      placeholder,
      type,
      unit,
      className,
      meta: { touched, error },
    } = this.props;
    const { focused } = this.state;
    const value = input.value ? input.value : 0;

    return (
      <div>
        {focused && (
          <input
            ref={this.setTextInputRef}
            {...input}
            onBlur={this.onBlur}
            placeholder={placeholder}
            type={type}
            className={styles.inputOnFocus}
            max={60}
          />
        )}
        {!focused && (
          <span className={styles.inputNotFocus} onClick={this.onClick}>
            {`${value} ${unit}`}
          </span>
        )}
        {touched &&
          (error && (
            <span style={{ color: 'red', position: 'absolute', bottom: '-16px', marginLeft: '-80px' }}>{error}</span>
          ))}
      </div>
    );
  }
}
