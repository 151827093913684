import * as types from './types';

const createAction = type => payload => ({ type, payload });

export const selectImage = createAction(types.SELECT_IMAGE);
export const resetSelectedImages = createAction(types.RESET_SELECTED_IMAGES);

export const uploadNewImages = createAction(types.UPLOAD_NEW_IMAGES);
export const uploadNewImagesRequest = createAction(types.UPLOAD_NEW_IMAGES_REQUEST);
export const uploadNewImagesSuccess = createAction(types.UPLOAD_NEW_IMAGES_SUCCESS);
export const uploadNewImagesFailure = createAction(types.UPLOAD_NEW_IMAGES_FAILURE);
