import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { SortableContainer } from 'react-sortable-hoc';
import styles from './PlaylistContents.module.scss';
import PlaylistContent from './PlaylistContent';
import ProgressItems from '../../../../common/components/ProgressItems';
import NestedPlaylist from './NestedPlaylist/NestedPlaylist';
import { templateTypes } from '../../../../../constants';

class PlaylistContents extends React.Component {
  renderNestedPlaylist = (item, idx) => {
    const { itemStyle, onEditItem, onDeleteItem, contentRemovalList, deep, playlistType } = this.props;

    return (
      <NestedPlaylist
        deep={deep}
        key={`playlist${item.id}`}
        index={idx}
        style={itemStyle}
        onEditItem={onEditItem}
        isInRemoving={!!contentRemovalList.find(ct => ct === item.id)}
        onDelete={e => {
          e.preventDefault();
          e.stopPropagation();
          onDeleteItem(item.id);
        }}
        playlistType={playlistType}
        {...item}
      />
    );
  };

  renderContent = (item, idx) => {
    const { playlistType, onEditItem, onDeleteItem, contentRemovalList, itemStyle } = this.props;

    return (
      <PlaylistContent
        key={`contentGroup${item.id}`}
        index={idx}
        // eslint-disable-next-line
        playtime={item._playtime}
        playlistType={playlistType}
        isInRemoving={!!contentRemovalList.find(ct => ct === item.id)}
        onDelete={onDeleteItem}
        onEdit={onEditItem}
        style={itemStyle}
        content={item}
      />
    );
  };

  renderItem = (item, idx) => {
    if (item.type === 'playlist') {
      return this.renderNestedPlaylist(item, idx);
    }

    return this.renderContent(item, idx);
  };

  render() {
    const { bordered, items, rootRef, className, progressItems, style, itemStyle } = this.props;

    const isEmpty = progressItems.length === 0 && items.length === 0;
    if (isEmpty) {
      return null;
    }

    return (
      <div
        ref={rootRef}
        className={cn(styles.container, bordered && styles.containerBordered, className && className)}
        style={style}
      >
        <ProgressItems itemStyle={itemStyle} items={progressItems} />
        {items.map(this.renderItem)}
      </div>
    );
  }
}

PlaylistContents.defaultProps = {
  bordered: true,
  editable: true,
  style: {},
  itemStyle: {},
};

PlaylistContents.propTypes = {
  bordered: PropTypes.bool,
  editable: PropTypes.bool,
  rootRef: PropTypes.object,
  className: PropTypes.string,
  progressItems: PropTypes.array,
  items: PropTypes.array.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  style: PropTypes.object,
  itemStyle: PropTypes.object,
  contentRemovalList: PropTypes.array.isRequired,
  deep: PropTypes.number,
  playlistType: PropTypes.oneOf([templateTypes.DEFAULT, templateTypes.THEATRE, templateTypes.SPLIT]).isRequired,
};

export default SortableContainer(PlaylistContents);
