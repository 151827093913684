import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import styles from './AddScreenForm.module.scss';
import EntityNameField from '../../../../common/components/UI/EntityNameField/EntityNameField';
import { validation } from '../../../../../utils';

const required = value => (value || typeof value === 'number' ? undefined : 'Please, first add screen name');

class AddScreenForm extends React.Component {
  constructor(props) {
    super(props);
    this.inputElement = React.createRef();
  }

  handleChangeNameOfScreen = event => {
    const { createdScreenId, updateScreenItem } = this.props;
    const { value } = event.target;

    const removeExtraSpaces = value.replace(/\s+/g, ' ').trim();
    if (removeExtraSpaces.length !== 0) {
      updateScreenItem(createdScreenId, { name: removeExtraSpaces });
    }
  };

  handleChangeNameOfNewScreen = event => {
    const { createScreenWithEmptyPlaylist } = this.props;
    const { value } = event.target;

    const removeExtraSpaces = value.replace(/\s+/g, ' ').trim();
    if (removeExtraSpaces.length !== 0) {
      this.inputElement.current.getRenderedComponent().inputRef.current.blur();
      createScreenWithEmptyPlaylist(removeExtraSpaces);
    }
  };

  render() {
    if (this.props.isEditMode) {
      return null;
    }

    const { createdScreenId } = this.props;

    const onChangeName = createdScreenId ? this.handleChangeNameOfScreen : this.handleChangeNameOfNewScreen;

    return (
      <form className={styles.addScreenFormStyle}>
        <Field
          ref={this.inputElement}
          withRef="true"
          isFocusAfterMounted={true}
          name="name"
          id="screenName"
          onBlur={onChangeName}
          component={EntityNameField}
          type="text"
          placeholder="Enter screen name"
          validate={[required, validation.isSpaceChar]}
          normalize={validation.normalizeExtraSpaces}
        />
      </form>
    );
  }
}

AddScreenForm.propTypes = {
  isEditMode: PropTypes.bool,
  toggle: PropTypes.func,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  createScreenWithEmptyPlaylist: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  screens: state.screens.screens,
  addScreenPlaylistId: state.screens.addScreenPlaylistId,
});

const AddScreenFormWrapper = reduxForm({
  form: 'addScreens',
})(AddScreenForm);

export default connect(mapStateToProps)(AddScreenFormWrapper);
