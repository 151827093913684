import { createSelector } from 'reselect';

export const getSelectedImages = createSelector(
  state => state.insertImage.selectedImages,
  selectedImages => selectedImages,
);

export const getIsLoading = state => state.insertImage.isLoading;

export const noop = () => {};
