import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { contentActions } from '../../../../state/ducks/content';
import { contentGroupActions } from '../../../../state/ducks/contentGroup';

import AddFilesBtn from '../UI/AddFilesBtn';

class ConnectedAddFilesBtn extends React.Component {
  handleClick = () => {
    const { setMediaLibraryModal, setContentTypeFilter, modalData } = this.props;
    setMediaLibraryModal(true, modalData);
    setContentTypeFilter('multiply');
  };

  render() {
    return <AddFilesBtn onClick={this.handleClick} {...this.props} />;
  }
}

/**
 * modalData  = {
 *  id: null,
 *  formData: formValues,
 * }
 */
ConnectedAddFilesBtn.propTypes = {
  ...AddFilesBtn.propTypes,
  modalData: PropTypes.object.isRequired,
};

const mapDispatchToProps = { ...contentActions, ...contentGroupActions };

export default connect(
  null,
  mapDispatchToProps,
)(ConnectedAddFilesBtn);
