import React from 'react';
import PropTypes from 'prop-types';
import styles from './SessionSection.module.scss';
import SessionForm from '../SessionForm';
import DescriptionField from '../DescriptionField';

const SessionSection = ({ programmes, change, description, dataFeedList }) => (
  <div className={styles.container}>
    <SessionForm programmes={programmes} dataFeedList={dataFeedList} />
    <DescriptionField change={change} description={description} />
  </div>
);

SessionSection.propTypes = {
  programmes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      ownerId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  change: PropTypes.func.isRequired,
  description: PropTypes.string,
  dataFeedList: PropTypes.array,
};

export default SessionSection;
