import React, { Component } from 'react';
import moment from 'moment';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './SessionTheatreItem.module.scss';
import SessionDayList from '../SessionDayList';
import DotsButton from '../../../../common/components/UI/DotsButton';
import MenuList from '../../../../common/components/UI/MenuList/MenuList';
import makeToggleable from '../../../../hocs/makeToggleable';

class SessionTheatreItem extends Component {
  render() {
    const {
      toggle,
      close,
      isOpen,
      rootRef,
      index,
      item,
      handleRemoveSession,
      handleEditSession,
      sessionRemovalList,
      handleAddSessionToDate,
      handleRemoveSessionByDate,
      canEdit,
    } = this.props;

    return (
      <div key={item.planingDay} className={styles.sessionDayItem}>
        <div className={styles.title} ref={rootRef}>
          Day {index + 1}, {moment(item.planingDay).format('dddd Do MMMM')}
          {canEdit && (
            <DotsButton
              size={16}
              className={styles.dotsButton}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                toggle();
              }}
            />
          )}
          <MenuList className={cn(styles.menu, isOpen && styles.menuIsOpen)}>
            <MenuList.Item
              title="Add session"
              color="#000000"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleAddSessionToDate(item.planingDay);
                close();
              }}
            />
            <MenuList.Item
              title="Delete"
              color="#EB5757"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleRemoveSessionByDate(item.planingDay);
                close();
              }}
            />
          </MenuList>
        </div>

        <SessionDayList
          sessionRemovalList={sessionRemovalList}
          handleRemoveSession={handleRemoveSession}
          handleEditSession={handleEditSession}
          listSessionDays={item.sessionListMatches}
          canEdit={canEdit}
        />
      </div>
    );
  }
}

SessionTheatreItem.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  rootRef: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    planingDay: PropTypes.string.isRequired,
    sessionListMatches: PropTypes.arrayOf(
      PropTypes.shape({
        day: PropTypes.string.isRequired,
        description: PropTypes.string,
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        programmeId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  handleRemoveSession: PropTypes.func.isRequired,
  handleEditSession: PropTypes.func.isRequired,
  sessionRemovalList: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleAddSessionToDate: PropTypes.func.isRequired,
  handleRemoveSessionByDate: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default makeToggleable(SessionTheatreItem);
