import React from 'react';
import PropTypes from 'prop-types';
import PlaylistTemplateCard from '../../../PlaylistTemplateCard';
import PlaylistNameForm from '../../../PlaylistNameForm/PlaylistNameForm';
import AddFilesBtn from '../../../../../../common/components/UI/AddFilesBtn/AddFilesBtn';
import DropZoneContainer from '../../../../../../common/components/UI/DropZoneContainer';
import ContentSettingsModal from '../../../../../../common/components/UI/ModalAddContent';
import PlaylistContents from '../../../../../Screens/components/PlaylistContents';

import DropZone from './DropZone';
import styles from './Default.module.scss';

class Default extends React.Component {
  state = {
    isOpenContentSettingsModal: false,
    contentSettingsModalValues: {},
  };

  handleOpenContentSettingsModal = contentGroup => {
    // TODO: create redux-actions for this
    this.setState({
      isOpenContentSettingsModal: true,
      contentSettingsModalValues: contentGroup,
    });

    this.props.setContentFormId(contentGroup.type);
  };

  handleCloseContentSettingsModal = () => {
    this.setState({ isOpenContentSettingsModal: false });
  };

  render() {
    const { onChangeName, currentPlaylist, setMediaLibraryModal, setContentTypeFilter } = this.props;
    const playlistId = currentPlaylist.id;

    return (
      <>
        <DropZone selectedPlaylistId={playlistId}>
          {({ getRootProps, isDragActive, isDragReject }) => (
            <DropZoneContainer
              className={styles.container}
              isDragReject={isDragReject}
              isDragActive={isDragActive}
              getRootProps={getRootProps}
            >
              <PlaylistNameForm
                {...{ currentPlaylist, onChangeName, playlistId }}
                initialValues={{ name: currentPlaylist.name }}
              />
              <PlaylistTemplateCard type={currentPlaylist.type} style={{ marginBottom: '24px' }} isLocked />
              <PlaylistContents
                style={{ marginBottom: 24 }}
                playlistId={playlistId}
                onEditItem={this.handleOpenContentSettingsModal}
              />
              <AddFilesBtn
                onClick={() => {
                  setMediaLibraryModal(true, {
                    id: playlistId,
                  });
                  setContentTypeFilter('multiply');
                }}
              />
            </DropZoneContainer>
          )}
        </DropZone>
        <ContentSettingsModal
          // TODO: rename props
          modalToggler={this.handleCloseContentSettingsModal}
          modalScreens={this.state.isOpenContentSettingsModal}
          values={this.state.contentSettingsModalValues}
        />
      </>
    );
  }
}

Default.propTypes = {
  initialize: PropTypes.func,
  currentPlaylist: PropTypes.object,
  playlistsContents: PropTypes.array,
  isLoadingContent: PropTypes.bool,
  playlistId: PropTypes.number,
  onChangeName: PropTypes.func,
  setContentFormId: PropTypes.func.isRequired,
  isPlaylistEmpty: PropTypes.func,
};

export default Default;
