import { all } from 'redux-saga/effects';
import { reducer as formReducer } from 'redux-form';
import { authSagas, authReducer } from './auth';
import { appSagas, appReducer } from './app';
import { wsSagas, wsReducer } from './websockets';
import { screensReducer, screensSagas } from './screens';
import { playlistsSagas, playlistsReducer } from './playlists';
import { contentGroupSagas, contentGroupReducer } from './contentGroup';
import { contentSagas, contentReducer } from './content';
import { screenSizesSagas, screenSizesReducer } from './screenSizes';
import { contentMetaSagas, contentMetaReducer } from './contentMeta';
import { insertImageSagas, insertImageReducer } from './insertImage';
import { programmesSagas, programmesReducer } from './programmes';
import { sessionsSagas, sessionsReducer } from './sessions';
import { speakersSagas, speakersReducer } from './speakers';
import { dataFeedsSagas, dataFeedsReducer } from './dataFeeds';
import { confirmModalReducer } from './confirmModal';

export function* rootSaga() {
  yield all([
    ...wsSagas,
    ...appSagas,
    ...authSagas,
    ...playlistsSagas,
    ...screensSagas,
    ...contentSagas,
    ...screenSizesSagas,
    ...contentGroupSagas,
    ...contentMetaSagas,
    ...insertImageSagas,
    ...programmesSagas,
    ...sessionsSagas,
    ...speakersSagas,
    ...dataFeedsSagas,
  ]);
}

export const reducers = {
  app: appReducer,
  auth: authReducer,
  form: formReducer,
  screens: screensReducer,
  playlists: playlistsReducer,
  content: contentReducer,
  screenSizes: screenSizesReducer,
  contentGroup: contentGroupReducer,
  contentMeta: contentMetaReducer,
  programmes: programmesReducer,
  sessions: sessionsReducer,
  speakers: speakersReducer,
  ws: wsReducer,
  insertImage: insertImageReducer,
  dataFeeds: dataFeedsReducer,
  confirmModal: confirmModalReducer,
};
