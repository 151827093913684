import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import SpeakerItem from '../SpeakerItem';

function SpeakersList({ items, onRemove, onEdit, isOpen }) {
  return (
    <div>
      {items.map((value, index) => (
        <SpeakerItem
          disabled={isOpen}
          isOpen={isOpen}
          key={index}
          onRemove={() => onRemove(value.order)}
          onEdit={() => onEdit(value.order)}
          index={index}
          value={value}
        />
      ))}
    </div>
  );
}

SpeakersList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      job: PropTypes.string,
      company: PropTypes.string,
      order: PropTypes.number,
      ownerId: PropTypes.number,
      sessionId: PropTypes.number,
      id: PropTypes.number,
    }),
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SortableContainer(SpeakersList);
