import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  change,
} from "redux-form";
import { FormGroup, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import cn from "classnames";
import ReactTooltip from "react-tooltip";
import styles from "./AddContentForm.module.scss";
import DurationInputField from "../../DurationInputField";
import RenderDisplayTimeFields from "../../RenderDisplayTimeFields";
import { contentActions } from "../../../../../../state/ducks/content";
import { appActions } from "../../../../../../state/ducks/app";
import { contentGroupActions } from "../../../../../../state/ducks/contentGroup";
import {
  getScreenDimention,
  createHtmlFile,
} from "../../../../../../state/utils/create-file-helper";
import {
  positiveNumber,
  required,
  maxLength2,
} from "../../../../../../state/utils/validation";
import Icons from "../../Icons";
import AddContentChild from "../AddContentChild";
import SvgIcoOrientation from "../../../../../../assets/svg/SvgIcoOrientation/SvgIcoOrientation";
import SvgIcoNotDefault from "../../../../../../assets/svg/SvgIcoNotDefault/SvgIcoNotDefault";
import SvgIcoSquare from "../../../../../../assets/svg/SvgIcoSquare/SvgIcoSquare";
import ContentIcons from "../../ContentIcons";
import UploadSpinner from "../../UploadSpinner";
import ButtonDigital from "../../ButtonDigital";
import {
  validation,
  normalizeEditorContentStyleAsString,
} from "../../../../../../utils";
import FroalaEditor from "../../CommonMediaLibrary/components/FroalaEditor";
import EntityNameField from "../../EntityNameField/EntityNameField";
import ContentItem from "./ContentItem/ContentItem";
import ContentItemProgress from "./ContentItemProgress/ContentItemProgress";
import SometimesModal from "./SometimesModal";

import validate from "./validate";
import Modal from "../../../Modal";
import HtmlPreview from "./HtmlPreview";
import Switch from "../../Switch/Switch";

const { setPreviewModal } = appActions;

const durationValidation = [maxLength2, positiveNumber, required];

const {
  setUploadedContent,
  fetchReplaceContent,
  fetchUploadAndReplaceUrlForContent,
} = contentActions;
const { fetchRemoveContentFromContentGroup, fetchAbortUploadFile } =
  contentGroupActions;

function convertDimention(screenSizes, screenSizeId) {
  const getCurrScreenSize = screenSizes.find(
    (item) => item.id === screenSizeId
  );
  return (
    getCurrScreenSize &&
    getScreenDimention(
      getCurrScreenSize.resolutionWidth,
      getCurrScreenSize.resolutionHeight
    )
  );
}

const renderField = ({
  input,
  label,
  type,
  className,
  id,
  meta: { touched, error, warning },
}) => (
  <div className={styles.inputStyle}>
    <input
      {...input}
      placeholder={label}
      id={id}
      type={type}
      className={className}
      autoComplete="off"
    />

    {touched &&
      ((error && (
        <span style={{ bottom: "-18px", color: "red", position: "absolute" }}>
          {error}
        </span>
      )) ||
        (warning && (
          <span style={{ bottom: "-18px", color: "red", position: "absolute" }}>
            {warning}!
          </span>
        )))}
  </div>
);

const getIconType = (type) => {
  switch (type) {
    case "image":
      return <Icons icon="imageIconButton" widthIcon={24} />;
    case "video":
      return <Icons icon="videoButton" width={24} />;
    case "html":
      return <Icons icon="dynamicSlideButton" width={24} />;
    default:
      return <Icons icon="dynamicSlideButton" width={24} />;
  }
};

const isEmpty = (value) => (value ? value === "" : true);
const atLeastOneFieldIsFilled = (field) =>
  !isEmpty(field.start) || !isEmpty(field.end) || !isEmpty(field.date);

class AddContentForm extends React.Component {
  state = {
    selectedTab: "always",
    urlFieldToggler: false,
    checkedContentId: null,
    htmlContent: "",
    htmlFileName: "",
    newField: {
      start: "",
      end: "",
      date: "",
    },
    isLoading: false,
    isPreparingDownload: false,
  };

  handleToggleSometimes = () => {
    const { selectedTab } = this.state;
    const isOpenSometimes = selectedTab === "sometimes";

    if (isOpenSometimes) {
      this.handleCloseSometimes();
    } else {
      this.handleOpenSometimes();
    }
  };

  handleOpenSometimes = () => this.setState({ selectedTab: "sometimes" });

  handleCloseSometimes = () =>
    this.setState((state, { playTime }) => ({
      selectedTab: playTime.length <= 1 ? "always" : "sometimes",
    }));

  getOrientation = (sizeId, screenSizes) => {
    const currScreenSize = screenSizes.find((item) => item.id === sizeId);
    if (currScreenSize) {
      if (currScreenSize.default) {
        return (
          <SvgIcoOrientation
            orientation={currScreenSize.name}
            setColor={"#bcbebf"}
            iconSize={24}
          />
        );
      }
      if (
        !currScreenSize.default &&
        currScreenSize.resolutionWidth === currScreenSize.resolutionHeight
      ) {
        return (
          <SvgIcoSquare
            orientation={
              currScreenSize.resolutionWidth < currScreenSize.resolutionHeight
            }
            setColor={"#bcbebf"}
            iconSize={24}
          />
        );
      }
      if (
        !currScreenSize.default &&
        currScreenSize.resolutionWidth !== currScreenSize.resolutionHeight
      ) {
        return (
          <SvgIcoNotDefault
            orientation={
              currScreenSize.resolutionWidth < currScreenSize.resolutionHeight
            }
            setColor={"#bcbebf"}
            iconSize={24}
          />
        );
      }
    }

    return null;
  };

  async componentDidMount() {
    const {
      contents,
      initialValues,
      contentFormId,
      currentContentGroup: { type },
    } = this.props;

    const { _playtime } = initialValues;
    const isHtml = type === "html";

    if (isHtml) {
      this.setState({ isLoading: true });
      const contentGroupContents = contents.filter(
        (content) => content.contentGroupId === initialValues.entityId
      );
      const { fileName: htmlFileName, content: htmlContentUrl } =
        contentGroupContents[0];

      // eslint-disable-next-line
      const htmlContent = await fetch(htmlContentUrl).then((res) => res.text());

      this.setState({
        htmlContent,
        htmlFileName,
        isLoading: false,
        checkedContentId: null,
      });
    }

    if (_playtime.length < 1) {
      this.setState({
        selectedTab: "always",
      });
    }

    if (_playtime.length >= 1) {
      this.setState({
        selectedTab: "sometimes",
      });
    }

    // this.setState({ checkedContentId: null, htmlContent: '', htmlFileName: '' });
  }

  handleSubmitHtmlContent = () => {
    const { contentFormId, initialValues, uploadAndReplaceUrlForContent } =
      this.props;
    const { checkedContentId, htmlContent, htmlFileName } = this.state;

    // make replace when placed on html content settings page
    if (contentFormId === "html") {
      const getClearName = htmlFileName.substring(
        0,
        htmlFileName.lastIndexOf(".")
      );
      // const normalizedHtmlContent = normalizeEditorContentStyleAsString(htmlContent);
      const normalizedHtmlContent = htmlContent;
      const file = createHtmlFile(normalizedHtmlContent, getClearName);
      const contentGroupId =
        initialValues.entityType === "contentGroup" && initialValues.entityId;
      // pseudo edit html content
      uploadAndReplaceUrlForContent(file, checkedContentId, contentGroupId);
      this.setState({ checkedContentId: null, htmlContent });
    }
  };

  handleChangeTab = (e) => {
    e.preventDefault();
    const { value } = e.target;
    this.setState({
      selectedTab: value,
    });
  };

  urlFieldToggler = () => {
    this.setState({ urlFieldToggler: !this.state.urlFieldToggler });
  };

  removeContentFromContentGroup = (contentGroupId, contentId) => {
    this.props.fetchRemoveContentFromContentGroup(contentGroupId, contentId);
  };

  handleOnReplaceButton = (e, fileData) => {
    e.preventDefault();
    const { id: contentId, screenSizeId } = fileData;
    const { replaceContent } = this.props;
    const file = e.target.files[0];

    replaceContent(contentId, file, screenSizeId);
  };

  handleOnChangeAceEditor = (newValue) => {
    this.setState({
      htmlContent: newValue,
    });
  };

  handleSelectHtmlContent = async (contentId) => {
    const { contentFormId, contents } = this.props;

    if (contentFormId === "html") {
      // checkedContentId;
      const contentEntity = contents.find(
        (content) => content.id === contentId
      );

      // eslint-disable-next-line
      const html = await fetch(contentEntity.content).then((res) => res.text()); // html as text

      this.setState({
        checkedContentId: contentEntity.id,
        htmlContent: html,
        htmlFileName: contentEntity.fileName,
      });
    }
  };

  handleCloseHtmlEdit = async () => {
    const { checkedContentId } = this.state;
    const { contents } = this.props;

    const contentEntity = contents.find(
      (content) => content.id === checkedContentId
    );
    // eslint-disable-next-line
    const htmlContent = await fetch(contentEntity.content).then((res) =>
      res.text()
    );

    this.setState({
      htmlContent,
      checkedContentId: null,
    });
  };

  renderReplaceItemSpinner = (itemWhichWillBeReplaced) => {
    if (!itemWhichWillBeReplaced) {
      return null;
    }

    const { fileProgressData } = itemWhichWillBeReplaced;
    return (
      <div className={styles.replaceItemSpinnerContainer}>
        <button
          type="button"
          className={styles.cancelBtn}
          onClick={(e) => {
            e.preventDefault();
            this.props.abortUploadFile(fileProgressData.uniqueKey);
          }}
        >
          Cancel
        </button>
        <UploadSpinner />
      </div>
    );
  };

  handleShowPreviewOfContent = (event, content, screenSizeId) => {
    event.preventDefault();
    const { showPreviewModal } = this.props;
    showPreviewModal(true, { content, screenSizeId });
  };

  previewContent = (event) => {
    event.preventDefault();
    const { getCurrentValues } = this.props;
    window.open(getCurrentValues.content, "_blank");
  };

  downloadContent = async (event) => {
    this.setState({ ...this.state, isPreparingDownload: true });
    event.preventDefault();
    const { getCurrentValues } = this.props;
    const file = await fetch(getCurrentValues.content);
    const fileBlob = await file.blob();
    const fileURL = URL.createObjectURL(fileBlob);
    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.download = getCurrentValues.fileName;
    anchor.click();
    URL.revokeObjectURL(fileURL);
    this.setState({ ...this.state, isPreparingDownload: false });
  };

  handleSubmit = (values) => {
    this.props.onSubmit(values);
    this.props.modalToggler();
  };

  render() {
    const {
      valid,
      values,
      playTime,
      contents,
      screenSizes,
      modalToggler,
      initialValues,
      contentFormId,
      abortUploadFile,
      contentsProgress,
      currentContentGroup,
      defaultScreenSizeId,
    } = this.props;

    const { playlistType } = currentContentGroup;
    const {
      selectedTab,
      urlFieldToggler,
      checkedContentId,
      htmlContent,
      isPreparingDownload,
    } = this.state;

    const isOpenSometimes = selectedTab === "sometimes";
    const isSometimes = isOpenSometimes && playTime.length > 1;

    const filteredContents = contents.filter(
      (content) => content.contentGroupId === initialValues.entityId
    );
    const isHtml = currentContentGroup.type === "html";
    const isUrl = currentContentGroup.type === "url";
    const isDownloadableContent = contentFormId === 'image' || contentFormId === 'video';
    const isFullProgramme = currentContentGroup.type === "programme";

    const firstContent = filteredContents[0];

    const filteredScreenSizes = screenSizes.filter(({ id }) => {
      const isContainedInFilteredContents = !!filteredContents.find(
        (content) => content.screenSizeId === id
      );
      const isContainedInProgressContents = !!Object.values(
        contentsProgress
      ).find((cp) => cp.fileProgressData.screenSizeId === id);

      return !isContainedInFilteredContents && !isContainedInProgressContents;
    });

    const changeFormValue = (...args) =>
      this.props.dispatch(change("addContentForm", ...args));

    const hasOneContent = filteredContents.length === 1;
    const canDisplayFullScreenToggler =
      playlistType !== "default" && !isFullProgramme;

    return (
      <form
        className={styles.form}
        onSubmit={this.props.handleSubmit(this.handleSubmit)}
        style={isHtml ? { width: "415px" } : {}}
      >
        <div className={styles.header}>
          <Field
            name="name"
            type="text"
            component={EntityNameField}
            isEditable={!isFullProgramme}
            className={cn(styles.inputNameContainer, isUrl && styles.isUrl)}
            placeholder="Enter content name"
            validate={[validation.required]}
            normalize={validation.normalizeExtraSpaces}
            isNoWrap={isUrl}
          />
        </div>
        <div className={styles.contentWrapper}>
          {contentFormId === "url" && (
            <FormGroup className={styles.groupStyleRow}>
              <Label htmlFor="playListContentUrl" className="labelName">
                URL:{" "}
              </Label>
              <div className={styles.inputWrapper}>
                <Field
                  name="content"
                  id="playListContentUrl"
                  component={renderField}
                  type="text"
                  placeholder="Paste URL"
                  validate={[validation.required, validation.isUrl]}
                  className={
                    !urlFieldToggler
                      ? styles.inputFocused
                      : styles.inputNotFocused
                  }
                  onFocus={this.urlFieldToggler}
                  onBlur={this.urlFieldToggler}
                />
              </div>
            </FormGroup>
          )}
          {isFullProgramme && currentContentGroup.theatre && (
            <FormGroup className={styles.groupStyleRow}>
              <Label htmlFor="TheatreFullProgramme" className="labelName">
                Theatre:{" "}
              </Label>
              <div className={styles.inputWrapper}>
                <div style={{ fontSize: 14, color: "#000" }}>
                  {currentContentGroup.theatre}
                </div>
              </div>
            </FormGroup>
          )}
          {/*  IF we open only HTML settings START */}
          {contentFormId === "html" && checkedContentId && (
            <div>
              <div className={styles.editor}>
                <Modal
                  isOpen
                  isCloseOutside={false}
                  onClose={this.handleCloseHtmlEdit}
                >
                  <FroalaEditor
                    editMode
                    initialValues={{ name: currentContentGroup.name }}
                    screenSizes={screenSizes}
                    htmlEditorContent={htmlContent}
                    handleShowPreviewOfContent={this.handleShowPreviewOfContent}
                    handleOnChangeAceEditor={this.handleOnChangeAceEditor}
                    handleSubmitHtmlContent={this.handleSubmitHtmlContent}
                    handleCloseHtmlEdit={this.handleCloseHtmlEdit}
                    defaultScreenSizeId={defaultScreenSizeId}
                  />
                </Modal>
              </div>
            </div>
          )}
          {/* IF WE HAVE CHANGE HTML MODE HIDE THIS SECTION */}
          {checkedContentId === null && (
            <>
              {/*  IF we open only HTML settings END */}
              <FormGroup
                className={styles.groupStyleRow}
                style={{ marginBottom: "24px" }}
              >
                <Label htmlFor="duration" className="labelName">
                  Duration:
                </Label>
                <div className={styles.inputWrapperDuration}>
                  <Field
                    component={DurationInputField}
                    placeholder="15 min"
                    unit="min"
                    type="text"
                    name="durationMin"
                    validate={durationValidation}
                    normalize={validation.numberRegexp}
                  />
                </div>
                <div className={styles.inputWrapperDuration}>
                  <Field
                    placeholder="15 sec"
                    unit="sec"
                    component={DurationInputField}
                    type="text"
                    name="durationSec"
                    validate={durationValidation}
                    normalize={validation.numberRegexp}
                  />
                </div>
              </FormGroup>
              {canDisplayFullScreenToggler && (
                <FormGroup className={styles.fullscreenToggler}>
                  <label className={styles.fullscreenTogglerLabel}>
                    Full Screen:{" "}
                  </label>
                  <Field
                    name="isFullScreen"
                    type="checkbox"
                    component={Switch}
                  />
                </FormGroup>
              )}
              <button
                type="button"
                // data-tip="Schedule for certain times only"
                className={cn(
                  styles.clockBtn,
                  isSometimes && styles.clockBtnIsActive
                )}
                onClick={this.handleToggleSometimes}
              >
                {isSometimes ? (
                  <Icons icon="clockOrange" widthIcons={26} />
                ) : (
                  <Icons icon="clockDark" widthIcons={26} />
                )}
                <span>{isOpenSometimes ? "Sometimes" : "Always"}</span>
                {/* <ReactTooltip className="custom-tooltip" type="light" /> */}
              </button>
              {isDownloadableContent && (<div className={styles.previewContent}>
                <button
                  onClick={this.previewContent}
                  type="button"
                  className={styles.previewButton}
                >
                  <Icons icon="eyeOrange" widthIcons={24} />
                </button>
                <button onClick={this.downloadContent} type="button">
                  <Icons icon="downloadOrange" widthIcons={24} />
                </button>
                {isPreparingDownload && <UploadSpinner zIndex={9999} />}
              </div>)}
              <div
                className={cn(
                  styles.fieldArray,
                  !isOpenSometimes && styles.fieldArrayIsHidden
                )}
              >
                <FieldArray
                  name="_playtime"
                  component={RenderDisplayTimeFields}
                  changeFormValue={changeFormValue}
                  playTime={this.props.playTime}
                  removeCallback={() => {
                    if (
                      (playTime.length <= 2 &&
                        !atLeastOneFieldIsFilled(
                          playTime[playTime.length - 1]
                        )) ||
                      playTime.length === 1
                    ) {
                      this.handleCloseSometimes();
                    }
                  }}
                />
              </div>

              <Input
                className={styles.inputStyleCursor}
                tag={Field}
                id="always"
                component="input"
                name="displayTime"
                type="radio"
                onClick={this.handleChangeTab}
                value="always"
                hidden
              />
              <Input
                className={styles.inputStyleCursor}
                tag={Field}
                id="specific_time"
                component="input"
                name="displayTime"
                type="radio"
                onClick={this.handleChangeTab}
                value="sometimes"
                hidden
              />
              {contentFormId !== "url" && !isFullProgramme && (
                <div className={styles.contentGroupSizesContainer}>
                  {isHtml && (
                    <HtmlPreview
                      isLoading={
                        this.state.isLoading ||
                        (contentsProgress &&
                          !!Object.values(contentsProgress).find(
                            ({ fileProgressData }) =>
                              fileProgressData.contentId === firstContent.id
                          ))
                      }
                      htmlContent={this.state.htmlContent}
                      screenSize={
                        screenSizes.find(
                          (ss) => ss.id === defaultScreenSizeId
                        ) ||
                        screenSizes.find((ss) => ss.resolutionHeight === 1920)
                      }
                      onEdit={() =>
                        this.handleSelectHtmlContent(firstContent.id)
                      }
                    />
                  )}
                  {!isHtml && (
                    <>
                      {filteredContents.map((item) => (
                        <ContentItem
                          key={item.id}
                          id={item.id}
                          name={item.fileName}
                          type={item.type}
                          contentGroupId={item.contentGroupId}
                          screenSize={screenSizes.find(
                            (ss) => ss.id === item.screenSizeId
                          )}
                          screenSizes={screenSizes}
                          onEdit={
                            item.type === "html"
                              ? () => this.handleSelectHtmlContent(item.id)
                              : null
                          }
                          onDelete={
                            hasOneContent
                              ? null
                              : this.removeContentFromContentGroup
                          }
                        />
                      ))}
                      {contentsProgress &&
                        _.values(contentsProgress)
                          .filter(
                            (itemProgress) =>
                              itemProgress.fileProgressData
                                .currContentGroupId &&
                              itemProgress.fileProgressData
                                .currContentGroupId === initialValues.entityId
                          )
                          .map((content) => {
                            if (content) {
                              const { total, loaded, fileProgressData } =
                                content;
                              const progressValue = (loaded * 100) / total;
                              if (
                                content.fileProgressData.flagField !== "content"
                              ) {
                                return null;
                              }
                              return (
                                <ContentItemProgress
                                  key={fileProgressData.uniqueKey}
                                  name={fileProgressData.name}
                                  type={fileProgressData.contentType}
                                  progressValue={progressValue}
                                  screenSize={screenSizes.find(
                                    (ss) =>
                                      ss.id === fileProgressData.screenSizeId
                                  )}
                                  screenSizes={screenSizes}
                                  onAbortUpload={(e) => {
                                    e.preventDefault();
                                    abortUploadFile(fileProgressData.uniqueKey);
                                  }}
                                />
                              );
                            }
                            return true;
                          })}
                      <div className={styles.childDropZone}>
                        <AddContentChild
                          screenSizes={filteredScreenSizes}
                          entityId={initialValues.entityId}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>

        {/* HIDE THIS SECTION TOO IF EDIT HTML MODE  */}
        {checkedContentId === null && (
          <div className={styles.formGroupWrapper}>
            <button
              type="button"
              id={contentFormId}
              onClick={modalToggler}
              className={styles.buttonStyleCancel}
              color="primary float-left"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={styles.buttonStyle}
              color="secondary float-left"
            >
              Save
            </button>
          </div>
        )}
      </form>
    );
  }
}

AddContentForm.propTypes = {
  modalToggler: PropTypes.func,
  className: PropTypes.func,
  handleSubmit: PropTypes.func,
  setUploadedContent: PropTypes.func,
  currentPlaylistId: PropTypes.number,
  contentFormId: PropTypes.string,
  initialValues: PropTypes.object,
  orientationSize: PropTypes.number,
  getCurrentValues: PropTypes.object,
  fetchRemoveContentFromContentGroup: PropTypes.func,
  uploadAndReplaceUrlForContent: PropTypes.func.isRequired,
  replaceContent: PropTypes.func.isRequired,
  abortUploadFile: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector("addContentForm");
  const { duration, screenSizeId } = ownProps.getCurrentValues;
  const convertNumber = duration / 60;
  const integerNumber = Math.floor(convertNumber);
  ownProps.getCurrentValues.durationMin = integerNumber;
  ownProps.getCurrentValues.durationSec = Math.floor(
    (convertNumber - integerNumber) * 60
  );

  const initialValues = ownProps.getCurrentValues;
  const { playlistType } = initialValues;
  const { contentGroup } = state.contentGroup;
  const playTime = selector(state, "_playtime");
  const normalizedPlayTime = playTime || [];

  const currentContentGroup =
    initialValues.type === "programme"
      ? initialValues
      : {
          ...contentGroup.find(({ id }) => id === initialValues.entityId),
          playlistType,
        };

  return {
    contentGroup,
    orientationSize: screenSizeId,
    playTime: normalizedPlayTime,
    initialValues,
    currentContentGroup,
    currentPlaylistId: state.playlists.currentPlaylistId,
    screenSizes: state.screenSizes.screenSizes,
    contents: state.content.contents,
    contentsProgress: state.content.contentsProgress,
    isLoadingContent: state.content.isLoadingContent,
    contentFormId: state.app.contentFormId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUploadedContent,
      fetchRemoveContentFromContentGroup,
      replaceContent: fetchReplaceContent,
      uploadAndReplaceUrlForContent: fetchUploadAndReplaceUrlForContent,
      abortUploadFile: fetchAbortUploadFile,
      showPreviewModal: setPreviewModal,
    },
    dispatch
  );

const AddContentFormWrapper = reduxForm({
  validate,
  form: "addContentForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AddContentForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddContentFormWrapper);
