import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LayoutView from "../LayoutView";
import { authActions } from "../../../state/ducks/auth";
import { dataFeedsActions } from "../../../state/ducks/dataFeeds";
import { appActions } from "../../../state/ducks/app";
import { wsActions } from "../../../state/ducks/websockets";
import storage from "../../../state/utils/storage";

const { getDesktopStatus, screenIsUpdated } = wsActions;
const { changeAuthStatus, logout, setUser } = authActions;
const {
  showNotification,
  fetchData,
  fetchGeneratePasscode,
  fetchUpdatedData,
} = appActions;
const { fetchDataFeedApis } = dataFeedsActions;

class LayoutViewContainer extends React.Component {
  socketInterval = null;

  fetchDataInterval = null;

  componentDidMount() {
    const token = storage.getToken();
    if (token) {
      this.props.changeAuthStatus(true);
      this.props.fetchData();
      this.props.fetchDataFeedApis();
      setTimeout(() => this.props.getDesktopStatus(), 5000);
    }
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated } = this.props;
    if (prevProps.isAuthenticated !== isAuthenticated && isAuthenticated) {
      this.scheduleUpdateSocketConnection();
      this.scheduleFetchData();
    } else if (
      prevProps.isAuthenticated !== isAuthenticated &&
      !isAuthenticated
    ) {
      this.stopUpdateSocketConnection();
      this.stopFetchData();
    }
  }

  scheduleUpdateSocketConnection = () => {
    clearInterval(this.socketInterval);
    this.socketInterval = setInterval(() => {
      const { user, updateSocketConnection } = this.props;
      updateSocketConnection(user);
    }, 5000);
  };

  stopUpdateSocketConnection = () => {
    clearInterval(this.socketInterval);
    this.socketInterval = null;
  };

  scheduleFetchData = () => {
    clearInterval(this.fetchDataInterval);
    this.fetchDataInterval = setInterval(() => {
      this.props.fetchUpdatedData();
    }, 60000);
  };

  stopFetchData = () => {
    clearInterval(this.fetchDataInterval);
    this.fetchDataInterval = null;
  };

  componentWillUnmount = () => {
    this.stopUpdateSocketConnection();
    this.stopFetchData();
  };

  render() {
    return <LayoutView {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  fetching: state.auth.fetching,
  isAuthenticated: state.auth.isAuthenticated,
  notification: state.app.notification,
  showConfirmModal: state.app.showConfirmModal,
  confirmModalPlaylistScreens: state.app.confirmModalPlaylistScreens,
  showConfirmDuplicateModal: state.app.showConfirmDuplicateModal,
  user: state.auth.user,
  isLoadingContent: state.content.isLoadingContent,
  isLoadingCreateContent: state.content.isLoadingCreateContent,
});

const mapDispatchToProps = {
  changeAuthStatus,
  logout,
  showNotification,
  fetchData,
  getDesktopStatus,
  screenIsUpdated,
  generatePasscode: fetchGeneratePasscode,
  updateSocketConnection: setUser,
  fetchUpdatedData,
  fetchDataFeedApis,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LayoutViewContainer)
);
