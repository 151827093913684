export const SET_DATA_FEED_LIST = 'dataFeeds/SET_DATA_FEED_LIST';
export const SET_DATA_FEED_HISTORY_LIST = 'dataFeeds/SET_DATA_FEED_HISTORY_LIST';

export const CREATE_DATA_FEED = 'dataFeeds/CREATE_DATA_FEED';
export const CREATE_DATA_FEED_REQUEST = 'dataFeeds/CREATE_DATA_FEED_REQUEST';
export const CREATE_DATA_FEED_SUCCESS = 'dataFeeds/CREATE_DATA_FEED_SUCCESS';
export const CREATE_DATA_FEED_FAILURE = 'dataFeeds/CREATE_DATA_FEED_FAILURE';

export const UPDATE_DATA_FEED = 'dataFeeds/UPDATE_DATA_FEED';
export const UPDATE_DATA_FEED_REQUEST = 'dataFeeds/UPDATE_DATA_FEED_REQUEST';
export const UPDATE_DATA_FEED_SUCCESS = 'dataFeeds/UPDATE_DATA_FEED_SUCCESS';
export const UPDATE_DATA_FEED_FAILURE = 'dataFeeds/UPDATE_DATA_FEED_FAILURE';

export const DELETE_DATA_FEED = 'dataFeeds/DELETE_DATA_FEED';
export const DELETE_DATA_FEED_CONFIRM = 'dataFeeds/DELETE_DATA_FEED_CONFIRM';
export const DELETE_DATA_FEED_REQUEST = 'dataFeeds/DELETE_DATA_FEED_REQUEST';
export const DELETE_DATA_FEED_SUCCESS = 'dataFeeds/DELETE_DATA_FEED_SUCCESS';
export const DELETE_DATA_FEED_FAILURE = 'dataFeeds/DELETE_DATA_FEED_FAILURE';

export const FETCH_DATA_FEEDS = 'dataFeeds/FETCH_DATA_FEEDS';
export const FETCH_DATA_FEEDS_REQUEST = 'dataFeeds/FETCH_DATA_FEEDS_REQUEST';
export const FETCH_DATA_FEEDS_SUCCESS = 'dataFeeds/FETCH_DATA_FEEDS_SUCCESS';
export const FETCH_DATA_FEEDS_FAILURE = 'dataFeeds/FETCH_DATA_FEEDS_FAILURE';

export const FETCH_DATA_FEED_APIS = 'dataFeeds/FETCH_DATA_FEED_APIS';
export const FETCH_DATA_FEED_APIS_REQUEST = 'dataFeeds/FETCH_DATA_FEED_APIS_REQUEST';
export const FETCH_DATA_FEED_APIS_SUCCESS = 'dataFeeds/FETCH_DATA_FEED_APIS_SUCCESS';
export const FETCH_DATA_FEED_APIS_FAILURE = 'dataFeeds/FETCH_DATA_FEED_APIS_FAILURE';

export const OPEN_CREATE_MODAL = 'dataFeeds/OPEN_CREATE_MODAL';
export const CLOSE_CREATE_MODAL = 'dataFeeds/CLOSE_CREATE_MODAL';

export const OPEN_EDIT_MODAL = 'dataFeeds/OPEN_EDIT_MODAL';
export const CLOSE_EDIT_MODAL = 'dataFeeds/CLOSE_EDIT_MODAL';
