import React from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';
import { Field } from 'redux-form';

import styles from './OptionsModal.module.scss';
import Modal from '../../../../../../../common/components/Modal';
import Button from '../../../../../../../common/components/UI/Button';
import Input from '../../../../../../../common/components/UI/Input';
import Switch from '../../../../../../../common/components/UI/Switch/Switch';
import ColorPicker from '../../../../../../../common/components/UI/ColorPicker';

import SizeRange from './SizeRange';
import BottomBarField from '../../../../BottomBarField/BottomBarField';

class OptionsModal extends React.Component {
  handleCancel = () => {
    this.props.onCancel();
    this.props.change('options', JSON.parse(this.props.currentPlaylist.options));
  };

  handleSubmit = () => {
    const { currentPlaylist, updatePlaylist, formValues } = this.props;
    const formData = { options: formValues.options };
    updatePlaylist({ playlistId: currentPlaylist.id, formData });
    this.props.onCancel();
  };

  renderBody = () => (
    <div className={styles.body}>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Font color:</span>
        <Field name="options.fontColor" type="button" component={ColorPicker} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Color theme:</span>
        <Field name="options.themeColor" type="button" component={ColorPicker} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Bottom bar:</span>
        <Field name="options.hasBottomBar" type="checkbox" component={Switch} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Bottom bar text:</span>
        <BottomBarField formValues={this.props.formValues} change={this.props.change} />
      </div>
      <Field
        name="options.sizes"
        hasBottomBar={
          this.props.formValues && this.props.formValues.options && this.props.formValues.options.hasBottomBar
        }
        component={SizeRange}
      />
    </div>
  );

  render() {
    const { renderBody, handleCancel } = this;
    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={handleCancel} scroll="modal">
        <Modal.Title>Playlist Options</Modal.Title>
        {renderBody()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleCancel} theme="invert">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit}>Confirm</Button>
        </div>
      </Modal>
    );
  }
}

OptionsModal.defaultProps = {};

OptionsModal.propTypes = {
  ...Modal.propTypes,
  playlistId: PropTypes.number.isRequired,
  updatePlaylist: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  currentPlaylist: PropTypes.object.isRequired,
};

export default OptionsModal;
