import reducer from './reducers';
import * as actions from './actions'; // actions
import sagas from './sagas'; // sagas
import * as selectors from './selectors';
import * as types from './types';

export const dataFeedsActions = actions;
export const dataFeedsSagas = sagas;
export const dataFeedsSelectors = selectors;
export const dataFeedsReducer = reducer;
export const dataFeedsTypes = types;
