import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressItem.module.scss';
import ContentIcons from '../ContentIcons';

const ProgressItem = ({ name, progressValue, contentType, onClick, style, iconStyle }) => (
  <div className={styles.container} style={style}>
    <div className={styles.content}>
      <ContentIcons type={contentType} style={iconStyle} iconPosition={styles.iconPosition} />
      <div className={styles.nameContainer}>
        <span className={styles.name}>{name}</span>
        <progress className={styles.progressBar} max="100" value={progressValue} />
      </div>
    </div>
    <button type="button" onClick={onClick}>
      Cancel
    </button>
  </div>
);

ProgressItem.defaultProps = {
  style: {},
  iconStyle: {},
};

ProgressItem.propTypes = {
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  progressValue: PropTypes.number.isRequired,
};

export default ProgressItem;
