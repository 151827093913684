import React from 'react';
import PropTypes from 'prop-types';
import styles from './SessionModal.module.scss';
import Modal from '../../../../common/components/Modal';
import SessionAddForm from '../SessionAddForm';
import SessionEditForm from '../SessionEditForm';

const SessionModal = ({ sessionModal: { isOpen, type }, handleCloseSessionModal }) => (
  <div>
    <Modal
      isOpen={isOpen}
      onClose={isOpen ? () => ({}) : handleCloseSessionModal}
      containerClassName={styles.modalContainer}
      modalClassName={styles.modal}
    >
      {type === 'new' && <SessionAddForm handleCloseSessionModal={handleCloseSessionModal} />}
      {type === 'edit' && <SessionEditForm handleCloseSessionModal={handleCloseSessionModal} />}
    </Modal>
  </div>
);

SessionModal.propTypes = {
  sessionModal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    type: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  handleCloseSessionModal: PropTypes.func.isRequired,
};

export default SessionModal;
