import _ from 'lodash';
import { put, call, all, takeLatest, select, takeEvery, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { change } from 'redux-form';
import * as types from './types';
import * as actions from './actions';
import { showNotification } from '../app/actions';
import { speakersActions } from '../speakers';
import { fetch, storage, apiRoutes, sentryReport, getErrorMessage, getFlatDate, mixpanel } from '../../utils';
import { getUser, getIsAuthenticated } from '../auth/selectors';
import { setProgrammes } from '../programmes/actions';

const { setSessions, setSessionModal, sessionRemoveStart, sessionRemoveEnd } = actions;
const { setSpeakers } = speakersActions;

function* fetchAddSpeakers(speakers, sessionId) {
  const normalizeNameOfSpeakers = speakers.map(({ speakerCompany, speakerJob, speakerName, order }) => ({
    name: speakerName,
    job: speakerJob,
    company: speakerCompany,
    order,
  }));

  const user = storage.getUser();
  const { url, method } = apiRoutes.createSpeaker;

  const response = yield all(
    normalizeNameOfSpeakers.map(speaker => call(fetch, url(user.userId), method, { ...speaker, sessionId })),
  );

  return response;
}

function* fetchEditSpeakers(speakers) {
  const normalizeNameOfSpeakers = speakers.map(({ speakerCompany, speakerJob, speakerName, ...rest }) => ({
    name: speakerName,
    job: speakerJob,
    company: speakerCompany,
    ...rest,
  }));

  const user = storage.getUser();
  const { url, method } = apiRoutes.updateSpeaker;

  const response = yield all(
    normalizeNameOfSpeakers.map(speaker => call(fetch, url(user.userId, speaker.id), method, speaker)),
  );

  return response;
}

function* fetchRemoveSpeakers(speakers) {
  const normalizeNameOfSpeakers = speakers.map(({ speakerCompany, speakerJob, speakerName, ...rest }) => ({
    name: speakerName,
    job: speakerJob,
    company: speakerCompany,
    ...rest,
  }));

  const user = storage.getUser();
  const { url, method } = apiRoutes.removeSpeaker;

  yield all(normalizeNameOfSpeakers.map(speaker => call(fetch, url(user.userId, speaker.id), method)));

  return normalizeNameOfSpeakers;
}

function* fetchCreateSessionFlow(action) {
  try {
    const {
      value: { title, day, start, end, description, image, theatre, speakers: allSpeakers },
      isAddAnother,
    } = action.payload;

    const { value: programmeId } = theatre;
    const { url, method } = apiRoutes.createSession;
    const user = storage.getUser();

    mixpanel.track('Add session to programme', { title, programmeId });

    const sessionResp = yield call(fetch, url(user.userId), method, {
      title,
      day,
      start,
      end,
      image: image && JSON.stringify(image),
      description,
      programmeId,
    });

    const normalizeSession = {
      ...sessionResp,
      image: sessionResp.image && JSON.parse(sessionResp.image),
    };

    const { id: sessionId } = normalizeSession;

    const sessions = yield select(state => state.sessions.sessions);
    yield put(setSessions([...sessions, normalizeSession]));

    if (allSpeakers && allSpeakers.length) {
      const speakersResp = yield call(fetchAddSpeakers, allSpeakers, sessionId);
      const speakers = yield select(state => state.speakers.speakers);
      yield put(setSpeakers([...speakers, ...speakersResp]));
    }

    yield put(showNotification({ level: 'info', autoDismiss: 3, message: 'The Session was added' }));

    if (isAddAnother) {
      yield put(setSessionModal(true, 'new', sessionId));
      yield put(change('sessionAdd', 'day', day));
      yield put(change('sessionAdd', 'theatre', theatre));
    }

    yield put({ type: types.FETCH_CREATE_SESSION_END, payload: { programmeId } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: 'error', message: messageError }));
  }
}

function* fetchEditSessionFlow(action) {
  try {
    const {
      sessionId,
      title,
      day,
      start,
      end,
      description,
      image,
      theatre: { value: programmeId },
      speakers: allSpeakers,
    } = action.payload;

    const sessions = yield select(state => state.sessions.sessions);
    const currentSession = sessions.find(({ id }) => id === sessionId);

    const { url, method } = apiRoutes.updateSession;
    const user = storage.getUser();

    const sessionResp = yield call(fetch, url(user.userId, sessionId), method, {
      title,
      day,
      start,
      end,
      description,
      programmeId,
      image: image && JSON.stringify(image),
    });

    const normalizeSession = {
      ...sessionResp,
      image: sessionResp.image && JSON.parse(sessionResp.image),
    };

    yield put(setSessions(sessions.map(session => (session.id === sessionId ? normalizeSession : session))));

    if (allSpeakers && allSpeakers instanceof Array) {
      const speakers = yield select(state => state.speakers.speakers);
      const oldSessionSpeakers = speakers.filter(item => item.sessionId === sessionId);

      const removeSpeakers = oldSessionSpeakers.filter(oldSpk => !allSpeakers.find(spk => spk.id === oldSpk.id));
      const removeSpeakersResp = yield call(fetchRemoveSpeakers, removeSpeakers);

      const addSpeakers = allSpeakers.filter(speaker => !speaker.id);
      const addSpeakersResp = yield call(fetchAddSpeakers, addSpeakers, sessionId);

      const editSpeakers = allSpeakers.filter(speaker => !!speaker.id || speaker.id === 0);
      const editSpeakersResp = yield call(fetchEditSpeakers, editSpeakers);

      const replaceEditedSpeakers = speakers
        .map(speaker => {
          const editedSpeaker = editSpeakersResp.find(esr => esr.id === speaker.id);
          return editedSpeaker || speaker;
        })
        .filter(speaker => !removeSpeakersResp.find(rsp => rsp.id === speaker.id));

      const combineSpeakers = [...replaceEditedSpeakers, ...addSpeakersResp];
      yield put(setSpeakers(combineSpeakers));
    }

    yield put(showNotification({ level: 'info', autoDismiss: 1, message: 'The Session was updated' }));
    yield put({
      type: types.FETCH_EDIT_SESSION_END,
      payload: { programmeId, prevProgrammeId: currentSession.programmeId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: 'error', message: messageError }));
  }
}

function* fetchRemoveSessionFlow(action) {
  const { sessionId } = action.payload;
  try {
    yield put(sessionRemoveStart(sessionId));
    const { url, method } = apiRoutes.removeSession;
    const user = storage.getUser();
    yield call(fetch, url(user.userId, sessionId), method);

    const sessions = yield select(state => state.sessions.sessions);
    const { programmeId } = sessions.find(session => session.id === sessionId);
    yield put(setSessions(sessions.filter(session => session.id !== sessionId)));

    const speakers = yield select(state => state.speakers.speakers);
    yield put(setSpeakers(speakers.filter(speaker => speaker.sessionId !== sessionId)));

    yield put(sessionRemoveEnd(sessionId, programmeId));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: 'error', message: messageError }));
    yield put(sessionRemoveEnd(sessionId));
  }
}

function* fetchRemoveSessionsByDateFlow(action) {
  try {
    const { programmeId, date } = action.payload;
    const sessions = yield select(state => state.sessions.sessions);

    const sessionEntry = sessions
      .filter(session => session.programmeId === programmeId)
      .filter(session => getFlatDate(session.day) === getFlatDate(date));

    yield all(sessionEntry.map(session => call(fetchRemoveSessionFlow, { payload: { sessionId: session.id } })));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    yield put(showNotification({ level: 'error', message: messageError }));
  }
}

function* getSessions() {
  const { url, method } = apiRoutes.getSessions;
  const { id: userId } = yield select(getUser);
  const sessions = yield call(fetch, url(userId), method);
  return sessions;
}

export function* fetchSessionsFlow() {
  try {
    const sessions = yield* getSessions();
    yield put(setSessions(sessions));
  } catch (e) {
    // \_/)
    // (o.o)
    // (___)0
  }
}

export function* watchExternalSessionChanges() {
  const minutes = minCount => 1000 * (60 * minCount);

  while (true) {
    yield delay(minutes(5));

    const isAuthenticated = yield select(getIsAuthenticated);
    if (isAuthenticated) {
      const sessions = yield* getSessions();
      const externalSessions = sessions.filter(({ externalId }) => Boolean(externalId));
      const programmeIds = _.uniq(externalSessions.map(({ programmeId }) => programmeId));
      yield put(actions.updateExternalSessions({ programmeIds }));
    }
  }
}

export default [
  fork(watchExternalSessionChanges),
  takeLatest(types.FETCH_CREATE_SESSION, fetchCreateSessionFlow),
  takeLatest(types.FETCH_EDIT_SESSION, fetchEditSessionFlow),
  takeEvery(types.FETCH_REMOVE_SESSION, fetchRemoveSessionFlow),
  takeLatest(types.FETCH_REMOVE_SESSION_BY_DATE, fetchRemoveSessionsByDateFlow),
];
