import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import onClickOutside from 'react-onclickoutside';
import styles from './Modal.module.scss';

const Title = ({ children, className, ...rest }) => (
  <h2 className={cn(styles.title, className)} {...rest}>
    {children}
  </h2>
);

class ModalBody extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  handleClickOutside = event => {
    const { isCloseOutside } = this.props;
    if (isCloseOutside) this.props.onClose(event);
  };

  render() {
    const { children, modalClassName } = this.props;

    return (
      <div ref={this.modalRef} className={cn(styles.modal, modalClassName && modalClassName)} role="document">
        {children}
      </div>
    );
  }
}

ModalBody.defaultProps = {
  onClose: () => {},
  isCloseOutside: true,
};

ModalBody.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  modalClassName: PropTypes.string,
  isCloseOutside: PropTypes.bool,
};

const ModalBodyClickOutside = onClickOutside(ModalBody);

class Modal extends React.Component {
  render() {
    const { isOpen, containerClassName, scroll } = this.props;

    if (!isOpen) {
      return null;
    }

    const isScrollBody = scroll === 'body';
    const isScrollModal = scroll === 'modal';

    return (
      <div
        className={cn(
          styles.container,
          isScrollBody && styles.containerScrollBody,
          isScrollModal && styles.containerScrollModal,
          isOpen && styles.containerIsOpen,
          containerClassName && containerClassName,
        )}
        tabIndex="-1"
        role="dialog"
      >
        <ModalBodyClickOutside {...this.props} />
      </div>
    );
  }
}

Modal.Title = Title;

Modal.defaultProps = {
  isOpen: false,
  scroll: 'body',
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  scroll: PropTypes.oneOf(['body', 'modal']),
  containerClassName: PropTypes.string,
};

export default Modal;
