import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './SelectedPlaylistIndicator.module.scss';

import Icons from '../../../../common/components/UI/Icons';
import DotsButton from '../../../../common/components/UI/DotsButton';
import makeToggleable from '../../../../hocs/makeToggleable';
import MenuList from '../../../../common/components/UI/MenuList/MenuList';
import UploadSpinner from '../../../../common/components/UI/UploadSpinner';

class SelectedPlaylistIndicator extends React.Component {
  onOpenChoosePlaylistModal = () => {
    this.props.onOpenChoosePlaylistModal();
    this.props.toggle();
  };

  handleRedirectToPlaylist = () => {
    this.props.onRedirectToPlaylist();
    this.props.toggle();
  };

  handleRemovePlaylist = () => {
    this.props.onRemovePlaylist();
    this.props.toggle();
  };

  render() {
    const { isOpen, rootRef, open, title, isExistScreen, isLoading } = this.props;
    const { onOpenChoosePlaylistModal, handleRedirectToPlaylist, handleRemovePlaylist } = this;

    return (
      <div ref={rootRef} className={styles.container}>
        {isLoading ? (
          <UploadSpinner />
        ) : (
          <>
            <Icons icon="playlistIconGray" widthIcons={24} marginRight={16} />
            <span className={styles.title}>{title}</span>
            {/* <div style={{ color: 'red' }}>{id}</div> */}
            <DotsButton onClick={open} />
            <MenuList isOpen={isOpen} right={10} top={5}>
              <MenuList.Item title="Choose Playlist" onClick={onOpenChoosePlaylistModal} />
              {isExistScreen && <MenuList.Item title="Edit Playlist" onClick={handleRedirectToPlaylist} />}
              <MenuList.Item title="Remove Playlist" onClick={handleRemovePlaylist} />
            </MenuList>
          </>
        )}
      </div>
    );
  }
}

SelectedPlaylistIndicator.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  rootRef: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isExistScreen: PropTypes.bool.isRequired,
  onRedirectToPlaylist: PropTypes.func,
  onRemovePlaylist: PropTypes.func,
  onOpenChoosePlaylistModal: PropTypes.func.isRequired,
};

export default makeToggleable(SelectedPlaylistIndicator);
