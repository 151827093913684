import * as types from './types';

// sync
export const setScreenSizes = screenSizes => ({ type: types.SET_SCREEN_SIZES, payload: screenSizes });

// async
export const fetchCreateScreenSizes = screenSize => ({
  type: types.FETCH_CREATE_SCREEN_SIZES,
  payload: { screenSize },
});
export const fetchRemoveScreenSizes = screenSizeId => ({
  type: types.FETCH_REMOVE_SCREEN_SIZES,
  payload: { screenSizeId },
});
