import { createSelector } from 'reselect';

export const contentMetaSelector = state => state.contentMeta.contentMeta;
const contentGroupsSelector = state => state.contentGroup.contentGroup;
const playlistsSelector = state => state.playlists.playlists;

export const getContentsMeta = state => state.contentMeta.contentMeta;

const getContentGroupWithMeta = createSelector(
  [contentMetaSelector, contentGroupsSelector, playlistsSelector],
  (contentMeta, contentGroups, playlists) => {
    const combineContentGroupWithMeta = contentMeta.map(meta => {
      const entity =
        meta.entityType === 'playlist'
          ? playlists.find(({ id }) => meta.entityType === 'playlist' && meta.entityId === id)
          : contentGroups.find(({ id }) => meta.entityType === 'contentGroup' && meta.entityId === id);

      return { ...entity, ...meta };
    });

    const addPlaylistNameToMeta = combineContentGroupWithMeta.map(meta => {
      const getCurrentPlaylist = playlists.find(playlist => playlist.id === meta.playlistId);
      return { ...meta, playlistName: getCurrentPlaylist && getCurrentPlaylist.name };
    });

    return addPlaylistNameToMeta;
  },
);

export default getContentGroupWithMeta;
