import reducer from './reducers';
import * as actions from './actions';
import sagas from './sagas';
import * as selectors from './selectors';
import * as types from './types';

export const programmesActions = actions;
export const programmesSagas = sagas;
export const programmesReducer = reducer;
export const programmesSelectors = selectors;
export const programmesTypes = types;
