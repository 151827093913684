import reducer from './reducers';
import * as actions from './actions';
import sagas from './sagas';
import * as selectors from './selectors';
import * as types from './types';

export const speakersActions = actions;
export const speakersSagas = sagas;
export const speakersReducer = reducer;
export const speakersSelectors = selectors;
export const speakersTypes = types;
