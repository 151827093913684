import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './DotsButton.module.scss';
import Icons from '../Icons';

function DotsButton({ className, size, ...rest }) {
  return (
    <button
      className={cn(styles.container, className && className)}
      style={{ width: `${size}px`, height: `${size}px` }}
      {...rest}
    >
      <Icons icon="dots" widthIcons={size} />
      {/* <svg className={styles.icon} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
        <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
      </svg> */}
    </button>
  );
}

DotsButton.defaultProps = {
  size: 24,
  className: null,
};

DotsButton.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default DotsButton;
