import { registerSelectors } from 'reselect-tools';
import reducer from './reducers';
import * as actions from './actions'; // actions
import sagas from './sagas'; // sagas
import * as selectors from './selectors';
import * as types from './types';

export const screensActions = actions;
export const screensSagas = sagas;
export const screensReducer = reducer;
export const screensSelectors = selectors;
export const screensTypes = types;
registerSelectors(selectors);
