import React, { Component } from 'react';
import SvgIcoOrientation from '../../../../../../assets/svg/SvgIcoOrientation/SvgIcoOrientation';
import SvgIcoNotDefault from '../../../../../../assets/svg/SvgIcoNotDefault/SvgIcoNotDefault';
import SvgIcoSquare from '../../../../../../assets/svg/SvgIcoSquare/SvgIcoSquare';

export default class DefineOrientationIco extends Component {
  render() {
    const { screenSize, isEditMode, orientationSelect, customSizeBtnIsActive } = this.props;
    return (
      <div>
        {screenSize.default && (
          <SvgIcoOrientation
            orientation={screenSize.name}
            setColor={orientationSelect === screenSize.id && !customSizeBtnIsActive ? '#EC812B' : '#bcbebf'}
          />
        )}

        {!screenSize.default && screenSize.resolutionWidth === screenSize.resolutionHeight && (
          <SvgIcoSquare
            iconSize={40}
            orientation={screenSize.name}
            setColor={
              !isEditMode
                ? '#EC812B'
                : orientationSelect === screenSize.id && !customSizeBtnIsActive
                ? '#EC812B'
                : '#bcbebf'
            }
          />
        )}

        {!screenSize.default && screenSize.resolutionWidth !== screenSize.resolutionHeight && (
          <SvgIcoNotDefault
            orientation={screenSize.resolutionWidth < screenSize.resolutionHeight}
            setColor={orientationSelect === screenSize.id && !customSizeBtnIsActive ? '#EC812B' : '#bcbebf'}
          />
        )}
      </div>
    );
  }
}
