export const positiveNumber = value => (value && !/^[0-9][0-9]*$/.test(value) ? 'Must be positive number' : undefined);

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

export const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export const required = value => (value || typeof value === 'number' ? undefined : 'Required');

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength2 = maxLength(2);

export const normalizeDuration = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
};
