import * as types from './types';
import { createAction } from '../../utils';

// sync
export const setProgrammes = programmes => ({ type: types.SET_PROGRAMMES, payload: programmes });
export const createNewTheatreStart = () => ({ type: types.CREATE_NEW_THEATRE_START });
export const createNewTheatreEnd = () => ({ type: types.CREATE_NEW_THEATRE_END });
export const updateProgrammeSuccess = createAction(types.UPDATE_PROGRAMME_SUCCESS);
export const toggleSyncOfProgramme = createAction(types.TOGGLE_SYNC_OF_PROGRAMME);

// async
export const createNewTheatreProgram = title => ({ type: types.CREATE_NEW_THEATRE, payload: { title } });
export const removeTheatreProgram = programmeId => ({ type: types.REMOVE_THEATRE, payload: { programmeId } });
export const fetchChangeTheatreName = (title, programmeId) => ({
  type: types.FETCH_CHANGE_THEATRE_NAME,
  payload: { title, programmeId },
});
export const fetchChangeTheatreHTMLTitle = (htmlTitle, programmeId) => ({
  type: types.FETCH_CHANGE_THEATRE_HTML_TITLE,
  payload: { htmlTitle, programmeId },
});
export const fetchChangeTheatreOptions = (options, programmeId) => ({
  type: types.FETCH_CHANGE_THEATRE_OPTIONS,
  payload: { options, programmeId },
});
export const changeTheatreImage = (image, programmeId) => ({
  type: types.CHANGE_THEATRE_IMAGE,
  payload: { image, programmeId },
});
export const fetchCreateTheatreOpenSession = title => ({
  type: types.FETCH_CREATE_THEATRE_OPEN_SESSION,
  payload: { title },
});
