import { createSelector } from 'reselect';

export const getProgrammes = state => state.programmes.programmes;

export const getPureProgrammes = createSelector(
  getProgrammes,
  programmes => programmes.filter(({ dataFeedId }) => dataFeedId === null),
);

export const getProgrammesWithDataFeed = createSelector(
  getProgrammes,
  programmes => programmes.filter(({ dataFeedId }) => dataFeedId !== null),
);

export const getDataFeedProgrammes = createSelector(
  [getProgrammesWithDataFeed, (_, dataFeedId) => dataFeedId],
  (programmes, dataFeedId) => programmes.find(({ id }) => id === dataFeedId),
);

const selectorTheatreId = (
  state,
  {
    match: {
      params: { theatreId },
    },
  },
) => Number(theatreId);

export const getCurrentTheatre = createSelector(
  [getProgrammes, selectorTheatreId],
  (programmes, theatreId) => programmes.find(programme => programme.id === theatreId),
);

export const getCurrentTheatre2222 = createSelector(
  [getProgrammes, (state, theatreId) => theatreId],
  (programmes, theatreId) => {
    console.log('ProgrammesProgrammes', programmes);
    console.log('theatreId', theatreId);
  },
);
