import moment from 'moment';
import arrayIdInjector from './array-id-injector';

const { isArray } = Array;

const hasContentWithoutPlaytime = (cmArr, screenType) => {
  const normalizedCmArr =
    screenType === 'theatre' ? cmArr : cmArr.filter(({ entityType }) => entityType !== 'programme');

  return normalizedCmArr.some(cm => {
    if (isArray(cm)) return hasContentWithoutPlaytime(cm, screenType);
    return !cm._playtime.length; // eslint-disable-line
  });
};

const cloneDeep = arr =>
  arr.map(item => {
    if (Array.isArray(item)) {
      return arrayIdInjector(cloneDeep(item), item.id);
    }
    return { ...item };
  });

export default function(contentMeta, startPlaylistTime, endPlaylistTime, screenType) {
  if (!hasContentWithoutPlaytime(contentMeta, screenType)) {
    return [];
  }

  const filledMeta = cloneDeep(contentMeta);

  const flatList = [];
  let startTime = startPlaylistTime;

  function checkDate(playtime) {
    const startDate = `${playtime.date} ${playtime.start}:00`;
    const endDate = `${playtime.date} ${playtime.end}:59`;
    const currentDate = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
    return startDate <= currentDate && currentDate <= endDate;
  }

  // eslint-disable-next-line
  const canAddToSchedule = cm => !cm._playtime.length || cm._playtime.find(checkDate);

  function incrementIndex(current, arr) {
    return current + 1 >= arr.length ? 0 : current + 1;
  }

  function scheduleItemFactory(content, arr) {
    const { type, name } = content.entity;

    if (!canAddToSchedule(content)) return;
    if (screenType !== 'theatre' && type === 'programme') return;

    const { duration = 15 } = content;
    const durationMs = duration * 1000;

    const scheduleItem = {
      name: name || 'Full Programme',
      type,
      date: moment(startTime).format('dddd'),
      timeStart: moment(startTime).format('HH:mm:ss'),
      timeEnd: moment(startTime + durationMs).format('HH:mm:ss'),
      // currentDate: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
    };

    startTime += durationMs;
    arr.push(scheduleItem);
  }

  /* eslint-disable no-param-reassign */
  function getContentIndex(parent, playlistId) {
    const initialContentIndex = 0;

    if (!parent.contentIndexes) {
      parent.contentIndexes = { [playlistId]: initialContentIndex };
    } else if (!parent.contentIndexes[playlistId]) {
      parent.contentIndexes[playlistId] = initialContentIndex;
    }

    return parent.contentIndexes[playlistId];
  }

  function setContentIndex(parent, playlistId, newIndex) {
    parent.contentIndexes[playlistId] = newIndex;
  }
  /* eslint-enable */

  function nestedFiller(content, parent) {
    if (!isArray(content)) {
      scheduleItemFactory(content, flatList);
      return;
    }

    // if (!hasContentWithoutPlaytime(content, screenType)) return;
    if (content.every(c => !canAddToSchedule(c))) return;

    const contentIndex = getContentIndex(parent, content.id);
    const nestedContent = content[contentIndex];

    const newContentIndex = incrementIndex(contentIndex, content);
    setContentIndex(parent, content.id, newContentIndex);

    const canNotTakeNestedContent = !isArray(nestedContent) && !canAddToSchedule(nestedContent);

    const nextContent = canNotTakeNestedContent ? content : nestedContent;
    const nextParent = canNotTakeNestedContent ? parent : content;
    nestedFiller(nextContent, nextParent);
  }

  function flatListFiller(cmArr) {
    cmArr.forEach(content => {
      if (isArray(content)) {
        nestedFiller(content, cmArr);
      } else {
        scheduleItemFactory(content, flatList);
      }
    });
  }

  while (startTime <= endPlaylistTime) {
    flatListFiller(filledMeta);
  }

  return flatList;
}
