import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import PlaylistTemplateCard from '../../../PlaylistTemplateCard';
import PlaylistNameForm from '../../../PlaylistNameForm/PlaylistNameForm';

import styles from './Split.module.scss';
import NestedPlaylistBox from '../../../../../../common/components/NestedPlaylistBox';
import ChoosePlaylistModal from '../../../../../../common/components/ChoosePlaylistModal';
import ContentSettingsModal from '../../../../../../common/components/UI/ModalAddContent';

import { getDefaultPlaylists } from '../../../../../../../state/ducks/playlists/selectors';

import { playlistsActions } from '../../../../../../../state/ducks/playlists';
import { fetchMultipleCreateContentGroupSetToPlaylist } from '../../../../../../../state/ducks/contentGroup/actions';
import OptionsButton from '../../../../../../common/components/UI/OptionsButton';
import OptionsModal from './OptionsModal';
// import NestedPlaylistBoxes from '../../../../../../common/components/NestedPlaylistBoxes/NestedPlaylistBoxes';

const isOpenChoosePlaylistModal = state => {
  const { isOpenFirstChoosePlaylistModal, isOpenSecondChoosePlaylistModal } = state;
  return isOpenFirstChoosePlaylistModal || isOpenSecondChoosePlaylistModal;
};

const getSelectedPlaylistIdOfModal = (state, props) => {
  const { isOpenFirstChoosePlaylistModal, isOpenSecondChoosePlaylistModal } = state;
  const {
    formValues: { options = {} },
  } = props;

  let selectedPlaylistIdOfModal = null;
  if (isOpenFirstChoosePlaylistModal) {
    selectedPlaylistIdOfModal = options.firstPlaylistId;
  } else if (isOpenSecondChoosePlaylistModal) {
    selectedPlaylistIdOfModal = options.secondPlaylistId;
  }

  return selectedPlaylistIdOfModal;
};

class Split extends React.Component {
  state = {
    isOpenOptionsModal: false,
    isOpenFirstChoosePlaylistModal: false,
    isOpenSecondChoosePlaylistModal: false,
    isOpenContentSettingsModal: false,
    contentSettingsModalValues: {},
  };

  handleOpenOptionsModal = () => this.setState({ isOpenOptionsModal: true });

  handleCloseOptionsModal = () => this.setState({ isOpenOptionsModal: false });

  handleOpenContentSettingsModal = contentGroup => {
    // TODO: create redux-actions for this
    this.setState({
      isOpenContentSettingsModal: true,
      contentSettingsModalValues: contentGroup,
    });

    this.props.setContentFormId(contentGroup.type);
  };

  handleCloseContentSettingsModal = () => {
    this.setState({ isOpenContentSettingsModal: false });
  };

  handleOpenFirstChoosePlaylistModal = () => this.setState({ isOpenFirstChoosePlaylistModal: true });

  handleOpenSecondChoosePlaylistModal = () => this.setState({ isOpenSecondChoosePlaylistModal: true });

  handleCloseChoosePlaylistModal = () =>
    this.setState({ isOpenSecondChoosePlaylistModal: false, isOpenFirstChoosePlaylistModal: false });

  handleChoosePlaylist = playlistId => {
    const { isOpenFirstChoosePlaylistModal, isOpenSecondChoosePlaylistModal } = this.state;
    const {
      formValues: { options = {} },
    } = this.props;

    let extraOptionsFields;
    if (isOpenFirstChoosePlaylistModal) {
      extraOptionsFields = { firstPlaylistId: playlistId };
      this.props.change('options.firstPlaylistId', playlistId);
    } else if (isOpenSecondChoosePlaylistModal) {
      extraOptionsFields = { secondPlaylistId: playlistId };
      this.props.change('options.secondPlaylistId', playlistId);
    }

    const finalOptions = { ...options, ...extraOptionsFields };
    this.props.updatePlaylist({ playlistId: this.props.currentPlaylist.id, formData: { options: finalOptions } });
  };

  render() {
    const { isOpenOptionsModal } = this.state;
    const {
      formValues,
      playlistId,
      onChangeName,
      currentPlaylist,
      playlists,
      createContentGroupAndAddToPlaylist,
    } = this.props;

    const { options = {} } = formValues;

    const firstPlaylist = playlists.find(p => p.id === options.firstPlaylistId);
    const secondPlaylist = playlists.find(p => p.id === options.secondPlaylistId);

    return (
      <div className={styles.container}>
        <PlaylistNameForm
          {...{ currentPlaylist, onChangeName, playlistId }}
          initialValues={{ name: currentPlaylist && currentPlaylist.name }}
        />
        <div style={{ display: 'flex' }}>
          <PlaylistTemplateCard type={currentPlaylist.type} isLocked style={{ marginRight: 10 }} />
          <OptionsButton onClick={this.handleOpenOptionsModal} />
        </div>
        <div className={styles.body}>
          {/* <NestedPlaylistBoxes firstPlaylistId={options.firstPlaylistId} secondPlaylistId={options.secondPlaylistId} /> */}
          <div className={styles.playlistBoxes}>
            <NestedPlaylistBox
              playlist={firstPlaylist}
              placeholder="First Playlist"
              onAcceptDrop={acceptedFiles => createContentGroupAndAddToPlaylist(acceptedFiles, firstPlaylist.id)}
              onOpenChoosePlaylistModal={this.handleOpenFirstChoosePlaylistModal}
              onOpenContentSettingsModal={this.handleOpenContentSettingsModal}
              isMain
            />
            <NestedPlaylistBox
              playlist={secondPlaylist}
              placeholder="Second Playlist"
              onAcceptDrop={acceptedFiles => createContentGroupAndAddToPlaylist(acceptedFiles, secondPlaylist.id)}
              onOpenContentSettingsModal={this.handleOpenContentSettingsModal}
              onOpenChoosePlaylistModal={this.handleOpenSecondChoosePlaylistModal}
            />
          </div>
          <div className={styles.bottomBar}>#EventHashtag</div>
        </div>
        <ChoosePlaylistModal
          playlists={playlists}
          isOpen={isOpenChoosePlaylistModal(this.state)}
          onChoosePlaylist={this.handleChoosePlaylist}
          selectedPlaylistId={getSelectedPlaylistIdOfModal(this.state, this.props)}
          onClose={this.handleCloseChoosePlaylistModal}
        />
        <ContentSettingsModal
          // TODO: rename props
          modalToggler={this.handleCloseContentSettingsModal}
          modalScreens={this.state.isOpenContentSettingsModal}
          values={this.state.contentSettingsModalValues}
        />
        <OptionsModal
          isOpen={isOpenOptionsModal}
          playlistId={playlistId}
          currentPlaylist={currentPlaylist}
          formValues={formValues}
          onCancel={this.handleCloseOptionsModal}
          onConfirm={this.handleCloseOptionsModal}
          change={this.props.change}
          updatePlaylist={this.props.updatePlaylist}
        />
      </div>
    );
  }
}

Split.propTypes = {
  currentPlaylist: PropTypes.object,
  playlistId: PropTypes.number,
  playlists: PropTypes.array.isRequired,
  createContentGroupAndAddToPlaylist: PropTypes.func.isRequired,
};

const formName = 'playlistOptions';

const mapStateToProps = (state, { currentPlaylist }) => {
  const formValues = getFormValues(formName)(state) || {};
  const playlists = getDefaultPlaylists(state);
  const fontColor = {
    hex: '#000',
    rgb: { r: 0, g: 0, b: 0, a: 100 },
  };

  const options = { fontColor, ...JSON.parse(currentPlaylist.options) };
  const initialValues = { ...currentPlaylist, options };

  return {
    playlists,
    formValues,
    initialValues,
  };
};

const mapDispatchToProps = {
  createContentGroupAndAddToPlaylist: fetchMultipleCreateContentGroupSetToPlaylist,
  ...playlistsActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
  })(Split),
);
