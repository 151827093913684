import React from 'react';
import PropTypes from 'prop-types';
import { mixpanel } from '../../../../../../state/utils';

import PlaylistTemplateCard from '../../PlaylistTemplateCard';
import Button from '../../../../../common/components/UI/Button';
import Icon from '../../../../../common/components/UI/Icon';
import Tooltip from '../../../../../common/components/UI/Tooltip/Tooltip';

import styles from './PlaylistTypeEditor.module.scss';
import { templateTypes } from '../../../../../../constants';
import PlaylistNameField from '../../PlaylistNameField/PlaylistNameField';

const playlistTypes = Object.values(templateTypes);

class PlaylistTypeEditor extends React.Component {
  state = {
    selectedType: null,
  };

  componentDidMount() {
    this.handleChange(this.props.selectedType);
  }

  handleChange = playlistType => this.setState({ selectedType: playlistType });

  handleConfirm = () => {
    mixpanel.track('Select playlist template', { playlistType: this.state.selectedType });
    this.props.onConfirm(this.state.selectedType);
  };

  render() {
    const { selectedType } = this.state;
    const { onCancel } = this.props;

    return (
      <div className={styles.root}>
        <PlaylistNameField />
        <div className={styles.tip}>
          <span>Select Template:</span>
          <Icon name="help" data-tip="You won't be able to change the template after adding content to the playlist" />
          <Tooltip />
        </div>
        <div className={styles.templateCards}>
          {playlistTypes.map(playlistType => (
            <PlaylistTemplateCard
              key={playlistType}
              type={playlistType}
              className={styles.templateCardsItem}
              isActive={selectedType === playlistType}
              onClick={() => this.handleChange(playlistType)}
            />
          ))}
        </div>
        <div className={styles.footer}>
          <Button onClick={this.handleConfirm} className={styles.btn}>
            Confirm
          </Button>
          <Button onClick={onCancel} theme="invert">
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

PlaylistTypeEditor.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedType: PropTypes.string.isRequired,
};

export default PlaylistTypeEditor;
