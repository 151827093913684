import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlaylistTemplateCard from '../../../PlaylistTemplateCard';

import styles from './Split.module.scss';
import NestedPlaylistBox from './NestedPlaylistBox/NestedPlaylistBox';
import ChoosePlaylistModal from '../../../../../../common/components/ChoosePlaylistModal';

import { fetchMultipleCreateContentGroupSetToPlaylist } from '../../../../../../../state/ducks/contentGroup/actions';
import OptionsButton from '../../../../../../common/components/UI/OptionsButton';
import OptionsModal from './OptionsModal';
import PlaylistNameField from '../../../PlaylistNameField/PlaylistNameField';
import PlaylistDetailsWrapper from '../../../PlaylistDetailsWrapper';
import UploadSpinner from '../../../../../../common/components/UI/UploadSpinner';

// const isOpenChoosePlaylistModal = state => {
//   const { opene } = state;
//   return isOpenFirstChoosePlaylistModal || isOpenSecondChoosePlaylistModal;
// };

const choosePlaylistModals = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
};

class Split extends React.Component {
  state = {
    isOpenOptionsModal: false,
    openedChoosePlaylistModal: null, // choosePlaylistModal.FIRST || choosePlaylistModal.SECOND
  };

  handleOpenOptionsModal = () => this.setState({ isOpenOptionsModal: true });

  handleCloseOptionsModal = () => this.setState({ isOpenOptionsModal: false });

  handleConfirmOptionsModal = options => {
    this.props.change('options', options);
    this.handleCloseOptionsModal();
  };

  handleOpenFirstChoosePlaylistModal = () =>
    this.setState({
      openedChoosePlaylistModal: choosePlaylistModals.FIRST,
    });

  handleOpenSecondChoosePlaylistModal = () =>
    this.setState({
      openedChoosePlaylistModal: choosePlaylistModals.SECOND,
    });

  handleCloseChoosePlaylistModal = () =>
    this.setState({
      openedChoosePlaylistModal: null,
    });

  handleChoosePlaylist = playlistId => {
    const { openedChoosePlaylistModal } = this.state;
    const { formValues, createPlaylist } = this.props;

    const { options } = formValues;
    const extraOptionsFields =
      openedChoosePlaylistModal === choosePlaylistModals.FIRST
        ? { firstPlaylistId: playlistId }
        : { secondPlaylistId: playlistId };

    const finalOptions = JSON.stringify({ ...options, ...extraOptionsFields });

    this.handleCloseChoosePlaylistModal();
    createPlaylist({ ...formValues, options: finalOptions });
  };

  render() {
    const { isOpenOptionsModal } = this.state;
    const { openedChoosePlaylistModal } = this.state;
    const { playlists, formValues, onChangeName, onToggleEditMode, isCreatingPlaylist } = this.props;

    const isOpenChoosePlaylistModal = Boolean(openedChoosePlaylistModal);

    return (
      <PlaylistDetailsWrapper>
        {isCreatingPlaylist && <UploadSpinner zIndex={1000} />}
        <PlaylistNameField name="name" type="text" onBlur={onChangeName} />
        <div style={{ display: 'flex' }}>
          <PlaylistTemplateCard type={formValues.type} onEdit={onToggleEditMode} marginRight={10} />
          <OptionsButton onClick={this.handleOpenOptionsModal} />
        </div>
        <div className={styles.body}>
          <div className={styles.playlistBoxes}>
            <NestedPlaylistBox
              placeholder="First Playlist"
              onOpenChoosePlaylistModal={this.handleOpenFirstChoosePlaylistModal}
              onOpenContentSettingsModal={this.handleOpenContentSettingsModal}
              isMain
            />
            <NestedPlaylistBox
              placeholder="Second Playlist"
              onOpenContentSettingsModal={this.handleOpenContentSettingsModal}
              onOpenChoosePlaylistModal={this.handleOpenSecondChoosePlaylistModal}
            />
          </div>
          <div className={styles.bottomBar}>#EventHashtag</div>
        </div>
        <ChoosePlaylistModal
          playlists={playlists}
          isOpen={isOpenChoosePlaylistModal}
          onChoosePlaylist={this.handleChoosePlaylist}
          onClose={this.handleCloseChoosePlaylistModal}
        />
        <OptionsModal
          isOpen={isOpenOptionsModal}
          initialValues={this.props.formValues}
          onCancel={this.handleCloseOptionsModal}
          onConfirm={this.handleConfirmOptionsModal}
        />
      </PlaylistDetailsWrapper>
    );
  }
}

Split.propTypes = {
  change: PropTypes.func.isRequired,
  playlists: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
  isCreatingPlaylist: PropTypes.bool.isRequired,
  onChangeName: PropTypes.func.isRequired,
  createPlaylist: PropTypes.func.isRequired,
  onToggleEditMode: PropTypes.func.isRequired,
  createPlaylistAndContentGroup: PropTypes.func.isRequired,
};

const mapDispatchToProps = { createContentGroupAndAddToPlaylist: fetchMultipleCreateContentGroupSetToPlaylist };

export default connect(
  null,
  mapDispatchToProps,
)(Split);
