import * as types from "./types";
import { createReducer } from "../../utils";

export default createReducer({
  contentMeta: [],
  linkedContentMetaIds: [],
})({
  [types.SET_CONTENT_META]: (state, action) => ({
    ...state,
    contentMeta: action.payload,
  }),
  [types.ADD_CONTENT_META]: (state, action) => ({
    ...state,
    contentMeta: [...state.contentMeta, action.payload],
  }),
  [types.SET_LINKED_CONTENT_META_IDS]: (state, action) => ({
    ...state,
    linkedContentMetaIds: action.payload,
  }),
});
