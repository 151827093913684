import { createSelector } from 'reselect';
import moment from 'moment';
import { getProgrammesWithDataFeed } from '../programmes/selectors';

export const dataFeedListSelector = state => state.dataFeeds.dataFeedList;
export const getDataFeedApis = state => state.dataFeeds.dataFeedApis;
export const getDataFeedHistoryList = state => state.dataFeeds.dataFeedHistoryList;

export const getEditFeedId = state => state.dataFeeds.editFeedId;
export const getIsOpenCreateFeedModal = state => state.dataFeeds.isOpenCreateFeedModal;

export const getIsCreatingDataFeed = state => state.dataFeeds.isCreatingDataFeed;

export const getDataFeedList = createSelector(
  [dataFeedListSelector, getDataFeedHistoryList, getProgrammesWithDataFeed],
  // eslint-disable-next-line no-unused-vars
  (dataFeedList, dataFeedHistoryList, programmes) =>
    dataFeedList.map(dataFeed => {
      const compare = (a, b) => {
        const updatedA = moment(a.data.updated, 'DD-MM-YYYY HH:mm:ss').valueOf();
        const updatedB = moment(b.data.updated, 'DD-MM-YYYY HH:mm:ss').valueOf();
        return updatedB - updatedA;
      };

      return {
        ...dataFeed,
        requestHistory: dataFeedHistoryList
          .filter(({ dataFeedId }) => dataFeedId === dataFeed.id)
          .reverse()
          .sort(compare),
        programmes: programmes.filter(({ dataFeedId }) => dataFeedId === dataFeed.id),
      };
    }),
);

export const getDataFeedById = createSelector(
  [getDataFeedList, (_, dataFeedId) => dataFeedId],
  (dataFeedList, dataFeedId) => dataFeedList.find(({ id }) => id === dataFeedId),
);
