import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import AddContentForm from '../AddContentForm';
import styles from './ModalContainer.module.scss';
import { contentActions } from '../../../../../../state/ducks/content';
import { fetchUpdatePlaylistContentGroupMeta } from '../../../../../../state/ducks/playlists/actions';
import Modal from '../../../Modal';
// import OutsideHandler from '../../OutsideHandler';

const { fetchCreatePlaylistContent, fetchPlaylistContents } = contentActions;

class ModalContainer extends React.Component {
  handleEditedSubmitContent = value => {
    const { updatePlaylistContentMetaGroup } = this.props;
    const updateDuration = Number(value.durationMin) * 60 + Number(value.durationSec);
    value.duration = updateDuration;
    if (value._playtime.length <= 1) value.displayTime = 'always';
    value._playtime = value._playtime.slice(0, -1);
    // if (value.displayTime === 'always') value._playtime = [];
    updatePlaylistContentMetaGroup(value);
  };

  render() {
    const {
      modalToggler,
      modalScreens,
      contentFormId,
      newScreenName,
      orientationSelect,
      values,
      isEdit,
      showPreviewModal,
      currentScreen,
    } = this.props;

    const isOpen = modalScreens;
    const noop = () => {};
    const { screenSizeId: defaultScreenSizeId } = currentScreen || { screenSizeId: null };

    return (
      <Modal
        isOpen={isOpen}
        containerClassName={styles.modalContainer}
        modalClassName={styles.modal}
        onClose={showPreviewModal ? noop : modalToggler}
      >
        <AddContentForm
          onSubmit={this.handleEditedSubmitContent}
          contentFormId={contentFormId}
          modalToggler={modalToggler}
          newScreenName={newScreenName}
          orientationSelect={orientationSelect}
          getCurrentValues={values}
          isEdit={isEdit}
          defaultScreenSizeId={defaultScreenSizeId}
        />
      </Modal>
    );
  }
}

ModalContainer.propTypes = {
  modalToggler: PropTypes.func,
  className: PropTypes.func,
  modal: PropTypes.bool,
  formId: PropTypes.string,
  toggleModalVideo: PropTypes.func,
  modalVideo: PropTypes.bool,
  contentFormId: PropTypes.string,
  fetchCreatePlaylistContent: PropTypes.func,
  fetchPlaylistContents: PropTypes.func,
  currentPlaylistId: PropTypes.number,
  modalScreens: PropTypes.bool,
  addContentStatus: PropTypes.string,
  updatePlaylistContentMetaGroup: PropTypes.func,
  currentScreen: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    playlists: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  contentFormId: state.app.contentFormId,
  playlistsContents: state.content.playlistsContents,
  currentPlaylistId: state.playlists.currentPlaylistId,
  showPreviewModal: state.app.showPreviewModal,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCreatePlaylistContent,
      fetchPlaylistContents,
      updatePlaylistContentMetaGroup: fetchUpdatePlaylistContentGroupMeta,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalContainer);
