import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { adjustElementBySize } from '../../../../../../../utils';
import Icons from '../../../Icons';
import UploadSpinner from '../../../UploadSpinner';
import styles from './HtmlPreview.module.scss';

export default class HtmlPreview extends Component {
  static propTypes = {
    onEdit: PropTypes.func.isRequired,
    screenSize: PropTypes.object.isRequired,
    htmlContent: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  constructor() {
    super();
    this.rootRef = React.createRef();
    this.iframeWrapperRef = React.createRef();
  }

  componentDidMount() {
    if (this.rootRef.current) {
      const { screenSize } = this.props;

      adjustElementBySize({
        container: this.rootRef.current,
        element: this.iframeWrapperRef.current,
        width: screenSize.resolutionWidth,
        height: screenSize.resolutionHeight,
      });
    }
  }

  render() {
    const { isLoading, htmlContent, onEdit } = this.props;

    return (
      <div ref={this.rootRef} className={styles.root}>
        {isLoading && <UploadSpinner className={styles.spinner} underlayClassName={styles.spinnerUnderlay} />}
        <div style={{ overflow: 'hidden' }} ref={this.iframeWrapperRef}>
          <iframe width="100%" height="100%" srcDoc={htmlContent} />
        </div>
        {!isLoading && (
          <button type="button" onClick={onEdit} className={styles.button}>
            <Icons icon="editIcon" marginRight={10} />
            Edit
          </button>
        )}
      </div>
    );
  }
}
