import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './OptionsButton.module.scss';
import Icon from '../Icon';

function OptionsButton({ children, className, ...rest }) {
  return (
    <button type="button" className={cn(styles.root, className && className)} {...rest}>
      <Icon name="settings" size={40} />
      <span className={styles.text}>Options</span>
    </button>
  );
}

OptionsButton.propTypes = {
  className: PropTypes.string,
};

export default OptionsButton;
