import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { formValueSelector } from 'redux-form';
import MediaLibrary from '../components/MediaLibrary';
import { appActions } from '../../../../../../state/ducks/app';
import { contentActions } from '../../../../../../state/ducks/content';
import { contentGroupActions, contentGroupSelectors } from '../../../../../../state/ducks/contentGroup';
import { setLinkedContentMetaIds } from '../../../../../../state/ducks/contentMeta/actions';
import LayoutConfirmationModal from '../../../../../layouts/LayoutConfirmationModal';
import styles from './CommonMediaLibraryContainer.module.scss';
import { createHtmlFile } from '../../../../../../state/utils/create-file-helper';
import getContentGroupWithMeta from '../../../../../../state/ducks/contentMeta/selectors';
import Modal from '../../../Modal';
import DropZoneContainer from '../../DropZoneContainer';
import OnDropZone from '../../OnDropZone';
import { getDefaultPlaylists } from '../../../../../../state/ducks/playlists/selectors';
import { getScreens } from '../../../../../../state/ducks/screens/selectors';
// import { normalizeEditorContentStyleAsString } from '../../../../../../utils';

const isNull = value => value === undefined || value === null;

const {
  fetchMultipleCreateContentGroup,
  setContentTypeFilter,
  setContentGroupToCheckList,
  fetchArrDeleteContentGroup,
  fetchAddContentGroupsToNotExistPlaylistFromMedia,
  fetchAddContentGroupsToExistPlaylistFromMedia,
  fetchAbortUploadFile,
  addPlaylistToPlaylist,
  createPlaylistAndAddContentMeta,
} = contentGroupActions;

const { getContentGroup } = contentGroupSelectors;

const { setShowConfirmModal, setPreviewModal } = appActions;
const { setContentsFilter, setContents, fetchAddContentMetaToPlaylist, setMediaLibraryModal } = contentActions;

class CommonMediaLibraryContainer extends React.Component {
  state = {
    mediaFiles: [],
    htmlEditorContent: '',
    isHtmlArea: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.mediaFiles) {
      const filteredExistIds = prevState.mediaFiles.filter(ids => !nextProps.checkList.find(cl => cl === ids));
      return {
        mediaFiles: [...filteredExistIds, ...nextProps.checkList],
      };
    }
    return null;
  }

  handleDeleteContent = ({ id, type }) => {
    const options =
      type === 'playlist'
        ? { title: 'Delete playlist?', fetchAction: 'fetchDeletePlaylist', id }
        : { title: 'Delete content item?', fetchAction: 'fetchDeleteContent', id };

    this.props.setShowConfirmModal(true, options);
  };

  handleSelectContent = item => {
    const { type, id } = item;
    const { mediaFiles } = this.state;
    const { setCheckList } = this.props;

    const isThisMediaFile = mf => mf.type === type && mf.id === id;
    const isExist = Boolean(mediaFiles.find(isThisMediaFile));

    const newMediaFiles = isExist ? mediaFiles.filter(mf => !isThisMediaFile(mf)) : [...mediaFiles, item];
    setCheckList(newMediaFiles);
    this.setState({ mediaFiles: newMediaFiles });
  };

  setCheckBox = () => {
    this.setState({ mediaFiles: [] });
  };

  addMediaFilesToPlaylist = mediaFiles => {
    const {
      newScreenName,
      addContentGroupMetaToNotExistPlaylist,
      addContentGroupMetaToExistPlaylist,
      orientationSelect,
      location: { pathname },
      currentPlaylistId,
      setMediaLibraryModal,
      setCheckList,
      cleanUpOfLinkedContentMeta,
    } = this.props;

    cleanUpOfLinkedContentMeta([]);
    setCheckList([]);
    this.setCheckBox();

    const currentPlaylistIdPath = pathname.substring(pathname.lastIndexOf(`/`) + 1);

    if (currentPlaylistId && currentPlaylistIdPath !== 'new') {
      addContentGroupMetaToExistPlaylist(mediaFiles, currentPlaylistId);
    } else if (currentPlaylistIdPath === 'new') {
      const { formData } = this.props;

      if (formData) {
        this.props.createPlaylistAndAddContentMeta({ formData, mediaFiles });
      } else {
        addContentGroupMetaToNotExistPlaylist(mediaFiles, newScreenName, orientationSelect);
      }

      setMediaLibraryModal(false, { id: null, newScreenName: null, orientationSelect: null });
    }
  };

  addContentToPlaylist = () => {
    const { mediaFiles } = this.state;
    this.addMediaFilesToPlaylist(mediaFiles);
  };

  deletePlaylist = playlistId => {
    const { screens } = this.props;

    const screensUsedPlaylist = screens.filter(
      screen => screen.playlists && screen.playlists.find(playlist => playlist.id === playlistId),
    );

    const screenNames = screensUsedPlaylist.reduce((accumulate, curr) => [...accumulate, curr.name], []);
    const stringOfScreenNames = screenNames.join(', ');

    this.props.setShowConfirmModal(true, {
      screenNames: stringOfScreenNames,
      isDeletingPlaylist: true,
      playlistId,
    });
  };

  deleteContentFromMediaLibrary = () => {
    const { mediaFiles } = this.state;
    const { contentGroupWithMeta, setCheckList, screens } = this.props;

    const playlistIds = mediaFiles.filter(({ type }) => type === 'playlist').map(({ id }) => id);
    const firstPlaylistIdWhichUsedBySomeScreen = playlistIds.find(playlistId =>
      screens.find(screen => screen.playlists && screen.playlists.find(playlist => playlist.id === playlistId)),
    );

    if (!isNull(firstPlaylistIdWhichUsedBySomeScreen)) {
      this.deletePlaylist(firstPlaylistIdWhichUsedBySomeScreen);
      return;
    }

    const getContentGroupsUsedByPlaylist = contentGroupWithMeta.filter(meta =>
      mediaFiles.find(
        ({ id: entityId }) =>
          (meta.entityType === 'contentGroup' && meta.entityId === entityId) ||
          (meta.entityType === 'playlist' && meta.entityId === entityId),
      ),
    );

    if (getContentGroupsUsedByPlaylist.length !== 0) {
      const retrieveScreensNames = getContentGroupsUsedByPlaylist.map(item => item.playlistName);
      const removeDuplicateScreenNames = [...new Set(retrieveScreensNames)];

      const retrieveContentGroupNames = getContentGroupsUsedByPlaylist.map(item => `"${item.name}"`);
      const removeDuplicateContentGroupNames = [...new Set(retrieveContentGroupNames)];

      this.props.setShowConfirmModal(true, {
        title: 'Are you sure you want to delete it?',
        fetchAction: 'fetchArrDeleteContentGroup',
        removeUsedContent: true,
        id: mediaFiles,
        listOfPlaylists: removeDuplicateScreenNames,
        listOfContentGroups: removeDuplicateContentGroupNames,
      });
    } else {
      this.props.setShowConfirmModal(true, {
        title: 'Are you sure you want to delete this file?',
        fetchAction: 'fetchArrDeleteContentGroup',
        id: mediaFiles,
      });
    }

    setCheckList([]);
  };

  onClickSetContentFilter = e => {
    const { setContentType } = this.props;
    setContentType(e.target.id);
  };

  handleSendHtml = () => {
    const { multipleUploadContent, froalaFileName } = this.props;
    const { htmlEditorContent } = this.state;

    function myTrim(x) {
      return x.replace(/^\s+|\s+$/gm, '');
    }

    // const normalizedContent = normalizeEditorContentStyleAsString(htmlEditorContent);
    const normalizedContent = htmlEditorContent;
    const file = createHtmlFile(normalizedContent, myTrim(froalaFileName));
    const isCustomHtml = true;
    multipleUploadContent([file], isCustomHtml);

    this.setState({
      htmlEditorContent: '',
      isHtmlArea: false,
    });
  };

  handleChangeHtmlArea = boolData => {
    this.setState({
      isHtmlArea: boolData,
    });
  };

  handleCleanFieldsOfHtmlEditor = () => {
    this.setState({
      htmlEditorContent: '',
      isHtmlArea: false,
    });
  };

  closeMediaLibraryModal = () => {
    this.setCheckBox();
    this.props.setMediaLibraryModal(false, { id: null, newScreenName: null, orientationSelect: null });
    this.props.setCheckList([]);
  };

  handleSetHtmlTemplate = (event, template) => {
    event && event.preventDefault();
    this.setState({
      htmlEditorContent: template,
    });
  };

  handleShowPreviewOfContent = (event, content, screenSizeId) => {
    event.preventDefault();
    const { showPreviewModal } = this.props;
    showPreviewModal(true, { content, screenSizeId });
  };

  handleOnChangeAceEditor = newValue => {
    this.setState({
      htmlEditorContent: newValue,
    });
  };

  render() {
    const {
      isUploadingContent,
      contentsProgress,
      isOpenPreviewModal,
      froalaFileName,
      defaultScreenSizeId,
      isModalOpen,
    } = this.props;
    const { mediaFiles, htmlEditorContent, isHtmlArea } = this.state;
    const noop = () => {};

    return (
      <Modal
        isOpen={isModalOpen}
        scroll="body"
        modalClassName={styles.modal}
        containerClassName={styles.modalContainer}
        onClose={isOpenPreviewModal ? noop : this.closeMediaLibraryModal}
      >
        <OnDropZone
          requestType="content"
          acceptType="multiply"
          isMediaLibrary={true}
          isHtmlArea={isHtmlArea}
          contentFilter={this.props.contentFilter}
          // mediaStyles={styles.dropZone}
          onClickSetContentFilter={this.onClickSetContentFilter}
          handleCleanFieldsOfHtmlEditor={this.handleCleanFieldsOfHtmlEditor}
        >
          {({ isDragActive, getInputProps, open, getRootProps, isDragReject }) => (
            <>
              <DropZoneContainer className={styles.modalBody} {...{ isDragActive, isDragReject, getRootProps }}>
                <div className={styles.modalHeader}>
                  My Files
                  <button type="button" className={styles.modalCloseBtn} onClick={this.closeMediaLibraryModal} />
                </div>
                <MediaLibrary
                  open={open}
                  handleSendHtml={this.handleSendHtml}
                  onDeleteContent={this.handleDeleteContent}
                  onSelectContent={this.handleSelectContent}
                  handleChangeHtmlArea={this.handleChangeHtmlArea}
                  addContentToPlaylist={this.addContentToPlaylist}
                  handleSetHtmlTemplate={this.handleSetHtmlTemplate}
                  handleOnChangeAceEditor={this.handleOnChangeAceEditor}
                  addMediaFilesToPlaylist={this.addMediaFilesToPlaylist}
                  setMediaLibraryModal={this.props.setMediaLibraryModal}
                  onClickSetContentFilter={this.onClickSetContentFilter}
                  handleShowPreviewOfContent={this.handleShowPreviewOfContent}
                  deleteContentFromMediaLibrary={this.deleteContentFromMediaLibrary}
                  handleCleanFieldsOfHtmlEditor={this.handleCleanFieldsOfHtmlEditor}
                  {...{
                    mediaFiles,
                    isHtmlArea,
                    getInputProps,
                    froalaFileName,
                    contentsProgress,
                    htmlEditorContent,
                    isUploadingContent,
                    defaultScreenSizeId,
                  }}
                  {...this.props}
                />
                <LayoutConfirmationModal
                  confirmModal={this.props.confirmModal}
                  setCheckboxFunction={this.setCheckBox}
                />
              </DropZoneContainer>
            </>
          )}
        </OnDropZone>
      </Modal>
    );
  }
}

const selector = formValueSelector('froalaForm');
const screenSizeFormSelector = formValueSelector('sizeForm');

const getDefaultScreenSizeId = screens => {
  const pathParts = window.location.pathname.split('/');
  const isScreens = pathParts[1] === 'screens';

  if (!isScreens) {
    return null;
  }

  const screenId = pathParts[2];
  const isNewScreen = screenId === 'new';
  if (isNewScreen) {
    return null;
  }

  const currentScreen = screens.find(({ id }) => id === +screenId);

  if (!currentScreen) {
    return null;
  }

  return currentScreen.screenSizeId;
};

const mapStateToProps = state => ({
  contents: getContentGroup(state),
  contentGroupWithMeta: getContentGroupWithMeta(state),
  currentPlaylistId: state.content.mediaLibraryModal.data.id,
  formData: state.content.mediaLibraryModal.data.formData,
  playlistsContents: state.content.playlistsContents,
  newScreenName: state.content.mediaLibraryModal.data.newScreenName,
  playlistName: state.content.mediaLibraryModal.data.playlistName,
  orientationSelect: state.content.mediaLibraryModal.data.orientationSelect,
  isUploadingContent: state.content.isUploadingContent,
  contentsProgress: state.content.contentsProgress,
  confirmModal: state.app.confirmModal,
  addScreenPlaylistId: state.screens.screens,
  isModalOpen: state.content.mediaLibraryModal.modalToggler,
  checkList: state.contentGroup.checkList,
  screenSizes: state.screenSizes.screenSizes,
  isOpenPreviewModal: state.app.showPreviewModal,
  froalaFileName: selector(state, 'name'),
  playlists: getDefaultPlaylists(state),
  screens: getScreens(state),
  defaultScreenSizeId: screenSizeFormSelector(state, 'screenSizeId') || getDefaultScreenSizeId(state.screens.screens),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setShowConfirmModal,
      setContentsFilter,
      setContents,
      fetchAddContentMetaToPlaylist,
      setMediaLibraryModal,
      fetchArrDeleteContentGroup,
      fetchAbortUploadFile,
      createPlaylistAndAddContentMeta,
      addContentGroupMetaToNotExistPlaylist: fetchAddContentGroupsToNotExistPlaylistFromMedia,
      addContentGroupMetaToExistPlaylist: fetchAddContentGroupsToExistPlaylistFromMedia,
      setContentType: setContentTypeFilter,
      setCheckList: setContentGroupToCheckList,
      cleanUpOfLinkedContentMeta: setLinkedContentMetaIds,
      multipleUploadContent: fetchMultipleCreateContentGroup,
      showPreviewModal: setPreviewModal,
      addPlaylistToPlaylist,
    },
    dispatch,
  );

const CommonMediaLibraryContainerWithRouter = withRouter(CommonMediaLibraryContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommonMediaLibraryContainerWithRouter);
