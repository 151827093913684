import React from 'react';
import PropTypes from 'prop-types';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import cn from 'classnames';
import styles from './SpeakerItem.module.scss';
import SettingsButtons from '../../../../common/components/UI/SettingsButtons';

const DragHandle = SortableHandle(({ isOpen }) => (
  <div className={cn(styles.dragHandle, isOpen && styles.disableDrag)} />
));

function SpeakerItem({ value, onRemove, onEdit, isOpen }) {
  return (
    <div className={styles.container}>
      <div className={styles.innerContent}>
        <DragHandle isOpen={isOpen} />
        {`${value.speakerName}${value.speakerJob && `, ${value.speakerJob}`}${value.speakerCompany &&
          `, ${value.speakerCompany}`}`}
      </div>
      <div className={styles.btnGroup}>
        <SettingsButtons
          customClassEditBtn={styles.editBtn}
          customClassDeleteBtn={styles.deleteBtn}
          handleOnEdit={onEdit}
          handleOnRemove={onRemove}
        />
      </div>
    </div>
  );
}

SpeakerItem.propTypes = {
  value: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default SortableElement(SpeakerItem);
