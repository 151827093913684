import * as types from './types';

export const wsInit = () => ({ type: types.WS_INIT });

export const wsEmit = payload => ({
  type: types.WS_CMS_EMIT,
  payload,
});

export const getDesktopStatus = () => ({
  type: types.WS_CMS_EMIT,
  wsType: types.GET_DESKTOP_STATUS,
});

export const screenIsUpdated = payload => ({
  type: types.WS_CMS_EMIT,
  wsType: types.SCREEN_IS_UPDATED,
  wsPayload: payload,
});

export const receiveDesktopStatus = payload => ({
  type: types.RECEIVE_DESKTOP_STATUS,
  payload,
});
