import React from 'react';
import PropTypes from 'prop-types';
import styles from './SessionTheatreList.module.scss';
import SessionTheatreItem from '../SessionTheatreItem';

const SessionTheatreList = ({
  sessionList,
  handleRemoveSession,
  handleEditSession,
  sessionRemovalList,
  handleAddSessionToDate,
  handleRemoveSessionByDate,
  canEdit,
}) => (
  <div className={styles.sessionTheatreList}>
    {sessionList.map((item, index) => (
      <SessionTheatreItem
        handleAddSessionToDate={handleAddSessionToDate}
        handleRemoveSessionByDate={handleRemoveSessionByDate}
        sessionRemovalList={sessionRemovalList}
        handleRemoveSession={handleRemoveSession}
        handleEditSession={handleEditSession}
        key={item.planingDay}
        item={item}
        index={index}
        canEdit={canEdit}
      />
    ))}
  </div>
);

SessionTheatreList.propTypes = {
  canEdit: PropTypes.bool,
  sessionList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      planingDay: PropTypes.string.isRequired,
      sessionListMatches: PropTypes.arrayOf(
        PropTypes.shape({
          day: PropTypes.string.isRequired,
          description: PropTypes.string,
          start: PropTypes.string.isRequired,
          end: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
          programmeId: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  handleRemoveSession: PropTypes.func.isRequired,
  handleEditSession: PropTypes.func.isRequired,
  sessionRemovalList: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleAddSessionToDate: PropTypes.func.isRequired,
  handleRemoveSessionByDate: PropTypes.func.isRequired,
};

export default SessionTheatreList;
