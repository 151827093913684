import React from "react";
import axios from "axios";
import axiosCancel from "axios-cancel";
import { Router, Switch } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { provideDispatchToFetch } from "./state/utils/fetch";
import { provideDispatchToHandleError } from "./state/utils/handle-error";
import { history } from "./state/utils";

import Layout from "./views/layouts";
import configureStore from "./state/store";
import ErrorBoundary from "./views/common/components/ErrorBoundary/ErrorBoundary";

export const reduxStore = configureStore({});

provideDispatchToFetch(reduxStore.dispatch);
provideDispatchToHandleError(reduxStore.dispatch);

axiosCancel(axios, {
  debug: false,
});

const App = () => (
  <ReduxProvider store={reduxStore}>
    <ErrorBoundary>
      <Router history={history}>
        <Switch>
          <Layout />
        </Switch>
      </Router>
    </ErrorBoundary>
  </ReduxProvider>
);

export default App;
