import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./CompanionQRCode.module.scss";
import Icons from "../../../common/components/UI/Icons";
import MenuList from '../../../common/components/UI/MenuList/MenuList';
import makeToggleable from '../../../hocs/makeToggleable';

function CompanionQRCode({ showNotification, accessCode, toggle, isOpen }) {

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/player/${accessCode}`
      );
      showNotification({
        level: "success",
        autoDismiss: 3,
        message: "Player URL successfully copied to clipboard",
      });
    } catch (err) {
      showNotification({
        level: "error",
        autoDismiss: 3,
        message: "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <div className={cn(styles.container, isOpen && styles.containerIsOpen, accessCode && styles.tooltip)} >
      <button
        disabled={!accessCode}
        onClick={() => {
          toggle();
        }}
        className={`${styles.buttonPlayer} ${accessCode ? "" : styles.buttonDisabled
          }`}
      >
        <span className={styles.text}>QR</span>
      </button>
      <MenuList className={styles.actionsMenu}>
        <img src="https://cdn.pixabay.com/photo/2013/07/12/14/45/qr-code-148732_960_720.png" />
      </MenuList>
    </div>
  );
}

CompanionQRCode.propTypes = {
  accessCode: PropTypes.string.isRequired,
};

export default makeToggleable(CompanionQRCode);
