import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Field } from 'redux-form';

import DatePicker from '../../DatePicker';
import TimeField from '../../TimeField';
import Icons from '../../Icons';

import styles from './FieldItem.module.scss';

class FieldItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      isTouched: false,
    };

    this.endTimeInput = React.createRef();
  }

  componentDidUpdate() {
    this.props.onAddNewField();
  }

  handleFocus = () => this.setState({ isFocused: true });

  handleBlur = () => this.setState({ isFocused: false, isTouched: true });

  render = () => {
    const { period, index, field, fields, onRemove, onAddNewField } = this.props;
    const { isFocused } = this.state;

    const isEmpty = value => (value ? value === '' : true);
    const atLeastOneFieldIsFilled = !isEmpty(field.start) || !isEmpty(field.end) || !isEmpty(field.date);

    const fieldProps = { onBlur: this.handleBlur, onFocus: this.handleFocus };
    const isShowTrash = !this.state.isFocused && atLeastOneFieldIsFilled;

    // const prevField = fields.get(index - 1);
    // const defaultDate = prevField ? prevField.date : null;

    return (
      <div key={index} className={cn(styles.container, isFocused && styles.containerIsFocused)}>
        <Field
          placeholder="08:00"
          component={TimeField}
          name={`${period}.start`}
          onChange={e => {
            if (!e.target.value.includes('_') && e.target.value !== '' && this.endTimeInput.current) {
              this.endTimeInput.current.inputElement.focus();
            }
            if (!this.state.isTouched) {
              this.props.onAddFieldDate();
            }
          }}
          className={styles.item}
          {...fieldProps}
        />
        <span className={styles.to}>to</span>
        <Field
          placeholder="14:00"
          component={TimeField}
          name={`${period}.end`}
          innerRef={this.endTimeInput}
          onChange={() => {
            if (!this.state.isTouched) {
              this.props.onAddFieldDate();
            }
          }}
          className={styles.item}
          {...fieldProps}
        />
        <Field
          // defaultValue={defaultDate}
          className={styles.datePicker}
          inputClassName={styles.item}
          component={DatePicker}
          name={`${period}.date`}
          isShowCalendarIcon={!isShowTrash}
          {...fieldProps}
        />
        {isShowTrash && (
          <div
            className={styles.trash}
            onClick={() => {
              this.setState({ isTouched: false });
              onRemove();
            }}
          >
            <Icons icon="deleteTrash" width={24} />
          </div>
        )}
      </div>
    );
  };
}

FieldItem.propTypes = {
  fields: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  onAddNewField: PropTypes.func.isRequired,
};

export default FieldItem;
