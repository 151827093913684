import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { playlistsActions } from '../../../../../state/ducks/playlists';
import styles from './ScreenDetailsView.module.scss';
import ScreenSizeEditScreen from '../ScreenSizeEditScreen';
import { screensActions, screensSelectors } from '../../../../../state/ducks/screens';
import { screenSizesActions, screenSizesSelectors } from '../../../../../state/ducks/screenSizes';
import { appActions } from '../../../../../state/ducks/app';
import ScreenNameForm from '../ScreenNameForm';
import ScreenSizeCard from '../ScreenSizeCard';
import SelectedPlaylistIndicator from '../SelectedPlaylistIndicator';
import PlaylistContents from '../PlaylistContents';
import DropZone from './DropZone';
import ChoosePlaylistModal from '../../../../common/components/ChoosePlaylistModal';
import ContentSettingsModal from '../../../../common/components/UI/ModalAddContent';
import { contentActions } from '../../../../../state/ducks/content';
import { contentGroupActions } from '../../../../../state/ducks/contentGroup';
import DropZoneContainer from '../../../../common/components/UI/DropZoneContainer';
import history from '../../../../../state/utils/history';
import { templateTypes } from '../../../../../constants';
import PlaylistTemplateCard from '../../../PlayLists/components/PlaylistTemplateCard';
import ConnectedAddFilesBtn from '../../../../common/components/ConnectedAddFilesBtn/ConnectedAddFilesBtn';
import NestedPlaylistBoxes from '../../../../common/components/NestedPlaylistBoxes/NestedPlaylistBoxes';
import { fetchUpdateScreenPlaylists } from '../../../../../state/ducks/screens/actions';

const { fetchRemoveAndSetPlaylistToScreen } = screensActions;

const { fetchRemoveContentGroupFromPlaylist } = playlistsActions;
const { setContentFormId } = appActions;
const { getScreenWithDesktop } = screensSelectors;
const { fetchCreateScreenSizes } = screenSizesActions;
const { fetchScreenUpdate } = screensActions;
const { getScreenSizes } = screenSizesSelectors;
const { setContentTypeFilter } = contentGroupActions;
const { setMediaLibraryModal } = contentActions;
const { fetchReorderContentGroupMeta, fetchAbortUploadFile } = contentGroupActions;

class ScreenDetailsView extends React.Component {
  state = {
    // I don't know why these properties
    delId: null,
    createdScreenName: null,
    showDeleteButton: false,
    isSelectContent: 'addContent',

    // But know why these
    isOpenChoosePlaylistModal: false,
    isOpenContentSettingsModal: false,
    contentSettingsModalValues: {},
    selectedPlaylist: null,
  };

  handleOpenChoosePlaylistModal = () => this.setState({ isOpenChoosePlaylistModal: true });

  handleCloseChoosePlaylistModal = () => this.setState({ isOpenChoosePlaylistModal: false });

  handleOpenContentSettingsModal = contentGroup => {
    // TODO: create redux-actions for this
    this.setState({
      isOpenContentSettingsModal: true,
      contentSettingsModalValues: contentGroup,
    });

    this.props.setContentFormId(contentGroup.type);
  };

  handleCloseContentSettingsModal = () => {
    this.setState({ isOpenContentSettingsModal: false });
  };

  handleChangeNameOfScreen = event => {
    const {
      match: { params },
      updateScreenItem,
    } = this.props;
    const screenId = Number(params.id);

    const { value } = event.target;

    const removeExtraSpaces = value.replace(/\s+/g, ' ').trim();
    if (removeExtraSpaces.length !== 0) {
      updateScreenItem(screenId, { name: removeExtraSpaces });
    }
  };

  handleRedirectToPlaylist = playlistId => {
    history.push(`/playlists/${playlistId}`);
  };

  renderEditScreenSize = () => {
    const {
      match: { params },
      screens,
      isEditMode,
      screenSizes,
      currentScreen,
      createScreenSize,
      updateScreenItem,
      handleClickOpenEdit,
      handleClickCloseEdit,
      customSizeBtnIsActive,
      handleCloseAddCustomSize,
      handleClickAddCustomSize,
    } = this.props;

    const activeScreenId = Number(params.id);
    const currentScreenSizeId =
      screens.find(el => el.id === activeScreenId) && screens.find(el => el.id === activeScreenId).screenSizeId;

    return (
      <>
        <div className={styles.titleSelectSize}>Select size of the screen</div>
        <ScreenSizeEditScreen
          isEditMode={isEditMode}
          screenSizeList={screenSizes}
          currentScreenSizeId={currentScreenSizeId}
          currentScreen={currentScreen}
          initialValues={{ screenSizeId: currentScreenSizeId }}
          handleClickOpenEdit={handleClickOpenEdit}
          handleClickCloseEdit={handleClickCloseEdit}
          updateScreenItem={updateScreenItem}
          handleClickAddCustomSize={handleClickAddCustomSize}
          handleCloseAddCustomSize={handleCloseAddCustomSize}
          customSizeBtnIsActive={customSizeBtnIsActive}
          createScreenSize={createScreenSize}
        />
      </>
    );
  };

  renderDefaultPlaylist = defaultPlaylist => (
    <>
      <PlaylistContents
        playlistId={defaultPlaylist.id}
        style={{ marginBottom: 24 }}
        onEditItem={this.handleOpenContentSettingsModal}
      />
      <div className={styles.footer}>
        <ConnectedAddFilesBtn modalData={{ id: defaultPlaylist.id }} />
      </div>
    </>
  );

  renderTheatrePlaylist = theatrePlaylist => {
    const { selectedPlaylistId } = JSON.parse(theatrePlaylist.options);
    return (
      <>
        <PlaylistContents
          withFullProgramme
          playlistType="theatre"
          playlistId={selectedPlaylistId}
          style={{ marginBottom: 24 }}
          onEditItem={this.handleOpenContentSettingsModal}
        />
        <div className={styles.footer}>
          <ConnectedAddFilesBtn modalData={{ id: theatrePlaylist.id }} />
        </div>
      </>
    );
  };

  renderSplitPlaylist = splitPlaylist => {
    const { firstPlaylistId, secondPlaylistId } = JSON.parse(splitPlaylist.options);

    return (
      <NestedPlaylistBoxes
        firstPlaylistId={firstPlaylistId}
        secondPlaylistId={secondPlaylistId}
        selectedPlaylistId={splitPlaylist.id}
      />
    );
  };

  renderPlaylist = selectedPlaylist => {
    if (!selectedPlaylist) {
      return null;
    }

    const renderers = {
      [templateTypes.DEFAULT]: this.renderDefaultPlaylist,
      [templateTypes.SPLIT]: this.renderSplitPlaylist,
      [templateTypes.THEATRE]: this.renderTheatrePlaylist,
    };

    return renderers[selectedPlaylist.type](selectedPlaylist);
  };

  renderPlaylistWrapper = ({ children, selectedPlaylistType }) => {
    const { selectedPlaylistId } = this.props;

    const isSplit = selectedPlaylistType === templateTypes.SPLIT;

    if (isSplit) {
      return <div className={styles.container}>{children}</div>;
    }

    return (
      <DropZone selectedPlaylistId={selectedPlaylistId}>
        {({ getRootProps, isDragActive, isDragReject }) => (
          <DropZoneContainer
            className={styles.container}
            isDragReject={isDragReject}
            isDragActive={isDragActive}
            getRootProps={getRootProps}
          >
            {children}
          </DropZoneContainer>
        )}
      </DropZone>
    );
  };

  render() {
    const { currentScreen } = this.props;

    if (!currentScreen) {
      return <Redirect to="/screens" />;
    }

    const {
      match: { params },
      screens,
      playlists,
      isEditMode,
      screenSizes,
      selectedPlaylists,
      handleClickOpenEdit,
    } = this.props;

    const { selectedPlaylist } = this.state;

    const activeScreenId = Number(params.id);
    const currentScreenSizeId =
      screens.find(el => el.id === activeScreenId) && screens.find(el => el.id === activeScreenId).screenSizeId;

    const { renderPlaylist } = this;
    const { isOpenChoosePlaylistModal } = this.state;
    const selectedPlaylistType = (selectedPlaylists[0] || {}).type || '';

    return (
      <>
        {isEditMode ? (
          this.renderEditScreenSize()
        ) : (
          <div className={styles.container}>
            <ScreenNameForm
              isEditMode={isEditMode}
              currentScreen={currentScreen}
              initialValues={{ name: currentScreen && currentScreen.name }}
              onChangeName={this.handleChangeNameOfScreen}
            />
            <div className={styles.screenInfo}>
              <ScreenSizeCard id={currentScreenSizeId} screenSizes={screenSizes} onEdit={handleClickOpenEdit} />
              {selectedPlaylistType !== templateTypes.DEFAULT && selectedPlaylistType !== '' && (
                <PlaylistTemplateCard type={selectedPlaylistType} style={{ marginLeft: 10 }} />
              )}
            </div>
            {selectedPlaylists.map(_selectedPlaylist => (
              <>
                <SelectedPlaylistIndicator
                  isExistScreen={true}
                  isLoading={!_selectedPlaylist}
                  title={(_selectedPlaylist || {}).name || ''}
                  id={(_selectedPlaylist || {}).id}
                  onRemovePlaylist={() =>
                    this.props.updateScreenPlaylists(currentScreen.id, undefined, _selectedPlaylist.id)
                  }
                  onOpenChoosePlaylistModal={() => {
                    this.setState({ selectedPlaylist: _selectedPlaylist });
                    this.handleOpenChoosePlaylistModal();
                  }}
                  onRedirectToPlaylist={() => this.handleRedirectToPlaylist(_selectedPlaylist.id)}
                />
                {renderPlaylist(_selectedPlaylist)}
              </>
            ))}
            {selectedPlaylistType === 'theatre' ? (
              <SelectedPlaylistIndicator
                title={'Add playlist'}
                onOpenChoosePlaylistModal={this.handleOpenChoosePlaylistModal}
              />
            ) : (
              ''
            )}
          </div>
        )}
        <ChoosePlaylistModal
          playlists={playlists}
          isOpen={isOpenChoosePlaylistModal}
          selectedPlaylistId={selectedPlaylist ? selectedPlaylist.id : ''}
          onClose={this.handleCloseChoosePlaylistModal}
          onChoosePlaylist={newSelectedPlaylistId => {
            if (selectedPlaylist)
              this.props.updateScreenPlaylists(currentScreen.id, newSelectedPlaylistId, selectedPlaylist.id);
            else this.props.updateScreenPlaylists(currentScreen.id, newSelectedPlaylistId);
          }}
        />
        <ContentSettingsModal
          // TODO: rename props
          modalToggler={this.handleCloseContentSettingsModal}
          modalScreens={this.state.isOpenContentSettingsModal}
          values={this.state.contentSettingsModalValues}
          currentScreen={currentScreen}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentScreenId = +ownProps.match.params.id;
  const currentScreen = getScreenWithDesktop(state, currentScreenId);

  const selectedPlaylistIds =
    (currentScreen && currentScreen.playlists && currentScreen.playlists.map(p => p.id)) || null;

  const selectedPlaylists = selectedPlaylistIds
    ? state.playlists.playlists.filter(({ id }) => selectedPlaylistIds.includes(id))
    : [];

  return {
    currentScreen,
    selectedPlaylists,
    selectedPlaylistIds,
    screens: state.screens.screens,
    screenSizes: getScreenSizes(state),
    playlists: state.playlists.playlists,
    contentsProgress: state.content.contentsProgress,
    contentRemovalList: state.playlists.contentRemovalList,
  };
};

const mapDispatchToProps = {
  setContentFormId,
  setMediaLibraryModal,
  setContentTypeFilter,
  abortUploadFile: fetchAbortUploadFile,
  updateScreenItem: fetchScreenUpdate,
  createScreenSize: fetchCreateScreenSizes,
  addPlaylistToScreen: fetchRemoveAndSetPlaylistToScreen,
  updateScreenPlaylists: fetchUpdateScreenPlaylists,
  updateOrderOfContentGroupMeta: fetchReorderContentGroupMeta,
  removeContentGroupFromPlaylist: fetchRemoveContentGroupFromPlaylist,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ScreenDetailsView));
