import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Range } from 'rc-slider';
import styles from './SizeRange.module.scss';

// const min = 0;
// const max = 70;

// const getDefaultRangeValue = (hasBottomBar = false) => (hasBottomBar ? [min, max - 10, max] : [min, max, max]);

// const p = value => `${value}%`;

// const getComputedRangeValue = ({ insidePlaylist }) => [min, insidePlaylist, max];

// const getComputedInputValue = (rangeValue, hasBottomBar) => {
//   const result = {};
//   const [, firstValue] = rangeValue;

//   result.insidePlaylist = firstValue;

//   if (hasBottomBar) {
//     result.bottomBar = max - firstValue;
//   } else {
//     result.bottomBar = 0;
//   }

//   return result;
// };

class SizeRange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      min: 0,
      max: 60,

    };
  }

  getDefaultRangeValue = (hasBottomBar = false) => {
    const { min, max } = this.state;
    return hasBottomBar ? [min, max - 10, max] : [min, max, max]
  };

  p = value => `${value}%`;

  getComputedRangeValue = ({ insidePlaylist }) => {
    const { min, max } = this.state;
    return [min, insidePlaylist, max]
  };

  getComputedInputValue = (rangeValue, hasBottomBar) => {
    const { max } = this.state;
    const result = {};
    const [, firstValue] = rangeValue;

    result.insidePlaylist = firstValue;

    if (hasBottomBar) {
      result.bottomBar = max - firstValue;
    } else {
      result.bottomBar = 0;
    }

    return result;
  };

  componentDidUpdate(prevProps) {
    const isOffBottomBar = prevProps.hasBottomBar && !this.props.hasBottomBar;
    const isOnBottomBar = !prevProps.hasBottomBar && this.props.hasBottomBar;

    if (isOffBottomBar || isOnBottomBar) {
      this.handleReset();
    }
  }

  canChange = newValue => {
    const [, insidePlSize] = newValue;
    return insidePlSize > 9;
  };

  handleChange = newValue => {
    if (!this.canChange(newValue)) return;

    const {
      hasBottomBar,
      input: { onChange, onBlur },
    } = this.props;

    onChange(this.getComputedInputValue(newValue, hasBottomBar));
    onBlur();
  };

  handleReset = () => {
    const { hasBottomBar } = this.props;
    this.setState({ min: 0, max: 60 });
    this.handleChange(this.getDefaultRangeValue(hasBottomBar));
  };

  renderRange() {
    const { min, max } = this.state;
    const { hasBottomBar, input } = this.props;
    const { insidePlaylist, bottomBar } = input.value;

    const insidePlaylistPercent = this.p((insidePlaylist * 100) / max);
    const bottomBarPercent = this.p((bottomBar * 100) / max);

    const rangeValue = this.getComputedRangeValue(input.value, hasBottomBar);
    const handleStyle = rangeValue.map((_, idx) =>
      idx === 0 || idx === rangeValue.length - 1 ? { display: 'none' } : {},
    );

    return (
      <div className={styles.rangeRoot}>
        <div className={styles.rangeContainer}>
          <Range
            min={min}
            max={max}
            pushable={hasBottomBar ? 1 : 0}
            railStyle={{ height: 0 }}
            handleStyle={handleStyle}
            value={rangeValue}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.display}>
          <div style={{ width: insidePlaylistPercent }} className={cn(styles.displayItem, styles.insidePlaylist)}>
            <div className={styles.box}>Inside Playlist</div>
            <div className={styles.percent}>{this.p(insidePlaylist)}</div>
          </div>

          {hasBottomBar && (
            <div style={{ width: bottomBarPercent }} className={cn(styles.displayItem, styles.bottomBar)}>
              <div className={styles.box}>Bottom bar</div>
              <div className={styles.percent}>{this.p(bottomBar)}</div>
            </div>
          )}
          <div className={styles.bottomLine} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.size}>
        <div className={styles.control}>
          <span className={styles.sizeLabel}>Size:</span>
          <div className={styles.fontSizeInput}>
            <input className="my-form-control" value={this.state.max} type="number" onChange={e => this.setState({ ...this.state, max: Number(e.target.value) })} />
            <div className={styles.pt}>%</div>
          </div>
          <div className={styles.spacer} />
          <button type="button" className={styles.sizeReset} onClick={this.handleReset}>
            Default
          </button>
        </div>
        <div className={styles.sizeControl}>{this.renderRange()}</div>
      </div>
    );
  }
}

SizeRange.defaultProps = {
  hasBottomBar: false,
};

SizeRange.propTypes = {
  id: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    warning: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.isRequired,
    value: PropTypes.isRequired,
    onBlur: PropTypes.isRequired,
    onChange: PropTypes.isRequired,
  }).isRequired,
  hasBottomBar: PropTypes.bool,
};

export default SizeRange;
