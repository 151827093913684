import * as types from './types';
import { createAction } from '../../utils';

export const setDataFeedList = createAction(types.SET_DATA_FEED_LIST);
export const setDataFeedHistoryList = createAction(types.SET_DATA_FEED_HISTORY_LIST);

export const openCreateModal = createAction(types.OPEN_CREATE_MODAL);
export const closeCreateModal = createAction(types.CLOSE_CREATE_MODAL);

export const openEditModal = createAction(types.OPEN_EDIT_MODAL);
export const closeEditModal = createAction(types.CLOSE_EDIT_MODAL);

// CREATE DATA FEED
export const createDataFeed = createAction(types.CREATE_DATA_FEED);
export const createDataFeedRequest = createAction(types.CREATE_DATA_FEED_REQUEST);
export const createDataFeedSuccess = createAction(types.CREATE_DATA_FEED_SUCCESS);
export const createDataFeedFailure = createAction(types.CREATE_DATA_FEED_FAILURE);

// UPDATE DATA FEED
export const updateDataFeed = createAction(types.UPDATE_DATA_FEED);
export const updateDataFeedRequest = createAction(types.UPDATE_DATA_FEED_REQUEST);
export const updateDataFeedSuccess = createAction(types.UPDATE_DATA_FEED_SUCCESS);
export const updateDataFeedFailure = createAction(types.UPDATE_DATA_FEED_FAILURE);

// DELETE DATA FEED
export const deleteDataFeed = createAction(types.DELETE_DATA_FEED);
export const deleteDataFeedConfirm = createAction(types.DELETE_DATA_FEED_CONFIRM);
export const deleteDataFeedRequest = createAction(types.DELETE_DATA_FEED_REQUEST);
export const deleteDataFeedSuccess = createAction(types.DELETE_DATA_FEED_SUCCESS);
export const deleteDataFeedFailure = createAction(types.DELETE_DATA_FEED_FAILURE);

// FETCH DATA FEEDs
export const fetchDataFeeds = createAction(types.FETCH_DATA_FEEDS);
export const fetchDataFeedsRequest = createAction(types.FETCH_DATA_FEEDS_REQUEST);
export const fetchDataFeedsSuccess = createAction(types.FETCH_DATA_FEEDS_SUCCESS);
export const fetchDataFeedsFailure = createAction(types.FETCH_DATA_FEEDS_FAILURE);

// FETCH DATA FEED APIs
export const fetchDataFeedApis = createAction(types.FETCH_DATA_FEED_APIS);
export const fetchDataFeedApisRequest = createAction(types.FETCH_DATA_FEED_APIS_REQUEST);
export const fetchDataFeedApisSuccess = createAction(types.FETCH_DATA_FEED_APIS_SUCCESS);
export const fetchDataFeedApisFailure = createAction(types.FETCH_DATA_FEED_APIS_FAILURE);
