import React from 'react';
import PropTypes from 'prop-types';
// import styles from './Default.module.scss';

import UploadSpinner from '../../../../../../common/components/UI/UploadSpinner';
import DropZone from '../../../../../../common/components/DropZone/DropZone';
import PlaylistTemplateCard from '../../../PlaylistTemplateCard';
import PlaylistDetailsWrapper from '../../../PlaylistDetailsWrapper';
import PlaylistNameField from '../../../PlaylistNameField/PlaylistNameField';
import ConnectedAddFilesBtn from '../../../../../../common/components/ConnectedAddFilesBtn/ConnectedAddFilesBtn';

class Default extends React.Component {
  handleAcceptedFiles = acceptedFiles => {
    const { formValues, createPlaylistAndContentGroup } = this.props;
    createPlaylistAndContentGroup({ formData: formValues, files: acceptedFiles });
  };

  render() {
    const { isCreatingPlaylist, formValues, onChangeName, onToggleEditMode } = this.props;

    return (
      <DropZone onAcceptDrop={this.handleAcceptedFiles}>
        {() => (
          <PlaylistDetailsWrapper>
            {isCreatingPlaylist && <UploadSpinner zIndex={100} />}
            <PlaylistNameField name="name" type="text" onBlur={onChangeName} />
            <PlaylistTemplateCard type={formValues.type} onEdit={onToggleEditMode} marginBottom={24} />
            <ConnectedAddFilesBtn modalData={{ id: null, formData: formValues }} />
          </PlaylistDetailsWrapper>
        )}
      </DropZone>
    );
  }
}

Default.propTypes = {
  formValues: PropTypes.object.isRequired,
  isCreatingPlaylist: PropTypes.bool.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onToggleEditMode: PropTypes.func.isRequired,
  createPlaylistAndContentGroup: PropTypes.func.isRequired,
};

export default Default;
