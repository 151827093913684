import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Modal from '../Modal';
import styles from './ChoosePlaylistModal.module.scss';
import Button from '../UI/Button';

class ChoosePlaylistModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlaylistId: props.selectedPlaylistId,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ selectedPlaylistId: this.props.selectedPlaylistId });
    }
  }

  handleClose = () => {
    this.setState({ selectedPlaylistId: null });
    this.props.onClose();
  };

  handleChoosePlaylist = () => {
    const { selectedPlaylistId } = this.state;
    
    if (!selectedPlaylistId) return;

    this.props.onChoosePlaylist(selectedPlaylistId);
    this.props.onClose();
  };

  render() {
    const { isOpen, playlists } = this.props;
    const { selectedPlaylistId } = this.state;
    const isEmptyPlaylists = playlists.length === 0;

    return (
      <Modal isOpen={isOpen} onClose={this.handleClose} modalClassName={styles.modal}>
        <h2 className={styles.title}>Choose playlist</h2>
        {/* <h3 className={styles.subtitle}>Choose playlist</h3> */}
        <div className={styles.list}>
          <div className={styles.listInner}>
            {isEmptyPlaylists && (
              <p className={styles.notFoundPlaylists}>
                Please, add the {"'"}No template{"'"} playlist first
              </p>
            )}
            {playlists.map(({ id, name }) => (
              <div
                key={id}
                className={cn(styles.item, selectedPlaylistId === id && styles.itemIsSelected)}
                onClick={() => this.setState({ selectedPlaylistId: id })}
              >
                {name}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.footer}>
          <Button onClick={this.handleClose} theme="invert">
            Cancel
          </Button>
          <Button onClick={this.handleChoosePlaylist}>Confirm</Button>
        </div>
      </Modal>
    );
  }
}

ChoosePlaylistModal.defaultProps = {
  playlists: [],
  selectedPlaylistId: null,
};

ChoosePlaylistModal.propTypes = {
  ...Modal.propTypes,
  playlists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onChoosePlaylist: PropTypes.func.isRequired,
  selectedPlaylistId: PropTypes.number,
};

export default ChoosePlaylistModal;
