import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './ProgrammeItem.module.scss';
import DotsButton from '../../../../common/components/UI/DotsButton';
import makeToggleable from '../../../../hocs/makeToggleable';
import MenuList from '../../../../common/components/UI/MenuList/MenuList';

const ProgrammeItem = ({ id, name, toggle, close, isOpen, rootRef, handleClickRemoveTheatre, isSelected }) => (
  <Link
    to={!isSelected ? `/programmes/${id}` : '/programmes'}
    className={cn(styles.programmeItem, isSelected && styles.isSelected)}
    innerRef={rootRef}
  >
    <div className={styles.content}>
      <span className={styles.name}>{name}</span>
    </div>
    <div className={styles.icon} />
    <DotsButton
      size={16}
      className={styles.dotsButton}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        toggle();
      }}
    />
    <MenuList className={cn(styles.menu, isOpen && styles.menuIsOpen)}>
      <MenuList.Item
        title="Delete"
        color="#EB5757"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClickRemoveTheatre(id);
          close();
        }}
      />
    </MenuList>
  </Link>
);

ProgrammeItem.propTypes = {
  rootRef: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  handleClickRemoveTheatre: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default makeToggleable(ProgrammeItem);
