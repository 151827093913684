import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import AddSponsorLogoInput from './AddSponsorLogoInput';
import styles from './AddSponsorLogoForm.module.scss';

class AddSponsorLogoForm extends React.Component {
  render() {
    const { canEdit, onChangeTheatreImage } = this.props;
    return (
      <div className={styles.root}>
        <Field name="image" component={AddSponsorLogoInput} onChangeCallback={onChangeTheatreImage} canEdit={canEdit} />
      </div>
    );
  }
}

AddSponsorLogoForm.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  onChangeTheatreImage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'theatre-sponsor-logo',
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: false,
})(AddSponsorLogoForm);
