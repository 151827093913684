import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { SortableHandle, SortableElement } from "react-sortable-hoc";
import styles from "./PlaylistContent.module.scss";

import Icons from "../../../../../common/components/UI/Icons";
import ContentIcons from "../../../../../common/components/UI/ContentIcons";

const DragHandle = SortableHandle(() => <div className={styles.dragHandle} />);

const renderMenu = (params) => (
  <div className={styles.menu}>
    <button
      className={cn(styles.menuButton, styles.menuButtonEdit)}
      onClick={params.onEdit}
    >
      {/* <Icons icon="editDark" /> */}
    </button>
    <button
      className={cn(styles.menuButton, styles.menuButtonDelete)}
      onClick={params.onDelete}
    >
      {/* <Icons icon="deleteTrash" /> */}
    </button>
  </div>
);

class PlaylistContent extends React.PureComponent {
  handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { isInRemoving, onDelete, content } = this.props;
    if (isInRemoving) return;

    onDelete(content.id);
  };

  handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { isInRemoving, onEdit, content, playlistType } = this.props;
    if (isInRemoving) return;

    onEdit({ ...content, playlistType });
  };

  render() {
    const { content, playtime, isInRemoving, style } = this.props;
    const { type, name, duration } = content;

    const isShowSometimes = playtime && playtime.length !== 0;

    const { handleDelete, handleEdit } = this;

    return (
      <div className={styles.root}>
        <div className={styles.container} style={style}>
          {isInRemoving && <div className={styles.removalSpinner} />}
          <DragHandle />
          <div className={styles.contentIcon}>
            <ContentIcons type={type} />
          </div>
          <span className={styles.name}>{name}</span>
          <div className={styles.settings}>
            {isShowSometimes && (
              <div className={styles.clockIcon}>
                <Icons icon="clock" />
              </div>
            )}
            {content.isFullScreen && (
              <div className={styles.isFullScreen}>
                <Icons icon="maximizeGray" widthIcons={18} />
              </div>
            )}
            <span className={styles.duration}>{duration}s</span>
            {renderMenu({ onEdit: handleEdit, onDelete: handleDelete })}
          </div>
        </div>
      </div>
    );
  }
}

PlaylistContent.defaultProps = {
  style: {},
};

PlaylistContent.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
  style: PropTypes.object,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  playtime: PropTypes.array.isRequired,
  isInRemoving: PropTypes.bool.isRequired,
  playlistType: PropTypes.string.isRequired,
};

export default SortableElement(PlaylistContent);
