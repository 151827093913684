import React from 'react';
import SvgIco from '../assets/svg';

const { SvgIcoSquare, SvgIcoNotDefault, SvgIcoOrientation } = SvgIco;

export default (sizeId, screenSizeList, ops) => {
  const { size = 24, color = '#000' } = ops;
  const currScreenSize = screenSizeList.find(item => item.id === sizeId);
  if (currScreenSize) {
    if (currScreenSize.default) {
      return <SvgIcoOrientation orientation={currScreenSize.name} setColor={color} iconSize={size} />;
    }
    if (!currScreenSize.default && currScreenSize.resolutionWidth === currScreenSize.resolutionHeight) {
      return (
        <SvgIcoSquare
          orientation={currScreenSize.resolutionWidth < currScreenSize.resolutionHeight}
          setColor={color}
          iconSize={size}
        />
      );
    }
    if (!currScreenSize.default && currScreenSize.resolutionWidth !== currScreenSize.resolutionHeight) {
      return (
        <SvgIcoNotDefault
          orientation={currScreenSize.resolutionWidth < currScreenSize.resolutionHeight}
          setColor={color}
          // iconSize={size}
        />
      );
    }
  }
};
