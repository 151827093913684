import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './LayoutSidebar.module.scss';
import Icons from '../../common/components/UI/Icons';
import SvgIco from '../../../assets/svg';
import { mixpanel } from '../../../state/utils';

const { SvgIcoCloud, SvgIcoWindows, SvgIcoAppStore } = SvgIco;
const isProduction = process.env.REACT_APP_BUILD === 'production';

class LayoutSidebar extends React.Component {
  state = {
    isToggleAppList: false,
  };

  onToggleAppList = () => {
    const { isToggleAppList } = this.state;
    this.setState({ isToggleAppList: !isToggleAppList });
  };

  render() {
    const {
      location: { pathname },
      user,
    } = this.props;
    const { isToggleAppList } = this.state;
    const passCode = user['pass-code'];

    return (
      <div className={styles.container}>
        <Nav vertical className={styles.navClass}>
          <NavItem className={styles.navItemClass} id="screens">
            <NavLink
              id="screens"
              tag={Link}
              className={pathname.slice(1, 8) === 'screens' ? styles.linkStyleActive : styles.linkStyle}
              to="/screens"
            >
              <span className={styles.iconPosition} id="screens">
                <Icons
                  icon={pathname.slice(1, 8) === 'screens' ? 'screenIcon' : 'screenIconGray'}
                  widthIcons={30}
                  id="screens"
                />
              </span>
              Screens
            </NavLink>
          </NavItem>

          <NavItem className={styles.navItemClass} id="playlists">
            <NavLink
              id="playlists"
              tag={Link}
              className={pathname.slice(1, 10) === 'playlists' ? styles.linkStyleActive : styles.linkStyle}
              to="/playlists"
            >
              <span className={styles.iconPosition} id="playlists">
                <Icons
                  icon={pathname.slice(1, 10) === 'playlists' ? 'playlistIcon' : 'playlistIconGray'}
                  widthIcons={30}
                  id="screens"
                />
              </span>
              Playlists
            </NavLink>
          </NavItem>

          <NavItem className={styles.navItemClass} id="programmes">
            <NavLink
              id="programmes"
              tag={Link}
              className={pathname.slice(1, 11) === 'programmes' ? styles.linkStyleActive : styles.linkStyle}
              to="/programmes"
            >
              <span className={styles.iconPosition} id="programmes">
                <Icons
                  icon={pathname.slice(1, 11) === 'programmes' ? 'programmesIcon' : 'programmesGrayIcon'}
                  widthIcons={30}
                  id="screens"
                />
              </span>
              Programmes
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

LayoutSidebar.propTypes = {
  user: PropTypes.object.isRequired,
  setWinToggler: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withRouter(LayoutSidebar);
