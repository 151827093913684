import reducer from './reducers';
import * as actions from './actions'; // actions
import sagas from './sagas'; // sagas
import * as selectors from './selectors'; // selectors
import * as types from './types';

export const contentActions = actions;
export const contentSagas = sagas;
export const contentReducer = reducer;
export const contentSelectors = selectors;
export const contentTypes = types;
