/* eslint-disable no-undef */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Froala from 'react-froala-wysiwyg';
import cn from 'classnames';
import * as $ from 'jquery';
import _ from 'lodash';
import jsBeautify from 'js-beautify';
import ReactTooltip from 'react-tooltip';
import 'froala-editor/js/froala_editor.pkgd.min';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/css/plugins/code_view.css';
import './theme.css';
import 'font-awesome/css/font-awesome.css';
import './FroalaEditor.scss';
import { Field, reduxForm } from 'redux-form';
import CodeMirror from 'codemirror';
import styles from './FroalaEditor.module.scss';
import ButtonDigital from '../../../ButtonDigital';
import Select from '../../../Select';
import SvgIcons from '../../../../../../../assets/svg';
import { validation, defineIconOfSizeOrientation, adjustElementBySize } from '../../../../../../../utils';
import configFroala from './configFroala';
import InsertImageModal from './InsertImageModal';
import EntityNameField from '../../../EntityNameField';
import Icons from '../../../Icons';
import emptyFroalaText from './emptyFroalaText';
import minifyHtml from './minifyHtml';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/markdown/markdown';
import './neo.css';
import '../../../../../../../fonts.css';

const {
  SvgEye,
  OrientationLandscape,
  OrientationCustomPort,
  OrientationCustomLand,
  OrientationPortrait,
  OrientationSquare,
} = SvgIcons;

window.$ = $;
window.jQuery = $;

const getIframeDocument = iframe => iframe.contentDocument || iframe.contentWindow.document;

// init codeView button
$.FroalaEditor.DefineIcon('codeView', { NAME: 'code' });
$.FroalaEditor.RegisterCommand('codeView', {
  title: 'Code View',
});

class FroalaEditor extends Component {
  state = {
    isFullscreen: false,
    isEditName: false,
    selectedScreenSize: {},
    isOpenInsertImageModal: false,
    isInsertImageMode: false,
    isImageSelected: false,
    isCodeView: false,
  };

  constructor(props) {
    super(props);
    this.froalaContainerRef = React.createRef();
    this.froalaRef = React.createRef();
    this.codemirrorRef = React.createRef();
    this.fullscreenBtnRef = React.createRef();

    this.mousemoveIntervalId = null;

    this.throttleCodeMirrorValueChange = _.throttle(this.codemirrorValueChange, 500);
    // set when componentDidMount
    this.editor = null;
    this.froalaIframeWrapper = null;
    this.froalaIframe = null;
  }

  componentDidMount() {
    const { screenSizes, htmlEditorContent } = this.props;

    $.FroalaEditor.RegisterCommand('insertImage', {
      title: 'Insert image',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: () => {
        this.handleOpenInsertImageModal();
      },
    });

    $.FroalaEditor.RegisterCommand('imageReplace', {
      title: 'Replace',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        this.handleOpenInsertImageModal();
      },
    });

    this.editor = this.froalaRef.current.$element;

    // eslint-disable-next-line
    this.froalaRoot = ReactDOM.findDOMNode(this);
    this.froalaBox = this.froalaRoot && this.froalaRoot.querySelector('.fr-box');
    this.froalaToolbar = this.froalaRoot && this.froalaRoot.querySelector('.fr-toolbar');
    this.froalaIframeWrapper = this.froalaRoot && this.froalaRoot.querySelector('.fr-wrapper');
    this.froalaIframe = this.froalaIframeWrapper.querySelector('iframe');
    // console.log('this.froalaIframe', this.froalaIframe);
    const newScreenSizeId =
      this.props.defaultScreenSizeId ||
      screenSizes.filter(item => item.default).find(item => item.resolutionWidth === 1080).id;

    this.handleSetScreenSize(newScreenSizeId);

    getIframeDocument(this.froalaIframe).addEventListener('mousemove', this.handleMousemove);
    this.froalaBox.addEventListener('mousemove', this.handleMousemove);

    // codemirror init
    this.codeMirror = CodeMirror(
      elt => {
        this.codemirrorRef.current.parentNode.replaceChild(elt, this.codemirrorRef.current);
      },
      {
        lineNumbers: true,
        lineWrapping: true,
        mode: 'htmlmixed',
        theme: 'neo',
      },
    );

    this.codeMirror.setValue(this.getFormattedCode());
    this.codeMirror.on('change', this.throttleCodeMirrorValueChange);
  }

  codemirrorValueChange = doc => {
    const { handleOnChangeAceEditor } = this.props;
    const value = doc.getValue();
    handleOnChangeAceEditor(value);
  };

  getFormattedCode = () => {
    const { htmlEditorContent } = this.props;
    return htmlEditorContent ? jsBeautify.html(htmlEditorContent, { indent_size: 2, preserve_newlines: false }) : '';
  };

  componentDidUpdate() {
    const { htmlEditorContent, handleOnChangeAceEditor } = this.props;
    // const { htmlEditorContent } = this.props;
    const { isCodeView } = this.state;

    $.FroalaEditor.RegisterCommand('backgroundColor', {
      title: 'Background Color',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      callback: (cmd, backgroundColor) => {
        const bgColor = backgroundColor === 'REMOVE' ? 'white' : backgroundColor;

        const bodyOpenTag = '<body';
        const bodyStartIndex = htmlEditorContent.indexOf(bodyOpenTag);
        const firstPartHtml = htmlEditorContent.substring(0, bodyStartIndex);
        const secondPartHtml = htmlEditorContent.substring(bodyStartIndex + bodyOpenTag.length);

        const newHtml = `${firstPartHtml}<body style="background: ${bgColor};" ${secondPartHtml}`;
        handleOnChangeAceEditor(newHtml);
      },
    });

    $.FroalaEditor.RegisterCommand('codeView', {
      title: 'Code View',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        if (isCodeView) {
          this.editor.froalaEditor('toolbar.enable');
          const cleanedHtml = this.editor.froalaEditor('clean.html', htmlEditorContent);
          handleOnChangeAceEditor(cleanedHtml);
        } else {
          this.codeMirror.setValue(this.getFormattedCode());
          this.editor.froalaEditor('toolbar.disable');
        }
        this.setState({ isCodeView: !isCodeView });
      },
      refresh($btn) {
        $btn[0].classList.remove('fr-disabled');
      },
    });
  }

  componentWillUnmount() {
    const { handleChangeHtmlArea, froalaFileName, htmlEditorContent, editMode } = this.props;
    if (!editMode) {
      if (froalaFileName.length === 0 || froalaFileName !== 'Visitor Message' || htmlEditorContent) return;
      handleChangeHtmlArea(false);
    }
  }

  handleMousemove = () => {
    clearInterval(this.mousemoveIntervalId);
    const fullscreenBtn = this.fullscreenBtnRef.current;
    if (fullscreenBtn) {
      fullscreenBtn.classList.add(styles.fullscreenBtnIsVisible);
    }

    this.mousemoveIntervalId = setInterval(() => {
      if (fullscreenBtn) {
        fullscreenBtn.classList.remove(styles.fullscreenBtnIsVisible);
      }
    }, 1000);
  };

  handleOpenInsertImageModal = () => this.setState({ isOpenInsertImageModal: true });

  handleCloseInsertImageModal = () => this.setState({ isOpenInsertImageModal: false });

  handleSubmitInsertImage = images => {
    if (this.editor) {
      images.forEach(({ id, content: url }) => {
        this.editor.froalaEditor('image.insert', url, true, { id });
      });
    }
  };

  handleClickChangeName = () => {
    this.setState({ isEditName: true });
  };

  handleCloseEditName = () => {
    this.setState({
      isEditName: false,
    });
  };

  handleSetScreenSize = (screenSizeId, event) => {
    const { screenSizes } = this.props;
    const screenSize = screenSizes.find(({ id }) => screenSizeId === id);

    if (event) {
      event.preventDefault();
    }

    this.setState({
      selectedScreenSize: screenSize,
    });

    this.setEditorSize(screenSize);
  };

  setEditorSize = screenSize => {
    const { resolutionWidth: width, resolutionHeight: height } = screenSize;

    adjustElementBySize({
      width,
      height,
      element: this.froalaIframeWrapper,
      container: this.froalaBox,
      subElement: this.froalaIframe,
    });
  };

  defineSvgOrientationIco = (screenSize, isActive) => {
    const getKey = defineIconOfSizeOrientation(screenSize);

    switch (getKey) {
      case 'landscape':
        return <OrientationLandscape isActive={isActive} />;
      case 'portrait':
        return <OrientationPortrait isActive={isActive} />;
      case 'square':
        return <OrientationSquare isActive={isActive} />;
      case 'customLandscape':
        return <OrientationCustomLand isActive={isActive} />;
      case 'customPortrait':
        return <OrientationCustomPort isActive={isActive} />;
      default:
        return <OrientationLandscape isActive={isActive} />;
    }
  };

  handleOnKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleCloseEditName();
    }
  };

  renderImageControls = () => (
    <div className={cn(styles.imageControls, this.state.isImageSelected && styles.imageControlsIsShow)}>
      <button type="button" className={styles.imageControlsControl} id="align-image-left" data-tip="Align image left">
        <i className={cn('fa fa-align-left', styles.icon)} />
      </button>
      <button
        type="button"
        className={styles.imageControlsControl}
        id="align-image-center"
        data-tip="Align image center"
      >
        <i className={cn('fa fa-align-center', styles.icon)} />
      </button>
      <button type="button" className={styles.imageControlsControl} id="align-image-right" data-tip="Align image right">
        <i className={cn('fa fa-align-right', styles.icon)} />
      </button>
      <button type="button" className={styles.imageControlsControl} id="image-trash" data-tip="Delete image">
        <i className={cn('fa fa-trash', styles.icon)} />
      </button>
      <ReactTooltip
        delayHide={200}
        delayShow={200}
        className="custom-tooltip-froala"
        type="dark"
        place="bottom"
        effect="solid"
      />
    </div>
  );

  render() {
    const { selectedScreenSize, isFullscreen, isCodeView } = this.state;
    const {
      handleChangeHtmlArea,
      handleCleanFieldsOfHtmlEditor,
      handleSendHtml,
      froalaFileName,
      htmlEditorContent,
      handleShowPreviewOfContent,
      handleOnChangeAceEditor,
      screenSizes,
      editMode,
      handleCloseHtmlEdit,
      htmlContent,
      handleSubmitHtmlContent,
    } = this.props;

    const config = configFroala({
      getState: () => this.state,
      getElements: () => ({
        cm: this.cm,
        froalaBox: this.froalaBox,
        froalaRoot: this.froalaRoot,
        froalaIframe: this.froalaIframe,
        froalaContainer: this.froalaContainerRef.current,
        froalaIframeWrapper: this.froalaIframeWrapper,
        normalizeEditorContentStyle: this.normalizeEditorContentStyle,
      }),
      setState: newState => this.setState(newState),
      setEditorSize: this.setEditorSize,
    });

    return (
      <div className={styles.container}>
        {/* CHANGE NAME AND PREVIEW SECTION */}
        {!editMode && (
          <div style={editMode && { paddingTop: 0 }} className={styles.nameFieldGroup}>
            <Field
              name="name"
              type="text"
              component={EntityNameField}
              className={styles.inputNameContainer}
              placeholder="Enter content name"
              validate={[validation.required]}
              normalize={validation.normalizeExtraSpaces}
            />
          </div>
        )}
        {/* SELECT SCREEN SIZE PREVIEW SECTION */}
        <div className={styles.controls}>
          <div className={styles.selectContainer}>
            <Select
              value={{ value: selectedScreenSize.id, label: selectedScreenSize.name }}
              options={screenSizes.map(({ name: label, id: value }) => ({ label, value }))}
              onChange={({ value }) => this.handleSetScreenSize(value)}
            />
          </div>
          {this.renderImageControls()}
          <button
            disabled={this.state.isCodeView}
            className={styles.previewBtn}
            type="button"
            onClick={event => handleShowPreviewOfContent(event, htmlEditorContent, selectedScreenSize.id)}
          >
            <SvgEye /> Preview
          </button>
        </div>
        {/* WYSIWYG HTML EDITOR SECTION */}
        <div ref={this.froalaContainerRef} className={cn(styles.wrapFroala, 'froala-no-fullscreen')}>
          <Froala
            ref={this.froalaRef}
            tag="textarea"
            config={config}
            model={htmlEditorContent}
            onModelChange={handleOnChangeAceEditor}
          />
          <button
            disabled={this.state.isCodeView || this.state.isImageSelected}
            ref={this.fullscreenBtnRef}
            type="button"
            className={styles.fullscreenBtn}
            id="fullscreen"
          >
            <Icons icon={isFullscreen ? 'minimize' : 'maximize'} widthIcons={24} marginRight={8} />
            <span>{isFullscreen ? 'Roll Up' : 'Full Screen'}</span>
          </button>
          {/* CUSTOM CODE MIRROR */}
          <div
            className={cn(styles.codeMirrorWrapper, isFullscreen && styles.cmFullScreen)}
            style={
              !isCodeView
                ? {
                    position: 'absolute !important',
                    clip: 'rect(1px, 1px, 1px, 1px)',
                    padding: '0 !important',
                    border: '0 !important',
                    height: '0px !important',
                    width: '0px !important',
                    overflow: 'hidden',
                  }
                : {}
            }
          >
            <textarea defaultValue={htmlEditorContent} ref={this.codemirrorRef} autoComplete="off" />
          </div>
        </div>
        {/* BUTTONS SECTION */}
        <div className={styles.btnGroup}>
          {!editMode && (
            <>
              <ButtonDigital
                type="button"
                onClick={() => {
                  handleChangeHtmlArea(false);
                  handleCleanFieldsOfHtmlEditor();
                }}
              >
                Cancel
              </ButtonDigital>
              <ButtonDigital
                isConfirmStyle={true}
                type="button"
                onClick={handleSendHtml}
                disable={
                  (!editMode && !froalaFileName) ||
                  !htmlEditorContent ||
                  minifyHtml(htmlEditorContent) === emptyFroalaText
                }
              >
                Add
              </ButtonDigital>
            </>
          )}
          {editMode && (
            <>
              <ButtonDigital onClick={handleCloseHtmlEdit} type="button" isConfirmStyle={false}>
                Cancel
              </ButtonDigital>
              <ButtonDigital
                disable={
                  minifyHtml(htmlEditorContent) === emptyFroalaText ||
                  !htmlEditorContent ||
                  htmlContent === '<!DOCTYPE html><html><head><title></title></head><body></body></html>'
                }
                onClick={handleSubmitHtmlContent}
                type="button"
                isConfirmStyle={true}
              >
                Save
              </ButtonDigital>
            </>
          )}
        </div>
        <InsertImageModal
          isOpen={this.state.isOpenInsertImageModal}
          onClose={this.handleCloseInsertImageModal}
          onSubmit={this.handleSubmitInsertImage}
        />
      </div>
    );
  }
}

FroalaEditor.propTypes = {
  editMode: PropTypes.bool,
  froalaFileName: PropTypes.string,
  screenSizes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      default: PropTypes.bool.isRequired,
      resolutionWidth: PropTypes.number.isRequired,
      resolutionHeight: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  htmlEditorContent: PropTypes.string.isRequired,
  handleOnChangeAceEditor: PropTypes.func.isRequired,
  handleShowPreviewOfContent: PropTypes.func.isRequired,
  fileName: PropTypes.string,
  handleSendHtml: PropTypes.func,
  handleChangeHtmlArea: PropTypes.func,
  handleSetHtmlTemplate: PropTypes.func,
  handleCleanFieldsOfHtmlEditor: PropTypes.func,
  defaultScreenSizeId: PropTypes.number,
};

const FroalaEditorWrapper = reduxForm({
  form: 'froalaForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  initialValues: {
    name: 'Visitor Message',
  },
})(FroalaEditor);

export default FroalaEditorWrapper;
