import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import AuthView from '../components/AuthView';
import { authActions } from '../../../../state/ducks/auth';

import UploadSpinner from '../../../common/components/UI/UploadSpinner';

const { fetchAuth } = authActions;

class AuthViewContainer extends React.Component {
  handleSignIn = value => {
    const updatedValue = { ...value, email: value.email && value.email.toLowerCase() };

    this.props.fetchAuth(updatedValue);
  };

  render() {
    const { fetching } = this.props;

    if (fetching) {
      return <UploadSpinner />;
    }

    return <AuthView handleSignIn={this.handleSignIn} {...this.props} />;
  }
}

const mapStateToProps = state => {
  const selector = formValueSelector('signIn');
  const { email, password } = selector(state, 'email', 'password');

  return {
    fetching: state.auth.fetching,
    email,
    password,
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAuth,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthViewContainer);
