import { createSelector } from 'reselect';

const screens = state => state.screens.screens;
export const screenSizes = state => state.screenSizes.screenSizes;

export const getScreenSizes = createSelector(
  [screens, screenSizes],
  (screens, screenSizes) =>
    screenSizes.map(screenSize => {
      const findUsedScreen = screens.filter(sc => sc.screenSizeId === screenSize.id);
      return { ...screenSize, usedScreens: findUsedScreen };
    }),
);
