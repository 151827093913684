import moment from 'moment';

const cleanDateDay = date => moment(date).format('DD/MM/YYYY');

const getDifferenceDays = (dateNow, selectedDate) => {
  const convertSelectDate = moment(cleanDateDay(selectedDate), 'DD/MM/YYYY').format('x');
  const convertNowDate = moment(cleanDateDay(dateNow), 'DD/MM/YYYY').format('x');
  return convertSelectDate - convertNowDate;
};

export default getDifferenceDays;
