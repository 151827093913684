import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import styles from './AddContentChildDropZone.module.scss';
import { contentActions } from '../../../../../../state/ducks/content';
import { contentGroupActions } from '../../../../../../state/ducks/contentGroup';
import { showNotification } from '../../../../../../state/ducks/app/actions';
// import getOrientationIcon from '../../../RenderMediaList/SortableItem/getOrientationIcon';
import { getScreenDimensions } from '../../../../../../utils';
import getScreenOrientationIcon from './getScreenOrientationIcon';
import DropZoneContainer from '../../DropZoneContainer';

const { setUploadedContent, setContentsFilter } = contentActions;

const { setContentTypeFilter, fetchAddContentToContentGroup } = contentGroupActions;

class AddContentChildDropZone extends React.Component {
  checkFileType = files => {
    let flagSingleFileType = 0;
    let flagType = '';
    let contentTypeTab = 'multiply';

    files.map(item => {
      const itemTypeSplit = item.type.split('/')[0];
      const flagTypeSplit = flagType.split('/')[0];
      if (itemTypeSplit !== flagTypeSplit) {
        flagSingleFileType += 1;
        flagType = item.type;
      }
      return flagTypeSplit;
    });

    if (flagSingleFileType > 1) {
      contentTypeTab = 'multiply';
    } else {
      const updatecontentTypeTab = flagType.split('/')[0];
      contentTypeTab = updatecontentTypeTab;
      if (contentTypeTab === 'text') contentTypeTab = 'html';
    }
    this.props.setContentTypeFilter(contentTypeTab);
  };

  onDropChildFile = (acceptedFiles, rejectedFiles) => {
    const { showNotify, entityId, currentScreenSize } = this.props;
    if (acceptedFiles.length) {
      this.props.addContentToContentGroup(entityId, currentScreenSize, acceptedFiles[0]);
    }
    rejectedFiles.map(file => showNotify({ level: 'error', message: `The format of ${file.name} is not supported` }));
  };

  defineType = () => {
    const { acceptType } = this.props;

    switch (acceptType) {
      case 'multiply':
        return 'image/jpeg, image/png, image/webp, video/mp4, text/html';
      case 'image':
        return 'image/jpeg, image/png, image/webp';
      case 'video':
        return 'video/mp4';
      case 'html':
        return 'text/html';
      default:
        return ['image/jpeg', 'image/png', 'image/webp', 'video/mp4', 'text/html'];
    }
  };

  render() {
    const { screenSize, screenSizes } = this.props;

    return (
      <Dropzone mediaStyles={styles.dropZoneStyle1} onDrop={this.onDropChildFile} accept={this.defineType()}>
        {({ getRootProps, isDragActive, isDragReject, getInputProps, open }) => (
          <DropZoneContainer
            className={styles.container}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
            onClick={open}
          >
            {/* <div {...getRootProps()} className={styles.dropArea} style={isOverOnDrag}> */}
            <div className={styles.sizeCard}>
              <span className={styles.sizeCardIcon}>
                {getScreenOrientationIcon(screenSize.id, screenSizes, { color: '#8A8A8F' })}
              </span>
              <span className={styles.sizeCardText}>
                {screenSize.name} {getScreenDimensions(screenSize.resolutionWidth, screenSize.resolutionHeight)}
              </span>
            </div>
            <div className={styles.content}>Upload file for {screenSize.name} screens</div>
            <input {...getInputProps()} multiple={false} />
          </DropZoneContainer>
        )}
      </Dropzone>
    );
  }
}

AddContentChildDropZone.propTypes = {
  setUploadedContent: PropTypes.func,
  currentPlaylistId: PropTypes.number,
  contentType: PropTypes.string,
  contentName: PropTypes.string,
  isScreen: PropTypes.bool,
  acceptType: PropTypes.string,
};

AddContentChildDropZone.defineType = {
  isScreen: false,
};

const mapStateToProps = state => ({
  acceptType: state.app.contentFormId,
  currentPlaylistId: state.playlists.currentPlaylistId,
  playlists: state.playlists.playlists,
  contentsProgress: state.content.contentsProgress,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUploadedContent,
      showNotify: showNotification,
      setContentsFilter,
      setContentTypeFilter,
      addContentToContentGroup: fetchAddContentToContentGroup,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddContentChildDropZone);
