import React from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';
import { connect } from 'react-redux';
import { getFormValues, reduxForm, Field } from 'redux-form';

import styles from './OptionsModal.module.scss';
import Modal from '../../../../../../../common/components/Modal';
import Button from '../../../../../../../common/components/UI/Button';

import Switch from '../../../../../../../common/components/UI/Switch/Switch';
import ColorPicker from '../../../../../../../common/components/UI/ColorPicker';

import SizeRange from './SizeRange';
import ScaleSlider from './ScaleSlider';
import BottomBarField from '../../../../BottomBarField/BottomBarField';
import Input from '../../../../../../../common/components/UI/Input';
import { validation } from '../../../../../../../../utils';
import SelectAdapter from '../../../../../../../common/components/UI/SelectAdapter';

class OptionsModal extends React.Component {
  handleCancel = () => {
    const {
      onCancel,
      change,
      initialValues: { options },
    } = this.props;

    onCancel();
    change('options', options);
  };

  fontSizeInput = props => (
    <div className={styles.fontSizeInput}>
      <Input {...props} />
      <div className={styles.pt}>pt</div>
    </div>
  );

  renderBody = () => (
    <div className={styles.body}>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Font family:</span>
        <Field
          name="options.fontFamily"
          type="button"
          component={SelectAdapter}
          options={[
            { label: 'Open Sans', value: 'Open Sans' },
            { label: 'Montserrat', value: 'Montserrat' },
            { label: 'Dosis', value: 'Dosis' },
            { label: 'Gotham Pro', value: 'Gotham Pro' },
            { label: 'Roboto', value: 'Roboto, sans-serif' },
            { label: 'Sofia Pro', value: 'Sofia Pro, sans-serif' },
            { label: 'AlfaSlabOne', value: 'AlfaSlabOne, sans-serif' },
            { label: 'Myriad Pro', value: 'Myriad Pro, sans-serif' },
            { label: 'Blood Orange', value: 'Blood Orange, sans-serif' },
            { label: 'Museo', value: 'Museo, sans-serif' },
          ]}
        />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Font size (theatre name):</span>
        <Field
          normalize={validation.normalizeNumber}
          name="options.fontSize"
          type="text"
          component={this.fontSizeInput}
          style={{ width: 137 }}
        />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Font scale:</span>
        <Field
          name="options.fontScale"
          min={50} max={100} defaultValue={100}
          component={ScaleSlider}
        />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Elements Spacing:</span>
        <Field
          name="options.elementsSpacing"
          min={0} max={200} defaultValue={100}
          component={ScaleSlider}
        />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Title line-height:</span>
        <Field
          name="options.titleLineSpacing"
          min={50} max={150} defaultValue={100}
          component={ScaleSlider}
        />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Speakers line-height:</span>
        <Field
          name="options.speakersLineSpacing"
          min={50} max={150} defaultValue={100}
          component={ScaleSlider}
        />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Description line-height:</span>
        <Field
          name="options.descriptionLineSpacing"
          min={50} max={150} defaultValue={100}
          component={ScaleSlider}
        />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Font color:</span>
        <Field name="options.fontColor" type="button" component={ColorPicker} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Color theme:</span>
        <Field name="options.themeColor" type="button" component={ColorPicker} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Bottom bar:</span>
        <Field name="options.hasBottomBar" type="checkbox" component={Switch} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Font size (bottom bar):</span>
        <Field
          normalize={validation.normalizeNumber}
          name="options.bottomBarFontSize"
          type="text"
          component={this.fontSizeInput}
          style={{ width: 137 }}
        />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Bottom bar text:</span>
        <BottomBarField formValues={this.props.formValues} change={this.props.change} />
      </div>
      <Field name="options.sizes" hasBottomBar={this.props.formValues.options.hasBottomBar} component={SizeRange} />
    </div>
  );

  render() {
    const { renderBody, handleCancel } = this;
    const { isOpen, formValues } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={handleCancel} scroll="body">
        <Modal.Title>Playlist Options</Modal.Title>
        {renderBody()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleCancel} theme="invert">
            Cancel
          </Button>
          <Button onClick={() => this.props.onConfirm(formValues.options)}>Confirm</Button>
        </div>
      </Modal>
    );
  }
}

OptionsModal.defaultProps = {};

OptionsModal.propTypes = {
  ...Modal.propTypes,
  onConfirm: PropTypes.func.isRequired,
  playlistId: PropTypes.number.isRequired,
  updatePlaylist: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};

const formName = 'playlistOptions';

const mapStateToProps = (state, { initialValues }) => {
  const formValues = getFormValues(formName)(state) || initialValues;
  return { formValues };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
  })(OptionsModal),
);
