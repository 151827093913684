import React from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { playlistSelectors, playlistsActions } from '../../../../../state/ducks/playlists';
import { contentGroupActions } from '../../../../../state/ducks/contentGroup';
import { contentActions } from '../../../../../state/ducks/content';
import { appActions } from '../../../../../state/ducks/app';
import getRenderPlaylist from './getRenderPlaylist';
import { getOptions } from '../../../../../utils';

const { setContentFormId } = appActions;
const { setContentTypeFilter } = contentGroupActions;
const { setMediaLibraryModal } = contentActions;
const { currentPlaylist: getCurrentPlaylist } = playlistSelectors;

class PlaylistDetailsForm extends React.Component {
  handleChangeName = ({ target: { value } }) => {
    const { updatePlaylist, currentPlaylist } = this.props;

    if (value === '') {
      return;
    }

    const name = value.replace(/\s+/g, ' ').trim();
    const playlistId = currentPlaylist.id;
    const formData = { name };

    updatePlaylist({ playlistId, formData });
  };

  render() {
    const { currentPlaylist } = this.props;

    if (!currentPlaylist) {
      return <Redirect to="/playlists" />;
    }

    const playlistType = currentPlaylist.type;
    const props = { ...this.props, onChangeName: this.handleChangeName };

    const renderPlaylist = getRenderPlaylist(playlistType);
    return renderPlaylist(props);
  }
}

PlaylistDetailsForm.propTypes = {
  updatePlaylist: PropTypes.func.isRequired,
  currentPlaylist: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const currentPlaylist = getCurrentPlaylist(state, ownProps);
  const options = currentPlaylist ? currentPlaylist.options : null;

  const initialValues = {
    ...currentPlaylist,
    options: getOptions(options),
  };

  const formValues = {
    ...currentPlaylist,
    options: getOptions(options),
  };

  return {
    formValues,
    initialValues,
    currentPlaylist,
  };
};

const mapDispatchToProps = {
  ...playlistsActions,
  setMediaLibraryModal,
  setContentTypeFilter,
  setContentFormId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlaylistDetailsForm);
