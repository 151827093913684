import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import Icons from '../Icons';

import styles from './DatePicker.module.scss';

function DatePickerInput(ops, ref) {
  const {
    onClick,
    inputClassName,
    isShowCalendarIcon = true,
    onCustomFocus,
    onCustomBlur,
    value,
    placeholder,
    customFormat,
  } = ops;

  const isEmpty = value === '';

  const changeValue = customFormat ? moment(value).format(customFormat) : value;

  return (
    <button
      ref={ref}
      type="button"
      className={cn(styles.input, isEmpty && styles.inputIsEmpty, inputClassName && inputClassName)}
      onClick={e => {
        onClick(e);
        onCustomFocus(e);
      }}
      onBlur={() => {
        onCustomBlur();
      }}
    >
      <span>{isEmpty ? placeholder : changeValue}</span>
      {isShowCalendarIcon && (
        <span className={styles.icon}>
          <Icons icon="calendarDark" widthIcons={24} />
        </span>
      )}
    </button>
  );
}

const DatePickerInputWrapper = React.forwardRef(DatePickerInput);

// propTypes must contain DatePickerInputWrapper instead of the DatePickerInput, otherwise it will error
DatePickerInputWrapper.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onCustomFocus: PropTypes.func.isRequired,
  onCustomBlur: PropTypes.func.isRequired,
  isShowCalendarIcon: PropTypes.bool,
};

export default DatePickerInputWrapper;
