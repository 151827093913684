import * as types from './types';
import { createReducer } from '../../utils';

const selectMultiple = ({ state, image }) => {
  const { selectedImages } = state;
  const isSelected = selectedImages.findIndex(item => item.id === image.id) !== -1;

  const newSelectedImages = isSelected
    ? selectedImages.filter(item => item.id !== image.id)
    : [...selectedImages, image];

  return {
    selectedImages: newSelectedImages,
  };
};

const select = ({ state: { selectedImages }, image }) => {
  const newSelectedImages = () => {
    if (image) {
      if (selectedImages[0] && selectedImages[0].id === image.id) {
        return [];
      }

      return [image];
    }

    return [];
  };

  return { selectedImages: newSelectedImages() };
};

export default createReducer({
  selectedImages: [],
  isLoading: false,
})({
  [types.UPLOAD_NEW_IMAGES_REQUEST]: state => ({ ...state, isLoading: true }),
  [types.UPLOAD_NEW_IMAGES_SUCCESS]: state => ({ ...state, isLoading: false }),
  [types.UPLOAD_NEW_IMAGES_FAILURE]: state => ({ ...state, isLoading: false }),
  [types.SELECT_IMAGE]: (state, { payload: { image, multiple = true } }) =>
    multiple ? selectMultiple({ state, image }) : select({ state, image }),
  [types.RESET_SELECTED_IMAGES]: () => ({ selectedImages: [] }),
});
