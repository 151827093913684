import React, { Component } from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './SpeakersEditForm.module.scss';
import Input from '../../../../common/components/UI/Input';
import ButtonDigital from '../../../../common/components/UI/ButtonDigital';
import { validation } from '../../../../../utils';

class SpeakersEditForm extends Component {
  componentDidMount() {
    const { change, speakers, isEditOrder } = this.props;
    const editedSpeaker = speakers.find(speaker => speaker.order === isEditOrder);
    const { speakerCompany, speakerJob, speakerName } = editedSpeaker;
    change('speakerCompany', speakerCompany);
    change('speakerName', speakerName);
    change('speakerJob', speakerJob);
  }

  componentWillUnmount() {
    const { change, resetEditOrder } = this.props;
    change('speakerCompany', '');
    change('speakerName', '');
    change('speakerJob', '');
    resetEditOrder();
  }

  handleEditSpeaker = () => {
    const {
      speakerName,
      speakerCompany = '',
      speakerJob = '',
      speakers,
      change,
      isEditOrder,
      resetEditOrder,
    } = this.props;

    if (!speakerName) return null;

    const editableSpeaker = speakers.map(speaker =>
      speaker.order === isEditOrder
        ? {
            ...speaker,
            speakerCompany: speakerCompany.trim(),
            speakerName: speakerName.trim(),
            speakerJob: speakerJob.trim(),
          }
        : speaker,
    );

    change('speakers', editableSpeaker);
    resetEditOrder();
    change('speakerCompany', '');
    change('speakerName', '');
    change('speakerJob', '');
  };

  handleCancelClick = () => {
    const { handleOpenForm, change } = this.props;
    handleOpenForm();
    change('speakerCompany', '');
    change('speakerName', '');
    change('speakerJob', '');
  };

  render() {
    const { speakerName, isOpen, handleOpenForm } = this.props;

    const valid = speakerName && speakerName.trim() !== '';
    return (
      <div className={styles.container}>
        <div className={cn(styles.speakerAddForm, isOpen && styles.formOpen)}>
          <div className={styles.wrapField}>
            <Field
              name="speakerName"
              id="add-name-speaker"
              label="Name:"
              customLabelClass={styles.customLabel}
              component={Input}
              type="text"
              placeholder="Enter full name"
              disableAutoComplete={true}
              validate={validation.isEmpty}
            />
          </div>
          <div className={styles.wrapField}>
            <Field
              name="speakerJob"
              id="add-job-title-speaker"
              label="Job title:"
              customLabelClass={styles.customLabel}
              component={Input}
              type="text"
              placeholder="Enter job tittle"
              disableAutoComplete={true}
            />
          </div>
          <div className={styles.wrapField}>
            <Field
              name="speakerCompany"
              id="add-company-speaker"
              label="Company:"
              customLabelClass={styles.customLabel}
              component={Input}
              type="text"
              placeholder="Enter company name"
              disableAutoComplete={true}
            />
          </div>

          {valid && (
            <div className={cn(styles.wrapBtn, isOpen && styles.openedForm)}>
              <ButtonDigital type="button" className={styles.cancelBtn} onClick={this.handleCancelClick}>
                Cancel
              </ButtonDigital>
              <ButtonDigital type="button" isConfirmStyle onClick={this.handleEditSpeaker}>
                Confirm Edit
              </ButtonDigital>
            </div>
          )}
        </div>
        {!valid && (
          <div className={cn(styles.wrapBtn, isOpen && styles.openedForm)}>
            <ButtonDigital type="button" isConfirmStyle={!isOpen} onClick={handleOpenForm}>
              {!isOpen ? 'Add Speaker' : 'Cancel'}
            </ButtonDigital>
          </div>
        )}
      </div>
    );
  }
}

SpeakersEditForm.propTypes = {
  fields: PropTypes.object.isRequired,
  speakerCompany: PropTypes.string,
  speakerName: PropTypes.string,
  speakerJob: PropTypes.string,
  speakers: PropTypes.array,
  change: PropTypes.func.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  resetEditOrder: PropTypes.func.isRequired,
  isEditOrder: PropTypes.number,
};

SpeakersEditForm.defaultType = {
  isEditOrder: null,
};

export default SpeakersEditForm;
