import React from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';
import Select from '../../../../common/components/UI/Select';
import styles from './SessionForm.module.scss';
import Input from '../../../../common/components/UI/Input';
import TimeField from '../../../../common/components/UI/TimeField';
import * as validation from '../../../../../utils/validation';
import DatePicker from '../../../../common/components/UI/DatePicker';
import ImageField from './ImageField';
import { safeGet } from '../../../../../utils';

export default function SessionForm({ programmes, dataFeedList }) {
  const normalizedProgrammes = (programmes || []).filter(p => {
    const options = p.options || {};
    const programmeDataFeed = dataFeedList.find(({ id }) => p.dataFeedId === id) || {};
    return !options.sync || !safeGet(() => programmeDataFeed.options.sync, false);
  });

  return (
    <div className={styles.sessionForm}>
      <div className={cn(styles.gridRow)}>
        <label>Theatre: </label>
        <div>
          <Field
            name="theatre"
            options={normalizedProgrammes.map(({ title: label, id: value }) => ({ label, value }))}
            component={props => <Select {...props.input} {...props} />}
            onBlur={e => e.preventDefault()}
          />
        </div>
      </div>
      <Field
        name="title"
        id="add-title-session"
        label="Title:"
        component={Input}
        type="text"
        placeholder="How to Build Effective Defensive Strategies Against Priv"
        customLabelClass={styles.customLabel}
        validate={[validation.required]}
        autoComplete="off"
      />
      <div className={styles.timeSection}>
        <Field
          name="start"
          id="add-time-start-session"
          label="Start:"
          className={styles.textField}
          component={TimeField}
          type="text"
          placeholder="10:00"
          customLabelClass={styles.customLabel}
          validate={[validation.required, validation.isTimeCorrect]}
          autoComplete="off"
        />
        <Field
          name="end"
          id="add-time-finish-session"
          label="Finish:"
          className={styles.textField}
          component={TimeField}
          type="text"
          placeholder="12:00"
          customLabelClass={styles.customLabel}
          validate={[validation.required, validation.isTimeCorrect]}
          autoComplete="off"
        />
      </div>
      <div className={cn(styles.gridRow, styles.gridDay)}>
        <label>Day: </label>
        <div>
          <Field
            minDate={new Date()}
            defaultValue={new Date()}
            className={styles.datePicker}
            inputClassName={styles.item}
            component={DatePicker}
            name="day"
            customFormat="dddd Do MMM"
          />
        </div>
      </div>
      <div className={cn(styles.gridRow, styles.gridDay)}>
        <label>Image: </label>
        <div>
          <Field inputClassName={styles.item} name="image" component={ImageField} />
        </div>
      </div>
    </div>
  );
}
