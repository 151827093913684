import { select, put, takeLatest, call, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import _ from 'lodash';
import { apiRoutes, fetch } from '../../utils';
import * as types from './types';
import * as actions from './actions';
import handleError from '../../utils/handle-error';
import { getUser, getIsAuthenticated } from '../auth/selectors';
import { getDataFeedList, getDataFeedById, getDataFeedHistoryList } from './selectors';
import { openConfirmModal } from '../confirmModal/actions';
import { reduxStore } from '../../../App';
import { getTheatrePlaylists } from '../playlists/selectors';
import parseEntityField from '../../utils/parse-entity-field';
import { noop } from '../../../utils';
import { fetchProgrammesFlow } from '../programmes/sagas';
import { fetchSessionsFlow } from '../sessions/sagas';
import { fetchSpeakersFlow } from '../speakers/sagas';

function* createDataFeedFlow({ payload: formData }) {
  yield put(actions.createDataFeedRequest());
  try {
    const { options } = formData;
    const requestBody = {
      ...formData,
      options: JSON.stringify(options),
    };

    const { url, method } = apiRoutes.createDataFeed;
    const { id: userId } = yield select(getUser);
    const createdDataFeed = yield call(fetch, url(userId), method, requestBody);

    yield put(actions.closeCreateModal());
    yield put(actions.createDataFeedSuccess(createdDataFeed));
  } catch (error) {
    handleError(error);
    yield put(actions.createDataFeedFailure());
  }
}

function* updateDataFeedFlow({ payload: formData }) {
  const dataFeedList = yield select(getDataFeedList);
  const currentDataFeed = dataFeedList.find(({ id }) => id === formData.id);

  yield put(actions.updateDataFeedRequest(formData));
  yield put(actions.closeEditModal());
  try {
    const { id: dataFeedId, options } = formData;
    const requestBody = {
      ...formData,
      options: JSON.stringify(options),
    };

    const { url, method } = apiRoutes.updateDataFeed;
    const { id: userId } = yield select(getUser);
    yield call(fetch, url(userId, dataFeedId), method, requestBody);

    yield put(actions.updateDataFeedSuccess());
  } catch (error) {
    handleError(error);
    yield put(actions.updateDataFeedFailure(currentDataFeed));
  }
}

function getConfirmModalPayload(firstUsedProgramme, dataFeedId) {
  if (firstUsedProgramme) {
    return {
      text: `Theatre "${firstUsedProgramme.selectedProgramme.title}" is being used by "${
        firstUsedProgramme.playlistName
      }". Please assign another Theatre to "Theatre", before deleting this Data Feed.`,
      confirmText: 'Confirm',
      onConfirm: noop,
    };
  }

  return {
    text: 'Are you sure you want to delete this data feed?',
    confirmText: 'Yes',
    cancelText: 'No',
    onConfirm: () => {
      reduxStore.dispatch(actions.deleteDataFeed(dataFeedId));
    },
  };
}

function* deleteDataFeedConfirmFlow({ payload: dataFeedId }) {
  const currentDataFeed = yield select(getDataFeedById, dataFeedId);
  const theatrePlaylists = yield select(getTheatrePlaylists);

  const firstUsedProgramme = theatrePlaylists
    .map(p => parseEntityField(p, 'options'))
    .map(({ name, options }) => ({
      playlistName: name,
      selectedProgramme: currentDataFeed.programmes.find(({ id }) => +id === +options.selectedProgrammeId),
    }))
    .find(({ selectedProgramme }) => Boolean(selectedProgramme));

  yield put(openConfirmModal(getConfirmModalPayload(firstUsedProgramme, dataFeedId)));
}

function* deleteDataFeedFlow({ payload: dataFeedId }) {
  const dataFeedList = yield select(getDataFeedList);
  const deletedDataFeedIndex = dataFeedList.findIndex(({ id }) => id === dataFeedId);
  const deletedDataFeed = dataFeedList[deletedDataFeedIndex];

  yield put(actions.deleteDataFeedRequest(dataFeedId));
  try {
    const { url, method } = apiRoutes.deleteDataFeed;
    const { id: userId } = yield select(getUser);
    yield call(fetch, url(userId, dataFeedId), method);
    yield put(actions.deleteDataFeedSuccess(dataFeedId));
  } catch (error) {
    handleError(error);
    yield put(
      actions.deleteDataFeedFailure({
        index: deletedDataFeedIndex,
        deletedDataFeed,
      }),
    );
  }
}

function* fetchDataFeedApisFlow() {
  console.log('fetchDataFeedApisFlow');
  yield put(actions.fetchDataFeedApisRequest());
  try {
    const { url, method } = apiRoutes.getDataFeedApis;
    const dataFeedApis = yield call(fetch, url(), method);
    yield put(actions.fetchDataFeedApisSuccess(dataFeedApis));
  } catch (error) {
    handleError(error);
    yield put(actions.fetchDataFeedApisFailure());
  }
}

// function* fetchDataFeedFlow() {
//   try {
//     const { url, method } = apiRoutes.getDataFeeds;
//     const { id: userId } = yield select(getUser);
//     const dataFeedList = yield call(fetch, url(userId), method);
//     yield put(actions.setDataFeedList(dataFeedList));
//     console.log('update');
//   } catch (e) {
//     // \_/)
//     // (o.o)
//     // (___)0
//   }
// }

function* fetchDataFeedHistoriesFlow() {
  try {
    const { url, method } = apiRoutes.getDataFeedHistories;
    const { id: userId } = yield select(getUser);
    const dataFeedHistoryList = yield call(fetch, url(userId), method);

    const currentDataHistoryList = yield select(getDataFeedHistoryList);
    const newDataFeedHistoryList = _.unionBy([...currentDataHistoryList, ...dataFeedHistoryList], 'id');

    yield put(actions.setDataFeedHistoryList(newDataFeedHistoryList));
  } catch (e) {
    // \_/)
    // (o.o)
    // (___)0
  }
}

function* watchDataFeedsChanges() {
  const minutes = minCount => 1000 * (60 * minCount);

  while (true) {
    yield delay(minutes(0.7));

    const isAuthenticated = yield select(getIsAuthenticated);
    if (isAuthenticated) {
      yield* fetchProgrammesFlow();
      yield* fetchSessionsFlow();
      yield* fetchSpeakersFlow();
      yield* fetchDataFeedHistoriesFlow();
    }
  }
}

export default [
  fork(watchDataFeedsChanges),
  takeLatest(types.CREATE_DATA_FEED, createDataFeedFlow),
  takeLatest(types.UPDATE_DATA_FEED, updateDataFeedFlow),
  takeLatest(types.DELETE_DATA_FEED, deleteDataFeedFlow),
  takeLatest(types.DELETE_DATA_FEED_CONFIRM, deleteDataFeedConfirmFlow),
  takeLatest(types.FETCH_DATA_FEED_APIS, fetchDataFeedApisFlow),
];
