import React from 'react';
import { connect } from 'react-redux';
import ReactDropZone from 'react-dropzone';
import PropTypes from 'prop-types';
import { showNotification } from '../../../../state/ducks/app/actions';
import { noop, getAcceptFilesTypes } from '../../../../utils';

import DropZoneContainer from '../UI/DropZoneContainer';

class DropZone extends React.Component {
  onDropFile = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      this.props.onAcceptDrop(acceptedFiles);
    }

    rejectedFiles.forEach(file =>
      this.props.showNotification({ level: 'error', message: `The format of ${file.name} is not supported` }),
    );
  };

  render() {
    const { className, children, ...rest } = this.props;

    return (
      <ReactDropZone disableClick onDrop={this.onDropFile} accept={getAcceptFilesTypes()} {...rest}>
        {props => (
          <DropZoneContainer className={className} {...props}>
            {children && children({ ...props })}
          </DropZoneContainer>
        )}
      </ReactDropZone>
    );
  }
}

DropZone.defaultProps = {
  onAcceptDrop: noop,
};

DropZone.propTypes = {
  onAcceptDrop: PropTypes.func,
  children: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  showNotification,
};

export default connect(
  null,
  mapDispatchToProps,
)(DropZone);
