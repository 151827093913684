import * as types from './types';

export const setContents = contents => ({ type: types.SET_CONTENTS, payload: contents });
export const setPlaylistsContents = contents => ({ type: types.SET_PLAYLISTS_CONTENTS, payload: contents });
export const setScreensContents = contents => ({ type: types.SET_SCREENS_CONTENTS, payload: contents });
export const setCurrentContent = content => ({ type: types.SET_CURRENT_CONTENT, payload: content });
export const setUploadedContent = file => ({ type: types.SET_UPLOADED_CONTENT, payload: file });
export const setPlaylistContentMeta = data => ({ type: types.SET_PLAYLIST_CONTENT_META, payload: data });

export const setContentsFilter = type => ({ type: types.SET_CONTENTS_FILTER, payload: type });

export const setMediaLibraryModal = (modalAction, data) => ({
  type: types.SET_MEDIA_LIBRARY_MODAL,
  payload: { modalToggler: modalAction, data },
});
export const setProgressToContent = (urlKey, total, loaded, fileProgressData) => ({
  type: types.SET_PROGRESS_TO_CONTENT,
  payload: { urlKey, total, loaded, fileProgressData },
});
export const removeProgressFromContent = (urlKey, isAbort) => ({
  type: types.REMOVE_PROGRESS_FROM_CONTENT,
  payload: { urlKey, isAbort },
});

export const fetchContentStart = () => ({ type: types.FETCH_CONTENT_START });
export const fetchContentFinished = () => ({ type: types.FETCH_CONTENT_FINISHED });
export const fetchContentMetaStart = () => ({ type: types.FETCH_CONTENT_META_START });
export const fetchContentMetaFinished = () => ({ type: types.FETCH_CONTENT_META_FINISHED });
export const fetchCreateContentStart = () => ({ type: types.FETCH_CREATE_CONTENT_START });
export const fetchCreateContentFinish = () => ({ type: types.FETCH_CREATE_CONTENT_FINISHED });
export const fetchUploadContentStart = () => ({ type: types.FETCH_UPLOAD_CONTENT_START });
export const fetchUploadContentFinished = () => ({ type: types.FETCH_UPLOAD_CONTENT_FINISHED });

export const fetchUploadContentToPlaylist = dataFile => ({
  type: types.FETCH_UPLOAD_CONTENT_TO_PLAYLIST,
  payload: dataFile,
});

export const fetchUpdatePlaylistContent = (playlistId, contentId) => ({
  type: types.FETCH_UPDATE_PLAYLIST_CONTENT,
  payload: { playlistId, contentId },
});

export const fetchPlaylistContents = playlistId => ({
  type: types.FETCH_PLAYLIST_CONTENTS,
  payload: playlistId,
});

export const fetchAddContentToNotExistPlaylist = (type, acceptedFiles, screenName, orientationSelect, isScreen) => ({
  type: types.FETCH_ADD_CONTENT_TO_NOT_EXIST_PLAYLIST,
  payload: {
    type,
    acceptedFiles,
    screenName,
    orientationSelect,
    isScreen,
  },
});

export const fetchAddContentFromMediaLibrary = (type, acceptedFiles, screenName, orientationSelect) => ({
  type: types.FETCH_ADD_CONTENT_FROM_MEDIA_LIBRARY_FLOW,
  payload: {
    type,
    acceptedFiles,
    screenName,
    orientationSelect,
  },
});

export const fetchCreatePlaylistContent = (playlistId, formData) => ({
  type: types.FETCH_CREATE_PLAYLIST_CONTENT,
  payload: {
    playlistId,
    formData,
  },
});

export const fetchDeleteContent = contentId => ({
  type: types.FETCH_DELETE_CONTENT,
  payload: contentId,
});

export const fetchAddContentMetaToPlaylist = (playlistId, contentId) => ({
  type: types.FETCH_ADD_CONTENT_META_TO_PLAYLIST,
  payload: { playlistId, contentId },
});

export const fetchReplaceContent = (contentId, fileData, screenSizeId) => ({
  type: types.FETCH_REPLACE_CONTENT,
  payload: { contentId, fileData, screenSizeId },
});

export const fetchUploadAndReplaceUrlForContent = (file, contentId, contentGroupId) => ({
  type: types.FETCH_EDIT_HTML_CONTENT,
  payload: { file, contentId, contentGroupId },
});
