import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContentItemProgress.module.scss';
import getScreenOrientationIcon from './getScreenOrientationIcon';
import { getScreenDimensions } from '../../../../../../../utils';
import Icons from '../../../Icons';
import ContentIcons from '../../../ContentIcons';

function ContentItemProgress({ name, type, progressValue, screenSize, screenSizes, onAbortUpload }) {
  return (
    <div className={styles.container}>
      <div className={styles.sizeCard}>
        <span className={styles.sizeCardIcon}>
          {getScreenOrientationIcon(screenSize.id, screenSizes, { color: '#8A8A8F' })}
        </span>
        <span className={styles.sizeCardText}>
          {screenSize.name} {getScreenDimensions(screenSize.resolutionWidth, screenSize.resolutionHeight)}
        </span>
      </div>
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <div className={styles.contentInnerMain}>
            <div className={styles.contentIcon}>
              <ContentIcons type={type} />
            </div>
            <span className={styles.text}>{name}</span>
          </div>
          <progress className={styles.progressBar} max="100" value={progressValue} />
        </div>
        <button className={styles.delete} onClick={onAbortUpload}>
          <Icons icon="deleteTrash" widthIcons={24} />
        </button>
      </div>
    </div>
  );
}

ContentItemProgress.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  screenSize: PropTypes.object.isRequired,
  screenSizes: PropTypes.array.isRequired,
  onAbortUpload: PropTypes.func.isRequired,
  progressValue: PropTypes.number.isRequired,
};

export default ContentItemProgress;
