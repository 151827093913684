import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './ScreenSizeEditScreen.module.scss';
import ButtonDigital from '../../../../common/components/UI/ButtonDigital';
import { getScreenDimention } from '../../../../../state/utils/create-file-helper';
import Icons from '../../../../common/components/UI/Icons';
import { fetchRemoveScreenSizes } from '../../../../../state/ducks/screenSizes/actions';
import SvgPlus from '../../../../../assets/svg/SvgPlus/SvgPlus';
import {
  setShowConfirmModalPlaylistScreens,
  setShowConfirmModalScreenSizes,
} from '../../../../../state/ducks/app/actions';
import { validation, getScreenOrientationIcon } from '../../../../../utils';

class ScreenSizeEditScreen extends Component {
  componenDidMount() {
    const { currentScreenSizeId, change } = this.props;
    change('sizeFormEdit', currentScreenSizeId);
  }

  componentWillUnmount() {
    const { handleCloseAddCustomSize } = this.props;
    handleCloseAddCustomSize();
  }

  handleConfirmSelectSize = () => {
    const { currentScreen, handleClickCloseEdit, updateScreenItem, orientationSelect } = this.props;

    if (currentScreen) {
      updateScreenItem(currentScreen.id, { screenSizeId: orientationSelect });
    }
    handleClickCloseEdit();
  };

  handleOnCancelSelectSize = () => {
    const { handleClickCloseEdit } = this.props;
    handleClickCloseEdit();
  };

  handleSubmitCreateNewSize = () => {
    const { sizeName, resolutionWidth, resolutionHeight, createScreenSize } = this.props;
    createScreenSize({
      name: sizeName,
      resolutionWidth: Number(resolutionWidth),
      resolutionHeight: Number(resolutionHeight),
    });
    this.handleCloseCreateCustomSize();
  };

  handleCloseCreateCustomSize = () => {
    const { change, handleCloseAddCustomSize } = this.props;

    change('sizeName', '');
    change('resolutionWidth', '');
    change('resolutionHeight', '');
    handleCloseAddCustomSize();
  };

  handleClickDeleteSize = (event, screenSize) => {
    event.preventDefault();
    const { showModalWindow, showModalScreeSizes } = this.props;
    if (screenSize.usedScreens.length !== 0) {
      showModalScreeSizes(true, {
        title: `The screens below are still using "${
          screenSize.name
        }" size. Are you sure you want to delete it and resize the screens to "Portrait" (9:16)?`,
        fetchAction: 'fetchRemoveScreenSizes',
        screenSizeId: screenSize.id,
        usedScreens: screenSize.usedScreens,
      });
    } else {
      showModalWindow(true, {
        title: 'Are you sure you want to delete the size?',
        fetchAction: 'fetchRemoveScreenSizes',
        id: screenSize.id,
      });
    }
  };

  render() {
    const {
      currentScreenSizeId,
      screenSizeList,
      customSizeBtnIsActive,
      handleClickAddCustomSize,
      isEditMode,
      orientationSelect,
      sizeName,
      resolutionWidth,
      resolutionHeight,
      screenSizes,
    } = this.props;

    return (
      <form className={styles.screenSizeForm}>
        <div className={styles.orientationBox}>
          {screenSizeList
            .filter(screenSize => (!isEditMode ? screenSize.id === currentScreenSizeId : screenSize))
            .map(el => {
              const isSelected = orientationSelect === el.id && !customSizeBtnIsActive;
              const color = isSelected ? '#fc7300' : '#8A8A8F';

              return (
                <label
                  key={el.id}
                  className={cn(styles.orientationContainer, isSelected && styles.orientationContainerIsSelected)}
                >
                  {!el.default && (
                    <div onClick={event => this.handleClickDeleteSize(event, el)} className={styles.closePosition}>
                      <Icons icon="close" widthIcon={16} />
                    </div>
                  )}
                  {getScreenOrientationIcon(el.id, screenSizes, {
                    size: 40,
                    color,
                  })}
                  <div className={styles.orientationContainerTextContainer}>
                    <span className={styles.orientationContainerTitle}>{el.name}</span>
                    <span className={styles.orientationContainerSubtitle}>
                      {getScreenDimention(el.resolutionWidth, el.resolutionHeight)}
                    </span>
                  </div>
                  <Field
                    id={el.id}
                    parse={value => Number(value)}
                    name="screenSizeId"
                    component="input"
                    type="radio"
                    value={el.id}
                    checked={orientationSelect === el.id}
                    onChange={this.onOrientationChanged}
                    hidden
                  />
                </label>
              );
            })}
          {isEditMode && (
            <div
              style={customSizeBtnIsActive ? { border: '1px solid rgb(252, 115, 0)' } : null}
              className={styles.orientationContainer}
              onClick={handleClickAddCustomSize}
            >
              <div style={{ marginBottom: '12px' }}>
                <SvgPlus setColor={!customSizeBtnIsActive ? '#8A8A8F' : '#fc7300'} iconSize={24} />
              </div>
              <span>Create Custom Size</span>
            </div>
          )}
        </div>
        {customSizeBtnIsActive && (
          <div className={styles.customSizeWrapper}>
            <label className={styles.titleText}>Create custom size</label>
            <div className={styles.wrapFormCustomSize}>
              <div className={styles.sizeNameBlock}>
                <label className={styles.customText}>Size name:</label>
                <Field
                  name="sizeName"
                  className={styles.sizeNameField}
                  component="input"
                  type="text"
                  placeholder="e.g. Portrait"
                  normalize={validation.normalizeExtraSpaces}
                />
              </div>
              <div className={styles.resolutionBlock}>
                <label className={styles.customText}>Resolution:</label>
                <Field
                  name="resolutionWidth"
                  className={styles.resolutionField}
                  component="input"
                  normalize={validation.numberRegexp}
                  type="text"
                  placeholder="720 w"
                />
                <Field
                  name="resolutionHeight"
                  className={styles.resolutionField}
                  component="input"
                  normalize={validation.numberRegexp}
                  type="text"
                  placeholder="1280 h"
                />
              </div>
              <div className={styles.btnGroup}>
                <ButtonDigital
                  isConfirmStyle={true}
                  type="button"
                  onClick={this.handleSubmitCreateNewSize}
                  disable={!sizeName || !resolutionWidth || !resolutionHeight}
                >
                  Create
                </ButtonDigital>
                <ButtonDigital isConfirmStyle={false} type="button" onClick={this.handleCloseCreateCustomSize}>
                  Cancel
                </ButtonDigital>
              </div>
            </div>
          </div>
        )}

        {!customSizeBtnIsActive &&
          (isEditMode && (
            <div className={styles.btnGroup}>
              <ButtonDigital isConfirmStyle={true} type="button" onClick={this.handleConfirmSelectSize}>
                Confirm
              </ButtonDigital>
              <ButtonDigital isConfirmStyle={false} type="button" onClick={this.handleOnCancelSelectSize}>
                Cancel
              </ButtonDigital>
            </div>
          ))}
      </form>
    );
  }
}

ScreenSizeEditScreen.propTypes = {
  currentScreen: PropTypes.shape({
    name: PropTypes.string.isRequired,
    screenSizeId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    ownerId: PropTypes.number.isRequired,
  }),
  screenSizes: PropTypes.array.isRequired,
  screenSizeList: PropTypes.array,
  orientationSelect: PropTypes.number,
  customSizeBtnIsActive: PropTypes.bool,
  change: PropTypes.func,
  handleClickAddCustomSize: PropTypes.func,
  handleCloseAddCustomSize: PropTypes.func,
  handleClickCloseEdit: PropTypes.func,
  updateScreenItem: PropTypes.func,
  showModalScreeSizes: PropTypes.func,
  showModalWindow: PropTypes.func,
  sizeName: PropTypes.string,
  resolutionWidth: PropTypes.string,
  resolutionHeight: PropTypes.string,
  createScreenSize: PropTypes.func,
  isEditMode: PropTypes.bool,
  isExistName: PropTypes.bool,
  currentScreenSizeId: PropTypes.number,
};

const mapStateToProps = state => {
  const selector = formValueSelector('sizeFormEdit');
  const orientationSelect = selector(state, 'screenSizeId');

  return {
    orientationSelect,
    sizeName: selector(state, 'sizeName'),
    resolutionWidth: selector(state, 'resolutionWidth'),
    resolutionHeight: selector(state, 'resolutionHeight'),
    screenSizes: state.screenSizes.screenSizes,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeScreenSize: fetchRemoveScreenSizes,
      showModalWindow: setShowConfirmModalPlaylistScreens,
      showModalScreeSizes: setShowConfirmModalScreenSizes,
    },
    dispatch,
  );

const SizeFormWrapper = reduxForm({
  form: 'sizeFormEdit',
})(ScreenSizeEditScreen);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SizeFormWrapper);
