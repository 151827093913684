// async types
export const FETCH_SCREENS = 'screens/FETCH_SCREENS';
export const FETCH_CREATE_SCREEN = 'screens/FETCH_CREATE_SCREEN';
export const FETCH_UPDATE_SCREEN = 'screens/FETCH_UPDATE_SCREEN';
export const FETCH_CREATE_SCREEN_WITH_EMPTY_PLAYLIST = 'screens/FETCH_CREATE_SCREEN_WITH_EMPTY_PLAYLIST';

export const UPDATE_SCREEN_LATS_UPDATED_FIELD = 'screens/UPDATE_SCREEN_LATS_UPDATED_FIELD';
export const FETCH_DELETE_SCREEN = 'screens/FETCH_DELETE_SCREEN';
export const FETCH_DELETE_SCREEN_END = 'screens/FETCH_DELETE_SCREEN_END';
export const FETCH_SCREEN_START = 'screens/FETCH_SCREEN_START';
export const FETCH_SCREEN_FINISHED = 'screens/FETCH_SCREEN_FINISHED';
export const FETCH_ADD_PLAYLIST_TO_SCREEN = 'screens/FETCH_ADD_PLAYLIST_TO_SCREEN';
export const FETCH_ADD_PLAYLIST_TO_SCREEN_END = 'screens/FETCH_ADD_PLAYLIST_TO_SCREEN_END';
export const FETCH_REMOVE_PLAYLIST_FROM_SCREEN = 'screens/FETCH_REMOVE_PLAYLIST_FROM_SCREEN';
export const FETCH_GET_SCREEN_PLAYLIST_FLOW = 'screens/FETCH_GET_SCREEN_PLAYLIST_FLOW';
export const FETCH_ADD_PLAYLIST_TO_NOT_EXIST_SCREEN = 'screens/FETCH_ADD_PLAYLIST_TO_NOT_EXIST_SCREEN';
export const FETCH_SCREEN_UPDATE = 'screens/FETCH_SCREEN_UPDATE';
export const FETCH_SCREEN_UPDATE_END = 'screens/FETCH_SCREEN_UPDATE_END';
export const FETCH_REMOVE_AND_SELECT_PLAYLIST_TO_SCREEN = 'screen/FETCH_REMOVE_AND_SELECT_PLAYLIST_TO_SCREEN';
export const FETCH_UPDATE_SCREEN_PLAYLISTS = 'screen/FETCH_UPDATE_SCREEN_PLAYLISTS';
export const FETCH_REMOVE_AND_SELECT_PLAYLIST_TO_SCREEN_END = 'screen/FETCH_REMOVE_AND_SELECT_PLAYLIST_TO_SCREEN_END';
export const FETCH_CREATE_SCREEN_WITH_RELATION_OF_PLAYLIST = 'screen/FETCH_CREATE_SCREEN_WITH_RELATION_OF_PLAYLIST';
// sync types
export const SET_SCREENS = 'screens/SET_SCREENS';
export const SET_NEW_PLAYLIST_SCREEN_ID = 'screens/SET_NEW_PLAYLIST_SCREEN_ID';
export const SET_SCREEN_PAGE_STATUS = 'screens/SET_SCREEN_PAGE_STATUS';
export const SET_SELECTED_PLAYLIST = 'screens/SET_SELECTED_PLAYLIST';

// old
export const ADD_SCREEN = 'screens/ADD_SCREEN';
export const REMOVE_SCREEN = 'screens/REMOVE_SCREEN';
export const EDIT_SCREEN_TITLE = 'screens/EDIT_SCREEN_TITLE';
export const CHANGE_SCREEN_ORIENTATION = 'screens/CHANGE_SCREEN_ORIENTATION';
export const REMOVE_PLAYLIST = 'screens/REMOVE_PLAYLIST';
export const ADD_PLAYLIST = 'screens/ADD_PLAYLIST';
export const SET_SCREEN_ID = 'screens/SET_SCREEN_ID';
