import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './ScreensPreview.module.scss';
import Icons from '../../../../common/components/UI/Icons';
import monitorIcon from '../../../../../assets/images/monitor.png';

function ScreensPreview({ onCreateNewScreen }) {
  return (
    <div className={styles.container}>
      <div className={styles.monitorIconContainer}>
        <img className={styles.monitorIcon} src={monitorIcon} role="presentation" alt="monitor" />
      </div>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>Add your first screen!</h1>
        <p className={styles.text}>Just click the button below</p>
      </div>
      <Link to={`/screens/new`} className={styles.button} onClick={onCreateNewScreen}>
        <Icons icon="plusWhite" widthIcons={24} marginRight={5} />
        Create New Screen
      </Link>
    </div>
  );
}

ScreensPreview.propTypes = {
  onCreateNewScreen: PropTypes.func.isRequired,
};

export default ScreensPreview;
