import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactSwitch from 'react-switch';
import styles from './Switch.module.scss';
import './SwitchOverride.css';

function Switch({ className, id, label, input, meta, ...rest }) {
  const { touched, error, warning } = meta;

  return (
    <div className={cn(styles.root, className)}>
      <ReactSwitch
        checked={!!input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        uncheckedIcon={false}
        checkedIcon={false}
        onHandleColor="#ffffff"
        offColor="#F6F6F6"
        onColor="#EC812B"
        boxShadow="0 0 0 1px #E5E5E5"
        activeBoxShadow="0 0 0 1px #EC812B"
        height={20}
        width={40}
        {...rest}
      />
      {touched && error && <div className={styles.error}>{error}</div>}
      {touched && warning && <div className={styles.warning}>{warning}</div>}
    </div>
  );
}

Switch.defaultProps = {
  label: null,
};

Switch.propTypes = {
  id: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    warning: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.isRequired,
    value: PropTypes.isRequired,
    onBlur: PropTypes.isRequired,
    onChange: PropTypes.isRequired,
  }).isRequired,
  label: PropTypes.string,
};

export default Switch;
