import React from 'react';
import { connect } from 'react-redux';
import ReactDropZone from 'react-dropzone';
import PropTypes from 'prop-types';
import { contentActions } from '../../../../../../../../state/ducks/content';
import { contentGroupActions } from '../../../../../../../../state/ducks/contentGroup';
import { showNotification } from '../../../../../../../../state/ducks/app/actions';
import { getAcceptFilesTypes } from '../../../../../../../../utils';

const { setUploadedContent } = contentActions;

const {
  fetchMultipleCreateContentGroup,
  fetchMultipleCreateContentGroupSetToPlaylist,
  fetchCreateContentGroupSetToNotExistPlaylist,
} = contentGroupActions;

class DropZone extends React.Component {
  onDropFile = (acceptedFiles, rejectedFiles) => {
    const { multipleUploadContentToPlaylist, selectedPlaylistId, showNotify } = this.props;

    if (acceptedFiles.length) {
      multipleUploadContentToPlaylist(acceptedFiles, selectedPlaylistId);
    }

    rejectedFiles.forEach(file =>
      showNotify({ level: 'error', message: `The format of ${file.name} is not supported` }),
    );
  };

  render() {
    const { children } = this.props;

    return (
      <ReactDropZone disableClick onDrop={this.onDropFile} accept={getAcceptFilesTypes()}>
        {props => children && children({ ...props, ...this.props })}
      </ReactDropZone>
    );
  }
}

DropZone.propTypes = {
  children: PropTypes.func.isRequired,
  showNotify: PropTypes.func.isRequired,
  selectedPlaylistId: PropTypes.number.isRequired,
  multipleUploadContentToPlaylist: PropTypes.func.isRequired,
};

DropZone.defineType = {
  isScreen: false,
};

const mapStateToProps = state => ({
  currentPlaylistId: state.playlists.currentPlaylistId,
  playlists: state.playlists.playlists,
  contentsProgress: state.content.contentsProgress,
});

const mapDispatchToProps = {
  setUploadedContent,
  showNotify: showNotification,
  multipleUploadContent: fetchMultipleCreateContentGroup,
  multipleUploadContentToPlaylist: fetchMultipleCreateContentGroupSetToPlaylist,
  addContentGroupToNotExistPlaylist: fetchCreateContentGroupSetToNotExistPlaylist,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropZone);
