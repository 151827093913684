import * as types from './types';
import { createReducer } from '../../utils';

export default createReducer({
  payload: {},
  desktopStatuses: {},
  initialized: false,
})({
  [types.WS_CMS_EMIT]: (state, action) => ({ ...state, payload: action.payload }),
  [types.WS_INIT]: state => ({ ...state, initialized: true }),
  [types.RECEIVE_DESKTOP_STATUS]: (state, { payload }) => {
    if (!payload.screenId) return state;
    const { uuid } = payload;
    const { desktopStatuses } = state;

    const prevDesktopStatus = desktopStatuses[uuid] || { addedAt: +new Date() };
    const newDesktopStatus = { ...prevDesktopStatus, ...payload };

    return {
      ...state,
      initialized: true,
      desktopStatuses: { ...desktopStatuses, [uuid]: newDesktopStatus },
    };
  },
});
