import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './AddFilesBtn.module.scss';
import Button from '../Button';
import Icon from '../Icon';

function AddFilesBtn({ size, withTip, tip, onClick }) {
  const isMediumSize = size === 'medium';
  const iconProps = {
    size: isMediumSize ? 16 : 24,
    marginLeft: isMediumSize ? 10 : 8,
  };

  const renderBtn = (
    <Button className={cn(styles.button, isMediumSize && styles.buttonMedium)} onClick={onClick}>
      <span>Add</span>
      <Icon name="videoFrameWhite" {...iconProps} />
      <Icon name="webLogoWhite" {...iconProps} />
      <Icon name="pictureWhite" {...iconProps} />
      <Icon name="file" {...iconProps} />
    </Button>
  );

  if (!withTip) {
    return renderBtn;
  }

  return (
    <div className={styles.container}>
      {renderBtn}
      {tip && <span className={styles.tip}>or Drag & Drop your files here</span>}
    </div>
  );
}

AddFilesBtn.defaultProps = {
  tip: 'or Drag & Drop your files here',
  withTip: true,
  onClick: () => {},
};

AddFilesBtn.propTypes = {
  tip: PropTypes.string,
  onClick: PropTypes.func,
  withTip: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'medium']),
};

export default AddFilesBtn;
