// async types
export const FETCH_SCREEN_CONTENTS = 'content/FETCH_SCREEN_CONTENTS';
export const FETCH_PLAYLIST_CONTENTS = 'content/FETCH_PLAYLIST_CONTENTS';
export const FETCH_CREATE_SCREEN_CONTENT = 'content/FETCH_CREATE_SCREEN_CONTENT';
export const FETCH_CREATE_PLAYLIST_CONTENT = 'content/FETCH_CREATE_PLAYLIST_CONTENT';
export const FETCH_DELETE_CONTENT = 'content/FETCH_DELETE_CONTENT';
export const FETCH_CONTENT_START = 'content/FETCH_CONTENT_START';
export const FETCH_CONTENT_FINISHED = 'content/FETCH_CONTENT_FINISHED';
export const FETCH_CREATE_CONTENT_START = 'content/FETCH_CREATE_CONTENT_START';
export const FETCH_CREATE_CONTENT_FINISHED = 'content/FETCH_CREATE_CONTENT_FINISHED';
export const FETCH_CONTENT_META_START = 'content/FETCH_CONTENT_META_START';
export const FETCH_CONTENT_META_FINISHED = 'content/FETCH_CONTENT_META_FINISHED';
export const FETCH_UPDATE_CONTENT_META_ORDER = 'content/FETCH_UPDATE_CONTENT_META_ORDER';
export const FETCH_UPDATE_PLAYLIST_CONTENT = 'content/FETCH_UPDATE_PLAYLIST_CONTENT';
export const ADD_CONTENT_TO_NEW_ENTITY_FROM_MEDIA_LIBRARY = 'content/ADD_CONTENT_TO_NEW_ENTITY_FROM_MEDIA_LIBRARY';
export const FETCH_UPLOAD_CONTENT_TO_PLAYLIST = 'content/FETCH_UPLOAD_CONTENT_TO_PLAYLIST';
export const FETCH_UPLOAD_CONTENT = 'content/FETCH_UPLOAD_CONTENT';
export const FETCH_UPLOAD_CONTENT_START = 'content/FETCH_UPLOAD_CONTENT_START';
export const FETCH_UPLOAD_CONTENT_FINISHED = 'content/FETCH_UPLOAD_CONTENT_FINISHED';
export const FETCH_UPLOAD_CONTENT_TO_PLAYLIST_NEW_ENTITY = 'content/FETCH_UPLOAD_CONTENT_TO_PLAYLIST_NEW_ENTITY';
export const FETCH_ADD_CONTENT_META_TO_PLAYLIST = 'content/FETCH_ADD_CONTENT_META_TO_PLAYLIST';
export const FETCH_ADD_CONTENT_TO_NOT_EXIST_PLAYLIST = 'content/FETCH_ADD_CONTENT_TO_NOT_EXIST_PLAYLIST';
export const FETCH_ADD_CONTENT_FROM_MEDIA_LIBRARY_FLOW = 'content/FETCH_ADD_CONTENT_FROM_MEDIA_LIBRARY_FLOW';
export const FETCH_REPLACE_CONTENT = 'content/FETCH_REPLACE_CONTENT';
export const FETCH_REPLACE_CONTENT_END = 'content/FETCH_REPLACE_CONTENT_END';
export const FETCH_EDIT_HTML_CONTENT = 'content/FETCH_EDIT_HTML_CONTENT';
export const FETCH_EDIT_HTML_CONTENT_END = 'content/FETCH_EDIT_HTML_CONTENT_END';
// sync types
export const SET_CONTENTS = 'content/SET_CONTENTS';
export const SET_CONTENTS_FILTER = 'content/SET_CONTENTS_FILTER';
export const SET_WIN_TOGGLER = 'content/SET_WIN_TOGGLER';
export const SET_PLAYLISTS_CONTENTS = 'content/SET_PLAYLISTS_CONTENTS';
export const SET_SCREENS_CONTENTS = 'content/SET_SCREENS_CONTENTS';
export const SET_CURRENT_CONTENT = 'content/SET_CURRENT_CONTENT';
export const SET_CURRENT_PLAYLIST = 'content/SET_CURRENT_CONTENT';
export const SET_UPLOADED_CONTENT = 'content/SET_UPLOADED_CONTENT';
export const ADD_CONTENT_TO_NEW_ENTITY = 'content/ADD_CONTENT_TO_NEW_ENTITY';
export const SET_PLAYLIST_CONTENT_META = 'content/SET_PLAYLIST_CONTENT_META';
export const SET_MEDIA_LIBRARY_MODAL = 'content/SET_MEDIA_LIBRARY_MODAL';
export const SET_PROGRESS_TO_CONTENT = 'content/SET_PROGRESS_TO_CONTENT';
export const REMOVE_PROGRESS_FROM_CONTENT = 'content/REMOVE_PROGRESS_FROM_CONTENT';
// export const SET_AXIOS_ABORT_METHOD = 'content/SET_AXIOS_ABORT_METHOD';
// export const FETCH_ABORT_UPLOAD_FILE = 'content/FETCH_ABORT_UPLOAD_FILE';
