import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { getDesktopStatuses } from '../websockets/selectors';
import createFlatPlaylist from '../../utils/create-flat-playlist';
import arrayIdInjector from '../../utils/array-id-injector';
import { filterScheduleList, getDifferenceDays, history } from '../../utils';
import { safeGet } from '../../../utils';

export const getScreens = state => state.screens.screens;
const getCurrentScreenId = (state, ownProps) => ownProps && Number(ownProps.match.params.id);
const getContentGroup = state => state.contentGroup.contentGroup;
const getProgrammes = state => state.programmes.programmes;
const getServerTime = state => state.app.timeFromMidnight;
const getAppTime = state => state.app.appTime;

const selector = formValueSelector('schedule');
const getFilterTime = state => ({
  filterTimeFrom: selector(state, 'from'),
  filterTimeTo: selector(state, 'to'),
  filterDay: selector(state, 'day'),
});

const getScreenDesktopStatus = detectedDevices => {
  if (detectedDevices.length > 1) {
    const sortedDevices = detectedDevices.sort((a, b) => a.addedAt - b.addedAt);
    const onlineDevices = sortedDevices.filter(({ status }) => status !== 'offline');

    if (onlineDevices.length) return onlineDevices[0];
    return sortedDevices[0];
  }

  return detectedDevices[0];
};

const defaultDesktopStatus = { status: 'offline', model: 'No PC detected' };
export const getScreensWithDesktopStatus = createSelector(
  [getScreens, getDesktopStatuses],
  (screens, desktopStatuses) =>
    screens.map(screen => {
      const desktopStatusesValues = Object.values(desktopStatuses);
      const detectedDevices = desktopStatusesValues.filter(({ screenId }) => screenId === screen.id);

      const screenDesktopStatus = getScreenDesktopStatus(detectedDevices);
      const desktopStatus = screenDesktopStatus || defaultDesktopStatus;
      return { ...screen, desktopStatus };
    }),
);

export const getScreenWithDesktop = createSelector(
  [getScreensWithDesktopStatus, (_, id) => id],
  (screensWithDesktopStatuses, id) => screensWithDesktopStatuses.find(screen => screen.id === id),
);

export const currentScreen = createSelector(
  [getScreens, getCurrentScreenId],
  (scr, currId) => scr.find(item => item.id === currId),
);

export const getCurrentScreen = createSelector(
  currentScreen,
  crnScr => crnScr,
);

// get current playlist id in area selected
export const getCurrentPlaylistId = (state, ownProps) => {
  const getCurrentScreen = state.screens.screens.find(sc => sc.id === getCurrentScreenId(state, ownProps));
  const getPlaylistSelectedId = getCurrentScreen && getCurrentScreen.playlists && getCurrentScreen.playlists[0].id;

  return getPlaylistSelectedId;
};

// get all playlists with meta data
export const getPlaylistsWithMeta = state => {
  const {
    playlists: { playlists },
    contentMeta: { contentMeta },
  } = state;

  return playlists.map(playlist => {
    const getMetaOfPlaylist = contentMeta.filter(meta => {
      const options = playlist.options ? playlist.options : '{}';
      const theatrePlaylistId = JSON.parse(options).selectedPlaylistId;
      return playlist.type === 'theatre' ? meta.playlistId === theatrePlaylistId : meta.playlistId === playlist.id;
    });

    return getMetaOfPlaylist.length !== 0
      ? { ...playlist, contentMeta: getMetaOfPlaylist }
      : { ...playlist, contentMeta: [] };
  });
};

export const getSortedPlaylist = createSelector(
  [getCurrentPlaylistId, getPlaylistsWithMeta],
  (currentPlaylistId, playlistsWithMeta) => {
    const sortPlaylist = playlistsWithMeta
      // sort by empty playlist
      .sort((a, b) => (a.id < b.id ? (a.contentMeta.length === 0 ? 1 : -1) : b.contentMeta.length === 0 ? -1 : 1))
      // sort by selected playlist
      .reduce((prev, curr) => {
        const filter = prev.filter(item => item.id !== curr.id);
        const find = curr.id === currentPlaylistId;

        return find ? [curr, ...filter] : [...filter, curr];
      }, []);

    return sortPlaylist;
  },
);

function shapeNestedContentMeta(playlistsWithMeta, allPlaylists) {
  function mapContent(plContentMeta) {
    const isPlaylist = ({ entityType }) => entityType === 'playlist';
    const addedPlaylists = new Map();

    const iter = (arr, level) => {
      const sortedArr = arr.sort((a, b) => a.order - b.order);
      return sortedArr.map(content => {
        if (isPlaylist(content)) {
          const { entityId } = content;

          if (addedPlaylists.has(entityId)) {
            const addedPlaylist = addedPlaylists.get(entityId);
            if (addedPlaylist.level !== level) {
              return [];
            }
          }

          addedPlaylists.set(entityId, {
            id: entityId,
            level,
          });

          const nestedPlaylist = allPlaylists.find(p => p.id === entityId);
          // const result = iter(nestedPlaylist.contentMeta, level + 1);
          // result.id = nestedPlaylist.id;
          // return result;
          return arrayIdInjector(iter(nestedPlaylist.contentMeta, level + 1), nestedPlaylist.id);
        }

        return content;
      });
    };

    return iter(plContentMeta, 0);
  }

  function removeEmptyArrays(nestedContentMeta) {
    const { isArray } = Array;
    const isPassFilter = cm => !isArray(cm) || (isArray(cm) && Boolean(cm.length));

    const removedEmptyArrays = nestedContentMeta.filter(isPassFilter);
    return removedEmptyArrays
      .map(item => {
        if (isArray(item)) {
          // const result = removeEmptyArrays(item);
          // result.id = item.id;
          // return result;
          return arrayIdInjector(removeEmptyArrays(item), item.id);
        }
        return item;
      })
      .filter(isPassFilter);
  }

  const nestedContentMeta = playlistsWithMeta.map(plMeta => {
    if (!plMeta) return [];
    return removeEmptyArrays(mapContent(plMeta.contentMeta));
  });

  return nestedContentMeta;
}

export const getNestedContentMeta = createSelector(
  [currentScreen, getPlaylistsWithMeta],
  (screen, playlistContents) => {
    const screenType = safeGet(() => screen.playlists[0].type);

    function getPlaylistIds(playlist) {
      if (playlist.type === 'split') return [playlist.options.firstPlaylistId, playlist.options.secondPlaylistId];
      return [playlist.id];
    }

    const playlistIds = getPlaylistIds(screen.playlists[0]);
    const playlistsWithMeta = playlistIds.map(id => playlistContents.find(p => p.id === id));
    const nestedContentMeta = shapeNestedContentMeta(playlistsWithMeta, playlistContents);

    nestedContentMeta.screenType = screenType;

    return nestedContentMeta;
  },
);

export const getFlatPlaylist = createSelector(
  [getNestedContentMeta, getContentGroup, getProgrammes, getServerTime, getAppTime, getFilterTime],
  (contentMeta, contentGroup, programmes, serverTimeFromMidnight, appTime, filterTime) => {
    const { filterTimeFrom, filterTimeTo, filterDay } = filterTime;

    const differenceDays = getDifferenceDays(appTime, filterDay);
    const startTime = appTime + differenceDays - serverTimeFromMidnight;
    const endTime = startTime + 86400000;

    const entityInjector = meta => {
      switch (meta.entityType) {
        case 'contentGroup':
          return { ...meta, entity: contentGroup.find(cg => cg.id === meta.entityId) };
        case 'programme':
          return { ...meta, entity: { ...programmes.find(p => p.id === meta.entityId), type: 'programme' } };
        default:
          return meta;
      }
    };

    function entityFiller(contentData) {
      return contentData.map(c => {
        if (Array.isArray(c)) return arrayIdInjector(entityFiller(c), c.id);
        return entityInjector(c);
      });
    }

    // array of content meta for support multiple playlist
    const filledMeta = contentMeta.map(cm => entityFiller(cm));
    const flatList = filledMeta.map(i => createFlatPlaylist(i, startTime, endTime, contentMeta.screenType));

    // filtering flat list by time
    const filteredFlatList = filterScheduleList(flatList, filterTimeFrom, filterTimeTo, filterDay);
    return filteredFlatList;
  },
);
