import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '../../../../../../../common/components/UI/Button';
import Icon from '../../../../../../../common/components/UI/Icon';
import Tooltip from '../../../../../../../common/components/UI/Tooltip/Tooltip';

import styles from './NestedPlaylistBox.module.scss';
import AddFilesBtn from '../../../../../../../common/components/UI/AddFilesBtn/AddFilesBtn';
import makeToggleable from '../../../../../../../hocs/makeToggleable';
import MenuList from '../../../../../../../common/components/UI/MenuList/MenuList';
import DotsButton from '../../../../../../../common/components/UI/DotsButton';
import PlaylistContents from '../../../../../../Screens/components/PlaylistContents';
import DropZone from '../../../../../../../common/components/DropZone/DropZone';

class NestedPlaylistBox extends React.Component {
  renderMenu = () => (
    <MenuList isOpen={this.props.isOpen} right={18} top={18}>
      <MenuList.Item
        title="Choose Playlist"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          this.props.onOpenChoosePlaylistModal();
          this.props.close();
        }}
      />
    </MenuList>
  );

  renderHeader = hasPlaylist => {
    const { rootRef, open, playlist, placeholder, isMain } = this.props;

    const renderRight = hasPlaylist && (
      <div className={styles.headerLeft}>
        <AddFilesBtn size="medium" withTip={false} />
        <DotsButton
          size={16}
          className={styles.dotsButton}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            open();
          }}
        />
      </div>
    );

    return (
      <div ref={rootRef} className={styles.header}>
        <div className={styles.textContainer}>
          {hasPlaylist && <div className={styles.name}>{playlist.name}</div>}
          {placeholder && (
            <div className={styles.placeholderWrapper}>
              <div className={cn(styles.placeholder, hasPlaylist && styles.placeholderSmall)}>{placeholder}</div>
              {isMain && (
                <div className={styles.tipContainer} data-tip="Sound will be played from this playlist">
                  <Icon name="volume" size={16} />
                  <Tooltip className={styles.tooltip} />
                </div>
              )}
            </div>
          )}
        </div>
        {renderRight}
        {this.renderMenu()}
      </div>
    );
  };

  render() {
    const { renderHeader } = this;
    const { playlist, onAcceptDrop, onOpenChoosePlaylistModal, onOpenContentSettingsModal } = this.props;

    const hasPlaylist = Boolean(playlist);

    const renderContent = (
      <>
        {renderHeader(hasPlaylist)}
        <div className={styles.body}>
          {!hasPlaylist && (
            <Button className={styles.choosePlaylistBtn} onClick={onOpenChoosePlaylistModal}>
              Choose Playlist
            </Button>
          )}
          {hasPlaylist && (
            <PlaylistContents bordered={false} playlistId={playlist.id} onEditItem={onOpenContentSettingsModal} />
          )}
        </div>
      </>
    );

    if (!hasPlaylist) {
      return <div className={styles.container}>{renderContent}</div>;
    }

    return (
      <DropZone className={styles.container} onAcceptDrop={onAcceptDrop}>
        {() => renderContent}
      </DropZone>
    );
  }
}

NestedPlaylistBox.defaultProps = {
  isMain: false,
};

NestedPlaylistBox.propTypes = {
  isMain: PropTypes.bool,
  name: PropTypes.string,
  playlist: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  onAcceptDrop: PropTypes.func.isRequired,
  onOpenChoosePlaylistModal: PropTypes.func.isRequired,
  onOpenContentSettingsModal: PropTypes.func.isRequired,
  ...makeToggleable.propTypes,
};

export default makeToggleable(NestedPlaylistBox);
