import { registerSelectors } from 'reselect-tools';
import reducer from './reducers';
import * as actions from './actions';
import sagas from './sagas'; // sagas
import * as selectors from './selectors';
import * as types from './types';

export const playlistsActions = actions;
export const playlistsReducer = reducer;
export const playlistsSagas = sagas;
export const playlistSelectors = selectors;
export const playlistsTypes = types;
registerSelectors(selectors);
