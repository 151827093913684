import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { SketchPicker } from 'react-color';
import makeToggleable from '../../../../hocs/makeToggleable';

import styles from './ColorPicker.module.scss';

function ColorPicker({ rootRef, isOpen, close, open, className, id, label, input, meta, ...rest }) {
  const { touched, error, warning } = meta;

  const popover = {
    position: 'absolute',
    zIndex: '1000',
    top: '100%',
    left: 0,
  };

  // const cover = {
  //   position: 'fixed',
  //   top: '0px',
  //   right: '0px',
  //   bottom: '0px',
  //   left: '0px',
  //   zIndex: '1',
  // };

  const { rgb, hex } = input.value;

  return (
    <button
      type="button"
      ref={rootRef}
      onBlur={() => {
        input.onBlur(input.value);
      }}
      className={cn(styles.root, className)}
      onClick={open}
      {...rest}
    >
      <div className={styles.input}>
        <span className={styles.value}>{hex && hex.toUpperCase()}</span>
        <div className={styles.indicator} style={{ backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})` }} />
        {isOpen && (
          <>
            {/* <div style={cover} onClick={close} /> */}
            <div style={popover}>
              <SketchPicker disableAlpha color={rgb} onChange={input.onChange} />
            </div>
          </>
        )}
      </div>
      {touched && error && <div className={styles.error}>{error}</div>}
      {touched && warning && <div className={styles.warning}>{warning}</div>}
    </button>
  );
}

ColorPicker.defaultProps = {
  label: null,
};

ColorPicker.propTypes = {
  ...makeToggleable.propTypes,
  className: PropTypes.func,
  id: PropTypes.any,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    warning: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.isRequired,
    value: PropTypes.isRequired,
    onBlur: PropTypes.isRequired,
    onChange: PropTypes.isRequired,
  }).isRequired,
  label: PropTypes.string,
};

export default makeToggleable(ColorPicker);
