import * as types from './types';
import { WS_CMS_EMIT, SCREEN_IS_UPDATED } from '../websockets/types';
import { createReducer } from '../../utils';

export default createReducer({
  screens: [],
  dataFetched: false,
  isLoading: null,
  selectedPlaylist: null, // @todo check and remove if not used
  isScreen: false,
})({
  // [WS_CMS_EMIT]: (state, action) => {
  //   if (action.wsType !== SCREEN_IS_UPDATED) {
  //     return state;
  //   }

  //   const { screenId, lastUpdated } = action.wsPayload;
  //   const updatedScreens = state.screens.map(screen => {
  //     if (screen.id === screenId) {
  //       return { ...screen, lastUpdated };
  //     }
  //     return screen;
  //   });

  //   return { ...state, screens: updatedScreens };
  // },
  [types.SET_SCREENS]: (state, action) => ({ ...state, screens: action.payload, dataFetched: true }),
  [types.FETCH_SCREEN_START]: state => ({ ...state, isLoading: true }),
  [types.FETCH_SCREEN_FINISHED]: state => ({ ...state, isLoading: false }),
  [types.SET_SCREEN_PAGE_STATUS]: (state, action) => ({ ...state, isScreen: action.payload }),
  [types.SET_SELECTED_PLAYLIST]: (state, action) => ({ ...state, selectedPlaylist: action.payload }),
});
