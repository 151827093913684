import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import makeToggleabel from '../../../../../../../../hocs/makeToggleable';

import { Settings } from '../../../../../../../../common/components/UI/Svg';
import FeedProgramme from '../FeedProgramme';

import styles from './Feed.module.scss';
import Icon from '../../../../../../../../common/components/UI/Icon';

class Feed extends React.Component {
  handleSettingsClick = e => {
    e.stopPropagation();
    const { id, onOpenEditModal } = this.props;
    onOpenEditModal(id);
  };

  handleDeleteClick = e => {
    e.stopPropagation();
    const { id, onDeleteDataFeed } = this.props;
    onDeleteDataFeed(id);
  };

  render() {
    const { handleSettingsClick, handleDeleteClick } = this;
    const { title, isOpen, toggle, onToggleSyncOfProgramme, programmes } = this.props;

    return (
      <div className={cn(styles.root, isOpen && styles.rootIsOpen)}>
        <div className={styles.header} onClick={toggle}>
          <span className={styles.title}>{title}</span>
          <button className={styles.settings} onClick={handleSettingsClick}>
            <Settings stroke="#000" />
          </button>
          <button className={styles.delete} onClick={handleDeleteClick}>
            <Icon name="deleteTrash" size="24px" />
          </button>
        </div>
        {isOpen && (
          <div className={styles.programmes}>
            {programmes.length > 0 ? (
              programmes.map(programme => (
                <FeedProgramme
                  id={programme.id}
                  key={programme.id}
                  title={programme.title}
                  options={programme.options}
                  onToggleSync={onToggleSyncOfProgramme}
                />
              ))
            ) : (
              <div className={styles.programmesEmptyText}>No programmes</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Feed.propTypes = {
  ...makeToggleabel.propTypes,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  programmes: PropTypes.array.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onDeleteDataFeed: PropTypes.func.isRequired,
  onToggleSyncOfProgramme: PropTypes.func.isRequired,
};

export default makeToggleabel(Feed);
