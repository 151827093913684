import moment from 'moment';

const hasMatches = (content, timeFrom, timeTo, selectDate = new Date()) => {
  if (!timeFrom || !timeTo) return true;
  if (timeFrom.includes('_') || timeTo.includes('_')) return true;

  const { timeStart, currentDate } = content;
  // const val = moment(`${moment(selectDate).format('YYYY-MM-DD')} ${timeStart}`).format('x');

  const cut = timeStart
    .slice(0, -3)
    .split(':')
    .join('');
  const from = timeFrom.split(':').join('');
  const to = timeTo.split(':').join('');

  if (from <= cut && cut <= to) {
    return true;
  }

  return false;
};

const filterScheduleList = (flatList, timeFrom, timeTo, date) =>
  flatList.map(playlist =>
    playlist
      .filter(item => hasMatches(item, timeFrom, timeTo, date))
      .filter(i => (date ? i.date === moment(date).format('dddd') : true)),
  );

export default filterScheduleList;
