import { select, call, put } from 'redux-saga/effects';
import { setSpeakers } from './actions';
import { apiRoutes, fetch } from '../../utils';
import { getUser } from '../auth/selectors';

export function* fetchSpeakersFlow() {
  try {
    const { url, method } = apiRoutes.getSpeakers;
    const { id: userId } = yield select(getUser);
    const speakers = yield call(fetch, url(userId), method);

    yield put(setSpeakers(speakers));
  } catch (e) {
    // \_/)
    // (o.o)
    // (___)0
  }
}

export default [];
