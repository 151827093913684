import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import styles from './ImageField.module.scss';
import InsertImageModal from '../../../../../common/components/UI/CommonMediaLibrary/components/FroalaEditor/InsertImageModal';
import Icon from '../../../../../common/components/UI/Icon';

class ImageField extends React.Component {
  state = {
    isOpen: false,
  };

  handleChange = ([selectedImage]) => {
    const {
      input: { onChange },
    } = this.props;

    onChange(selectedImage);
  };

  handleOpen = () => this.setState({ isOpen: true });

  handleClose = () => this.setState({ isOpen: false });

  handleDelete = e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      input: { onChange },
    } = this.props;

    onChange(null);
  };

  render() {
    const { input } = this.props;
    return (
      <>
        <div className={styles.root} onClick={this.handleOpen}>
          {input.value && (
            <div className={styles.selectedImage}>
              <span className={styles.text}>{input.value.fileName}</span>
              <button type="button" className={styles.delete} onClick={this.handleDelete}>
                <Icon name="xCircle" size={16} />
              </button>
            </div>
          )}
          <Icon className={styles.insertImage} name="imageIconButton" size={24} />
        </div>
        <InsertImageModal
          multiple={false}
          isOpen={this.state.isOpen}
          onClose={this.handleClose}
          onSubmit={this.handleChange}
        />
      </>
    );
  }
}

ImageField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.func.isRequired,
  }).isRequired,
};

export default ImageField;
