import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './NewPlaylistBtn.module.scss';
import Icons from '../../../../common/components/UI/Icons';

const NewPlaylistBtn = ({ isActive }) => (
  <Link to={`/playlists/new`} className={cn(styles.container, isActive && styles.containerIsActive)}>
    <Icons icon="plusDark" widthIcons={24} marginRight={8} />
    <span>New Playlist</span>
  </Link>
);

NewPlaylistBtn.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default NewPlaylistBtn;
