import reducer from './reducers';
import * as actions from './actions';
import sagas from './sagas';
import * as selectors from './selectors';
import * as types from './types';

export const screenSizesActions = actions;
export const screenSizesSagas = sagas;
export const screenSizesReducer = reducer;
export const screenSizesSelectors = selectors;
export const screenSizesTypes = types;
