import React from 'react';
import PropTypes from 'prop-types';
// import cn from 'classnames';
import { connect } from 'react-redux';
import { getFormValues, reduxForm, Field } from 'redux-form';

import styles from './OptionsModal.module.scss';
import Modal from '../../../../../../../common/components/Modal';
import Button from '../../../../../../../common/components/UI/Button';
import Input from '../../../../../../../common/components/UI/Input';
import Switch from '../../../../../../../common/components/UI/Switch/Switch';
import ColorPicker from '../../../../../../../common/components/UI/ColorPicker';

import SizeRange from './SizeRange';
import BottomBarField from '../../../../BottomBarField/BottomBarField';
import SelectAdapter from '../../../../../../../common/components/UI/SelectAdapter';

class OptionsModal extends React.Component {
  handleCancel = () => {
    const { onCancel, change, initialValues } = this.props;
    onCancel();
    change('options', initialValues.options);
  };

  renderBody = () => (
    <div className={styles.body}>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Font color:</span>
        <Field name="options.fontColor" type="button" component={ColorPicker} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Color theme:</span>
        <Field name="options.themeColor" type="button" component={ColorPicker} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Bottom bar:</span>
        <Field name="options.hasBottomBar" type="checkbox" component={Switch} />
      </div>
      <div className={styles.control}>
        <span className={styles.controlLabel}>Bottom bar text:</span>
        <BottomBarField formValues={this.props.formValues} change={this.props.change} />
      </div>
      <Field
        name="options.sizes"
        hasBottomBar={
          this.props.formValues && this.props.formValues.options && this.props.formValues.options.hasBottomBar
        }
        component={SizeRange}
      />
    </div>
  );

  render() {
    const { renderBody, handleCancel } = this;
    const { isOpen } = this.props;

    return (
      <Modal isOpen={isOpen} onClose={handleCancel} scroll="modal">
        <Modal.Title>Playlist Options</Modal.Title>
        {renderBody()}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleCancel} theme="invert">
            Cancel
          </Button>
          <Button onClick={() => this.props.onConfirm(this.props.formValues.options)}>Confirm</Button>
        </div>
      </Modal>
    );
  }
}

OptionsModal.defaultProps = {};

OptionsModal.propTypes = {
  ...Modal.propTypes,
  onConfirm: PropTypes.func.isRequired,
  playlistId: PropTypes.number.isRequired,
  updatePlaylist: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};

const formName = 'createPlaylistOptions';

const mapStateToProps = state => {
  const formValues = getFormValues(formName)(state) || {};
  return { formValues };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: false,
  })(OptionsModal),
);
