import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styles from './SessionEditForm.module.scss';
import SessionSection from '../SessionSection';
import NavigationTabs from '../NavigationTabs';
import ButtonDigital from '../../../../common/components/UI/ButtonDigital';
import SpeakersSection from '../SpeakersSection';
import { sessionsActions, sessionsSelectors } from '../../../../../state/ducks/sessions';
import { getDataFeedList } from '../../../../../state/ducks/dataFeeds/selectors';

const { fetchEditSession } = sessionsActions;
const { getSessionList } = sessionsSelectors;

class SessionEditForm extends Component {
  constructor(props) {
    super(props);
    this.sessionFormRef = React.createRef();
  }

  state = {
    selectedTab: 'session',
  };

  componentDidMount() {
    const {
      initialize,
      programmes,
      session: { id, ownerId, programmeId, speakers, ...rest },
    } = this.props;

    const { title: label, id: value } = programmes.find(item => item.id === programmeId) || programmes[0];
    const renameSpeakers = speakers.map(({ name, job, company, ...otherValues }) => ({
      speakerCompany: company,
      speakerName: name,
      speakerJob: job,
      ...otherValues,
    }));

    const initialValues = { sessionId: id, theatre: { label, value }, speakers: renameSpeakers, ...rest };
    initialize(initialValues);
  }

  handleSelectTab = event => {
    const { id } = event.target;
    this.setState({ selectedTab: id });
  };

  renderContentTab = () => {
    const { selectedTab } = this.state;
    const {
      dataFeedList,
      speakerCompany,
      speakerName,
      speakerJob,
      speakers,
      change,
      description,
      programmes,
    } = this.props;
    switch (selectedTab) {
      case 'session':
        return <SessionSection {...{ change, description, programmes, dataFeedList }} />;
      case 'speakers':
        return (
          <FieldArray
            {...{ speakerCompany, speakerName, speakerJob, speakers, change }}
            name="speakers"
            component={SpeakersSection}
          />
        );
      default:
        return null;
    }
  };

  handleSaveEditForm = value => {
    const { editSession, handleCloseSessionModal, isValidFields } = this.props;

    if (!isValidFields) {
      return this.setState({ selectedTab: 'session' }, () => this.sessionFormRef.current.click());
    }

    editSession(value);
    handleCloseSessionModal();
  };

  render() {
    const { selectedTab } = this.state;
    const { handleCloseSessionModal, handleSubmit, isValidFields } = this.props;

    return (
      <form className={styles.sessionContainer} onSubmit={handleSubmit(this.handleSaveEditForm)}>
        <div className={styles.title}>
          <span>Edit Session</span>
        </div>
        <NavigationTabs selectedTab={selectedTab} handleSelectTab={this.handleSelectTab} />
        {this.renderContentTab()}
        <div className={styles.bntGroup}>
          <ButtonDigital onClick={handleCloseSessionModal}>Cancel</ButtonDigital>
          <ButtonDigital btnRef={this.sessionFormRef} type="submit" isConfirmStyle>
            Save
          </ButtonDigital>
        </div>
      </form>
    );
  }
}

SessionEditForm.propTypes = {
  programmes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      ownerId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  handleCloseSessionModal: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  editSession: PropTypes.func.isRequired,
};

const selector = formValueSelector('sessionEdit');

const mapStateToProps = (state, ownProps) => {
  const title = selector(state, 'title');
  const start = selector(state, 'start');
  const end = selector(state, 'end');
  const isValidFields = !!(!!title && start && start.indexOf('_') === -1 && end && end.indexOf('_') === -1);

  const sessionId = state.sessions.sessionModal.type === 'edit' && state.sessions.sessionModal.id;

  const speakers = selector(state, 'speakers');
  return {
    sessionId,
    isValidFields,
    image: selector(state, 'image') || null,
    programmes: state.programmes.programmes,
    session: getSessionList(state, ownProps).find(item => item.id === sessionId),
    speakerName: selector(state, 'speakerName'),
    speakerJob: selector(state, 'speakerJob'),
    speakerCompany: selector(state, 'speakerCompany'),
    speakers: speakers && speakers.sort((a, b) => a.order - b.order),
    dataFeedList: getDataFeedList(state),
    description: selector(state, 'description'),
  };
};

const mapDispatchToProps = {
  editSession: fetchEditSession,
};

export default compose(
  reduxForm({ form: 'sessionEdit' }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SessionEditForm);
