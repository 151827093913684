import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

// import { contentGroupActions } from '../../../../../../../state/ducks/contentGroup';

import styles from './MediaPlaylist.module.scss';

import Icon from '../../../Icon';

import makeToggleable from '../../../../../../hocs/makeToggleable';
import { getCombinedPlaylistContentWithoutFullprogramme } from '../../../../../../../state/ducks/content/selectors';
import ContentIcons from '../../../ContentIcons';

function MediaPlaylist({ item, selectContent, isSelected, addContent, isOpen, toggle, children }) {
  const isEmpty = !children.length;

  return (
    <div className={styles.root} onClick={() => selectContent(item.id)}>
      <div className={cn(styles.inner, isEmpty && styles.innerIsEmpty, isOpen && styles.innerIsOpen)}>
        <div className={cn(styles.checkbox, isSelected && styles.checkboxIsSelected)} />
        <div
          className={styles.toggleArea}
          onClick={e => {
            e.stopPropagation();
            if (!isEmpty) {
              toggle();
            }
          }}
        >
          <Icon name="playlistFolder" size={24} marginRight={24} className={styles.playlistIcon} />
          <span className={styles.name} title={item.name}>
            {isEmpty && <span className={styles.empty}>Empty</span>}
            <span className={styles.nameText}>{item.name}</span>
          </span>
          <button
            type="button"
            className={styles.addBtn}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              addContent();
            }}
          >
            <Icon name="plusOrange" width={20} height={21} marginRight={8} />
            Add to Playlist
          </button>
          {!isEmpty && <Icon name={isOpen ? 'arrowTopOrange' : 'arrowDownGray'} size={16} className={styles.arrow} />}
          {isEmpty && <div className={styles.pusher} />}
        </div>
      </div>
      {isOpen && (
        <div className={styles.children}>
          {children.map(({ id: key, type, ...child }) => (
            <div key={key} className={styles.child}>
              <ContentIcons type={type} marginRight={24} className={styles.childIcon} />
              <div className={styles.childName} title={child.name}>
                {child.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

MediaPlaylist.propTypes = {
  fetchCreateUrlContentGroup: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  ...makeToggleable.propTypes,
};

const mapStateToProps = (state, { playlist }) => {
  const playlistContents = getCombinedPlaylistContentWithoutFullprogramme(state, playlist.id);

  return {
    children: playlistContents,
    contentRemovalList: state.playlists.contentRemovalList,
  };
};

export default connect(mapStateToProps)(makeToggleable(MediaPlaylist));
