import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import FroalaEditor from 'react-froala-wysiwyg';
// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import PropTypes from 'prop-types';
import * as $ from 'jquery';
import CodeMirror from 'codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/markdown/markdown';
import jsBeautify from 'js-beautify';
import _ from 'lodash';
import cn from 'classnames';
import styles from './ProgrammeHTMLTitleEditor.module.scss';
import { fontFaceData } from '../../../../../utils/editorContentCssText';
import './ProgrammeHTMLTitleEditor.scss';

export default class ProgrammeHTMLTitleEditor extends Component {
  state = {
    isCodeView: true,
    htmlTitleValue: '',
  };

  constructor(props) {
    super(props);
    this.froalaRef = React.createRef();
    this.codeMirrorRef = React.createRef();
    this.throttleHandleCodeMirrorChangeContent = _.throttle(this.handleCodeMirrorChangeContent, 500);
    this.throttleHtmlTitleChange = _.throttle(this.handleHtmlTitleChange, 500);
  }

  componentDidMount() {
    const { handleTitleChange, htmlTitle } = this.props;
    this.setState({ htmlTitleValue: htmlTitle });
    this.editor = this.froalaRef.current.$element;

    // init codeView button
    $.FroalaEditor.DefineIcon('codeView', { NAME: 'code' });
    $.FroalaEditor.RegisterCommand('codeView', {
      title: 'Code View',
    });

    // code mirror init
    this.codeMirror = CodeMirror(
      elt => {
        this.codeMirrorRef.current.parentNode.replaceChild(elt, this.codeMirrorRef.current);
      },
      {
        lineNumbers: true,
        lineWrapping: true,
        mode: 'htmlmixed',
        theme: 'neo',
      },
    );

    this.codeMirror.setValue(this.getFormattedCode());
    this.codeMirror.on('change', this.throttleHandleCodeMirrorChangeContent);
    this.codeMirror.on('blur', this.throttleHtmlTitleChange);

    this.registerCodeView();
  }

  componentDidUpdate() {
    const { htmlTitle } = this.props;

    $.FroalaEditor.RegisterCommand('backgroundColor', {
      title: 'Background Color',
      type: 'dropdown',
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      callback: (cmd, backgroundColor) => {
        const bgColor = backgroundColor === 'REMOVE' ? 'white' : backgroundColor;

        const bodyOpenTag = '<body';
        const bodyStartIndex = htmlTitle.indexOf(bodyOpenTag);
        const firstPartHtml = htmlTitle.substring(0, bodyStartIndex);
        const secondPartHtml = htmlTitle.substring(bodyStartIndex + bodyOpenTag.length);
        const newHtml = `${firstPartHtml}<body style="background: ${bgColor};" ${secondPartHtml}`;
        this.handleChangeContent(newHtml);
      },
    });

    this.registerCodeView();
  }

  registerCodeView = () => {
    const { htmlTitle } = this.props;
    const { isCodeView } = this.state;

    $.FroalaEditor.RegisterCommand('codeView', {
      title: 'Code View',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: () => {
        if (isCodeView) {
          this.editor.froalaEditor('toolbar.enable');
          const cleanedHtml = this.editor.froalaEditor('clean.html', htmlTitle);
          this.handleChangeContent(cleanedHtml);
        } else {
          this.codeMirror.setValue(this.getFormattedCode());
          this.editor.froalaEditor('toolbar.disable');
        }
        this.setState({ isCodeView: !isCodeView });
      },
      refresh($btn) {
        $btn[0].classList.remove('fr-disabled');
      },
    });
  };

  getFormattedCode = () => {
    const { htmlTitle } = this.props;
    return htmlTitle ? jsBeautify.html(htmlTitle, { indent_size: 2, preserve_newlines: false }) : '';
  };

  config = () => ({
    key: 'UA7D9D7D5dC3E3G3A7B6B5D5E4A2F2C-7KC1KXDF1INBh1KPe2TK==',
    placeholderText: 'HTML Title',
    charCounterCount: false,
    quickInsertTags: [],
    heightMin: 280,
    zIndex: 0,
    toolbarSticky: false,
    colorsHEXInput: false,
    fullPage: true,
    htmlUntouched: true,
    toolbarButtons: [],
    iframeStyle: fontFaceData,
    htmlRemoveTags: [],
    useClasses: true,
    codeView: true,
    pasteDeniedAttrs: [],
  });

  handleCodeMirrorChangeContent = doc => {
    // const { change } = this.props;
    const value = doc.getValue();
    console.log(value);
    this.setState({ htmlTitleValue: value });
    // change('htmlTitle', value || '');
  };

  handleHtmlTitleChange = () => {
    const { htmlTitleValue } = this.state;
    const { handleTitleChange } = this.props;

    handleTitleChange(htmlTitleValue);
  };

  handleChangeContent = content => {
    // const { change } = this.props;
    console.log(content);
    this.setState({ htmlTitleValue: content });
    // change('htmlTitle', content || '');
  };

  render() {
    const { htmlTitle } = this.props;
    const { isCodeView } = this.state;

    return (
      <div className={styles.container} style={{ display: 'block' }}>
        <div className={cn(styles.wrapFroala, 'description__froala')}>
          <FroalaEditor
            ref={this.froalaRef}
            tag="textarea"
            config={this.config()}
            model={htmlTitle || ''}
            onModelChange={this.handleChangeContent}
          />
          <div
            className={cn(
              styles.codeMirrorWrapper,
              this.editor && this.editor.froalaEditor('fullscreen.isActive') && styles.codeMirrorFullScreen,
            )}
            style={
              !isCodeView
                ? {
                  position: 'absolute !important',
                  clip: 'rect(1px, 1px, 1px, 1px)',
                  padding: '0 !important',
                  border: '0 !important',
                  height: '0px !important',
                  width: '0px !important',
                  overflow: 'hidden',
                }
                : {}
            }
          >
            <textarea defaultValue={htmlTitle} value={htmlTitle} ref={this.codeMirrorRef} autoComplete="off" />
          </div>
        </div>
      </div>
    );
  }
}

ProgrammeHTMLTitleEditor.propTypes = {
  htmlTitle: PropTypes.string,
  change: PropTypes.func.isRequired,
};
