import * as types from './types';

export const setScreens = screens => ({ type: types.SET_SCREENS, payload: screens });
export const fetchScreens = () => ({ type: types.FETCH_SCREENS });
export const fetchScreenStart = () => ({ type: types.FETCH_SCREEN_START });
export const fetchScreenFinished = () => ({ type: types.FETCH_SCREEN_FINISHED });
export const setScreenPageStatus = isScreenPage => ({ type: types.SET_SCREEN_PAGE_STATUS, payload: isScreenPage });
export const fetchScreenUpdate = (screenId, dataUpdate) => ({
  type: types.FETCH_SCREEN_UPDATE,
  payload: { screenId, dataUpdate },
});

export const updateScreenLastUpdatedField = payload => ({
  type: types.UPDATE_SCREEN_LATS_UPDATED_FIELD,
  payload,
});

export const fetchCreateScreen = (formData, playlistId) => ({
  type: types.FETCH_CREATE_SCREEN,
  payload: { formData, playlistId },
});

export const fetchUpdateScreen = (formData, screenId) => ({
  type: types.FETCH_UPDATE_SCREEN,
  payload: {
    formData,
    screenId,
  },
});

export const fetchDeleteScreen = screenId => ({
  type: types.FETCH_DELETE_SCREEN,
  payload: screenId,
});

export const fetchAddPlaylistToScreen = (screenId, playlistId) => ({
  type: types.FETCH_ADD_PLAYLIST_TO_SCREEN,
  payload: {
    screenId,
    playlistId,
  },
});

export const fetchRemovePlaylistFromScreen = (screenId, playlistId) => ({
  type: types.FETCH_REMOVE_PLAYLIST_FROM_SCREEN,
  payload: {
    screenId,
    playlistId,
  },
});

// export const fetchGetScreenPlaylist = screenId => ({
//   type: types.FETCH_GET_SCREEN_PLAYLIST_FLOW,
//   payload: screenId,
// });

export const fetchAddPlaylistToNotExistScreen = (playlistId, currentScreenId) => ({
  type: types.FETCH_ADD_PLAYLIST_TO_NOT_EXIST_SCREEN,
  payload: { playlistId, currentScreenId },
});

export const fetchRemoveAndSetPlaylistToScreen = (currentScreenId, clickedPlaylistId, selectedPlaylistId) => ({
  type: types.FETCH_REMOVE_AND_SELECT_PLAYLIST_TO_SCREEN,
  payload: { currentScreenId, clickedPlaylistId, selectedPlaylistId },
});

export const fetchUpdateScreenPlaylists = (currentScreenId, clickedPlaylistId, selectedPlaylistId) => ({
  type: types.FETCH_UPDATE_SCREEN_PLAYLISTS,
  payload: { currentScreenId, clickedPlaylistId, selectedPlaylistId },
});

export const fetchCreateScreenWithRelationOfPlaylist = playlistId => ({
  type: types.FETCH_CREATE_SCREEN_WITH_RELATION_OF_PLAYLIST,
  payload: { playlistId },
});

export const fetchCreateScreenWithEmptyPlaylist = screenName => ({
  type: types.FETCH_CREATE_SCREEN_WITH_EMPTY_PLAYLIST,
  payload: { screenName },
});
