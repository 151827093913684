import React from 'react';

const SvgRss = ({ fill = '#EC812B', ...rest }) => (
  <svg width={16} height={16} fill="none" {...rest}>
    <mask id="rss_svg__a" fill="none">
      <circle cx={2.602} cy={13.629} r={0.913} />
    </mask>
    <circle cx={2.602} cy={13.629} fill={fill} r={0.913} />
    <path
      d="M2.515 13.63c0-.049.039-.088.087-.088v2a1.913 1.913 0 001.913-1.913h-2zm.087-.088c.048 0 .087.039.087.087h-2c0 1.057.857 1.913 1.913 1.913v-2zm.087.087c0 .049-.039.088-.087.088v-2a1.913 1.913 0 00-1.913 1.912h2zm-.087.088a.088.088 0 01-.087-.088h2a1.913 1.913 0 00-1.913-1.912v2z"
      fill={fill}
      mask="url(#rss_svg__a)"
    />
    <path
      d="M2.002 6.87c2.448-.007 7.344 1.464 7.344 7.401M2.002 1.633c4.18-.011 12.54 2.502 12.54 12.64"
      stroke={fill}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgRss;
