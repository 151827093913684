import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { contentGroupActions } from '../../../../../../../state/ducks/contentGroup';
import { validation } from '../../../../../../../utils';
import styles from './AddWebSiteForm.module.scss';
import Button from '../../../Button';
import Input from '../../../Input';
import Icon from '../../../Icon';
import Modal from '../../../../Modal';

const { fetchCreateUrlContentGroup } = contentGroupActions;

class AddWebSiteForm extends React.Component {
  state = {
    isOpen: false,
  };

  handleOpen = () => this.setState({ isOpen: true });

  handleClose = () => this.setState({ isOpen: false });

  addWebSite = values => {
    this.props.fetchCreateUrlContentGroup(values.url, values.name);
    this.props.reset();
  };

  render() {
    const { handleOpen, handleClose } = this;
    const { changeFieldValue } = this.props;
    const { isOpen } = this.state;

    return (
      <div className={styles.root}>
        <button className={styles.addWebSiteBtn} type="button" onClick={handleOpen}>
          <Icon name="plusDark" size={20} marginRight={10} />
          Add Website
        </button>
        <Modal scroll="modal" isOpen={isOpen} onClose={handleClose}>
          <form className={styles.form} onSubmit={this.props.handleSubmit(this.addWebSite)}>
            <Modal.Title className={styles.title}>Add Website</Modal.Title>
            <div className={styles.inputWrapper}>
              <Field
                id="add-web-site-url"
                name="url"
                label="URL:"
                placeholder="Paste URL"
                component={Input}
                normalize={validation.normalizeExtraSpaces}
                validate={[validation.required, validation.isUrl]}
                onChange={e => changeFieldValue('AddWebSiteForm', 'name', e.target.value)}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Field
                id="add-web-site-name"
                name="name"
                label="Name:"
                placeholder="Enter name"
                component={Input}
                normalize={validation.normalizeExtraSpaces}
                validate={[validation.required]}
              />
            </div>
            <div className={styles.footer}>
              <Button theme="invert" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit">Add</Button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

AddWebSiteForm.propTypes = {
  fetchCreateUrlContentGroup: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
};

export default connect(
  null,
  { fetchCreateUrlContentGroup, changeFieldValue: change },
)(reduxForm({ form: 'AddWebSiteForm' })(AddWebSiteForm));
