import React from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import styles from './PlayListsView.module.scss';
import Icons from '../../../../common/components/UI/Icons';
import PlaylistItem from '../PlaylistItem';
import NewPlaylistBtn from '../NewPlaylistBtn';
import CreatePlaylistForm from '../CreatePlaylistForm';
import PlaylistDetailsForm from '../PlaylistDetailsForm/PlaylistDetailsForm';

export class PlayListsView extends React.Component {
  state = {
    toPlaylists: false,
    playlists: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.toPlaylists === false && this.state.toPlaylists === true) {
      this.setState({ toPlaylists: false });
    }
    if (prevProps.sortedPlaylist.length !== this.props.sortedPlaylist.length) {
      this.setState({ playlists: this.props.sortedPlaylist });
    }
  }
  componentDidMount() {
    this.setState({ playlists: this.props.sortedPlaylist });
  }

  handleFilterPlaylists = (e) => {
    const keyword = e.target.value;
    const { sortedPlaylist } = this.props;
    const filteredPlaylists = sortedPlaylist.filter(playlist => playlist.name.toLowerCase().includes(keyword.toLowerCase()));
    this.setState({ playlists: filteredPlaylists });
  };

  handleClosePlaylist = e => {
    e.preventDefault();
    if (e.target.id !== 'closePlaylistId') return;
    const { currentLocation } = this.props;
    if (currentLocation !== '/playlists') {
      this.setState({ toPlaylists: true });
    }
  };

  render() {
    const {
      showPlaylistFormDetail,
      showPlaylistForm,
      currentLocation,
      deletePlaylist,
      sortedPlaylist,
      duplicatePlaylist,
    } = this.props;
    const { playlists } = this.state;
    const hasPlaylists = sortedPlaylist.length > 0 || currentLocation === '/playlists/new';
    return (
      <div className="containerMenuWrapper">
        {!hasPlaylists && (
          <div className={!hasPlaylists ? styles.containerCenter : 'containerMenu'}>
            <div className={!hasPlaylists ? styles.contentListEmpty : 'contentList'}>
              <Icons icon="playlistIcon" widthIcons={50} />
            </div>
            <div className={styles.centeredEmtyScreenText} style={{ marginBottom: '32px' }}>
              <h1 className={styles.emptyTextStyle}>Add your first playlist!</h1>
              <p className={styles.textColor}>Just click the button below</p>
            </div>
            <Link to={`/playlists/new`} className={styles.createNewPlaylistBtn}>
              <Icons icon="plusWhite" widthIcons={24} marginRight={5} />
              Add New Playlist
            </Link>
          </div>
        )}
        {hasPlaylists && (
          <div className={styles.playlistItems} onClick={this.handleClosePlaylist} id="closePlaylistId">
            {/* <Input placeholder="Search" onChange={this.handleFilterPlaylists} /> */}
            <div className={styles.wrapper}>
              <input placeholder='Search playlists' onChange={this.handleFilterPlaylists} className={styles.inputSearch} type='text' />
              <div style={{ display: 'flex', flex: 1 }} />
              <NewPlaylistBtn isActive={currentLocation === '/playlists/new'} />
            </div>
            <hr />
            <div className={styles.playlistItemsList}>
              {playlists.map(playlist => (
                <PlaylistItem
                  playlist={playlist}
                  key={playlist.id}
                  id={playlist.id}
                  name={playlist.name}
                  type={playlist.type}
                  playlists={playlists}
                  ownerScreens={playlist.ownerScreens}
                  onDelete={() => deletePlaylist(playlist.id)}
                  onDuplicate={() => duplicatePlaylist(playlist)}
                  isActive={`/playlists/${playlist.id}` === currentLocation}
                />
              ))}
            </div>
          </div>
        )}
        {this.state.toPlaylists && <Redirect to="/playlists" />}
        <Switch>
          <Route
            path="/playlists/new"
            render={props => (
              <div className={styles.detailsActive}>
                <Link to="/playlists" className={styles.closeButton} />
                <CreatePlaylistForm {...props} />
              </div>
            )}
          />
          <Route
            path="/playlists/:id"
            render={props => (
              <div className={!showPlaylistFormDetail && !showPlaylistForm ? styles.details : styles.detailsActive}>
                <Link to="/playlists" className={styles.closeButton} />
                <PlaylistDetailsForm {...props} />
              </div>
            )}
          />
        </Switch>
      </div>
    );
  }
}

PlayListsView.propTypes = {
  currentLocation: PropTypes.string,
  showPlaylistForm: PropTypes.bool,
  deletePlaylist: PropTypes.func,
  duplicatePlaylist: PropTypes.func,
  showPlaylistFormDetail: PropTypes.bool,
  sortedPlaylist: PropTypes.array,
};

export default PlayListsView;
