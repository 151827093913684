import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './NewScreenBtn.module.scss';

function NewScreenBtn({ linkTo, isActive, onClick }) {
  return (
    <Link to={linkTo} className={cn(styles.container, isActive && styles.containerIsActive)} onClick={onClick}>
      <span className={styles.icon} />
      <span className={styles.text}>New Screen</span>
    </Link>
  );
}

NewScreenBtn.propTypes = {
  linkTo: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default NewScreenBtn;
