import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import PropTypes from "prop-types";
import styles from "./PlaylistItem.module.scss";
import makeToggleable from "../../../../hocs/makeToggleable";
import MenuList from "../../../../common/components/UI/MenuList/MenuList";
import DotsButton from "../../../../common/components/UI/DotsButton";
import { templateTypes } from "../../../../../constants";
import Icon from "../../../../common/components/UI/Icon";

const isPlaylistEmpty = (playlist, playlists) => {
  if (playlist.type === templateTypes.SPLIT) {
    const options = JSON.parse(playlist.options);
    const firstPlaylist = playlists.find(
      ({ id }) => options.firstPlaylistId === id
    );
    const secondPlaylist = playlists.find(
      ({ id }) => options.secondPlaylistId === id
    );

    return !firstPlaylist && !secondPlaylist;
  }

  if (playlist.type === templateTypes.THEATRE) {
    const options = JSON.parse(playlist.options);
    const selectedPlaylist = playlists.find(
      ({ id }) => options.selectedPlaylistId === id
    );

    return !selectedPlaylist;
  }

  return !playlist.contentMeta.length;
};

function renderOwnerScreens(ops) {
  const { ownerScreens, isShowAll } = ops;
  const len = ownerScreens.length;
  const maxLen = 3;
  if (!len) {
    return null;
  }

  const isMoreThanMaxLen = len > maxLen;
  const normalizedOwners =
    isMoreThanMaxLen && !isShowAll
      ? ownerScreens.slice(0, maxLen)
      : ownerScreens;

  return (
    <div className={styles.ownerScreensList}>
      {normalizedOwners.map(({ id, name }, idx) => {
        const isLast = idx === normalizedOwners.length - 1;
        return (
          <span
            key={id}
            className={cn(styles.ownerScreensListItem, isLast && styles.last)}
          >
            <span className={styles.text}>{name}</span>
          </span>
        );
      })}
      {isMoreThanMaxLen && !isShowAll && (
        <span className={styles.showAll}>{`+${len - maxLen} more`}</span>
      )}
    </div>
  );
}

class PlaylistItem extends React.Component {
  state = {
    isShowAll: false,
  };

  mousemoveIntervalId = null;

  handleShowAll = () => {
    clearInterval(this.mousemoveIntervalId);

    this.mousemoveIntervalId = setInterval(() => {
      this.setState({ isShowAll: true });
    }, 500);
  };

  handleHideAll = () => {
    clearInterval(this.mousemoveIntervalId);
    this.setState({ isShowAll: false });
  };

  render() {
    const { isShowAll } = this.state;
    const {
      playlist,
      id,
      name,
      type,
      toggle,
      isActive,
      isOpen,
      rootRef,
      ownerScreens,
      ...other
    } = this.props;
    const { onDelete, onDuplicate, playlists } = other;

    const isEmpty = isPlaylistEmpty(playlist, playlists);
    const hasOwnerScreens = ownerScreens && ownerScreens.length > 0;

    return (
      <Link
        innerRef={rootRef}
        to={isActive ? "/playlists" : `/playlists/${id}`}
        className={cn(
          styles.container,
          isActive && styles.containerIsActive,
          isEmpty && styles.containerIsEmpty,
          isShowAll && styles.containerIsShowAll
        )}
        onMouseEnter={this.handleShowAll}
        onMouseLeave={this.handleHideAll}
      >
        <div className={styles.content}>
          <div className={styles.name}>
            {hasOwnerScreens && isEmpty && (
              <span className={styles.empty}>Empty</span>
            )}
            <span>{name}</span>
          </div>
          {hasOwnerScreens && renderOwnerScreens({ isShowAll, ownerScreens })}
          {!hasOwnerScreens && isEmpty && (
            <span className={styles.empty}>Empty</span>
          )}
        </div>
        <div className={styles.right}>
          {type !== templateTypes.DEFAULT && (
            <div
              className={styles.icon}
              style={{ width: 24, height: 34, position: "relative" }}
            >
              <Icon
                size={40}
                name={type}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </div>
          )}
          <DotsButton
            size={16}
            className={styles.dotsButton}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggle();
            }}
          />
        </div>
        <MenuList className={cn(styles.menu, isOpen && styles.menuIsOpen)}>
          <MenuList.Item
            title="Duplicate"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDuplicate();
            }}
          />
          <MenuList.Item
            title="Delete"
            color="#EB5757"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete();
            }}
          />
        </MenuList>
      </Link>
    );
  }
}

PlaylistItem.defaultProps = {
  ownerScreens: [],
};

PlaylistItem.propTypes = {
  playlist: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  rootRef: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isEmpty: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  ownerScreens: PropTypes.array,
};

export default makeToggleable(PlaylistItem);
