import _ from 'lodash';
import * as types from './types';
import { createReducer } from '../../utils';

export default createReducer({
  contents: [],
  contentsProgress: {},
  contentFilter: null,
  playlistsContents: [],
  screensContents: [],
  currentContent: null,
  isLoadingContent: false,
  isLoadingCreateContent: false,
  isUploadingContent: false,
  uploadedContent: null,
  mediaLibraryModal: {
    modalToggler: false,
    data: { id: null, newScreenName: null, orientationSelect: null },
    checkBoxArray: {},
  },
})({
  [types.SET_CONTENTS]: (state, action) => ({ ...state, contents: action.payload }),
  [types.SET_CONTENTS_FILTER]: (state, action) => ({ ...state, contentFilter: action.payload }),
  [types.SET_PLAYLISTS_CONTENTS]: (state, action) => ({ ...state, playlistsContents: action.payload }),
  [types.SET_SCREENS_CONTENTS]: (state, action) => ({ ...state, screensContents: action.payload }),
  [types.SET_MEDIA_LIBRARY_MODAL]: (state, action) => ({ ...state, mediaLibraryModal: action.payload }),
  // [types.SET_PLAYLISTS]: (state, action) => ({ ...state, screensContents: action.payload}),
  [types.SET_CURRENT_PLAYLIST]: (state, action) => ({ ...state, currentPlaylist: action.payload }),
  [types.SET_UPLOADED_CONTENT]: (state, action) => ({ ...state, uploadedContent: action.payload }),
  [types.FETCH_CONTENT_START]: state => ({ ...state, isLoadingContent: true }),
  [types.FETCH_CONTENT_FINISHED]: state => ({ ...state, isLoadingContent: false }),
  [types.FETCH_CREATE_CONTENT_START]: state => ({ ...state, isLoadingCreateContent: true }),
  [types.FETCH_CREATE_CONTENT_FINISHED]: state => ({ ...state, isLoadingCreateContent: false }),
  [types.FETCH_UPLOAD_CONTENT_START]: state => ({ ...state, isUploadingContent: true }),
  [types.FETCH_UPLOAD_CONTENT_FINISHED]: state => ({ ...state, isUploadingContent: false }),
  [types.SET_PROGRESS_TO_CONTENT]: (state, action) => {
    const { urlKey, total, loaded, fileProgressData } = action.payload;
    return {
      ...state,
      contentsProgress: {
        ...state.contentsProgress,
        [urlKey]: {
          total,
          loaded,
          fileProgressData,
        },
      },
    };
  },
  [types.REMOVE_PROGRESS_FROM_CONTENT]: (state, action) => {
    const { urlKey, isAbort = false } = action.payload;
    const { total, loaded } = state.contentsProgress[urlKey];

    if (total === loaded || isAbort) {
      const { contentsProgress } = state;
      const toArrayKeys = _.keys(contentsProgress);
      const removeArray = toArrayKeys.filter(el => el !== urlKey);
      const result = _.pick(contentsProgress, removeArray);
      return {
        ...state,
        contentsProgress: result,
      };
    }

    return {
      ...state,
    };
  },
});
