import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styles from './ProgrammesList.module.scss';
import ProgrammeItem from '../ProgrammeItem';
import NewTheatreBtn from '../NewTheatreBtn';
import DataFeedBtn from '../DataFeedBtn';

function ProgrammesList({
  programmesList,
  handleRemoveTheatre,
  handleCreateNewTheatre,
  onWrapperClick,
  wrapperId,
  location: { pathname: currentLocation },
}) {
  const isActive = currentLocation === `/programmes/new`;
  console.log(programmesList)
  return (
    <div className={styles.programmesList} onClick={onWrapperClick} id={wrapperId}>
      {programmesList.map(el => (
        <ProgrammeItem
          key={el.id}
          name={el.title}
          id={el.id}
          isSelected={`/programmes/${el.id}` === currentLocation}
          handleClickRemoveTheatre={() => handleRemoveTheatre(el.id)}
        />
      ))}
      <div className={styles.footer}>
        <NewTheatreBtn linkTo={isActive ? `/programmes` : '/programmes/new'} handleOnClick={handleCreateNewTheatre} />
        <DataFeedBtn />
      </div>
    </div>
  );
}

ProgrammesList.propTypes = {
  programmesList: PropTypes.array.isRequired,
  handleCreateNewTheatre: PropTypes.func.isRequired,
  handleRemoveTheatre: PropTypes.func.isRequired,
  onWrapperClick: PropTypes.func.isRequired,
  currentLocation: PropTypes.string,
  wrapperId: PropTypes.string.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

export default withRouter(ProgrammesList);
