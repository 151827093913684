import isomorphicFetch from "isomorphic-fetch";
import storage from "./storage";
import { logout } from "../ducks/auth/actions";

function requestHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

function parseStatus(status, res, url, dispatch) {
  return new Promise((resolve, reject) => {
    if (status >= 200 && status < 300) {
      res.then((response) => resolve(response));
    } else {
      if (status === 401 && url !== "/Clients/logout" && dispatch)
        dispatch(logout());
      res.then((response) =>
        reject(response.error ? response.error : response)
      );
    }
  });
}

function parseJSON(response) {
  return response.text().then((text) => (text ? JSON.parse(text) : {}));
}

const getApiUrl = () => {

  return process.env.REACT_APP_API_URL;
};

const API_URL = getApiUrl();

const defaultOptions = {
  withToken: true, // flag to use access token in query
};

const fetch = (url, method, body = null, customOptions = {}) => {
  const combinedOptions = {
    ...defaultOptions,
    ...customOptions,
  };

  const token = storage.getToken();
  const query = combinedOptions.withToken ? `?access_token=${token}` : "";

  const options = {
    method,
    headers: requestHeaders(),
    body: body || method !== "GET" ? JSON.stringify(body) : null,
  };

  const path = `${API_URL}${url}${query}`;

  return isomorphicFetch(path, options).then((res) =>
    parseStatus(res.status, parseJSON(res), url, fetch.dispatch)
  );
};

export const provideDispatchToFetch = (dispatch) => {
  fetch.dispatch = dispatch;
};

export default fetch;
