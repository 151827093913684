import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { contentGroupActions } from '../../../../state/ducks/contentGroup';
import ProgressItem from '../UI/ProgressItem';

const { fetchAbortUploadFile } = contentGroupActions;

function ProgressItems({ items, abortUploadFile, itemStyle, itemIconStyle }) {
  return items.map(({ total, loaded, fileProgressData }) => (
    <ProgressItem
      style={itemStyle}
      iconStyle={itemIconStyle}
      name={fileProgressData.name}
      key={fileProgressData.uniqueKey}
      progressValue={(loaded * 100) / total}
      contentType={fileProgressData.contentType}
      onClick={e => {
        e.preventDefault();
        abortUploadFile(fileProgressData.uniqueKey);
      }}
    />
  ));
}

ProgressItems.defaultProps = {
  items: [],
  itemStyle: {},
  itemIconStyle: {},
};

ProgressItems.propTypes = {
  items: PropTypes.array,
  itemStyle: PropTypes.object,
  itemIconStyle: PropTypes.object,
};

const mapDispatchToProps = {
  abortUploadFile: fetchAbortUploadFile,
};

export default connect(
  null,
  mapDispatchToProps,
)(ProgressItems);
