import React from 'react';
import PropTypes from 'prop-types';
import iconVideo from '../../../../../assets/images/icons/button-video-frame.svg';
import iconImage from '../../../../../assets/images/icons/button-picture.svg';
import iconWebLogo from '../../../../../assets/images/icons/web-logo-gray.svg';
import icoHtmlPaper from '../../../../../assets/images/icons/html-paper.svg';
import fullProgramme from '../../../../../assets/images/icons/full-programme.svg';
import playlist from '../../../../../assets/images/icons/playlistFolder.svg';

const icons = {
  url: iconWebLogo,
  html: icoHtmlPaper,
  'text/html': icoHtmlPaper,
  video: iconVideo,
  'video/mp4': iconVideo,
  image: iconImage,
  'image/jpg': iconImage,
  'image/png': iconImage,
  'image/jpeg': iconImage,
  'image/webp': iconImage,
  programme: fullProgramme,
  playlist,
};

const getIcon = type => {
  const icon = icons[type];
  if (!icon) {
    return icoHtmlPaper;
  }

  return icon;
};

const ContentIcons = ({ type, iconPosition, ...rest }) => (
  <img alt="icon img" src={getIcon(type)} className={iconPosition} {...rest} />
);

ContentIcons.propTypes = {
  type: PropTypes.string,
  iconPosition: PropTypes.string,
};

export default ContentIcons;
