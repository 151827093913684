import * as types from './types';
import { createReducer } from '../../utils';
import { getFormatedPlaylists } from './utils';

export default createReducer({
  playlists: [],
  contentMeta: [],
  currentPlaylist: {},
  isLoading: null,
  contentRemovalList: [],
  isCreatingPlaylist: false,
})({
  [types.CREATE_PLAYLIST_END]: state => ({
    ...state,
    isCreatingPlaylist: false,
  }),
  [types.CREATE_PLAYLIST_START]: state => ({
    ...state,
    isCreatingPlaylist: true,
  }),

  [types.SET_PLAYLISTS]: (state, { payload }) => ({
    ...state,
    playlists: getFormatedPlaylists(payload),
  }),

  [types.SET_CURRENT_PLAYLIST]: (state, { payload }) => ({
    ...state,
    currentPlaylist: payload,
  }),
  [types.SET_CURRENT_PLAYLIST_META]: (state, action) => ({
    ...state,
    currentPlaylist: { ...state.currentPlaylist, contentMeta: action.payload },
  }),
  [types.SET_CURRENT_PLAYLIST_CONTENT]: (state, action) => ({
    ...state,
    currentPlaylist: { ...state.currentPlaylist, contents: action.payload },
  }),

  [types.FETCH_PLAYLIST_START]: state => ({ ...state, isLoading: true }),
  [types.FETCH_PLAYLIST_END]: state => ({ ...state, isLoading: false }),

  [types.CONTENT_REMOVAL_START]: (state, action) => {
    const { contentRemovalList } = state;
    const addContentToRemovalList = [...contentRemovalList, action.payload];
    return {
      ...state,
      contentRemovalList: addContentToRemovalList,
    };
  },
  [types.CONTENT_REMOVAL_END]: (state, action) => {
    const { contentRemovalList } = state;
    const deleteContentFromRemovalList = contentRemovalList.filter(ct => ct !== action.payload);
    return {
      ...state,
      contentRemovalList: deleteContentFromRemovalList,
    };
  },
});
