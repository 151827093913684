import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import * as playlistsActions from '../../../../../state/ducks/playlists/actions';
import {
  getIsCreatingPlaylist,
  getDefaultPlaylists,
  getPlaylists,
} from '../../../../../state/ducks/playlists/selectors';

import generateEntityName from '../../../../../utils/generateName';

import PlaylistTypeEditor from './PlaylistTypeEditor/PlaylistTypeEditor';
import getRenderPlaylistType from './getRenderPlaylistType';
import { templateTypes } from '../../../../../constants';
import { removeSpaces } from '../../../../../utils';
import { getProgrammes } from '../../../../../state/ducks/programmes/selectors';

const playlistTypes = Object.values(templateTypes);

const initialOptions = {
  [templateTypes.DEFAULT]: {},
  [templateTypes.SPLIT]: {
    firstPlaylistId: null,
    secondPlaylistId: null,
    fontColor: {
      hex: '#000',
      rgb: { r: 0, g: 0, b: 0, a: 100 },
    },
    themeColor: {
      hex: '#E5E5E5',
      rgb: { r: 229, g: 229, b: 229, a: 100 },
    },
    hasBottomBar: false,
    bottomBarText: '#EventHashtag',
    bottomBarImg: null,
    sizes: {
      firstPlaylist: 50,
      secondPlaylist: 50,
      bottomBar: 0,
    },
  },
  [templateTypes.THEATRE]: {
    selectedProgrammeId: null,
    selectedPlaylistId: null,
    fontSize: '',
    fontScale: 100,
    elementsSpacing: 100,
    titleLineSpacing: 100,
    speakersLineSpacing: 100,
    descriptionLineSpacing: 100,
    fontFamily: 'Open Sans',
    themeColor: {
      hex: '#E5E5E5',
      rgb: { r: 229, g: 229, b: 229, a: 100 },
    },
    fontColor: {
      hex: '#000',
      rgb: { r: 0, g: 0, b: 0, a: 100 },
    },
    hasBottomBar: false,
    bottomBarText: '#MyEvent2019',
    bottomBarImg: null,
    sizes: {
      insidePlaylist: 60,
      bottomBar: 0,
    },
  },
};

class CreatePlaylistForm extends React.Component {
  state = {
    isEditMode: false,
  };

  handleNameChange = () => {
    const { formValues } = this.props;
    const normalizedName = removeSpaces(formValues.name);

    if (normalizedName.length !== 0) {
      this.props.createPlaylist({ ...formValues, name: normalizedName });
    }
  };

  handleConfirmChangeType = selectedType => {
    this.props.change('type', selectedType);
    this.props.change('options', initialOptions[selectedType]);
    this.handleToggleEditMode();
  };

  handleToggleEditMode = () => this.setState(({ isEditMode }) => ({ isEditMode: !isEditMode }));

  render() {
    const { isEditMode } = this.state;
    const { formValues } = this.props;
    const renderPlaylistType = getRenderPlaylistType(formValues.type);
    const renderTypeEditor = (
      <PlaylistTypeEditor
        selectedType={formValues.type}
        onCancel={this.handleToggleEditMode}
        onConfirm={this.handleConfirmChangeType}
      />
    );

    const playlistProps = {
      ...this.props,
      onToggleEditMode: this.handleToggleEditMode,
      onChangeName: this.handleNameChange,
    };
    return isEditMode ? renderTypeEditor : renderPlaylistType(playlistProps);
  }
}

CreatePlaylistForm.propTypes = {
  formValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(playlistTypes).isRequired,
    options: PropTypes.object.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  createPlaylist: PropTypes.func.isRequired,
  isCreatingPlaylist: PropTypes.bool.isRequired,
  createPlaylistAndContentGroup: PropTypes.func.isRequired,
};

const formName = 'createPlaylistForm';

const mapStateToProps = state => {
  const initialValues = {
    name: generateEntityName(getPlaylists(state), 'Playlist'),
    type: templateTypes.DEFAULT,
    options: initialOptions[templateTypes.DEFAULT],
  };

  const formValues = getFormValues(formName)(state) || {};
  const playlists = getDefaultPlaylists(state);
  const programmes = getProgrammes(state);

  return {
    playlists,
    programmes,
    formValues,
    initialValues,
    isCreatingPlaylist: getIsCreatingPlaylist(state),
  };
};

export default connect(
  mapStateToProps,
  playlistsActions,
)(reduxForm({ form: formName })(CreatePlaylistForm));
