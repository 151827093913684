import * as types from './types';

// sync
export const setSessions = sessions => ({ type: types.SET_SESSIONS, payload: sessions });
export const setSessionModal = (modalToggler, modalType, id, date) => ({
  type: types.SET_SESSION_MODAL,
  payload: { modalToggler, modalType, id, date },
});

export const updateExternalSessions = payload => ({
  type: types.UPDATE_EXTERNAL_SESSIONS,
  payload,
});

export const sessionRemoveStart = sessionId => ({ type: types.SESSION_REMOVE_START, payload: sessionId });
export const sessionRemoveEnd = (sessionId, programmeId) => ({
  type: types.SESSION_REMOVE_END,
  payload: { sessionId, programmeId },
});

// async
export const fetchCreateSession = (value, isAddAnother) => ({
  type: types.FETCH_CREATE_SESSION,
  payload: { value, isAddAnother },
});
export const fetchRemoveSession = sessionId => ({
  type: types.FETCH_REMOVE_SESSION,
  payload: { sessionId },
});
export const fetchEditSession = value => ({ type: types.FETCH_EDIT_SESSION, payload: value });
export const fetchRemoveSessionsByDate = (programmeId, date) => ({
  type: types.FETCH_REMOVE_SESSION_BY_DATE,
  payload: { date, programmeId },
});
