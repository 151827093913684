// sync types
export const SET_PROGRAMMES = 'programmes/SET_PROGRAMMES';
export const CREATE_NEW_THEATRE_START = 'programmes/CREATE_NEW_THEATRE_START';
export const CREATE_NEW_THEATRE_END = 'programmes/CREATE_NEW_THEATRE_END';

// async types
export const CREATE_NEW_THEATRE = 'programmes/CREATE_NEW_THEATRE';
export const REMOVE_THEATRE = 'programmes/REMOVE_THEATRE';
export const FETCH_CHANGE_THEATRE_NAME = 'programmes/FETCH_CHANGE_THEATRE_NAME';
export const FETCH_CHANGE_THEATRE_HTML_TITLE = 'programmes/FETCH_CHANGE_THEATRE_HTML_TITLE';
export const FETCH_CHANGE_THEATRE_OPTIONS = 'programmes/FETCH_CHANGE_THEATRE_OPTIONS';
export const FETCH_CHANGE_THEATRE_NAME_END = 'programmes/FETCH_CHANGE_THEATRE_NAME_END';
export const FETCH_CREATE_THEATRE_OPEN_SESSION = 'session/FETCH_CREATE_THEATRE_OPEN_SESSION';
export const CHANGE_THEATRE_IMAGE = 'programmes/CHANGE_THEATRE_IMAGE';
export const CHANGE_THEATRE_IMAGE_END = 'programmes/CHANGE_THEATRE_IMAGE_END';
export const UPDATE_PROGRAMME_SUCCESS = 'programmes/UPDATE_PROGRAMME_SUCCESS';
export const TOGGLE_SYNC_OF_PROGRAMME = 'programmes/TOGGLE_SYNC_OF_PROGRAMME';

