import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './LayoutConfirmDeletePlaylistScreens.module.scss';
import { appActions } from '../../../state/ducks/app';
import { playlistsActions } from '../../../state/ducks/playlists';
import { screensActions } from '../../../state/ducks/screens';
import { contentActions } from '../../../state/ducks/content';
import { programmesActions } from '../../../state/ducks/programmes';
import { screenSizesActions } from '../../../state/ducks/screenSizes';
import { getPlaylists } from '../../../state/ducks/playlists/selectors';

const { setShowConfirmModalPlaylistScreens, setShowConfirmModalScreenSizes, setShowConfirmModalPlaylist } = appActions;
const { fetchDeletePlaylist } = playlistsActions;
const { fetchDeleteContent } = contentActions;
const { fetchDeleteScreen } = screensActions;
const { fetchRemoveScreenSizes } = screenSizesActions;
const { removeTheatreProgram } = programmesActions;
class LayoutConfirmDeletePlaylistScreens extends React.Component {
  getActionType = () => {
    const { setShowConfirmModalPlaylistScreens, confirmModal } = this.props;
    const fetchAction = this.props[confirmModal.fetchAction];
    fetchAction(confirmModal.id);
    setShowConfirmModalPlaylistScreens(false, {});
  };

  getActionTypeScreenSize = () => {
    const {
      confirmModalScreenSizesData: { fetchAction, screenSizeId },
      setShowConfirmModalScreenSizes,
    } = this.props;
    const getActionName = this.props[fetchAction];
    getActionName(screenSizeId);
    setShowConfirmModalScreenSizes(false, {});
  };

  render() {
    const {
      showConfirmModal,
      showConfirmModalScreenSizes,
      confirmModal,
      confirmModalScreenSizesData,
      setShowConfirmModalPlaylistScreens,
      showConfirmModalPlaylist,
      setShowConfirmModalPlaylist,
      confirmModalPlaylistData,
      playlists,
      confirmModalPlaylistData: { id: playlistId },
    } = this.props;
    const { canDelete = true } = confirmModal;
    const playlist = playlists.find(({ id }) => id === playlistId);

    return (
      <>
        {/* confirm modal for the rest */}
        <div className={showConfirmModal ? styles.containerActive : styles.container}>
          <div className={styles.opacityBackground} onClick={() => setShowConfirmModalPlaylistScreens(false, {})} />
          <div className={styles.confirmWindow}>
            <div className={styles.warnBody}>
              <p className={styles.warningStyle}>{confirmModal.title}</p>
            </div>
            <div className={styles.buttonGroup}>
              {canDelete && (
                <>
                  <button
                    type="button"
                    className={styles.buttonStyleCancel}
                    onClick={() => setShowConfirmModalPlaylistScreens(false, {})}
                  >
                    No
                  </button>
                  <button type="button" className={styles.buttonStyle} onClick={this.getActionType}>
                    Yes
                  </button>
                </>
              )}
              {!canDelete && (
                <button
                  type="button"
                  className={styles.buttonStyle}
                  onClick={() => setShowConfirmModalPlaylistScreens(false, {})}
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>

        {/* confirm modal for delete screen size */}
        <div className={showConfirmModalScreenSizes ? styles.containerActive : styles.container}>
          <div className={styles.opacityBackground} onClick={() => setShowConfirmModalPlaylistScreens(false, {})} />
          <div className={styles.confirmWindowScreenSizes}>
            <div className={styles.messageBlock}>
              <p className={styles.warningStyle}>{confirmModalScreenSizesData.title}</p>
            </div>
            <div className={styles.screensWrapper}>
              {confirmModalScreenSizesData.usedScreens &&
                confirmModalScreenSizesData.usedScreens.map(el => (
                  <div className={styles.screenElement} key={el.id}>
                    {el.name}
                  </div>
                ))}
            </div>
            <div className={styles.buttonGroup}>
              <button
                type="button"
                className={styles.buttonStyleCancel}
                onClick={() => this.props.setShowConfirmModalScreenSizes(false, {})}
              >
                No
              </button>
              <button type="button" className={styles.buttonStyle} onClick={this.getActionTypeScreenSize}>
                Yes
              </button>
            </div>
          </div>
        </div>

        {/* confirm modal for delete playlist */}
        <div className={showConfirmModalPlaylist ? styles.containerActive : styles.container}>
          <div className={styles.opacityBackground} onClick={() => setShowConfirmModalPlaylist(false, {})} />
          <div className={styles.confirmWindowScreenSizes}>
            <div className={styles.messageBlock}>
              <p className={styles.warningStyle}>
                Playlist {`"${playlist && playlist.name}"`} is being used by{' '}
                <b>{confirmModalPlaylistData.screenNames}</b>. Please assign another playlist to{' '}
                <b>{confirmModalPlaylistData.screenNames}</b>, before deleting this playlist.
              </p>
            </div>
            <div className={styles.buttonGroup}>
              <button
                type="button"
                className={styles.buttonStyle}
                onClick={() => setShowConfirmModalPlaylist(false, {})}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

LayoutConfirmDeletePlaylistScreens.propTypes = {
  confirmModalPlaylistData: PropTypes.shape({ id: PropTypes.number, screenNames: PropTypes.string }),
  confirmToggler: PropTypes.bool,
  confirmStatus: PropTypes.bool,
  notificationContent: PropTypes.string,
  setConfirmStatus: PropTypes.func,
  showConfirmModalPlaylist: PropTypes.bool,
  confirmModalScreenSizesData: PropTypes.object,
  setShowConfirmModalScreenSizes: PropTypes.func,
  setShowConfirmModalPlaylistScreens: PropTypes.func,
  setShowConfirmModalPlaylist: PropTypes.func,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setShowConfirmModalPlaylistScreens,
      setShowConfirmModalScreenSizes,
      setShowConfirmModalPlaylist,
      fetchDeletePlaylist,
      fetchDeleteScreen,
      fetchDeleteContent,
      fetchRemoveScreenSizes,
      removeTheatreProgram,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  playlists: getPlaylists(state),
  showConfirmModal: state.app.showConfirmModalPlaylistScreens,
  showConfirmModalPlaylist: state.app.showConfirmModalPlaylist,
  showConfirmModalScreenSizes: state.app.showConfirmModalScreenSizes,
  confirmModal: state.app.confirmModalPlaylistScreens,
  confirmModalScreenSizesData: state.app.confirmModalScreenSizesData,
  confirmModalPlaylistData: state.app.confirmModalPlaylistData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutConfirmDeletePlaylistScreens);
