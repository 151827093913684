import * as types from './types';
// import { contentGroupAction } from ".";

export const createPlaylistAndAddContentMeta = payload => ({
  type: types.CREATE_PLAYLIST_AND_ADD_CONTENT_META,
  payload,
});

export const fetchContentGroupStart = () => ({ type: types.FETCH_CONTENT_GROUP_START });
export const fetchContentGroupFinished = () => ({ type: types.FETCH_CONTENT_GROUP_FINISHED });
// sync
export const setContentGroup = contentGroup => ({ type: types.SET_CONTENT_GROUP, payload: contentGroup });
export const setContentTypeFilter = type => ({ type: types.SET_CONTENT_TYPE_FILTER, payload: type });
export const setContentGroupToCheckList = checkLists => ({
  type: types.SET_CONTENT_GROUP_TO_CHECK_LIST,
  payload: checkLists,
});
export const setScreenSizeId = screenSizeId => ({ type: types.SET_SCREEN_SIZE_ID, payload: screenSizeId });

// async
export const fetchCreateContentGroup = payload => ({
  type: types.FETCH_CREATE_CONTENT_GROUP,
  payload,
});

export const fetchMultipleCreateContentGroup = (uploadFiles, isCustomHtml) => ({
  type: types.FETCH_MULTIPLE_CREATE_CONTENT_GROUP,
  payload: { uploadFiles, isCustomHtml },
});

export const fetchCreateContentGroupSetToPlaylist = fileData => ({
  type: types.FETCH_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST,
  payload: fileData,
});

export const fetchMultipleCreateContentGroupSetToPlaylist = (uploadFiles, playlistId) => ({
  type: types.FETCH_MULTIPLE_CREATE_CONTENT_GROUP_SET_TO_PLAYLIST,
  payload: { uploadFiles, playlistId },
});

export const fetchDeleteContentGroup = contentGroupId => ({
  type: types.FETCH_DELETE_CONTENT_GROUP,
  payload: { contentGroupId },
});

export const fetchArrDeleteContentGroup = arrIds => ({
  type: types.FETCH_ARR_DELETE_CONTENT_GROUP,
  payload: arrIds,
});

export const fetchAddContentGroupsToNotExistPlaylistFromMedia = (acceptedFiles, name, screenSizeId) => ({
  type: types.FETCH_ADD_CONTENT_GROUPS_NOTEXIST_PLAYLIST_FROM_MEDIA,
  payload: {
    acceptedFiles,
    name,
    screenSizeId,
  },
});

export const fetchAddContentGroupsToExistPlaylistFromMedia = (acceptedFiles, playlistId) => ({
  type: types.FETCH_ADD_CONTENT_GROUPS_EXIST_PLAYLIST_FROM_MEDIA,
  payload: {
    acceptedFiles,
    playlistId,
  },
});

export const fetchUpdatePlaylistContentGroup = (playlistId, entityId, order, duration) => ({
  type: types.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP,
  payload: { playlistId, entityId, order, duration },
});

export const fetchCreateContentGroupSetToNotExistPlaylist = (uploadFiles, name, screenSizeId) => ({
  type: types.FETCH_CREATE_CONTENT_GROUP_SET_TO_NOTEXIST_PLAYLIST,
  payload: { uploadFiles, name, screenSizeId },
});

export const fetchReorderContentGroupMeta = (playlistId, newMetaOrder) => ({
  type: types.FETCH_REORDER_CONTENT_GROUP_META,
  payload: { playlistId, newMetaOrder },
});

export const fetchCreateRelationForContentWithContentGroup = fileData => ({
  type: types.FETCH_CREATE_RELATION_FOR_CONTENT_WITH_CONTENT_GROUP,
  payload: fileData,
});
export const fetchAddContentToContentGroup = (entityId, currentScreenSize, uploadFile) => ({
  type: types.FETCH_ADD_CONTENT_TO_CONTENT_GROUP,
  payload: { entityId, currentScreenSize, uploadFile },
});
export const fetchRemoveContentFromContentGroup = (contentGroupId, contentId) => ({
  type: types.FETCH_REMOVE_CONTENT_FROM_CONTENT_GROUP,
  payload: { contentGroupId, contentId },
});

export const fetchAbortUploadFile = uniqueKey => ({
  type: types.FETCH_ABORT_UPLOAD_FILE,
  payload: { uniqueKey },
});

export const fetchCreateUrlContentGroup = (urlData, urlName) => ({
  type: types.FETCH_CREATE_URL_CONTENT_GROUP,
  payload: { urlData, urlName },
});

export const addFullProgrammeToPlaylistStart = () => ({
  type: types.ADD_FULL_PROGRAMME_TO_PLAYLIST_START,
});

export const addFullProgrammeToPlaylistEnd = playlistId => ({
  type: types.ADD_FULL_PROGRAMME_TO_PLAYLIST_END,
  payload: { playlistId },
});

export const addFullProgrammeToPlaylist = payload => ({
  type: types.ADD_FULL_PROGRAMME_TO_PLAYLIST,
  payload,
});

export const addPlaylistToPlaylistStart = () => ({
  type: types.ADD_PLAYLIST_TO_PLAYLIST_START,
});

export const addPlaylistToPlaylistEnd = () => ({
  type: types.ADD_PLAYLIST_TO_PLAYLIST_END,
});

export const addPlaylistToPlaylist = payload => ({
  type: types.ADD_PLAYLIST_TO_PLAYLIST,
  payload,
});
