import { checkSelector } from 'reselect-tools';
import { createSelector } from 'reselect';
// import {  }

export const contentGroup$ = state => state.contentGroup.contentGroup;
const contentTypeFilter$ = state => state.contentGroup.contentTypeFilter;
const contentGroupMeta$ = state => state.contentMeta.contentMeta;
const playlistId$ = state => state.content.mediaLibraryModal.data.id;
const contents = state => state.content.contents;

export const getAllImages = createSelector(
  contentGroup$,
  contentGroup => contentGroup.filter(({ type }) => type === 'image'),
);

export const getContentGroup = createSelector(
  [contentGroup$, contentTypeFilter$],
  (contentGroup, contentTypeFilter) => {
    if (contentTypeFilter === 'multiply') {
      return contentGroup;
    }
    return contentGroup.filter(item => item.type === contentTypeFilter);
  },
);

export const getFilteredContentGroup = createSelector(
  [getContentGroup, contentGroupMeta$, playlistId$],
  (contentGroups, contentGroupsMeta, playlistId) => {
    if (playlistId) {
      const getPlaylistContentGroup = contentGroupsMeta.filter(cm => cm.playlistId === playlistId);
      const filter = contentGroups.filter(cg => !getPlaylistContentGroup.find(pcg => pcg.contentGroupId === cg.id));
      return filter;
    }

    return contentGroups;
  },
);

export const getChildContent = createSelector(
  [getContentGroup, contentGroupMeta$, playlistId$],
  (contentGroups, contentGroupsMeta, playlistId) => {
    if (playlistId) {
      const getPlaylistContentGroup = contentGroupsMeta.filter(cm => cm.playlistId === playlistId);
      const filter = contentGroups.filter(cg => !getPlaylistContentGroup.find(pcg => pcg.contentGroupId === cg.id));
      return filter;
    }

    return contentGroups;
  },
);

export const selector = state => state;

checkSelector(getContentGroup);
