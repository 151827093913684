import React from 'react';
import PropTypes from 'prop-types';
import { upperFirst } from 'lodash';
import moment from 'moment';
import styles from './Request.module.scss';

function Request(props) {
  const { data } = props;
  const { rowsUpdated, took = 0, updated, url, status } = data;

  return (
    <div className={styles.request}>
      <span className={styles.requestTime}>{moment(updated, 'DD-MM-YYYY HH:mm:ss').format('HH:mm:ss')}</span>
      <span className={styles.requestUrl}>{url}</span>
      <span className={styles.requestStatus}>Status: {upperFirst(status)}</span>
      <div className={styles.requestMeta}>
        <span className={styles.took}>Took: {took} sec</span>
        <span className={styles.recUpdated}>{rowsUpdated} records updated</span>
      </div>
    </div>
  );
}

Request.propTypes = {
  id: PropTypes.number,
  data: PropTypes.shape({
    took: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rowsUpdated: PropTypes.number,
    updated: PropTypes.string,
  }),
};

export default Request;
