import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './SettingsButtons.module.scss';

export default function SettingsButtons({ handleOnEdit, handleOnRemove, customClassEditBtn, customClassDeleteBtn }) {
  return (
    <div className={styles.menu}>
      <button
        type="button"
        className={cn(styles.menuButton, styles.menuButtonEdit, customClassEditBtn && customClassEditBtn)}
        onClick={handleOnEdit}
      />
      <button
        type="button"
        className={cn(styles.menuButton, styles.menuButtonDelete, customClassDeleteBtn && customClassDeleteBtn)}
        onClick={handleOnRemove}
      />
    </div>
  );
}

SettingsButtons.propTypes = {
  handleOnEdit: PropTypes.func.isRequired,
  handleOnRemove: PropTypes.func.isRequired,
  customClassEditBtn: PropTypes.string,
  customClassDeleteBtn: PropTypes.string,
};
