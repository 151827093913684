import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
// import './react-datepicker.css';

// import 'react-datepicker/dist/react-datepicker.min.css';
import './react-datepicker.css';

import styles from './DatePicker.module.scss';

import Input from './Input';

// See the doc https://reactdatepicker.com/#example-43
class DatePicker extends React.Component {
  constructor() {
    super();
    this.rootRef = React.createRef();
  }

  componentDidMount() {
    const {
      defaultValue,
      input: { value },
    } = this.props;

    if (!value && defaultValue) {
      this.handleChange(new Date(defaultValue));
    }
  }

  handleChange = date => {
    // this.props.input.onChange(date.toISOString());
    this.props.input.onChange(moment(date).format('YYYY-MM-DD'));
  };

  render() {
    const {
      input,
      meta,
      className,
      placeholder,
      inputClassName,
      isShowCalendarIcon,
      inputContainerClassName,
      customFormat,
      minDate,
    } = this.props;

    return (
      <div ref={this.rootRef} className={cn(styles.container, className && className)}>
        <div className={cn(styles.inputContainer, inputContainerClassName && inputContainerClassName)}>
          <ReactDatePicker
            minDate={minDate && minDate}
            dateFormat={'d MMMM yyyy'}
            popperClassName="some-custom-class"
            popperPlacement="auto-right"
            customInput={
              <Input
                isShowCalendarIcon={isShowCalendarIcon}
                inputClassName={inputClassName}
                onCustomFocus={input.onFocus}
                customFormat={customFormat}
                onCustomBlur={() => {
                  input.onBlur();
                }}
              />
            }
            onChange={this.handleChange}
            selected={input.value ? moment(input.value).format() : null}
            placeholderText={placeholder || moment(new Date()).format('D MMMM YYYY')}
          />
        </div>
        {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
      </div>
    );
  }
}

DatePicker.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.object,
  customFormat: PropTypes.string,
  minDate: PropTypes.object,
  isShowCalendarIcon: PropTypes.bool,
};

export default DatePicker;
