import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styles from './AddContentChild.module.scss';
import { contentActions } from '../../../../../../state/ducks/content';
import AddContentChildDropZone from '../AddContentChildDropZone';
import { getScreenDimention } from '../../../../../../state/utils/create-file-helper';
import UploadSpinner from '../../UploadSpinner';

const { setUploadedContent } = contentActions;

class AddContentChild extends React.Component {
  render() {
    const { screenSizes, entityId } = this.props;

    return (
      <>
        {screenSizes.map((ss, index) => (
          <div key={`screenSize-${index}`} className={styles.dropZoneWrapper}>
            <AddContentChildDropZone
              screenSize={ss}
              screenSizes={screenSizes}
              entityId={entityId}
              currentScreenSize={ss.id}
            />
          </div>
        ))}
      </>
    );
  }
}

AddContentChild.propTypes = {
  screenSizes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      default: PropTypes.bool.isRequired,
      resolutionWidth: PropTypes.number.isRequired,
      resolutionHeight: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  currentScreenSize: PropTypes.number,
};

const mapStateToProps = state => ({
  contents: state.content.contents,
  screenSizeId: state.contentGroup.screenSizeId,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUploadedContent,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddContentChild);
