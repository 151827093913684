import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import routes from "../../../routes";
import styles from "./LayoutView.module.scss";
import Header from "../LayoutHeader";
import NotificationSystem from "../NotificationSystem";
import Sidebar from "../LayoutSidebar";
import LayoutConfirmDeletePlaylistScreens from "../LayoutConfirmDeletePlaylistScreens";
import LayoutConfirmDuplicateModal from "../LayoutConfirmDuplicateModal";
import LayoutMediaLibraryModal from "../LayoutMediaLibraryModal/LayoutMediaLibraryModal";
import LayoutPreviewModal from "../LayoutPreviewModal";
import ConfirmModal from "../../common/components/ConfirmModal/ConfirmModal";

const Layout = ({
  isAuthenticated,
  logout,
  notification,
  showNotification,
  user,
  generatePasscode,
  isLoadingCreateContent,
  isLoadingContent,
  confirmModalPlaylistScreens,
}) => (
  <div className={styles.containerMain}>
    {isAuthenticated && (
      <Header
        logout={logout}
        isAuthenticated={isAuthenticated}
        showNotification={showNotification}
        accessCode={user.accessCode}
      />
    )}
    <div className={styles.contentWrapper}>
      {isAuthenticated && (
        <Sidebar
          user={user}
          generatePasscode={generatePasscode}
          isLoadingCreateContent={isLoadingCreateContent}
          isLoadingContent={isLoadingContent}
        />
      )}
      <div className={styles.content}>
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </div>
    </div>
    <NotificationSystem
      notification={notification}
      showNotification={showNotification}
    />
    <LayoutConfirmDeletePlaylistScreens
      confirmModalPlaylistScreens={confirmModalPlaylistScreens}
    />
    <LayoutConfirmDuplicateModal />
    <LayoutMediaLibraryModal />
    <LayoutPreviewModal />
    <ConfirmModal />
  </div>
);

Layout.propTypes = {
  user: PropTypes.object.isRequired,
  generatePasscode: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  notification: PropTypes.object.isRequired,
  showNotification: PropTypes.func.isRequired,
};

export default Layout;
