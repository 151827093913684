import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './MediaLibrary.module.scss';
import AddWebSiteForm from '../AddWebSiteForm';
import MediaElement from './MediaElement';
import FilterBtn from './FilterBtn';
import { getProgressItemsForMediaLibrary } from '../../../../../../../utils';
import FroalaEditor from '../FroalaEditor';
import ProgressItems from '../../../../ProgressItems';
import templatesFroala from '../FroalaEditor/templates';
import MediaPlaylist from '../MediaPlaylist';
import Icons from '../../../Icons';
import Modal from '../../../../Modal';
import Icon from '../../../Icon';

const filterNames = {
  MULTIPLY: 'multiply',
  PLAYLIST: 'playlist',
  IMAGE: 'image',
  VIDEO: 'video',
  HTML: 'html',
  URL: 'url',
};

const filters = [
  [filterNames.MULTIPLY, 'All'],
  [filterNames.VIDEO, 'Video'],
  [filterNames.HTML, 'Message'],
  [filterNames.IMAGE, 'Image'],
  [filterNames.URL, 'Website'],
  [filterNames.PLAYLIST, 'Playlist'],
];

class MediaLibrary extends React.Component {
  renderContent = () => {
    const {
      contents,
      isHtmlArea,
      mediaFiles,
      contentFilter,
      onDeleteContent,
      onSelectContent,
      contentsProgress,
      playlistsContents,
      handleChangeHtmlArea,
      addContentToPlaylist,
      handleCleanFieldsOfHtmlEditor,
      addMediaFilesToPlaylist,
      playlists,
      // addPlaylistToPlaylist,
    } = this.props;

    const isMultiplyFilter = contentFilter === filterNames.MULTIPLY;
    const isHtmlFilter = contentFilter === 'html';
    const isPlaylistFilter = contentFilter === 'playlist';
    const isHtmlEditorOpen = isHtmlArea && isHtmlFilter;
    const progressItems = getProgressItemsForMediaLibrary(contentsProgress, contentFilter);

    if (isHtmlEditorOpen) {
      return (
        <Modal
          isOpen={true}
          isCloseOutside={false}
          onClose={() => {
            handleChangeHtmlArea(false);
            handleCleanFieldsOfHtmlEditor();
          }}
        >
          <FroalaEditor {...this.props} />
        </Modal>
      );
    }

    const renderPlaylists = (
      <React.Fragment>
        {playlists.map(playlist => (
          <MediaPlaylist
            key={`playlist${playlist.id}`}
            item={playlist}
            playlist={playlist}
            addContent={() => addMediaFilesToPlaylist([{ id: playlist.id, type: 'playlist' }])}
            deleteContent={() => onDeleteContent({ id: playlist.id, type: 'playlist' })}
            selectContent={() => onSelectContent({ id: playlist.id, type: 'playlist' })}
            playlistsContents={playlistsContents}
            addContentToPlaylist={addContentToPlaylist}
            isSelected={!!mediaFiles.find(({ id, type }) => type === 'playlist' && id === playlist.id)}
          />
        ))}
      </React.Fragment>
    );

    return (
      <form className={styles.contentContainer}>
        {isPlaylistFilter && renderPlaylists}
        {!isHtmlEditorOpen && !isPlaylistFilter && (
          <React.Fragment>
            <ProgressItems items={progressItems} itemStyle={{ paddingLeft: 74 }} />
            {contents.map(item => (
              <MediaElement
                key={`contentGroup${item.id}`}
                item={item}
                addContent={() => addMediaFilesToPlaylist([{ id: item.id, type: 'contentGroup' }])}
                deleteContent={() => onDeleteContent({ id: item.id, type: 'contentGroup' })}
                selectContent={() => onSelectContent({ id: item.id, type: 'contentGroup' })}
                playlistsContents={playlistsContents}
                addContentToPlaylist={() => addContentToPlaylist({ id: item.id, type: 'contentGroup' })}
                isSelected={!!mediaFiles.find(({ id, type }) => type === 'contentGroup' && id === item.id)}
              />
            ))}
            {isMultiplyFilter && renderPlaylists}
          </React.Fragment>
        )}
      </form>
    );
  };

  renderFooter() {
    const { mediaFiles, addContentToPlaylist, deleteContentFromMediaLibrary } = this.props;
    const isSelectedMediaFiles = mediaFiles.length !== 0;

    if (!isSelectedMediaFiles) {
      return null;
    }

    return (
      <div className={styles.footer}>
        <button type="button" className={styles.trashBtn} onClick={deleteContentFromMediaLibrary}>
          <span className={styles.icon} />
        </button>
        <button type="button" className={styles.confirmBtn} onClick={addContentToPlaylist}>
          <Icon name="plusWhite" size={24} marginRight={8} />
          Add to Playlist
        </button>
      </div>
    );
  }

  renderDropZoneTip() {
    const { getInputProps, open } = this.props;
    return (
      <div className={styles.dropZone} onClick={open}>
        <input {...getInputProps()} />
        <p className={styles.dropZoneText}>
          Drag and drop files or <span>select files</span>
        </p>
      </div>
    );
  }

  renderHtmlFilter = () => {
    const { handleChangeHtmlArea, handleSetHtmlTemplate } = this.props;

    return (
      <React.Fragment>
        <div className={styles.addNewMessageBtnContainer}>
          <button
            className={styles.addNewMessageBtn}
            type="button"
            onClick={() => {
              handleChangeHtmlArea(true);
              handleSetHtmlTemplate(null, templatesFroala.defaultTemplate);
            }}
          >
            <Icons icon="pencil" widthIcons={16} marginRight={10} />
            New Message
          </button>
        </div>
        {this.renderContent()}
      </React.Fragment>
    );
  };

  renderUrlFilter = () => (
    <React.Fragment>
      <AddWebSiteForm />
      {this.renderContent()}
    </React.Fragment>
  );

  renderFilter = filter => {
    switch (filter) {
      case filterNames.MULTIPLY:
      case filterNames.PLAYLIST:
      case filterNames.IMAGE:
      case filterNames.VIDEO:
        return (
          <React.Fragment>
            {this.renderContent()}
            {this.renderDropZoneTip()}
          </React.Fragment>
        );
      case filterNames.HTML:
        return this.renderHtmlFilter();
      case filterNames.URL:
        return this.renderUrlFilter();
      default:
        return null;
    }
  };

  render() {
    const { contentFilter, onClickSetContentFilter } = this.props;

    const isHtmlFilter = contentFilter === 'html';

    return (
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={cn(styles.filters, isHtmlFilter && styles.filtersIsHtml)}>
            {filters.map(([name, title]) => (
              <FilterBtn
                key={name}
                name={name}
                title={title}
                onClick={onClickSetContentFilter}
                isActive={contentFilter === name}
              />
            ))}
          </div>
          {this.renderFilter(contentFilter)}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

MediaLibrary.propTypes = {
  contents: PropTypes.array,
  isHtmlArea: PropTypes.bool.isRequired,
  mediaFiles: PropTypes.array.isRequired,
  deleteContent: PropTypes.func,
  contentFilter: PropTypes.string,
  selectContent: PropTypes.func,
  handleSendHtml: PropTypes.func.isRequired,
  contentsProgress: PropTypes.object,
  playlistsContents: PropTypes.array,
  htmlEditorContent: PropTypes.string.isRequired,
  handleChangeHtmlArea: PropTypes.func.isRequired,
  addContentToPlaylist: PropTypes.func.isRequired,
  addPlaylistToPlaylist: PropTypes.func.isRequired,
  handleSetHtmlTemplate: PropTypes.func.isRequired,
  handleOnChangeAceEditor: PropTypes.func.isRequired,
  addMediaFilesToPlaylist: PropTypes.func.isRequired,
  onClickSetContentFilter: PropTypes.func.isRequired,
  cleanUpOfLinkedContentMeta: PropTypes.func.isRequired,
  handleShowPreviewOfContent: PropTypes.func.isRequired,
  deleteContentFromMediaLibrary: PropTypes.func.isRequired,
  handleCleanFieldsOfHtmlEditor: PropTypes.func.isRequired,
};

export default MediaLibrary;
