import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createDataFeed } from '../../../../../../../state/ducks/dataFeeds/actions';
import Modal from '../../../../../../common/components/Modal';
import Form from '../Form';
import { safeGet } from '../../../../../../../utils';
import generateEntityName from '../../../../../../../utils/generateName';

const formName = 'create-feed';
const CreateFeedForm = compose(
  connect(
    state => ({
      formValues: getFormValues(formName)(state) || {},
    }),
    { onSubmit: createDataFeed },
  ),
  reduxForm({ form: formName }),
)(Form);

function CreateFeedModal({ isOpen, onClose, dataFeedApis, dataFeedList, isLoading }) {
  const initialValues = {
    options: {
      frequency: 5,
      sync: true,
    },
    dataFeedApiId: safeGet(() => dataFeedApis[0].id),
    name: generateEntityName(dataFeedList, 'Data Feed'),
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scroll="modal">
      <CreateFeedForm
        initialValues={initialValues}
        dataFeedApis={dataFeedApis}
        onCancel={onClose}
        isLoading={isLoading}
      />
    </Modal>
  );
}

CreateFeedModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dataFeedList: PropTypes.array.isRequired,
  dataFeedApis: PropTypes.array.isRequired,
};

export default CreateFeedModal;
