import {
  put,
  call,
  takeLatest,
  select,
  all,
  takeEvery,
} from "redux-saga/effects";
import _ from "lodash";
import * as types from "./types";
import * as actions from "./actions";
import {
  storage,
  fetch,
  apiRoutes,
  sentryReport,
  getErrorMessage,
  history,
  mixpanel,
} from "../../utils";
import { showNotification } from "../app/actions";
import { contentMetaActions } from "../contentMeta";
import {
  setContentGroup,
  fetchReorderContentGroupMeta,
  fetchCreateContentGroupSetToPlaylist,
  fetchUpdatePlaylistContentGroup,
  addFullProgrammeToPlaylist,
} from "../contentGroup/actions";
import { getPlaylists } from "./selectors";
import { getFileType, getProcessedFile } from "../../utils/create-file-helper";
// import { fetchUpdatePlaylistContentGroupFlow } from '../contentGroup/sagas';
import delay from "../../utils/dalay-for-sagas";
import { getFormatedPlaylist } from "./utils";
import {
  getContentGroupMediaFiles,
  getPlaylistMediaFiles,
} from "../../../utils";
// import { addPlaylistToPlaylist } from '../contentGroup/sagas';

const { setContentMeta } = contentMetaActions;

const {
  fetchPlaylistsStart,
  fetchPlaylistsEnd,
  setPlaylists,
  contentRemovalStart,
  contentRemovalEnd,
  fetchRemoveContentGroupFromPlaylist,
  createPlaylistStart,
  createPlaylistEnd,
} = actions;

function* fetchPlaylistsFlow() {
  try {
    yield put(fetchPlaylistsStart());
    const { url, method } = apiRoutes.getPlaylists;
    const user = storage.getUser();
    yield call(fetch, url(user.userId), method);

    yield put(fetchPlaylistsEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(fetchPlaylistsEnd());
  }
}

export function* createPlaylist(formData) {
  const { url, method } = apiRoutes.createPlaylist;
  const user = storage.getUser();
  const newPlaylist = yield call(
    fetch,
    url(user.userId),
    method,
    getFormatedPlaylist(formData)
  );

  mixpanel.track("Create new playlist", formData);

  return newPlaylist;
}

export function* createPlaylistFlow({ payload: formData }) {
  try {
    yield put(createPlaylistStart());
    const newPlaylist = yield call(createPlaylist, formData);
    const playlists = yield select((state) => state.playlists.playlists);
    const updatedPlaylists = [...playlists, newPlaylist];

    yield put(setPlaylists(updatedPlaylists));
    if (history.location.pathname === "/playlists/new") {
      yield call(history.push, `/playlists/${newPlaylist.id}`);
    }

    yield put(createPlaylistEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(createPlaylistEnd());
  }
}

export function* duplicatePlaylistFlow({ payload: formData }) {
  try {
    yield put(createPlaylistStart());
    const newPlaylist = yield call(createPlaylist, formData);
    const playlists = yield select((state) => state.playlists.playlists);

    const contentMeta = yield all(
      formData.contentMeta.map(function*(_contentMeta) {
        console.log("LOL: ", _contentMeta);
        switch (_contentMeta.entityType) {
          case "programme":
            return yield put(
              addFullProgrammeToPlaylist({
                playlistId: newPlaylist.id,
                programmeId: _contentMeta.entityId,
                order: _contentMeta.order,
                duration: _contentMeta.duration,
              })
            );
          case "contentGroup":
            return yield put(
              fetchUpdatePlaylistContentGroup(
                newPlaylist.id,
                _contentMeta.entityId,
                _contentMeta.order,
                _contentMeta.duration
              )
            );
        }
      })
    );

    const updatedPlaylists = [...playlists, { ...newPlaylist }];
    yield put(setPlaylists(updatedPlaylists));
    yield call(history.push, `/playlists/${newPlaylist.id}`);

    yield put(
      showNotification({
        level: "info",
        autoDismiss: 1,
        message: "The playlist was duplicated.",
      })
    );
    yield put(createPlaylistEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(createPlaylistEnd());
  }
}

function* createPlaylistAndContentGroupFlow({ payload: { formData, files } }) {
  yield put(createPlaylistStart());
  try {
    yield put(createPlaylistStart());
    const playlists = yield select(getPlaylists);
    const newPlaylist = yield call(createPlaylist, formData);

    yield all(
      files.map(function* gen(file, order) {
        const fileType = getFileType(file.type);
        const fileData = getProcessedFile(file);

        yield put(
          fetchCreateContentGroupSetToPlaylist({
            order,
            fileData,
            fileType,
            playlistId: newPlaylist.id,
          })
        );
      })
    );

    yield put(setPlaylists([...playlists, newPlaylist]));

    const redirectTo = `/playlists/${newPlaylist.id}`;
    yield call(history.push, `${process.env.PUBLIC_URL}${redirectTo}`);

    yield put(createPlaylistEnd());
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, message }));
    yield put(createPlaylistEnd());
  }
}

export function* updatePlaylistFlow(action) {
  try {
    const { playlistId, formData, withNotification = true } = action.payload;
    const { url, method } = apiRoutes.updatePlaylist;
    const playlists = yield select(getPlaylists);
    const playlist = playlists.find((p) => p.id === playlistId);

    const updatedPlaylist = yield call(
      fetch,
      url(playlistId),
      method,
      getFormatedPlaylist({ ...playlist, ...formData })
    );

    mixpanel.track("Playlist was updated", { ...playlist });

    if (updatedPlaylist && withNotification)
      yield put(
        showNotification({
          level: "info",
          autoDismiss: 1,
          message: "The playlist was updated",
        })
      );

    const updatePlaylists = playlists.map((item) => {
      if (item.id === playlistId) {
        return { ...item, ...updatedPlaylist };
      }
      return item;
    });
    yield put(setPlaylists(updatePlaylists));
    yield put({ type: types.UPDATE_PLAYLIST_END, payload: { playlistId } });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(fetchPlaylistsEnd());
  }
}

function* fetchDeletePlaylistFlow(action) {
  try {
    const playlistId = action.payload;
    yield put(fetchPlaylistsStart());
    const { url, method } = apiRoutes.deletePlaylist;
    const res = yield call(fetch, url(playlistId), method);
    if (res)
      yield put(
        showNotification({
          level: "info",
          autoDismiss: 1,
          message: "The playlist was deleted",
        })
      );
    // set new playlist data to store
    const playlists = yield select((state) => state.playlists.playlists);
    const updatePlaylists = playlists.filter((item) => item.id !== playlistId);
    yield put(setPlaylists(updatePlaylists));
    // set new meta data to store
    const contentMeta = yield select((state) => state.contentMeta.contentMeta);
    const updatedContentMeta = contentMeta
      .filter((meta) => meta.playlistId !== playlistId)
      .filter(
        (meta) =>
          !(meta.entityType === "playlist" && meta.entityId === playlistId)
      );

    yield put(setContentMeta(updatedContentMeta));
    // redirect to playlists
    // const to = `/playlists`;
    // history.push(`${process.env.PUBLIC_URL}${to}`);

    yield put(fetchPlaylistsEnd());
  } catch (error) {
    sentryReport(error);
    console.log("error", error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
    yield put(fetchPlaylistsEnd());
  }
}

function* fetchAddContentToPlaylistFlow(action) {
  try {
    const { playlistId, contentId } = action.payload;
    const { url, method } = apiRoutes.addContentToPlaylist;
    // check what in response
    yield call(fetch, url(playlistId, contentId), method);
    // get screens and update them
    const playlists = yield select((state) => state.playlists.playlists);
    // change screens playlists
    yield put(setPlaylists(playlists));
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
  }
}

function* fetchRemoveContentGroupFromPlaylistFlow(action) {
  try {
    const { playlistId, contentGroupMetaId } = action.payload;
    yield put(contentRemovalStart(contentGroupMetaId));

    const { url, method } = apiRoutes.deletePlaylistContentMeta;

    yield call(fetch, url(playlistId, contentGroupMetaId), method);
    const contentMeta = yield select((state) => state.contentMeta.contentMeta);

    // get current and rest playlist meta
    const currentPlaylistMeta = contentMeta
      .filter((meta) => meta.playlistId === playlistId)
      .filter((meta) => meta.id !== contentGroupMetaId);
    const restPlaylistMeta = contentMeta.filter(
      (meta) => meta.playlistId !== playlistId
    );

    // reorder current playlist
    const sortContentMeta = currentPlaylistMeta.sort(
      (a, b) => a.order - b.order
    );
    const playlistReorder = sortContentMeta.map((meta, index) => ({
      ...meta,
      order: index,
    }));
    yield put(setContentMeta([...restPlaylistMeta, ...playlistReorder]));

    // send request on back end
    const restructuringData = playlistReorder.map((mt) => ({
      order: mt.order,
      contentMetaId: mt.id,
    }));
    yield put(fetchReorderContentGroupMeta(playlistId, restructuringData));

    yield put(contentRemovalEnd(contentGroupMetaId));
    yield put({
      type: types.FETCH_REMOVE_CONTENT_GROUP_FROM_PLAYLIST_END,
      payload: { playlistId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
  }
}

function* fetchMultipleRemoveContentGroupFromPlaylistFlow(action) {
  try {
    const { playlistId, metaIds } = action.payload;

    yield all(
      metaIds.map((metaId) =>
        put(fetchRemoveContentGroupFromPlaylist(playlistId, metaId))
      )
    );
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
  }
}

function* fetchRequestUpdateContentGroup(data) {
  const user = storage.getUser();
  const { name, contentGroupId } = data;
  const { url, method } = apiRoutes.updateContentGroup;
  const response = yield call(fetch, url(user.userId, contentGroupId), method, {
    name,
  });
  return response;
}

function* fetchUpdateContentFk(data, contentId) {
  const user = storage.getUser();
  const { url, method } = apiRoutes.updateContentFk;
  const response = yield call(fetch, url(user.userId, contentId), method, data);
  return response;
}

function* fetchUpdatePlaylistContentGroupMetaFlow(action) {
  try {
    const {
      contentGroupData,
      contentGroupData: {
        name,
        playlistId,
        // contentGroupId,
        id: contentMetaId,
        type,
        content: updatedUrl,
        entityType,
        entityId,
      },
    } = action.payload;

    // request update meta data of content group
    const { url, method } = apiRoutes.updatePlaylistContentMeta;
    const cleanMetaData = _.omit(contentGroupData, [
      "name",
      "durationMin",
      "durationSec",
    ]);
    const resUpdateMeta = yield call(
      fetch,
      url(playlistId, contentMetaId),
      method,
      cleanMetaData
    );
    const getContentGroupMeta = yield select(
      (state) => state.contentMeta.contentMeta
    );
    const updateContentGroupMetaPlaylist = getContentGroupMeta.map((cm) =>
      cm.id === contentMetaId ? resUpdateMeta : cm
    );
    yield put(setContentMeta(updateContentGroupMetaPlaylist));

    switch (entityType) {
      case "contentGroup": {
        const contentGroupId = entityId;
        // request update name of content group
        const resUpdateContentGroupName = yield call(
          fetchRequestUpdateContentGroup,
          {
            name,
            contentGroupId,
          }
        );
        const getContentGroup = yield select(
          (state) => state.contentGroup.contentGroup
        );
        const updateContentGroupName = getContentGroup.map((cg) =>
          cg.id === resUpdateContentGroupName.id
            ? resUpdateContentGroupName
            : cg
        );
        yield put(setContentGroup(updateContentGroupName));

        // update content if we have content type url
        if (type === "url") {
          const getAllContent = yield select((state) => state.content.contents);
          const findCurrentContent = getAllContent.find(
            (content) => content.contentGroupId === contentGroupId
          );
          yield call(
            fetchUpdateContentFk,
            { content: updatedUrl },
            findCurrentContent.id
          );
        }
        break;
      }
      case "playlist": {
        const playlistGroupId = entityId;
        console.log("playlistId=", playlistGroupId);
        break;
      }
      default:
        console.log("something", entityId);
        break;
    }

    yield put({
      type: types.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_META_END,
      payload: { playlistId },
    });
  } catch (error) {
    sentryReport(error);
    const messageError = getErrorMessage(error);
    const { level, message } = messageError;
    yield put(showNotification({ level, autoDismiss: 5, message }));
  }
}

export default [
  takeLatest(types.FETCH_PLAYLISTS, fetchPlaylistsFlow),
  takeLatest(types.DUPLICATE_PLAYLIST, duplicatePlaylistFlow),
  takeLatest(types.CREATE_PLAYLIST, createPlaylistFlow),
  takeLatest(types.UPDATE_PLAYLIST, updatePlaylistFlow),
  takeEvery(types.FETCH_DELETE_PLAYLIST, fetchDeletePlaylistFlow),
  takeLatest(
    types.FETCH_ADD_CONTENT_TO_PLAYLIST,
    fetchAddContentToPlaylistFlow
  ),
  takeEvery(
    types.FETCH_REMOVE_CONTENT_GROUP_FROM_PLAYLIST,
    fetchRemoveContentGroupFromPlaylistFlow
  ),
  takeEvery(
    types.CREATE_PLAYLIST_AND_CONTENT_GROUP,
    createPlaylistAndContentGroupFlow
  ),
  // takeEvery(types.CREATE_PLAYLIST_AND_ADD_CONTENT_GROUP, createPlaylistAndAddContentGroupFlow),
  takeLatest(
    types.FETCH_UPDATE_PLAYLIST_CONTENT_GROUP_META,
    fetchUpdatePlaylistContentGroupMetaFlow
  ),
  takeLatest(
    types.FETCH_MULTIPLE_REMOVE_CONTENT_GROUP_FROM_PLAYLIST,
    fetchMultipleRemoveContentGroupFromPlaylistFlow
  ),
];
